import React, { FC, useCallback, useEffect } from "react";
import {
  Content,
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { IconType } from "enums/Icon";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { KnowledgeDto } from "types/api/dashboard";
import { OpenRightPanel } from "types/common/rightPanel";
import Icon from "../../../../components/Icon/Icon";
import { getKnowledgeBaseHelpers } from "../../../../reduxFolder/reducers/dashboard";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  dashboardSelectIsKbHelpersLoading,
  dashboardSelectKbHelpers,
} from "../../../../reduxFolder/selectors/dashboardSelectors";
import { authSelectLocale } from "../../../../reduxFolder/selectors/globalSelectors";
import { Answer, Spinner, Title } from "./HelpPanel.styles";

interface HelpPanelProps {
  currentLocale: string;
  openRightPanel: OpenRightPanel;
  panelData: any;
  kbHelpersList: any;
  isKbHelpersLoading: any;
  getKnowledgeBaseHelpers: ({
    currentLocale,
  }: KnowledgeDto) => Promise<unknown>;
}

const HelpPanel: FC<HelpPanelProps> = React.memo(
  ({
    panelData,
    openRightPanel,
    kbHelpersList,
    isKbHelpersLoading,
    getKnowledgeBaseHelpers,
    currentLocale,
  }) => {
    const readOnly = panelData.get("readOnly");
    const hideBackButton = panelData.get("hideBackButton");
    const title = panelData.get("headerTitle")
      ? panelData.get("headerTitle")
      : "FAQ";
    const showHelpersCode = panelData.get("showHelpersCode")
      ? panelData.get("showHelpersCode")
      : "packopt";

    const filteredKbHelpersList = kbHelpersList.filter(
      (faqItem: any) => faqItem.get("code") === showHelpersCode,
    );

    const fetchKnowledgeBaseHelpers = async (currentLocale: string) => {
      try {
        await getKnowledgeBaseHelpers({ currentLocale });
      } catch (e) {
        console.error(e);
      }
    };

    useEffect(() => {
      getKnowledgeBaseHelpers({ currentLocale });
      fetchKnowledgeBaseHelpers(currentLocale);
    }, [currentLocale]);

    const openPackOptions = useCallback(
      () =>
        openRightPanel(
          "PACK_OPTIONS",
          panelData
            .set("preventReLoading", true)
            .set("returnScreen", "STORAGE_ITEM")
            .set("readOnly", readOnly),
        ),
      [panelData, openRightPanel, readOnly],
    );

    return (
      <ContentWrapper>
        <Heading $isBordered>
          {!hideBackButton && (
            <button onClick={openPackOptions}>
              <Icon type={IconType.Arrow} />
            </button>
          )}
          <Title>{title}</Title>
        </Heading>
        <Spinner isActive={isKbHelpersLoading && !kbHelpersList.size} />
        {!!filteredKbHelpersList.size && (
          <Content>
            {filteredKbHelpersList.map((faqItem: any) => (
              <Answer
                key={faqItem.get("code")}
                dangerouslySetInnerHTML={{ __html: faqItem.get("content") }}
              />
            ))}
          </Content>
        )}
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    kbHelpersList: dashboardSelectKbHelpers,
    isKbHelpersLoading: dashboardSelectIsKbHelpersLoading,
    currentLocale: authSelectLocale,
  }),
  {
    getKnowledgeBaseHelpers,
    openRightPanel,
  },
);

export default withConnect(HelpPanel);
