import { format } from "date-fns";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getFnsLocale } from "../translations/date-fns-locale";

export const getFormattedDate = (
  date: string | Date,
  locale: string,
  format: string = "DD MMMM, YYYY",
): string => {
  dayjs.locale(locale);
  return dayjs(date).format(format);
};

export type UseFormattedDateProps = {
  date: string;
  hideCurrentYearDisplay?: boolean;
};

export const useFormattedDate = ({
  date,
  hideCurrentYearDisplay,
}: UseFormattedDateProps) => {
  const { i18n } = useTranslation("common");

  const locale = getFnsLocale(i18n.language);
  const currentYear = new Date().getFullYear();
  const convertedDate = format(new Date(date), "dd MMMM, y", {
    locale,
  });

  if (hideCurrentYearDisplay) {
    const splitDate = convertedDate.split(", ");
    return currentYear === parseFloat(splitDate[1])
      ? splitDate[0]
      : convertedDate;
  }

  return convertedDate;
};
