import React, { FC, MouseEvent, useCallback, useMemo } from "react";
import { ButtonColor, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { OpenRightPanel } from "types/common/rightPanel";
import Button from "../../../../components/Button/Button";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { getRequestTypes } from "../../../../reduxFolder/reducers/serviceRequests";
import {
  getShipment,
  normalizeDetailedShipment,
  updateComment,
} from "../../../../reduxFolder/reducers/storage";
import { toastResponseError } from "../../../../utils/responseMessageHelper";
import {
  ProcessedContainer,
  ProcessedIcon,
  ServiceTooltip,
  Status,
  StorageIcon,
} from "./ServiceTooltip.styles";

interface ServiceTooltipItemProps {
  zIndex?: number;
  name: string;
  status: string;
  parcelId: string | number;
  additionalPanelData: any;
  openRightPanel: OpenRightPanel;
  getShipment: (id: string | number) => Promise<any>;
  updateComment: (id: string, data: { customer_comment: any }) => Promise<any>;
  getRequestTypes: () => void;
}

const ServiceTooltipItem: FC<ServiceTooltipItemProps> = React.memo(
  ({
    getShipment,
    updateComment,
    getRequestTypes,
    openRightPanel,
    name,
    status,
    parcelId,
    zIndex = 0,
    additionalPanelData,
  }) => {
    const { t } = useTranslation("common");
    const isProcessed = status === "processed";

    const openPanel = useCallback(
      (shipment = Map()) => {
        openRightPanel(
          "SERVICE_REQUEST",
          Map({
            id: shipment.get("id"),
            description: shipment.get("description"),
            item: shipment.getIn(["items", 0]),
            requests: shipment.get("service_requests"),
            emblem_thumb_url: shipment.get("emblem_thumb_url"),
            requestType: name,
            preventReLoading: true,
            updateComment: updateComment,
            ...additionalPanelData,
          }),
        );
      },
      [name, openRightPanel],
    );

    const openServicePanel = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        getRequestTypes();
        getShipment(parcelId)
          .then(({ value }) => {
            const normalizedData = normalizeDetailedShipment(value.get("data"));

            openPanel(normalizedData);
          })
          .catch(toastResponseError);
      },
      [getShipment, openPanel, parcelId, getRequestTypes],
    );

    const body = useMemo(
      () =>
        isProcessed ? (
          <ProcessedContainer>
            <ProcessedIcon type={name as IconType} />
            <Status> {t(`serviceRequests.${name}.processed`)}</Status>
            <Button
              onClick={openServicePanel}
              color={ButtonColor.Primary}
              variant={ButtonVariant.Filled}
            >
              {t("serviceRequests.check")}
            </Button>
          </ProcessedContainer>
        ) : (
          t(`serviceRequests.${name}.${status}`)
        ),
      [isProcessed, name, openServicePanel, status, t],
    );

    return (
      <ServiceTooltip key={name} place="bottom" body={body} $zIndex={zIndex}>
        <StorageIcon $isProcessed={isProcessed} type={name as IconType} />
      </ServiceTooltip>
    );
  },
);

const withConnect = connect(null, {
  openRightPanel,
  getShipment,
  updateComment,
  getRequestTypes,
});

export default withConnect(ServiceTooltipItem);
