import React, { FC, useCallback } from "react";
import mpsIcon from "components/Icon/icons/mps_icon.gif";
import { Map } from "immutable";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { OutgoingComponentCommonProps } from "types/outgoing";
import PackageSizes from "../../../../components/PackageSizes/PackageSizes";
import ServiceLogo from "../../../../components/ServiceLogo/ServiceLogo";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  panelSelectData,
  panelSelectOpened,
} from "../../../../reduxFolder/selectors/rightPanelSelectors";
import { ExcessMarker } from "../../../../styles/outgoingStyles";
import { Currency, ParcelRow } from "../../../../styles/parcelsStyles";
import formatter from "../../../../utils/formatter";
import { MPSIcon, OutgoingCol, Price, Weight } from "./Paid.styles";

const Paid: FC<OutgoingComponentCommonProps> = React.memo(
  ({ openRightPanel, parcel, isPanelOpened, panelData, units }) => {
    const { t } = useTranslation("common");
    const deliveryName = parcel.get("preferred_carrier");
    const isSelectedShipment =
      isPanelOpened &&
      panelData &&
      panelData.get("detailedItemID") === parcel.get("id");
    const hasFewPackages = (parcel.get("package_count") || 0) > 1;
    const hasExcessItems = parcel.get("excess_items").size > 0;

    const clickParcel = useCallback(
      () =>
        openRightPanel(
          "IN_PROGRESS",
          Map({
            detailedItemID: parcel.get("id"),
            excessItems: parcel.get("excess_items"),
            returnPreviousPanel: "IN_PROGRESS",
          }),
        ),
      [openRightPanel, parcel],
    );

    const getWeight = () => {
      let weight = parcel.get("total_weight");
      const unit = t(`units.${units}.primaryShort`);

      return `${weight} ${unit}`;
    };

    const getPackageDimmensions = () => {
      if (parcel.get("package_count") === 1) {
        return (
          <PackageSizes
            width={parcel.get("width")}
            height={parcel.get("height")}
            depth={parcel.get("depth")}
            units={units}
          />
        );
      } else {
        const package_count = parcel.get("package_count");
        const countId = package_count <= 10 ? package_count : "nth";
        return t(`conjugation.packages.${countId}`, { count: package_count });
      }
    };

    return (
      <ParcelRow selected={isSelectedShipment} onClick={clickParcel}>
        <OutgoingCol xs={3} sm={2} xl={1}>
          {parcel.get("sku")}
          {hasExcessItems ? <ExcessMarker /> : null}
          <Visible xs>
            {hasFewPackages ? <MPSIcon icon={mpsIcon} size={[14, 14]} /> : null}
          </Visible>
        </OutgoingCol>
        <OutgoingCol xs={5} sm={3} xl={2}>
          <ServiceLogo serviceKey={deliveryName} />
          <Hidden xs>{deliveryName}</Hidden>
          <Visible xs>{getPackageDimmensions()}</Visible>
        </OutgoingCol>
        <Hidden xs>
          <OutgoingCol xs={2} xl={1}>
            {getWeight()}
          </OutgoingCol>
          <OutgoingCol xs={2} sm={3} xl={2}>
            {getPackageDimmensions()}
          </OutgoingCol>
        </Hidden>
        <OutgoingCol xs={4} sm={2}>
          <Price>
            <Currency>$</Currency>{" "}
            {formatter.currency(parcel.getIn(["estimate", "total"]))}
            <Visible xs>
              <Weight>{` / ${getWeight()}`}</Weight>
            </Visible>
          </Price>
        </OutgoingCol>
      </ParcelRow>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
    panelData: panelSelectData,
  }),
  { openRightPanel },
);

export default withConnect(Paid);
