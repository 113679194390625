import { RasterIcon } from "components/Icon/Icon";
import styled from "styled-components";
import { ParcelCol } from "styles/parcelsStyles";

export const OutgoingCol = styled(ParcelCol)`
  ${(props) => props.theme.xs`
    &:nth-child(2) {
      font-size: 11px;
    }

    &:last-child {
      justify-content: flex-end;
      text-align: end;
    }
  `}
`;

export const Price = styled.div`
  margin: 0;
  font-weight: 700;
`;

export const Weight = styled.span`
  font-weight: 300;
`;

export const MPSIcon = styled(RasterIcon)`
  margin-left: 8px;
`;
