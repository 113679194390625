import React, { FC } from "react";
import { ButtonColor } from "enums/Button";
import { IconType } from "enums/Icon";
import { List, Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import Icon from "../../../../components/Icon/Icon";
import Logo from "../../../../components/Logo/Logo";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  panelSelectData,
  panelSelectType,
} from "../../../../reduxFolder/selectors/rightPanelSelectors";
import {
  FoundPackage,
  ParcelButton,
  ParcelHeader,
  ParcelInfo,
  ParcelText,
  ParcelTitle,
  ParcelWrapper,
} from "./ParcelComponents.styles";

interface ParcelProps {
  parcel: any;
  panelData: any;
  units: string;
  foundPackageId: number;
  panelType: string;
  openRightPanel: OpenRightPanel;
}

const Parcel: FC<ParcelProps> = React.memo(
  ({ parcel, units, openRightPanel, panelData, panelType, foundPackageId }) => {
    const { t } = useTranslation("common");
    const item = parcel.get("consolidation", Map());
    const number = item.get("sku");
    const items = item.get("items", List());
    const fullName = `${item.get("first_name")} ${item.get("last_name")}`;
    const address = `${item.get("city")}, ${item.get("postal_code")}, ${item.get(
      "country",
    )}, ${item.get("street")}${
      item.get("house") ? `, ${item.get("house")}` : ""
    }`;
    const foundPackage =
      items.find((item: any) => item.get("shipment_id") === foundPackageId) ||
      Map();
    const foundPackageLogo = foundPackage.get("emblem_thumb_url");

    const openParcelDetailed = () =>
      openRightPanel(
        "PARCEL_DETAILED",
        panelData
          .set("returnPreviousPanel", panelType)
          .set("number", number)
          .set("items", items)
          .set("id", parcel.getIn(["consolidation", "id"]))
          .set("sku", parcel.getIn(["consolidation", "sku"])),
      );

    return (
      <ParcelWrapper>
        <ParcelHeader>
          <ParcelTitle>
            <span>{number}</span>
            {` - ${parcel.get("weight", 0)} ${t(`units.${units}.primaryShort`)}`}
            {` - $${parcel.get("total_price")}`}
          </ParcelTitle>
          <div>
            {item.getIn(
              ["order", "tracking_number"],
              t("parcels.numberNotFound"),
            )}
          </div>
        </ParcelHeader>
        <ParcelInfo>
          <ParcelText>{fullName}</ParcelText>
          <ParcelText>{address}</ParcelText>
        </ParcelInfo>
        <ParcelButton
          $isSelected={foundPackage.size}
          onClick={openParcelDetailed}
          color={ButtonColor.Primary}
        >
          {!!foundPackage.size && (
            <FoundPackage>
              {foundPackageLogo ? (
                <Logo image={foundPackageLogo} />
              ) : (
                <Icon type={IconType.Package} />
              )}
              {foundPackage.get("shipment_tracking_number")}
            </FoundPackage>
          )}
          {`${t("parcels.moreDetails")} >>`}
        </ParcelButton>
      </ParcelWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    panelData: panelSelectData,
    panelType: panelSelectType,
  }),
  { openRightPanel },
);

export default withConnect(Parcel);
