import React, { FC, memo } from "react";
import placeholderIcon from "components/Icon/icons/not-selected-delivery.svg";
import styled from "styled-components";
import Logotype from "../../../components/Logo/Logo";

interface ServicesLogoProps {
  size?: number;
  logoUrl?: string;
}

const ServicesLogo: FC<ServicesLogoProps> = ({ logoUrl, size = 45 }) => {
  const image = logoUrl || placeholderIcon;

  return <Logo image={image} size={size} />;
};

export default memo(ServicesLogo);

const Logo = styled(Logotype)`
  margin-right: 30px;
  border-radius: 0;
`;
