import pathOr from "@ramda/pathor";
import { toast } from "react-toastify";

export const getResponseError = (response: unknown) => {
  if (!response) return null;
  if (typeof response === "string") return response;

  const error =
    pathOr(null, ["data", "errors"], response) ||
    pathOr(null, ["data", "error"], response) ||
    pathOr(null, ["data", "data", "error"], response) ||
    pathOr(null, ["data", "message"], response) ||
    pathOr(null, ["message"], response);

  if (error) {
    if (typeof error === "string") return error;
    else if (Array.isArray(error)) return error.join(", ");

    return pathOr(error.message, ["full_messages"], error).join(", ");
  }

  return null;
};

export const getResponseSuccess = (response: string) => {
  if (!response) return null;
  if (typeof response === "string") return response;

  return pathOr(response, ["data", "message"], response);
};

export const toastResponseError = (response: unknown, toastId?: string) => {
  toast.error(getResponseError(response), { toastId: toastId, icon: false });
};

export const toastResponseSuccess = (response: string) => {
  const message = getResponseSuccess(response);
  toast.success(message, { icon: false });
};
