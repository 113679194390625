import React, { FC, useCallback } from "react";
import { ButtonColor } from "enums/Button";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { OpenRightPanel } from "types/common/rightPanel";
import { openRightPanel } from "../../../reduxFolder/reducers/rightPanel";
import { AddButton } from "../Addresses.style";

interface AddAddressButtonProps {
  openRightPanel: OpenRightPanel;
  initialPanelData?: any;
  returnPreviousPanel?: string;
  className?: string;
}

const AddAddressButton: FC<AddAddressButtonProps> = React.memo(
  ({
    openRightPanel,
    initialPanelData,
    returnPreviousPanel,
    className = "",
  }) => {
    const { t } = useTranslation("common");
    const handleClick = useCallback(
      () =>
        openRightPanel(
          "ADDRESS_FORM",
          Map({
            isEditForm: false,
            initialPanelData,
            returnPreviousPanel,
          }),
        ),
      [openRightPanel],
    );

    return (
      <AddButton
        color={ButtonColor.Primary}
        onClick={handleClick}
        className={className}
      >
        + {t("common.addAddress", { word: t("common.another") })}
      </AddButton>
    );
  },
);

const withConnect = connect(null, { openRightPanel });

export default withConnect(AddAddressButton);
