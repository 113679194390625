import React, { FC, memo, useEffect } from "react";
import { Map } from "immutable";
import { Container } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { DeliveryShippingMethodsResponseDto } from "types/api/cart";
import {
  HistoryDetailedParamsDto,
  HistoryParamsDto,
  HistoryResponseDto,
} from "types/api/history";
import { OpenRightPanel } from "types/common/rightPanel";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import injectReducer from "../../reduxFolder/injectReducer";
import historyReducer, {
  getHistory,
  getHistoryDetailedItem,
} from "../../reduxFolder/reducers/historyReducer";
import { openRightPanel } from "../../reduxFolder/reducers/rightPanel";
import { getAllDeliveryMethods } from "../../reduxFolder/reducers/services";
import { authSelectUnits } from "../../reduxFolder/selectors/globalSelectors";
import {
  historySelectItems,
  historySelectLoading,
  historySelectYear,
} from "../../reduxFolder/selectors/historySelector";
import { servicesSelectAllDelivery } from "../../reduxFolder/selectors/servicesSelectors";
import { toastResponseError } from "../../utils/responseMessageHelper";
import HistoryHeader from "./components/HistoryHeader/HistoryHeader";
import Week from "./components/Week/Week";
import { HistoryEmpty, Wrapper } from "./History.styles";

interface HistoryProps {
  items: any;
  allDeliveryMethods: any;
  openRightPanel: OpenRightPanel;
  units: string;
  isLoading: boolean;
  selectedYear: string | number;
  getAllDeliveryMethods: () => Promise<DeliveryShippingMethodsResponseDto[]>;
  getHistory: (data: any) => Promise<HistoryResponseDto>;
  getHistoryDetailedItem: ({
    week_number,
    shipping_method,
    year,
    page,
  }: HistoryDetailedParamsDto) => Promise<void>;
}

const History: FC<HistoryProps> = ({
  items,
  selectedYear,
  isLoading,
  getHistory,
  units,
  openRightPanel,
  allDeliveryMethods,
  getAllDeliveryMethods,
  getHistoryDetailedItem,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const hasItems = !!items.size;
  const weeks = hasItems ? Object.keys(items.get("selected_year").toJS()) : [];

  useEffect(() => {
    !allDeliveryMethods.size && getAllDeliveryMethods();

    const receivedAtYear =
      location.state &&
      location.state.receivedAt &&
      location.state.receivedAt.slice(0, 4);
    const year = receivedAtYear || new Date().getFullYear();

    getHistory(Map({ year }))
      .then(() => {
        if (
          location.state &&
          location.state.shipmentId &&
          location.state.consolidationId
        ) {
          getHistoryDetailedItem(location.state.consolidationId);
        }
      })
      .catch(toastResponseError);

    if (location.state) {
      openRightPanel(
        "WEEK_ITEM",
        Map({
          id: location.state.consolidationId,
          foundPackageId: location.state.shipmentId,
          shippingMethod: location.state.shippingMethod,
          weekNumber: location.state.weekNumber,
          selectedYear: receivedAtYear ? receivedAtYear : selectedYear,
        }),
      );
      navigate(location.pathname, { state: undefined, replace: true });
    }
  }, []);

  return (
    <Wrapper>
      {hasItems && (
        <Container fluid>
          <LoadingBar isLoading={isLoading} isCartLoading={false} />
          <HistoryHeader
            selectedYear={selectedYear}
            years={items.get("archived")}
            getHistory={getHistory}
          />
          {!isLoading &&
            weeks
              .reverse()
              .map((week) => (
                <Week
                  units={units}
                  key={week}
                  numberWeek={week}
                  selectedYear={selectedYear}
                  items={items.getIn(["selected_year", week])}
                />
              ))}
          {!weeks.length && (
            <HistoryEmpty>{t("parcels.emptyHistory")}</HistoryEmpty>
          )}
        </Container>
      )}
    </Wrapper>
  );
};

const withReducer = injectReducer({
  key: "history",
  reducer: historyReducer,
});

const withConnect = connect(
  createStructuredSelector({
    units: authSelectUnits,
    items: historySelectItems,
    selectedYear: historySelectYear,
    isLoading: historySelectLoading,
    allDeliveryMethods: servicesSelectAllDelivery,
  }),
  {
    getHistory,
    getHistoryDetailedItem,
    openRightPanel,
    getAllDeliveryMethods,
  },
);

const enhance = compose(withReducer, withConnect, memo);

export default enhance(History);
