// utils
import { Map } from 'immutable';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_OUTGOING,
  getOutgoingError,
  getOutgoingSuccess,
} from '../../reduxFolder/reducers/outgoing';
// api
import api from '../../utils/api';
import { toastResponseError } from '../../utils/responseMessageHelper';

/* worker-saga */
function* onGetOutgoing({ payload }) {
  try {
    const response = yield call(api.outgoing.getOutgoing, payload.toJS());
    const headers = response
      .get('headers')
      .toArray()
      .map((arr) => arr.toArray());
    const total = headers?.[4]?.[1] ?? 0;
    const totalPrice = headers?.[5]?.[1] ?? 0;

    yield put(
      getOutgoingSuccess(
        Map({
          page: payload.getIn(['params', 'page']),
          type: payload.get('type'),
          items: response.get('data'),
          total: total,
          totalPrice: totalPrice,
        }),
      ),
    );
  } catch (error) {
    yield put(getOutgoingError(payload));
    toastResponseError(error);
  }
}

/* watcher-saga */
function* watchOutgoingActions() {
  yield takeEvery(`${GET_OUTGOING}_REQUEST`, onGetOutgoing);
}

export default watchOutgoingActions;
