import React, { FC, useCallback, useState } from "react";
import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  Heading,
  LeftArrowIcon,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonType, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { useFormik } from "formik";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { cartSelectCart } from "reduxFolder/selectors/cartSelectors";
import { createStructuredSelector } from "reselect";
import { PackingGradeFormProps } from "types/OutgoingForms/packingGradePanel";
import Button from "../../../../components/Button/Button";
import {
  setConsolidationAddonsMethods as setCartConsolidationAddonsMethods,
  setPackingMethods as setCartPackingMethods,
} from "../../../../reduxFolder/reducers/cart";
import {
  updateConsolidationAddons,
  updateConsolidationOptionalLineItems,
} from "../../../../reduxFolder/reducers/outgoing";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { outgoingSelectDetailedItems } from "../../../../reduxFolder/selectors/outgoingSelectors";
import { servicesSelectConsolidationAddons } from "../../../../reduxFolder/selectors/servicesSelectors";
import OutgoingConsolidationAddonsList from "../../../Delivery/components/OutgoingConsolidationAddonsList";
import PackingList from "../../../Delivery/components/PackingList/PackingList";
import Tab from "../../../RightPanel/components/Tab";
import TabsView from "../../../RightPanel/components/TabsView";
import { packingGradePanelFormik } from "./helperPackingGradePanel";
import {
  Container,
  ContentContainer,
  tabsViewStyles,
} from "./PackingGradePanel.styles";

const PackingGradePanel: FC<PackingGradeFormProps> = React.memo(
  ({
    panelData,
    openRightPanel,
    consolidationAddons,
    cart,
    updateConsolidationAddons,
    updateConsolidationOptionalLineItems,
    setCartPackingMethods,
    setCartConsolidationAddonsMethods,
    detailedItem,
  }) => {
    const { t } = useTranslation("common");
    const {
      values: { packingType, packingMethods, selectedAddonCodes },
      setFieldValue,
      isSubmitting,
      handleSubmit,
    } = useFormik(
      packingGradePanelFormik(
        panelData,
        openRightPanel,
        consolidationAddons,
        cart,
        updateConsolidationAddons,
        updateConsolidationOptionalLineItems,
        setCartPackingMethods,
        setCartConsolidationAddonsMethods,
      ),
    );

    const [activeTab, setActiveTab] = useState(t("parcels.packingGrade"));
    const isCartVariant = panelData.get("isCartVariant");
    const id = panelData.get("detailedItemID");
    const detailed = isCartVariant
      ? panelData.get("cart")
      : detailedItem.get(id, Map());

    const handleSelect = useCallback(
      (type: string) => {
        setFieldValue("packingType", type);
        setActiveTab(t("parcels.packingOptions"));
      },
      [setFieldValue, setActiveTab, t],
    );

    const handlePackingSubmit = useCallback(
      async (type: string) => {
        handleSubmit();
      },
      [handleSubmit],
    );

    const handleBack = useCallback(
      () => openRightPanel(panelData.get("returnPreviousPanel"), panelData),
      [openRightPanel, panelData],
    );

    return (
      <ContentWrapper>
        <Heading $isBordered>
          <BackButton color={ButtonColor.Black50} onClick={handleBack}>
            <LeftArrowIcon type={IconType.Arrow} />
            {detailed.get("sku")}:
          </BackButton>
          {t("parcels.packingGradeAndOptions")}
        </Heading>
        <TabsView
          openTab={activeTab}
          onTabChange={setActiveTab}
          tabsViewStyles={tabsViewStyles}
        >
          <Tab title={t("parcels.packingGrade")}>
            <ContentContainer>
              <Container>
                <PackingList
                  packingMethods={packingMethods}
                  packingType={packingType}
                  onSelect={handleSelect}
                  onSubmit={handlePackingSubmit}
                />
              </Container>
            </ContentContainer>
          </Tab>
          <Tab title={t("parcels.packingOptions")}>
            <ContentContainer>
              <Container>
                <OutgoingConsolidationAddonsList
                  addons={consolidationAddons}
                  selectedCodes={selectedAddonCodes}
                  setFormValues={(values) => {
                    setFieldValue("selectedAddonCodes", values);
                  }}
                />
              </Container>
            </ContentContainer>
          </Tab>
        </TabsView>
        <Container>
          <ButtonsWrapper>
            <Button onClick={handleBack}>{t("common.cancel")}</Button>
            <Button
              onClick={() => handleSubmit()}
              isLoading={isSubmitting}
              disabled={isSubmitting}
              type={ButtonType.Submit}
              color={ButtonColor.Primary}
              variant={ButtonVariant.Filled}
            >
              {t("common.saveChanges")}
            </Button>
          </ButtonsWrapper>
        </Container>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    cart: cartSelectCart,
    consolidationAddons: servicesSelectConsolidationAddons,
    detailedItem: outgoingSelectDetailedItems,
  }),
  {
    openRightPanel,
    updateConsolidationAddons,
    updateConsolidationOptionalLineItems,
    setCartPackingMethods,
    setCartConsolidationAddonsMethods,
  },
);

export default withConnect(PackingGradePanel);
