import React, { FC, useCallback } from "react";
import TotalPackingCost from "containers/Delivery/components/TotalPackingCost/TotalPackingCost";
import {
  SideItem,
  SideItemHead,
} from "containers/Delivery/styles/Delivery.styles";
import { Content } from "containers/RightPanel/RightPanel.styles";
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "enums/Button";
import { useFormik } from "formik";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { SetPartnerIdDto, SetPartnerIdResponseDto } from "types/api/cart";
import { CloseRightPanel } from "types/common/rightPanel";
import Button from "../../../../../components/Button/Button";
import Input from "../../../../../components/Input/Input";
import {
  consolidateCart,
  dropCart,
  setPartnerId,
} from "../../../../../reduxFolder/reducers/cart";
import { closeRightPanel } from "../../../../../reduxFolder/reducers/rightPanel";
import {
  cartSelectCart,
  cartSelectCustomInformation,
} from "../../../../../reduxFolder/selectors/cartSelectors";
import { authSelectAllowSearchByLocation } from "../../../../../reduxFolder/selectors/globalSelectors";
import {
  servicesSelectAllDelivery,
  servicesSelectPackingMethods,
} from "../../../../../reduxFolder/selectors/servicesSelectors";
import formatter from "../../../../../utils/formatter";
import {
  ChangeTab,
  CustomsDeclarationSummary,
  Footer,
  HeadingWrapper,
  Logo,
  PartnerInputWrapper,
  SideDescription,
  SideParagraph,
  StatValue,
  SummaryForm,
  TabMain,
  TotalCustomsPrice,
} from "../NotFinishedPanel.styles";
import { indexToTitleMapping, summaryPanelFormik } from "./helpers";

interface SummaryPanelProps {
  cart: any;
  packingMethods: any;
  customInformation: any;
  deliveryMethods: any;
  allowSearchByLocation: boolean;
  closeRightPanel: CloseRightPanel;
  setPartnerId: (id: string) => Promise<SetPartnerIdResponseDto>;
  consolidateCart: () => Promise<void>;
  dropCart: () => void;
  onActiveTabChange: (tab: string) => void;
}

const SummaryPanel: FC<SummaryPanelProps> = React.memo(
  ({
    cart,
    packingMethods,
    customInformation,
    deliveryMethods,
    onActiveTabChange,
    allowSearchByLocation,
    closeRightPanel,
    setPartnerId,
    consolidateCart,
    dropCart,
  }) => {
    const { t } = useTranslation("common");

    const delivery = cart.get("preferred_carrier");
    const packingCode = cart.getIn(["optional_line_item_codes", 0]);

    const { values, isSubmitting, handleChange, handleSubmit } = useFormik(
      summaryPanelFormik(
        allowSearchByLocation,
        closeRightPanel,
        setPartnerId,
        consolidateCart,
        dropCart,
      ),
    );

    const rendererDelivery = useCallback(() => {
      const deliveryMethod = deliveryMethods.find(
        (method: any) => method.get("name") === delivery,
      );

      return deliveryMethod ? (
        <SideDescription>
          <Logo logoUrl={deliveryMethod.get("logo_url")} />
          <SideParagraph>{deliveryMethod.get("name")}</SideParagraph>
        </SideDescription>
      ) : null;
    }, [delivery, deliveryMethods]);

    const rendererPacking = useCallback(() => {
      const packingMethod = packingMethods.find(
        (method: any) => method.get("code") === packingCode,
      );

      return packingMethod ? (
        <SideDescription>
          <Logo logoUrl={packingMethod.get("logo_url")} />
          <SideParagraph>{packingMethod.get("title")}</SideParagraph>
        </SideDescription>
      ) : null;
    }, [packingMethods, packingCode]);

    const rendererCustomsDeclarationSummary = () => {
      const totalQuantity = customInformation.reduce(
        (total: number, customsRow: any) => total + customsRow.get("quantity"),
        0,
      );
      const totalValue = customInformation.reduce(
        (total: number, customsRow: any) => total + customsRow.get("value"),
        0,
      );

      return (
        <CustomsDeclarationSummary>
          <StatValue>
            <strong>{customInformation.size}&nbsp;</strong>
            <span>
              {customInformation.size === 1
                ? t("shipping.singularCustomsSummary")
                : t("shipping.pluralCustomsSummary")}
            </span>
          </StatValue>
          <StatValue>
            <span>{t("shipping.quantityCustomsSummary")}</span>
            <strong>&nbsp;{totalQuantity}</strong>
          </StatValue>
          <TotalCustomsPrice>{`$${formatter.currency(
            totalValue,
          )}`}</TotalCustomsPrice>
        </CustomsDeclarationSummary>
      );
    };

    const rendererPartnerId = () => {
      return (
        <HeadingWrapper>
          <PartnerInputWrapper>
            <Input
              name="partnerId"
              placeholder={"Partner ID"}
              value={values.partnerId}
              onChange={handleChange}
            />
          </PartnerInputWrapper>
        </HeadingWrapper>
      );
    };

    return (
      <SummaryForm onSubmit={handleSubmit}>
        <TabMain>
          <Content>
            <Collapse isOpened>
              {delivery && (
                <SideItem $hideBorder>
                  <SideItemHead>
                    {t("shipping.delivery")}:
                    <ChangeTab
                      onClick={() =>
                        onActiveTabChange(t(indexToTitleMapping[0]))
                      }
                    >
                      {t("common.change")}
                    </ChangeTab>
                  </SideItemHead>
                  {rendererDelivery()}
                </SideItem>
              )}
              {packingCode && (
                <SideItem>
                  <SideItemHead>
                    {t("shipping.packing")}:
                    <ChangeTab
                      onClick={() =>
                        onActiveTabChange(t(indexToTitleMapping[0]))
                      }
                    >
                      {t("common.change")}
                    </ChangeTab>
                  </SideItemHead>
                  {rendererPacking()}
                </SideItem>
              )}
              <SideItem>
                <SideItemHead>
                  {t("parcels.customsDeclarations")}:
                  <ChangeTab
                    onClick={() => onActiveTabChange(t(indexToTitleMapping[1]))}
                  >
                    {t("common.change")}
                  </ChangeTab>
                </SideItemHead>
                {rendererCustomsDeclarationSummary()}
              </SideItem>
              {allowSearchByLocation && (
                <SideItem>{rendererPartnerId()}</SideItem>
              )}
              <TotalPackingCost />
            </Collapse>
          </Content>
          <Footer>
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting}
              type={ButtonType.Submit}
              size={ButtonSize.Large}
              color={ButtonColor.Primary}
              variant={ButtonVariant.Bordered}
            >
              {t("shipping.payAfterPacking")}
            </Button>
          </Footer>
        </TabMain>
      </SummaryForm>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    cart: cartSelectCart,
    packingMethods: servicesSelectPackingMethods,
    deliveryMethods: servicesSelectAllDelivery,
    customInformation: cartSelectCustomInformation,
    allowSearchByLocation: authSelectAllowSearchByLocation,
  }),
  {
    consolidateCart,
    dropCart,
    setPartnerId,
    closeRightPanel,
  },
);

export default withConnect(SummaryPanel);
