import React, { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import { ButtonColor } from "enums/Button";
import { useFormik } from "formik";
import { Map } from "immutable";
import { isEqual } from "lodash";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { AddonsMethodsResponseDto } from "types/api/cart";
import { ExpectedShipmentsDto } from "types/api/expected";
import { ShopsResponseDto } from "types/api/shipments";
import { OpenRightPanel } from "types/common/rightPanel";
import { ExpectedSearchValues } from "types/ExpectedForms/Search";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import useFetchOnceEffect from "../../hooks/useFetchOnceEffect";
import injectReducer from "../../reduxFolder/injectReducer";
import { addParcels } from "../../reduxFolder/reducers/cart";
import { openRightPanel } from "../../reduxFolder/reducers/rightPanel";
import serviceRequestsReducer from "../../reduxFolder/reducers/serviceRequests";
import { getAddonsMethods } from "../../reduxFolder/reducers/services";
import storageReducer, {
  getExpectedItems,
  getExpectedShops,
  updateStorage,
} from "../../reduxFolder/reducers/storage";
import { authSelectFfid } from "../../reduxFolder/selectors/globalSelectors";
import { servicesSelectAddonsAll } from "../../reduxFolder/selectors/servicesSelectors";
import {
  storageSelectExpectedItems,
  storageSelectExpectedItemsTotalAmount,
  storageSelectExpectedShops,
  storageSelectExpectedShopsLoading,
  storageSelectParams,
} from "../../reduxFolder/selectors/storageSelectors";
import { ListHeader } from "../../styles/parcelsStyles";
import { breakpoints } from "../../styles/styles";
import ExpectedList from "./components/ExpectedList/ExpectedList";
import NoItemsPlaceholder from "./components/NoItemsPlaceholder/NoItemsPlaceholder";
import CompactSearch from "./components/Search/CompactSearch";
import Search from "./components/Search/Search";
import { ResetFilterButton } from "./components/Search/Search.styles";
import { searchFormik } from "./components/Search/searchFormik";
import SearchPlaceholder from "./components/SearchPlaceholder/SearchPlaceholder";
import { StorageHeading, StyledGrid, Wrapper } from "./Expected.styles";

interface ExpectedProps {
  isLoading: boolean;
  expectedItems: any;
  shops: any;
  addons: any;
  params: any;
  totalAmount: number;
  getExpectedShops: () => Promise<ShopsResponseDto[]>;
  openRightPanel: OpenRightPanel;
  getAddonsMethods: () => Promise<AddonsMethodsResponseDto[]>;
  getExpectedItems: (values?: ExpectedShipmentsDto) => Promise<void>;
}

const Expected: FC<ExpectedProps> = React.memo(
  ({
    expectedItems,
    totalAmount,
    isLoading,
    getExpectedItems,
    getExpectedShops,
    shops,
    params,
    getAddonsMethods,
    addons,
    openRightPanel,
  }) => {
    const { t } = useTranslation("common");
    const [handleResetCompactFunction, setHandleResetCompactFunction] =
      useState<Function>(() => null);
    const hasItems = !!expectedItems.size;

    useFetchOnceEffect(!shops.size, getExpectedShops);
    useFetchOnceEffect(!addons.size, getAddonsMethods);
    useEffect(() => {
      getExpectedItems();
    }, [getExpectedItems]);

    const showExpectedParcelForm = () => {
      const isMobileBreakpoint = +window.innerWidth <= +breakpoints.xs;
      if (!isMobileBreakpoint) {
        openRightPanel("EXPECTED_ITEM", Map());
      }
    };

    const handleAddNewClick = () => {
      openRightPanel("EXPECTED_ITEM", Map());
    };

    useEffect(() => {
      showExpectedParcelForm();
    }, []);

    const onPagination = useCallback(() => {
      if (!isLoading) {
        getExpectedItems(params.update("page", (page: number) => page + 1));
      }
    }, [getExpectedItems, isLoading, params]);

    const expectedHasItems = expectedItems && expectedItems.count() > 0;

    const noItems = !expectedHasItems;
    const noItemSearch = !isLoading && expectedHasItems && !hasItems;

    const handleGetExpectedSubmit = (values: any) => {
      return getExpectedItems(values);
    };

    const formOptions = useFormik<ExpectedSearchValues>(
      searchFormik(handleGetExpectedSubmit),
    );

    const { values, initialValues, handleReset, handleSubmit } = formOptions;

    const isSearchDirty = !isLoading && !isEqual(values, initialValues);

    const handleResetClick = useCallback(
      (event: MouseEvent) => {
        handleReset(event);
        handleSubmit();
      },
      [handleReset, handleSubmit],
    );

    const handleResetClickCompactSearch = () => {
      handleResetCompactFunction();
    };

    const handleSetResetCompactFunction = (func: Function) =>
      setHandleResetCompactFunction(func);

    return (
      <Wrapper>
        <StyledGrid fluid>
          <LoadingBar isLoading={isLoading} />
          <StorageHeading>
            {hasItems && (
              <ListHeader>
                <Hidden xs>
                  {isSearchDirty && (
                    <ResetFilterButton
                      color={ButtonColor.Red}
                      onClick={handleResetClick}
                    >
                      {t("parcels.search.resetFilter")}
                    </ResetFilterButton>
                  )}
                </Hidden>
                <Visible xs>
                  {isSearchDirty && (
                    <ResetFilterButton
                      color={ButtonColor.Red}
                      onClick={handleResetClickCompactSearch}
                    >
                      {t("parcels.search.resetFilter")}
                    </ResetFilterButton>
                  )}
                </Visible>
              </ListHeader>
            )}
            {(expectedHasItems || noItems) && (
              <>
                <Hidden xs>
                  <Search
                    formOptions={formOptions}
                    onAddNewClick={handleAddNewClick}
                  />
                </Hidden>
                <Visible xs>
                  <CompactSearch
                    formOptions={formOptions}
                    handleSetResetCompactFunction={
                      handleSetResetCompactFunction
                    }
                    onAddNewClick={handleAddNewClick}
                  />
                </Visible>
              </>
            )}
          </StorageHeading>
          {expectedHasItems && (
            <ExpectedList
              parcels={expectedItems}
              totalAmount={totalAmount}
              onPagination={onPagination}
              disabled={isLoading}
              shops={shops}
            />
          )}
          {noItems && <NoItemsPlaceholder />}
          <Hidden xs>
            {noItemSearch && (
              <SearchPlaceholder handleReset={handleResetClick} />
            )}
          </Hidden>
          <Visible xs>
            {noItemSearch && (
              <SearchPlaceholder handleReset={handleResetClickCompactSearch} />
            )}
          </Visible>
        </StyledGrid>
      </Wrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    userFfid: authSelectFfid,
    expectedItems: storageSelectExpectedItems,
    totalAmount: storageSelectExpectedItemsTotalAmount,
    isLoading: storageSelectExpectedShopsLoading,
    params: storageSelectParams,
    shops: storageSelectExpectedShops,
    addons: servicesSelectAddonsAll,
  }),
  {
    getExpectedItems,
    updateStorage,
    addParcels,
    getExpectedShops,
    getAddonsMethods,
    openRightPanel,
  },
);

const withStorageReducer = injectReducer({
  key: "storage",
  reducer: storageReducer,
});

const withServiceRequestsReducer = injectReducer({
  key: "serviceRequsets",
  reducer: serviceRequestsReducer,
});

const enhance = compose(
  withStorageReducer,
  withServiceRequestsReducer,
  withConnect,
);

export default enhance(Expected);
