import React, { FC, memo } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { AddressFormValues } from "types/address";
import Input from "../../Input/Input";

const AddressCustomSender: FC<FormikProps<AddressFormValues>> = ({
  values,
  touched: touchedProp,
  errors: { address = {} },
  handleChange,
  handleBlur,
}) => {
  const touched = {
    address: {},
    native: {},
    ...touchedProp,
  };
  const { t } = useTranslation("common");

  return (
    <div>
      <Input
        placeholder={t("auth.originName")}
        name="address.originName"
        value={values.address.originName}
        onChange={handleChange}
        onBlur={handleBlur}
        tooltip={address.originName}
        invalid={address.originName && touched.address.originName}
      />
      <Input
        placeholder={t("auth.originAddress1")}
        name="address.originAddress1"
        value={values.address.originAddress1}
        onChange={handleChange}
        onBlur={handleBlur}
        tooltip={address.originAddress1}
        invalid={address.originAddress1 && touched.address.originAddress1}
      />
      <Input
        placeholder={t("auth.originCity")}
        name="address.originCity"
        value={values.address.originCity}
        onChange={handleChange}
        onBlur={handleBlur}
        tooltip={address.originCity}
        invalid={address.originCity && touched.address.originCity}
      />
      <Input
        placeholder={t("auth.originState")}
        name="address.originState"
        value={values.address.originState}
        onChange={handleChange}
        onBlur={handleBlur}
        tooltip={address.originState}
        invalid={address.originState && touched.address.originState}
      />
      <Input
        placeholder={t("auth.originZipCode")}
        name="address.originZipCode"
        value={values.address.originZipCode}
        onChange={handleChange}
        onBlur={handleBlur}
        tooltip={address.originZipCode}
        invalid={address.originZipCode && touched.address.originZipCode}
      />
    </div>
  );
};

export default memo(AddressCustomSender);
