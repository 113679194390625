import React, { FC, useEffect } from "react";
import { ButtonColor, ButtonVariant } from "enums/Button";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { amountFunds } from "reduxFolder/selectors/booksSelectors";
import { createStructuredSelector } from "reselect";
import { BooksAddFundsFormValues } from "types/books";
import { PayButton } from "../../styles/BooksAddFunds.style";

interface BooksAddFundsPayButtonProps {
  amountFunds: string;
  handleSubmit: FormikProps<BooksAddFundsFormValues>["handleSubmit"];
  isSubmitting: FormikProps<BooksAddFundsFormValues>["isSubmitting"];
  isValid: FormikProps<BooksAddFundsFormValues>["isValid"];
  setFieldValue: FormikProps<BooksAddFundsFormValues>["setFieldValue"];
}

const BooksAddFundsPayButton: FC<BooksAddFundsPayButtonProps> = React.memo(
  ({ handleSubmit, isSubmitting, isValid, setFieldValue, amountFunds }) => {
    const { t } = useTranslation("common");
    const payBtnTitle = `${t("common.processBankCard")} ($${amountFunds})`;

    useEffect(() => {
      if (amountFunds) setFieldValue("amount", amountFunds);
    }, [amountFunds]);

    return (
      <PayButton
        onClick={() => handleSubmit()}
        isLoading={isSubmitting}
        disabled={!isValid || isSubmitting}
        variant={ButtonVariant.Filled}
        color={ButtonColor.Secondary}
      >
        {payBtnTitle}
      </PayButton>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    amountFunds,
  }),
  {},
);

export default withConnect(BooksAddFundsPayButton);
