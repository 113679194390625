import React, { FC, MouseEvent, useCallback, useRef } from "react";
import CopyToClipboard from "components/CopyToClipboard/CopyToClipboard";
import { IconPng } from "components/Icon/Icon";
import PackageIcon from "components/Icon/icons/package.svg";
import { IconType } from "enums/Icon";
import { isMobile } from "react-device-detect";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import PackageTypeIcon from "../../../../components/PackageTypeIcon/PackageTypeIcon";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { panelSelectOpened } from "../../../../reduxFolder/selectors/rightPanelSelectors";
import { ParcelLogo } from "../../../../styles/parcelsStyles";
import { isTransferringState } from "../../../../utils/common";
import { useFormattedDate } from "../../../../utils/formatDate";
import { getAddonsIconsImage } from "../../../Delivery/deliveryHelper";
import { isUnprocessedRequest } from "../ServiceRequests/requestHelper";
import ServiceTooltips from "../ServiceTooltip/ServiceTooltips";
import {
  CheckboxAreaButton,
  Comment,
  Description,
  EmptyConsolidationSpacing,
  MainStorageCol,
  MobileCheckboxAreaButton,
  MobileCheckboxAreaButton2,
  NoCheckboxActionArea,
  ParcelDate,
  RedirectLink,
  RightArrowIcon,
  StorageCol,
  StorageItemRowWrapper,
  StorageRow,
  TransferIcon,
  WarningText,
} from "./StorageList.styles";

interface StorageItemRowProps {
  parcel: any;
  selectedParcel: number | null;
  onFocusStorageItemInPanel: (item: any) => void;
  isChecked: boolean;
  disabled: boolean;
  isPanelOpened: boolean;
  units: string;
  addons: any;
  handleSelect: (id: string | number) => void;
  selectedRangeParcels: (id: string | number) => any;
}

const StorageItemRow: FC<StorageItemRowProps> = React.memo(
  ({
    units,
    parcel,
    isChecked,
    handleSelect,
    selectedRangeParcels,
    isPanelOpened,
    selectedParcel,
    onFocusStorageItemInPanel,
    addons,
    disabled,
  }) => {
    const itemRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation("common");

    const isClientTransferring = isTransferringState(parcel.get("state"));
    const logoUrl = parcel.get("emblem_thumb_url");
    const parcelDate = useFormattedDate({
      date: parcel.get("received_at", new Date()),
      hideCurrentYearDisplay: true,
    });

    const requests = parcel.get("serviceRequestCount");
    const isProhibited = parcel.get("prohibited") || false;
    const isDisabled =
      !!isUnprocessedRequest(requests) ||
      isProhibited === true ||
      isClientTransferring;
    const tooltipTrigger = isDisabled ? (isMobile ? "click" : "hover") : "none";
    const tooltipBody =
      isDisabled &&
      (isProhibited
        ? t("parcels.shipmentProhibited")
        : t("parcels.shipmentNotSend"));
    const isSelectedShipment =
      (isMobile || isPanelOpened) && selectedParcel === parcel.get("id");
    const comment = parcel.get("customer_comment");
    const weight =
      parcel.get("weight") && Number(parcel.get("weight")).toFixed(2);
    const consolidationId = parcel.get("consolidation_id", 0);
    const package_type = parcel.get("package_type");
    const parcelAddons = parcel.get("addons");

    const handleItemSelect = useCallback(
      () => handleSelect(parcel.get("id")),
      [handleSelect, parcel],
    );
    const handleCheckBoxClick = useCallback(
      (event: MouseEvent<HTMLLabelElement | HTMLButtonElement>) => {
        event.stopPropagation();

        if (isDisabled || !!consolidationId) return;

        if (event.nativeEvent.shiftKey) {
          const selectedParcels = selectedRangeParcels(parcel.get("id"));

          handleSelect(selectedParcels);
        } else {
          handleItemSelect();
        }
      },
      [selectedRangeParcels, parcel, handleSelect, handleItemSelect],
    );

    const handleParcelLogoClick = useCallback(
      (event: MouseEvent) => {
        event.stopPropagation();

        if (isDisabled || !!consolidationId) return;

        handleItemSelect();
      },
      [handleItemSelect],
    );

    const compactDescription = (description: string) => {
      if (description && description.length > 20) {
        let chars = description.split("");
        chars.splice(8, 8, "...");
        return chars.join("");
      } else {
        return description;
      }
    };

    const prohibitedIcon = (isProhibited: boolean) => {
      return isProhibited ? (
        <Tooltip
          body={tooltipBody}
          place="right"
          openOnClick={!!tooltipTrigger}
          className="tooltip-content-checkbox"
        >
          <WarningText>!</WarningText>
        </Tooltip>
      ) : null;
    };

    const renderRedirectLink = () => {
      return (
        <RedirectLink
          to={`${parcel.get("state") === "shipped" ? "history" : "outgoing"}`}
          state={{
            consolidationId,
            shippingMethod: parcel.get("carrier"),
            weekNumber: parcel.get("week_number"),
            shipmentId: parcel.get("id"),
            receivedAt: parcel.get("shipped_on"),
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <span>{t("common.sentIn")}</span>
          <span>{`PK${consolidationId}`}</span>{" "}
          <RightArrowIcon type={IconType.RightArrow} />
        </RedirectLink>
      );
    };

    const hasConsolidation = !!consolidationId;

    return (
      <StorageItemRowWrapper ref={itemRef} disabled={disabled && isChecked}>
        <StorageRow
          align={"center"}
          checked={isChecked}
          selected={isSelectedShipment}
          styled={{ consolidationId }}
          onClick={() => onFocusStorageItemInPanel(parcel)}
        >
          {/* Mobile version of rows */}
          <StorageCol xs={2}>
            <Visible xs>
              {isChecked ? (
                <MobileCheckboxAreaButton2
                  onClick={(e: MouseEvent<HTMLButtonElement>) =>
                    handleCheckBoxClick(e)
                  }
                >
                  <Checkbox
                    checked={isChecked}
                    onChange={handleItemSelect}
                    onClick={(e: MouseEvent<HTMLLabelElement>) =>
                      handleCheckBoxClick(e)
                    }
                    disabled={isDisabled}
                  />
                </MobileCheckboxAreaButton2>
              ) : (
                <MobileCheckboxAreaButton onClick={handleParcelLogoClick}>
                  <ParcelLogo
                    image={logoUrl || PackageIcon}
                    onClick={
                      isDisabled || !!consolidationId
                        ? null
                        : handleParcelLogoClick
                    }
                    disabled={isDisabled}
                  />
                  {isClientTransferring && (
                    <TransferIcon type="transfer" width="14" height="14" />
                  )}
                </MobileCheckboxAreaButton>
              )}
            </Visible>
          </StorageCol>
          <StorageCol xs={10}>
            <Visible xs>
              <StorageCol xs={hasConsolidation ? 5 : 8}>
                <Description disabled={!!isDisabled}>
                  {prohibitedIcon(isProhibited)}
                  <CopyToClipboard text={parcel.get("description")}>
                    {compactDescription(parcel.get("description"))}
                  </CopyToClipboard>
                  {getAddonsIconsImage(parcelAddons, addons)}
                  {!!requests.size && (
                    <ServiceTooltips
                      parcel={parcel}
                      requests={requests.toJS()}
                      fullWidth={false}
                    />
                  )}
                  {comment && <Comment>{comment}</Comment>}
                </Description>
              </StorageCol>
              <StorageCol xs={4}>
                <PackageTypeIcon type={package_type} />
                {`${weight} ${t(`units.${units}.primaryShort`)}`}
              </StorageCol>
              {hasConsolidation && (
                <StorageCol xs={3}>{renderRedirectLink()}</StorageCol>
              )}
            </Visible>
          </StorageCol>

          {/* Desktop version of rows */}
          <MainStorageCol sm={12}>
            <Hidden xs>
              <StorageCol xs={12} sm={consolidationId ? 5 : 6} md={5}>
                {!consolidationId && isProhibited && (
                  <NoCheckboxActionArea>
                    {prohibitedIcon(isProhibited)}
                    <ParcelLogo image={logoUrl || PackageIcon} />
                  </NoCheckboxActionArea>
                )}
                {!consolidationId && !isProhibited && (
                  <CheckboxAreaButton onClick={handleCheckBoxClick}>
                    {isClientTransferring ? (
                      <IconPng type="transfer" />
                    ) : (
                      <Checkbox
                        checked={isChecked}
                        onChange={handleItemSelect}
                        disabled={isDisabled}
                      />
                    )}
                    <ParcelLogo image={logoUrl || PackageIcon} />
                  </CheckboxAreaButton>
                )}
                {!!consolidationId && (
                  <NoCheckboxActionArea>
                    <EmptyConsolidationSpacing />
                    <ParcelLogo image={logoUrl || PackageIcon} />
                  </NoCheckboxActionArea>
                )}
                <Description>
                  <CopyToClipboard text={parcel.get("description")}>
                    {parcel.get("description")}
                  </CopyToClipboard>
                  {getAddonsIconsImage(parcelAddons, addons)}
                  {!!requests.size && (
                    <ServiceTooltips
                      parcel={parcel}
                      requests={requests.toJS()}
                      fullWidth={false}
                    />
                  )}
                  {comment && <Comment>{comment}</Comment>}
                </Description>
              </StorageCol>
              <StorageCol xs={4} sm={2}>
                <PackageTypeIcon type={package_type} />
                {`${weight} ${t(`units.${units}.primaryShort`)}`}
              </StorageCol>
              <StorageCol xs={consolidationId ? 4 : 6} sm={3} md={2}>
                <ParcelDate>{parcelDate}</ParcelDate>
              </StorageCol>
              {!!consolidationId && (
                <StorageCol xs={4} sm={2} md={3}>
                  {renderRedirectLink()}
                </StorageCol>
              )}
            </Hidden>
          </MainStorageCol>
        </StorageRow>
      </StorageItemRowWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
  }),
);

export default withConnect(StorageItemRow);
