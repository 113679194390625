import React, { FC } from "react";
import {
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { redirect } from "react-router-dom";
import { RechargeCreditCardDto } from "types/api/recharge";
import {
  createRechargeBalance,
  updateAmountFunds,
} from "../../../../reduxFolder/reducers/books";
import addFundsFormik from "../../helpers/addFundsFormik";
import {
  ContentContainer,
  FormWrapper,
} from "../../styles/BooksAddFunds.style";
import BooksAddFundsForm from "./BooksAddFundsForm";
import BooksAddFundsPayButton from "./BooksAddFundsPayButton";

interface BooksAddFundsPanelProps {
  createRechargeBalance: ({ data }: RechargeCreditCardDto) => Promise<void>;
  updateAmountFunds: (amount: string) => void;
}

const BooksAddFundsPanel: FC<BooksAddFundsPanelProps> = React.memo(
  ({ createRechargeBalance, updateAmountFunds }) => {
    const { t } = useTranslation("common");
    const redirectFunc = () => redirect("/parcels");

    const {
      handleBlur,
      handleSubmit,
      handleChange,
      errors,
      values,
      touched,
      isSubmitting,
      isValid,
      setFieldValue,
    } = useFormik(
      addFundsFormik(t, createRechargeBalance, redirectFunc, updateAmountFunds),
    );

    return (
      <ContentWrapper>
        <Heading>{t("books.addFundsPanel.chargeBankCard")}</Heading>
        <ContentContainer>
          <FormWrapper>
            <BooksAddFundsForm
              handleBlur={handleBlur}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              createRechargeBalance={createRechargeBalance}
            />
          </FormWrapper>
          <BooksAddFundsPayButton
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
        </ContentContainer>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(null, {
  createRechargeBalance,
  updateAmountFunds,
});

export default withConnect(BooksAddFundsPanel);
