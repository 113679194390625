import Icon from "components/Icon/Icon";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const SectionTitle = styled.p`
  margin: 20px 0 15px;
  font-weight: 400;
  font-size: 22px;
`;

export const SectionText = styled.p`
  color: rgba(0, 0, 0, 0.5);
  margin: 4px 0 10px;
`;

export const AccountLink = styled(NavLink)`
  text-decoration: none;
  white-space: nowrap;
  color: ${(props) => props.theme.primary};
  margin-left: auto;
  margin-right: 20px;
  transition: color 0.2s;

  &:hover {
    color: ${(props) => props.theme.primary05};
  }
`;

export const SectionPasswords = styled.div`
  width: 100%;

  label:last-child {
    margin-top: 10px;
  }
`;

interface SettingsButtonProps {
  $isShow: boolean;
}

export const SettingsButton = styled.div<SettingsButtonProps>`
  width: 100%;

  ${({ $isShow }) =>
    $isShow
      ? `
        height: 100px;

        ${ButtonWrapper} {
          bottom: 0;
        }
      `
      : "height: 0px;"}
`;

export const ButtonWrapper = styled.div`
  background-color: #fff;
  position: fixed;
  left: 80px;
  width: 100%;
  z-index: 1;
  padding: 20px 30px;
  bottom: -110px;
  transition: bottom 0.4s ease-in-out;

  &::before {
    content: "";
    background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
    height: 30px;
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
  }

  ${(props) => props.theme.sm`
    left: 0;
  `}

  ${(props) => props.theme.xs`
    display: flex;
    justify-content: center;
  `}
`;

export const Wrapper = styled.li`
  margin-bottom: 12px;
  border-bottom: 1px solid #f2f2f2;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Section = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;

  &:last-child {
    font-size: 14px;
    margin-bottom: 12px;
  }
`;

export const TransactionIcon = styled(Icon)`
  display: inline-block;
  vertical-align: bottom;
  margin-right: 4px;
  background-color: #e1e2e6;
`;

interface StatusProps {
  status: string;
}

export const Status = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.3);
  text-transform: capitalize;

  &::after {
    content: "";
    width: 6px;
    height: 6px;
    margin-left: 4px;
    border-radius: 50%;
    background-color: ${({ status, theme }) => theme[status]};
  }
`;

export const Balance = styled.div`
  color: #000;
`;
