import React, { FC } from "react";
import Icon, { IconPng } from "components/Icon/Icon";
import ServiceLogo from "components/ServiceLogo/ServiceLogo";
import { getAddonsIconsImage } from "containers/Delivery/deliveryHelper";
import { ButtonColor, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import useFetchOnceEffect from "hooks/useFetchOnceEffect";
import { List } from "immutable";
import { Map } from "immutable/dist/immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { openRightPanel } from "reduxFolder/reducers/rightPanel";
import {
  getAddonsMethods,
  getConsolidationAddonsMethods,
  getPackingMethods,
} from "reduxFolder/reducers/services";
import { authSelectUnits } from "reduxFolder/selectors/globalSelectors";
import { historySelectFullDetailedConsolidation } from "reduxFolder/selectors/historySelector";
import { panelSelectData } from "reduxFolder/selectors/rightPanelSelectors";
import {
  servicesSelectAddons,
  servicesSelectConsolidationAddons,
  servicesSelectPackingMethods,
} from "reduxFolder/selectors/servicesSelectors";
import { createStructuredSelector } from "reselect";
import {
  HeaderTitle,
  InnerSection,
  PayButton,
  Section,
  SectionContent,
  SectionHeader,
} from "styles/outgoingStyles";
import { getFnsLocale } from "translations/date-fns-locale";
import {
  AddonsMethodsResponseDto,
  ConsolidationAddonsMethodsResponseDto,
  PackingMethodsResponseDto,
} from "types/api/cart";
import { OpenRightPanel } from "types/common/rightPanel";
import formatter from "utils/formatter";
import { normalizeImages } from "../../../../../reduxFolder/reducers/utils/helpers";
import ParcelInfoTabPaginatedPackagesSection from "./components/ParcelInfoTabPaginatedPackagesSection";
import ParcelInfoTabStandardPackageSection from "./components/ParcelInfoTabStandardPackageSection";
import { getFormatedDate } from "./helpers";
import {
  ContentWrapper,
  DeliveryContent,
  Footer,
  PackageDimensions,
  PaidInfoSectionWrapper,
  PanelAddressItem,
  Spinner,
  StyledPackageSizes,
  TrackingNumber,
} from "./ParcelInfoTab.styles";

interface ParcelInfoTabProps {
  id: number;
  units: string;
  openRightPanel: OpenRightPanel;
  parcel: any;
  addons: any;
  panelData: any;
  consolidationAddons: any;
  packingMethods: any;
  getAddonsMethods: () => Promise<AddonsMethodsResponseDto[]>;
  getConsolidationAddonsMethods: () => Promise<
    ConsolidationAddonsMethodsResponseDto[]
  >;
  getPackingMethods: () => Promise<PackingMethodsResponseDto[]>;
}

const ParcelInfoTab: FC<ParcelInfoTabProps> = React.memo(
  ({
    id,
    units,
    parcel,
    addons,
    openRightPanel,
    panelData,
    consolidationAddons,
    packingMethods,
    getConsolidationAddonsMethods,
    getAddonsMethods,
    getPackingMethods,
  }) => {
    const { i18n, t } = useTranslation("common");
    const locale = getFnsLocale(i18n.language);
    const delivery = parcel.get("preferred_carrier");
    const packingCode = parcel.getIn(["optional_line_item_codes", 0]);
    const packing = Map(
      packingMethods.find(
        (packingMethod: any) => packingMethod.get("code") === packingCode,
      ),
    );
    const packingTitle = (packing?.get("title") ?? "") as string;
    const services = !!(addons.size && packingMethods.size);
    const shipped = parcel.getIn(["order", "shipped_on"]);

    const packages = parcel
      .get("packages", List())
      .map((pkg: any) => pkg.update("pictures", normalizeImages))
      .unshift(parcel);

    const showPaginatedPackagesSection = packages.count() > 1;

    useFetchOnceEffect(!packingMethods.size, getPackingMethods);
    useFetchOnceEffect(!addons.size, getAddonsMethods);
    useFetchOnceEffect(
      !consolidationAddons.size,
      getConsolidationAddonsMethods,
    );

    const clickAlreadyPayed = () => {
      openRightPanel("REVIEW_AND_PAY", panelData.set("consolidation", parcel));
    };

    if (!parcel || parcel.get("id") !== id) return <Spinner isActive />;
    if (!services) return <Spinner isActive />;

    return (
      <>
        <ContentWrapper>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <Icon type={IconType.Address} /> {t("parcels.shippingAddress")}:
              </HeaderTitle>
            </SectionHeader>
            <PanelAddressItem address={parcel} isHideButtons />
          </Section>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <IconPng type="turbine" /> {t("parcels.shippingMethod")}:
              </HeaderTitle>
            </SectionHeader>
            <InnerSection>
              <DeliveryContent>{`${delivery} - ${parcel.get("weight")} ${t(
                `units.${units}.primaryShort`,
              )} / $${parcel.getIn(["order", "total_price"])}`}</DeliveryContent>
              <PackageDimensions>
                <StyledPackageSizes
                  width={parcel.get("width")}
                  height={parcel.get("height")}
                  depth={parcel.get("depth")}
                  units={units}
                />
              </PackageDimensions>
            </InnerSection>
          </Section>
          <Section>
            <InnerSection>
              <TrackingNumber>
                {parcel.getIn(
                  ["order", "tracking_number"],
                  t("parcels.numberNotFound"),
                )}
              </TrackingNumber>
              {shipped && (
                <>
                  {t("parcels.shipped")} {getFormatedDate(shipped, locale)}
                </>
              )}
            </InnerSection>
          </Section>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <IconPng type="tape" /> {t("parcels.packingGrade")}:
              </HeaderTitle>
            </SectionHeader>
            <InnerSection>
              <SectionContent>
                <ServiceLogo serviceKey={packingCode} />
                {packingTitle}
                {getAddonsIconsImage(
                  parcel.get("consolidation_addons"),
                  consolidationAddons,
                )}
              </SectionContent>
            </InnerSection>
          </Section>
          {!showPaginatedPackagesSection && (
            <ParcelInfoTabStandardPackageSection />
          )}
          {showPaginatedPackagesSection && (
            <ParcelInfoTabPaginatedPackagesSection
              packages={packages}
              hasFewPackages={showPaginatedPackagesSection}
            />
          )}
        </ContentWrapper>
        <Footer>
          <PaidInfoSectionWrapper>
            <PayButton
              color={ButtonColor.Secondary}
              onClick={() => clickAlreadyPayed()}
              variant={ButtonVariant.Filled}
            >
              {`${t("parcels.paymentPaid")} ($${formatter.currency(
                parcel.getIn(["estimate", "total"]),
              )})`}
            </PayButton>
          </PaidInfoSectionWrapper>
        </Footer>
      </>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    packingMethods: servicesSelectPackingMethods,
    panelData: panelSelectData,
    units: authSelectUnits,
    parcel: historySelectFullDetailedConsolidation,
    consolidationAddons: servicesSelectConsolidationAddons,
    addons: servicesSelectAddons,
  }),
  {
    openRightPanel,
    getPackingMethods,
    getAddonsMethods,
    getConsolidationAddonsMethods,
  },
);

export default withConnect(ParcelInfoTab);
