import React, { FC, Suspense } from "react";
import Layout from "layouts/Layout";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";
import {
  authSelectAuthenticated,
  authSelectUser,
} from "../../../reduxFolder/selectors/globalSelectors";
import { getAuthData } from "../../../utils/authData";

interface AuthorizedRouteProps {
  withHeader?: boolean;
  withMenu?: boolean;
  condition?: boolean;
  authenticated: boolean;
  redirect?: string;
}

const AuthorizedRoute: FC<AuthorizedRouteProps> = React.memo(
  ({ withHeader = true, withMenu = true, condition = false }) => {
    const token = getAuthData()["access-token"];

    if (condition) return <Navigate to="/" />;

    return token ? (
      <Layout withHeader={withHeader} withMenu={withMenu} rightPanel>
        <Suspense fallback={<LoadingPage />}>
          <Outlet />
        </Suspense>
      </Layout>
    ) : (
      <Navigate to="/signin" />
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    user: authSelectUser,
    authenticated: authSelectAuthenticated,
  }),
);

export default withConnect(AuthorizedRoute);
