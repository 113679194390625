import React, { FC, useCallback, useEffect, useState } from "react";
import CustomsDeclarationV2 from "components/CustomDeclaration/CustomsDeclarationV2";
import {
  Content,
  Heading,
  ContentWrapper as Wrapper,
} from "containers/RightPanel/RightPanel.styles";
import { List, Map } from "immutable";
import { useTranslation } from "react-i18next";
import ImmutableT from "react-immutable-proptypes";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  CustomInformationDto,
  UpdateCustomsDeclarationDto,
} from "types/api/cart";
import { CloseRightPanel } from "types/common/rightPanel";
import {
  cancelConsolidation,
  createCustomsDeclaration,
  deleteCustomsDeclaration,
  getCustomsDeclarations,
  getDetailedItem,
  updateCustomsDeclaration,
} from "../../../../reduxFolder/reducers/outgoing";
import { closeRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  outgoingSelectCustomsDeclarationImporting,
  outgoingSelectDetailedItems,
  outgoingSelectDetailedLoading,
} from "../../../../reduxFolder/selectors/outgoingSelectors";
import { USD } from "../../../../styles/formStyles";
import { Spinner } from "../../../../styles/outgoingStyles";
import { calculateCustomsDataTotal } from "../../../../utils/customsDataHelpers";
import formatter from "../../../../utils/formatter";
import { toastResponseError } from "../../../../utils/responseMessageHelper";
import Tab from "../../../RightPanel/components/Tab";
import TabsView from "../../../RightPanel/components/TabsView";
import CancelPacking from "../CancelPacking/CancelPacking";
import ParcelInfoTab from "../ParcelInfoTab/ParcelInfoTab";
import {
  CustomsDeclarationContent,
  DeclarationTotal,
  HeaderDate,
} from "./ConsolidationPanel.styles";
import { cancelAndPayAvaiable, customDeclarationEditable } from "./helpers";

interface ConsolidationPanelProps {
  isOpen: boolean;
  isLoadingDetailedItem: boolean;
  isLoadingCustomsDeclaration: boolean;
  panelData: any;
  detailedItem: any;
  closeRightPanel: CloseRightPanel;
  getDetailedItem: (id: number) => Promise<void>;
  cancelConsolidation: (id: number) => void;
  getCustomsDeclarations: (id: number) => Promise<void>;
  createCustomsDeclaration: (data: CustomInformationDto) => Promise<void>;
  updateCustomsDeclaration: ({
    id,
    data,
  }: UpdateCustomsDeclarationDto) => Promise<void>;
  deleteCustomsDeclaration: (id: number) => Promise<void>;
}

const ConsolidationPanel: FC<ConsolidationPanelProps> = React.memo(
  ({
    isOpen,
    panelData,
    detailedItem,
    closeRightPanel,
    getDetailedItem,
    cancelConsolidation,
    getCustomsDeclarations,
    isLoadingDetailedItem,
    isLoadingCustomsDeclaration,
    createCustomsDeclaration,
    updateCustomsDeclaration,
    deleteCustomsDeclaration,
  }) => {
    const { t } = useTranslation("common");
    const [isCanceling, setCanceling] = useState(false);
    const id = panelData.get("detailedItemID");
    const detailed = detailedItem.get(id, Map());
    const delivery = detailed.get("preferred_carrier");
    const isShowHSCodeColumn = delivery === "Berlin Post";
    const heading = detailed.get("sku");
    const createdAt = new Date(detailed.get("created_at"));
    const createdAtStr = formatter.date_ddmmyyyy(createdAt);

    const declarations = detailed.get("customs_declarations", List());
    const shouldRender = !!detailed.size && !isLoadingDetailedItem;
    const state = detailed.get("state");

    const editCustomsData = customDeclarationEditable(state);
    const isCancelAndPayAvaiable = cancelAndPayAvaiable(state);

    const getTotal = (nameProp: string) =>
      declarations.reduce(
        (sum: number, information: any) => sum + information.get(nameProp),
        0,
      );

    const [headerData, setHeaderData] = useState({
      size: declarations.size || 0,
      total: formatter.currency(getTotal("value")),
    });

    const handleCancelPacking = useCallback(() => {
      closeRightPanel();
      cancelConsolidation(id);
    }, [id, closeRightPanel, cancelConsolidation]);

    const toggleCanceling = () => setCanceling(!isCanceling);

    useEffect(() => {
      if (isOpen && !detailed.size && id) {
        getDetailedItem(id)
          .then(() => getCustomsDeclarations(id).catch(toastResponseError))
          .catch(toastResponseError);
      }
    }, [detailed, isOpen, getDetailedItem, getCustomsDeclarations, id]);

    const parcelInfoPanel = () => {
      if (isCancelAndPayAvaiable) {
        return isCanceling ? (
          <Content>
            <CancelPacking
              handleCancelPacking={handleCancelPacking}
              handleConfirmPacking={toggleCanceling}
            />
          </Content>
        ) : (
          <ParcelInfoTab onPackingCancel={toggleCanceling} id={id} />
        );
      } else {
        return <ParcelInfoTab id={id} />;
      }
    };

    const renderCustomsDelaration = () => {
      return (
        <>
          <DeclarationTotal>
            {`${t("shipping.total")}: ${headerData.size} ${t(
              "shipping.totalRows",
            )} - $${headerData.total} `}
            <USD>USD</USD>
          </DeclarationTotal>
          {isOpen && (
            <CustomsDeclarationV2
              itemId={id}
              readOnly={!editCustomsData}
              declarations={declarations}
              create={createCustomsDeclaration}
              update={updateCustomsDeclaration}
              remove={deleteCustomsDeclaration}
              onCustomsDataChange={handleCustomsDataChange}
              noPadding
              isShowHSCodeColumn={isShowHSCodeColumn}
              isHSCodeValidationRequired
            />
          )}
        </>
      );
    };

    const renderCustomsDelarationInfoPanel = () => {
      return (
        <>
          <CustomsDeclarationContent>
            {isLoadingCustomsDeclaration ? (
              <Spinner isActive />
            ) : (
              renderCustomsDelaration()
            )}
          </CustomsDeclarationContent>
        </>
      );
    };

    const handleCustomsDataChange = (newValues: any[]) => {
      const [newTotal, newLength] = calculateCustomsDataTotal(newValues);
      setHeaderData({
        size: newLength,
        total: newTotal,
      });
    };

    return (
      <Wrapper>
        <Spinner isActive={isLoadingDetailedItem} />
        {shouldRender && (
          <>
            <Heading>
              {heading}
              <HeaderDate>{`${t("common.from")} ${createdAtStr}`}</HeaderDate>
            </Heading>

            <TabsView openTab={panelData.get("openTab")}>
              <Tab title={t("parcels.details")}>{parcelInfoPanel()}</Tab>
              <Tab title={t("parcels.customsData")}>
                {renderCustomsDelarationInfoPanel()}
              </Tab>
            </TabsView>
          </>
        )}
      </Wrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    detailedItem: outgoingSelectDetailedItems,
    isLoadingDetailedItem: outgoingSelectDetailedLoading,
    isLoadingCustomsDeclaration: outgoingSelectCustomsDeclarationImporting,
  }),
  {
    getDetailedItem,
    closeRightPanel,
    cancelConsolidation,
    getCustomsDeclarations,
    createCustomsDeclaration,
    updateCustomsDeclaration,
    deleteCustomsDeclaration,
  },
);

export default withConnect(ConsolidationPanel);
