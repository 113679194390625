import CancelConfirmation from "./components/CancelConfirmation/CancelConfirmation";
import CheckCustomsData from "./components/CheckCustomsData/CheckCustomsData";
import ChooseAdditionalForm from "./components/ChooseForms/ChooseAdditionalForm/ChooseAdditionalForm";
import ChooseAddressForm from "./components/ChooseForms/ChooseAddressForm";
import ChooseDeliveryForm from "./components/ChooseForms/ChooseDeliveryForm";
import ChoosePackingForm from "./components/ChooseForms/ChoosePackingForm";
import SideDeliveryInfo from "./components/SideDeliveryInfo";
import SummaryForm from "./components/Summary/SummaryForm";

export const deliveryRoutes = [
  {
    path: "flow",
    DeliveryComponent: ChooseAddressForm,
  },
  {
    path: "flow/delivery",
    key: "delivery",
    DeliveryComponent: ChooseDeliveryForm,
    redirect: "shipping/flow",
  },
  {
    path: "flow/delivery/packing",
    DeliveryComponent: ChoosePackingForm,
    key: "packing",
    redirect: "shipping/flow",
  },
  {
    path: "flow/delivery/additional",
    DeliveryComponent: ChooseAdditionalForm,
    key: "addons",
    redirect: "shipping/flow",
  },
  {
    path: "flow/customs-data",
    DeliveryComponent: CheckCustomsData,
    key: "customInformation",
    redirect: "shipping",
  },
  {
    path: "summary",
    DeliveryComponent: SummaryForm,
  },
  {
    path: "cancel",
    DeliveryComponent: CancelConfirmation,
  },
  {
    path: "/shipping/flow/customs-data",
    DeliveryComponent: SideDeliveryInfo,
  },
];
