import React, {
  ChangeEvent,
  FC,
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ButtonColor, ButtonSize, ButtonVariant } from "enums/Button";
import { useFormik } from "formik";
import { List, Map } from "immutable";
import { filter, isEqual } from "lodash";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { StorageSearchValues } from "types/StorageForms/Search";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import useFetchOnceEffect from "../../hooks/useFetchOnceEffect";
import useProgressDelivery from "../../hooks/useProgressDelivery";
import injectReducer from "../../reduxFolder/injectReducer";
import { addParcels, getCart } from "../../reduxFolder/reducers/cart";
import { openRightPanel } from "../../reduxFolder/reducers/rightPanel";
import serviceRequestsReducer from "../../reduxFolder/reducers/serviceRequests";
import { getAddonsMethods } from "../../reduxFolder/reducers/services";
import storageReducer, {
  addParcelsToCart,
  deselectAll,
  getShops,
  getStorage,
  selectAll,
  selectParcel,
  updateStorage,
} from "../../reduxFolder/reducers/storage";
import {
  cartSelectIsEmpty,
  cartSelectLoading,
} from "../../reduxFolder/selectors/cartSelectors";
import {
  authSelectAllowSearchByLocation,
  authSelectFfid,
  authSelectUnits,
} from "../../reduxFolder/selectors/globalSelectors";
import { servicesSelectAddonsAll } from "../../reduxFolder/selectors/servicesSelectors";
import {
  storageSelectHasItems,
  storageSelectItems,
  storageSelectLoading,
  storageSelectParams,
  storageSelectSelected,
  storageSelectShops,
  storageSelectTotalAmount,
  storageSelectTotalWeight,
  storageSelectTransferringItems,
} from "../../reduxFolder/selectors/storageSelectors";
import {
  ListHeader,
  ListHeaderTitle,
  StorageButton,
} from "../../styles/parcelsStyles";
import { OpenRightPanel } from "../../types/common/rightPanel";
import CartInfo from "./components/CartInfo/CartInfo";
import NoItemsPlaceholder from "./components/NoItemsPlaceholder/NoItemsPlaceholder";
import CompactSearch from "./components/Search/CompactSearch";
import Search from "./components/Search/Search";
import { ResetFilterButton } from "./components/Search/Search.styles";
import { searchFormik } from "./components/Search/searchFormik";
import SearchPlaceholder from "./components/SearchPlaceholder/SearchPlaceholder";
import SelectedInfo from "./components/SelectedInfo/SelectedInfo";
import { isUnprocessedRequest } from "./components/ServiceRequests/requestHelper";
import StorageList from "./components/StorageList/StorageList";
import {
  Buttons,
  ButtonsWrapper,
  SelectAllSwitchWrapper,
  StorageHeading,
  StyledGrid,
  Wrapper,
} from "./Storage.styles";

interface StorageProps {
  units: string;
  isLoading: boolean;
  isCartLoading: boolean;
  isCartEmpty: boolean;
  allowSearchByLocation: boolean;
  storageHasItems: boolean;
  storageItems: List<any>;
  storageTransferringItems: List<any>;
  shops: any;
  addons: any;
  params: any;
  userFfid: string;
  totalAmount: number;
  totalWeight: number;
  getAddonsMethods: () => Promise<void>;
  addParcelsToCart: (data: any) => void;
  getCart: () => Promise<any>;
  selectAll: () => void;
  deselectAll: () => void;
  getStorage: (data?: any) => void;
  getShops: () => Promise<void>;
  selectParcel: any;
  selectedParcels: any;
  openRightPanel: OpenRightPanel;
}

const Storage: FC<StorageProps> = ({
  units,
  storageItems,
  storageTransferringItems,
  isLoading,
  isCartLoading,
  totalAmount,
  totalWeight,
  selectAll,
  deselectAll,
  addParcelsToCart,
  getStorage,
  getShops,
  selectParcel,
  selectedParcels,
  shops,
  storageHasItems,
  params,
  getCart,
  isCartEmpty,
  userFfid,
  allowSearchByLocation,
  getAddonsMethods,
  addons,
  openRightPanel,
}) => {
  const navigate = useNavigate();
  const [handleResetCompactFunction, setHandleResetCompactFunction] =
    useState<Function>(() => null);
  const [, setAccess] = useProgressDelivery(userFfid);
  const { t } = useTranslation("common");

  const storageHasTransferringItems = !!storageTransferringItems.size;

  // Always first show "transferring items" but only if there is no searchTerm.
  // If there is any filter applied then just show storage items.
  const extractedFilters = params.get("filter");
  const hasAnyFiltersApplied =
    filter(
      extractedFilters.toJS ? extractedFilters.toJS() : extractedFilters,
      (x) => x !== null && x !== undefined,
    ).length > 0;

  // If we are showing transferringItems then adjust both "totalAmount" and "totalWeight"
  const totalAmountWithTransferring = hasAnyFiltersApplied
    ? totalAmount
    : totalAmount + storageTransferringItems.size;
  const totalWeightWithTransferring = hasAnyFiltersApplied
    ? totalWeight
    : totalWeight +
      storageTransferringItems.reduce(
        (sum: number, information: any) => sum + information.get("weight"),
        0,
      );

  // This also affects other booleans
  const hasAnyItems = hasAnyFiltersApplied
    ? storageHasItems
    : storageHasItems || storageHasTransferringItems;

  const parcelsListAll = hasAnyFiltersApplied
    ? storageItems
    : storageTransferringItems.concat(storageItems);

  const hasSelected = storageHasItems && !!selectedParcels.size;
  const activeItems = useMemo(
    () =>
      storageItems.filter((item: any) => {
        const requests = item.get("serviceRequestCount");
        const isProhibited = item.get("prohibited");

        if (isProhibited && isProhibited === true) return false;
        if (!requests.size) return true;
        return !requests.find((request: string) => request === "unprocessed");
      }),
    [storageItems],
  );

  const isAllSelected =
    !isLoading && hasSelected && activeItems.size === selectedParcels.size;

  useFetchOnceEffect(!isCartLoading, getCart);
  useFetchOnceEffect(!shops.size, getShops);
  useFetchOnceEffect(!addons.size, getAddonsMethods);
  useEffect(() => {
    getStorage();
  }, [getStorage]);

  const onPagination = useCallback(
    () =>
      !isLoading &&
      getStorage(params.update("page", (page: number) => page + 1)),
    [getStorage, isLoading, params],
  );

  const selectedRangeParcels = useCallback(
    (endParcelId: string) => {
      const getIndexParcel = (parcelId: string) =>
        storageItems.findIndex((item: any) => item.get("id") === parcelId);
      const startSelected = getIndexParcel(selectedParcels.get(0));
      const endSelected = getIndexParcel(endParcelId);
      const from = Math.min(startSelected, endSelected);
      const to = Math.max(startSelected, endSelected);
      let selectedItems: any = List([]);

      for (let index = from; index <= to; index++) {
        const serviceRequestCount: any = storageItems.getIn([
          index,
          "serviceRequestCount",
        ]);
        const isProhibited = storageItems.getIn([index, "prohibited"]);
        if (
          !isUnprocessedRequest(serviceRequestCount) &&
          isProhibited !== true
        ) {
          selectedItems = selectedItems.push(storageItems.getIn([index, "id"]));
        }
      }

      return selectedItems;
    },
    [selectedParcels, storageItems],
  );

  const handleSelectAll = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.persist();
      selectAll();
    },
    [selectAll],
  );

  const handleDeselectAll = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.persist();
      deselectAll();
    },
    [deselectAll],
  );

  const handleToggleSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      if (isAllSelected === true) {
        deselectAll();
      } else {
        selectAll();
      }
    },
    [selectAll, deselectAll, isAllSelected],
  );

  const handleSend = useCallback(() => {
    if (!hasSelected && !isCartEmpty) {
      navigate("/shipping");
      return;
    }

    addParcelsToCart({ id: selectedParcels });
    if (isCartEmpty) {
      setAccess("initialSteps");
    }
  }, [selectedParcels, hasSelected, isCartEmpty, addParcelsToCart]);

  const handleBulkTransfer = useCallback(() => {
    const ids = selectedParcels.toJS();
    openRightPanel(
      "SERVICE_REQUEST",
      Map({
        id: ids[0],
        state: "received",
        requestType: "requestTransferShipment",
        preventReLoading: true,
      }),
    );
  }, [openRightPanel, selectedParcels]);

  const noItems = !isLoading && !hasAnyItems;

  const handleGetStorageSubmit = (values: any) => {
    return getStorage(values);
  };

  const formOptions = useFormik<StorageSearchValues>(
    searchFormik(handleGetStorageSubmit),
  );

  const { values, initialValues, handleSubmit, handleReset } = formOptions;
  const isSearchDirty = !isLoading && !isEqual(values, initialValues);

  const handleResetClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleReset(e);
      handleSubmit();
    },
    [handleReset, handleSubmit],
  );

  const handleResetCompactClick = () => {
    if (!handleResetCompactFunction) return;
    handleResetCompactFunction();
  };

  const handleSetResetCompactFunction = (func: Function) =>
    setHandleResetCompactFunction(func);

  return (
    <Wrapper>
      <StyledGrid fluid>
        <LoadingBar isLoading={isLoading} isCartLoading={isCartLoading} />
        <StorageHeading>
          {hasAnyItems && (
            <ListHeader>
              <Visible xs>
                <SelectAllSwitchWrapper>
                  <Checkbox
                    checked={isAllSelected}
                    onChange={handleToggleSelectAll}
                  />
                </SelectAllSwitchWrapper>
              </Visible>
              <ListHeaderTitle>
                {t("parcels.total", { amount: totalAmountWithTransferring })} /{" "}
                {`${totalWeightWithTransferring.toFixed(2)} ${t(`units.${units}.primaryShort`)}`}
              </ListHeaderTitle>
              <Hidden xs>
                {isAllSelected ? (
                  <StorageButton onClick={handleDeselectAll}>
                    {t("common.deselect")}
                  </StorageButton>
                ) : (
                  <StorageButton onClick={handleSelectAll}>
                    {t("common.selectAll")}
                  </StorageButton>
                )}
                {isSearchDirty && (
                  <ResetFilterButton
                    color={ButtonColor.Red}
                    onClick={handleResetClick}
                  >
                    {t("parcels.search.resetFilter")}
                  </ResetFilterButton>
                )}
              </Hidden>
              <Visible xs>
                {isSearchDirty && (
                  <ResetFilterButton
                    color={ButtonColor.Red}
                    onClick={handleResetCompactClick}
                  >
                    {t("parcels.search.resetFilter")}
                  </ResetFilterButton>
                )}
              </Visible>
            </ListHeader>
          )}
          {(hasAnyItems || noItems) && (
            <>
              <Hidden xs>
                <Search
                  allowSearchByLocation={allowSearchByLocation}
                  formOptions={formOptions}
                />
              </Hidden>

              <Visible xs>
                <CompactSearch
                  formOptions={formOptions}
                  handleSetResetCompactFunction={handleSetResetCompactFunction}
                />
              </Visible>
            </>
          )}
        </StorageHeading>

        {hasAnyItems && (
          <StorageList
            addons={addons}
            units={units}
            parcels={parcelsListAll}
            totalAmount={totalAmount}
            selectedParcels={selectedParcels}
            handleSelect={selectParcel}
            onPagination={onPagination}
            selectedRangeParcels={selectedRangeParcels}
            disabled={isCartLoading}
          />
        )}
        {(hasSelected || !isCartEmpty) && (
          <ButtonsWrapper>
            <Buttons>
              {hasSelected ? <SelectedInfo /> : <CartInfo />}
              <Button
                variant={ButtonVariant.Filled}
                color={
                  hasSelected ? ButtonColor.Primary : ButtonColor.Secondary
                }
                size={ButtonSize.Large}
                disabled={isCartLoading}
                onClick={handleSend}
              >
                {hasSelected ? t("common.addToCart") : t("common.sendTheCart")}
              </Button>
              {hasSelected && (
                <Button
                  variant={ButtonVariant.Filled}
                  color={ButtonColor.Secondary}
                  size={ButtonSize.Large}
                  disabled={isCartLoading}
                  onClick={handleBulkTransfer}
                >
                  {t("serviceRequests.requestTransferShipment.title")}
                </Button>
              )}
              {hasSelected && (
                <StorageButton
                  disabled={isCartLoading}
                  onClick={handleDeselectAll}
                >
                  {t("common.deselect")}
                </StorageButton>
              )}
            </Buttons>
          </ButtonsWrapper>
        )}
        {noItems && <NoItemsPlaceholder />}
        <Hidden xs>
          {noItems && <SearchPlaceholder handleReset={handleResetClick} />}
        </Hidden>
        <Visible xs>
          {noItems && (
            <SearchPlaceholder handleReset={handleResetCompactClick} />
          )}
        </Visible>
      </StyledGrid>
    </Wrapper>
  );
};

const withConnect = connect(
  createStructuredSelector({
    userFfid: authSelectFfid,
    allowSearchByLocation: authSelectAllowSearchByLocation,
    storageItems: storageSelectItems,
    storageTransferringItems: storageSelectTransferringItems,
    totalAmount: storageSelectTotalAmount,
    totalWeight: storageSelectTotalWeight,
    storageHasItems: storageSelectHasItems,
    isLoading: storageSelectLoading,
    isCartLoading: cartSelectLoading,
    selectedParcels: storageSelectSelected,
    params: storageSelectParams,
    shops: storageSelectShops,
    units: authSelectUnits,
    isCartEmpty: cartSelectIsEmpty,
    addons: servicesSelectAddonsAll,
  }),
  {
    addParcelsToCart,
    getCart,
    getStorage,
    selectAll,
    deselectAll,
    selectParcel,
    updateStorage,
    addParcels,
    getShops,
    getAddonsMethods,
    openRightPanel,
  },
);

const withStorageReducer = injectReducer({
  key: "storage",
  reducer: storageReducer,
});

const withServiceRequestsReducer = injectReducer({
  key: "serviceRequsets",
  reducer: serviceRequestsReducer,
});

const enhance = compose(
  withStorageReducer,
  withServiceRequestsReducer,
  withConnect,
  memo,
);

export default enhance(Storage);
