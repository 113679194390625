import React, { FC } from "react";
import { Map } from "immutable";
import { connect } from "react-redux";
import { Waypoint } from "react-waypoint";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  getShipment,
  setSelectedStorageItemId,
  updateComment,
} from "../../../../reduxFolder/reducers/storage";
import { selectedStorageItemId } from "../../../../reduxFolder/selectors/storageSelectors";
import StorageItemRow from "./StorageItemRow";
import { Spinner, Wrapper } from "./StorageList.styles";

interface StorageListProps {
  disabled: boolean;
  units: string;
  parcels: any;
  addons: any;
  totalAmount: number;
  selectedParcels: number[];
  selectedStorageItemId: number | null;
  onPagination: () => void;
  handleSelect: (selectParcel: any) => void;
  selectedRangeParcels: (endParcelId: any) => void;
  openRightPanel: OpenRightPanel;
  getShipment: (id: string) => Promise<any>;
  updateComment: (id: string, data: { customer_comment: any }) => Promise<any>;
  setSelectedStorageItemId: (id: number | null) => void;
}

const StorageList: FC<StorageListProps> = React.memo(
  ({
    addons,
    units,
    parcels,
    totalAmount,
    selectedParcels,
    handleSelect,
    selectedRangeParcels,
    onPagination,
    disabled,
    openRightPanel,
    getShipment,
    updateComment,
    selectedStorageItemId,
    setSelectedStorageItemId,
  }) => {
    const hasMore = totalAmount > parcels.size;

    const handleFocusStorageItemInPanel = (parcel: any) => {
      setSelectedStorageItemId(parcel.get("id"));
      openRightPanel(
        "STORAGE_ITEM",
        Map({
          getShipment,
          updateComment,
          hasInventoryItems: parcel.get("items_quantity", 0) > 1,
          originalItem: parcel,
          isCartVariant: true,
        }).merge(parcel),
      );
    };

    return (
      <Wrapper>
        {parcels.map((parcel: any) => (
          <StorageItemRow
            parcel={parcel}
            selectedParcel={selectedStorageItemId}
            onFocusStorageItemInPanel={handleFocusStorageItemInPanel}
            key={parcel.get("id")}
            isChecked={selectedParcels.includes(parcel.get("id"))}
            disabled={disabled}
            units={units}
            addons={addons}
            handleSelect={handleSelect}
            selectedRangeParcels={selectedRangeParcels}
          />
        ))}
        {hasMore && (
          <>
            <Waypoint onEnter={onPagination} />
            <Spinner key={0} isActive />
          </>
        )}
      </Wrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    selectedStorageItemId: selectedStorageItemId,
  }),
  {
    openRightPanel,
    getShipment,
    updateComment,
    setSelectedStorageItemId,
  },
);

export default withConnect(StorageList);
