import React, { FC, memo, useEffect } from "react";
import { Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { DashboardResponseDto } from "types/api/dashboard";
import WarehouseAddress from "../../../../components/WarehouseAddress/WarehouseAddress";
import injectReducer from "../../../../reduxFolder/injectReducer";
import dashboardReducer, {
  getDashboard,
} from "../../../../reduxFolder/reducers/dashboard";
import {
  dashboardSelectAddress,
  dashboardSelectLoading,
  dashboardSelectUserFfid,
} from "../../../../reduxFolder/selectors/dashboardSelectors";
import { authSelectFullName } from "../../../../reduxFolder/selectors/globalSelectors";
import {
  ColWrapper,
  Content,
  Paragraph,
  PlaceholderIcon,
  PlaceholderTitle,
  Wrapper,
} from "../../../../styles/placeholderStyles";
import placeholderIcon from "../../images/storage-placeholder.svg";
import { AddressWrapper, Spinner } from "./NoItemsPlaceholder.styles";

interface NoItemsPlaceholderProps {
  isLoading: boolean;
  userFfid: string;
  userFullName: string;
  warehouseAddress?: string;
  getDashboard: () => Promise<DashboardResponseDto>;
}

const NoItemsPlaceholder: FC<NoItemsPlaceholderProps> = ({
  isLoading,
  warehouseAddress,
  userFfid,
  userFullName,
  getDashboard,
}) => {
  const { t } = useTranslation("common");

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  return (
    <Wrapper>
      <Row>
        <ColWrapper md={6} xs={12}>
          <Content className="separator">
            <PlaceholderIcon
              src={placeholderIcon}
              alt={t("parcels.placeholderIcon")}
            />
            <Paragraph>{t("parcels.youDontAnyExpectedParcels")}</Paragraph>
          </Content>
        </ColWrapper>
        <ColWrapper md={6} xs={12}>
          <Content>
            <PlaceholderTitle>
              {t("parcels.youCouldStartOrder")}
            </PlaceholderTitle>
            <Spinner isActive={isLoading} />
            {warehouseAddress && (
              <AddressWrapper>
                <WarehouseAddress
                  userFfid={userFfid}
                  fullName={userFullName}
                  warehouseAddress={warehouseAddress}
                />
              </AddressWrapper>
            )}
          </Content>
        </ColWrapper>
      </Row>
    </Wrapper>
  );
};

const withDashboardReducer = injectReducer({
  key: "dashboard",
  reducer: dashboardReducer,
});

const withConnect = connect(
  createStructuredSelector({
    userFfid: dashboardSelectUserFfid,
    warehouseAddress: dashboardSelectAddress,
    isLoading: dashboardSelectLoading,
    userFullName: authSelectFullName,
  }),
  { getDashboard },
);

const enhance = compose(withDashboardReducer, withConnect, memo);

export default enhance(NoItemsPlaceholder);
