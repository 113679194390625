import React, { FC } from "react";
import { List } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import {
  cartSelectCartDeliveryMethod,
  cartSelectEstimate,
  cartSelectForOutgoing,
} from "../../../../reduxFolder/selectors/cartSelectors";
import { authSelectUnits } from "../../../../reduxFolder/selectors/globalSelectors";
import { Currency } from "../../../../styles/parcelsStyles";
import formatter from "../../../../utils/formatter";
import { SideItem, SideItemHead } from "../../styles/Delivery.styles";
import {
  DashConnector,
  PriceItem,
  PriceWrapper,
  TotalCostWrapper,
  TotalPrice,
  Wrapper,
} from "./TotalPackingCost.styles";

interface TotalPackingCostProps {
  estimate: any;
  delivery: string;
  units: string;
  userCart: any;
}

const TotalPackingCost: FC<TotalPackingCostProps> = React.memo(
  ({ estimate, delivery, userCart, units }) => {
    const { t } = useTranslation("common");

    const deliveryCost = parseFloat(estimate.get("price", 0));
    const packingGradePrice = parseFloat(
      estimate.get("optional_lines_cost", 0),
    );
    const servicesPrice = parseFloat(estimate.get("service_requests_cost", 0));
    const packingOptionsPrice =
      parseFloat(estimate.get("items_addons_cost", 0)) +
      parseFloat(estimate.get("consolidation_addons_cost", 0));

    const totalPrice =
      deliveryCost + packingOptionsPrice + packingGradePrice + servicesPrice;

    const consolidationItem = userCart.get("items", List()).first();
    const weight = consolidationItem?.get("weight") || 0;

    return (
      <>
        <SideItem>
          <SideItemHead>{t("shipping.expectedСost")}:</SideItemHead>
          <Wrapper>
            <PriceItem>
              {`${delivery} / ${weight} ${t(`units.${units}.primaryShort`)}`}
              <DashConnector />
              <PriceWrapper>
                <Currency>$</Currency>
                {deliveryCost}
              </PriceWrapper>
            </PriceItem>
            <PriceItem>
              {t("parcels.packingGrade")}
              <DashConnector />
              <PriceWrapper>
                <Currency>$</Currency>
                {packingGradePrice}
              </PriceWrapper>
            </PriceItem>
            <PriceItem>
              {t("shipping.services")}
              <DashConnector />
              <PriceWrapper>
                <Currency>$</Currency>
                {servicesPrice}
              </PriceWrapper>
            </PriceItem>
            <PriceItem>
              {t("parcels.packingOptions")}
              <DashConnector />
              <PriceWrapper>
                <Currency>$</Currency>
                {packingOptionsPrice}
              </PriceWrapper>
            </PriceItem>
          </Wrapper>
        </SideItem>
        <SideItem>
          <TotalCostWrapper>
            <TotalPrice>{`${t("shipping.totalExpectedСost")}:`}</TotalPrice>
            <strong>&nbsp;{`$${formatter.currency(totalPrice)}`}</strong>
          </TotalCostWrapper>
        </SideItem>
      </>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    estimate: cartSelectEstimate,
    units: authSelectUnits,
    delivery: cartSelectCartDeliveryMethod,
    userCart: cartSelectForOutgoing,
  }),
);

export default withConnect(TotalPackingCost);
