import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const initialState = fromJS({
  isVisible: false,
  imageSource: null,
  relativePosition: { x: 0, y: 0 },
  zoomLevel: 2,
});

// actions
const SHOW_FULLSCREEN_IMAGE = 'fullscreenImage/SHOW_FULLSCREEN_IMAGE';
const HIDE_FULLSCREEN_IMAGE = 'fullscreenImage/HIDE_FULLSCREEN_IMAGE';
const SET_FULLSCREEN_IMAGE = 'fullscreenImage/SET_FULLSCREEN_IMAGE';
const SET_FULLSCREEN_IMAGE_POSITION =
  'fullscreenImage/SET_FULLSCREEN_IMAGE_POSITION';

// actions creators
export const showFullscreenImage = createAction(SHOW_FULLSCREEN_IMAGE);
export const hideFullscreenImage = createAction(HIDE_FULLSCREEN_IMAGE);
export const setFullscreenImage = createAction(SET_FULLSCREEN_IMAGE);
export const setFullscreenImagePosition = createAction(
  SET_FULLSCREEN_IMAGE_POSITION,
);

// reducer
const rightPanelReducer = handleActions(
  {
    [SHOW_FULLSCREEN_IMAGE]: (state, { payload }) =>
      state
        .set('isVisible', true)
        .set(
          'zoomLevel',
          payload.zoomLevel ? payload.zoomLevel : initialState.zoomLevel,
        ),

    [HIDE_FULLSCREEN_IMAGE]: (state) => state.set('isVisible', false),
    [SET_FULLSCREEN_IMAGE]: (state, { payload }) =>
      state.set('imageSource', payload),
    [SET_FULLSCREEN_IMAGE_POSITION]: (state, { payload }) =>
      state.set('relativePosition', payload),
  },
  initialState,
);

export default rightPanelReducer;
