import React, { FC, useCallback } from "react";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import { getCountriesOptions } from "containers/SignUp/signUpHelper";
import { Col, Container } from "react-grid-system";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { authSelectLocale } from "reduxFolder/selectors/globalSelectors";
import { createStructuredSelector } from "reselect";
import { breakpoints } from "styles/styles";
import { BooksAddFundsFormProps } from "types/books";
import {
  AddFundsFormRow,
  BooksAddFundsFormWrapper,
} from "../../styles/BooksAddFunds.style";
import BooksAddFundsBlock from "./BooksAddFundsBlock";

const BooksAddFundsForm: FC<BooksAddFundsFormProps> = React.memo(
  ({
    values,
    handleChange,
    errors: { address = {}, cardDetails = {} },
    touched: touchedProp,
    handleBlur,
    setFieldValue,
    currentLocale,
  }) => {
    const { t } = useTranslation("common");
    const handleCountryChange = useCallback(
      (value: string) => setFieldValue("address.country", value),
      [setFieldValue],
    );
    const touched = {
      address: {},
      cardDetails: {},
      ...touchedProp,
    };
    const isMobileBreakpoint = +window.innerWidth <= +breakpoints.xs;

    return (
      <BooksAddFundsFormWrapper>
        <Container fluid>
          {isMobileBreakpoint && (
            <AddFundsFormRow>
              <Col xs={12}>
                <BooksAddFundsBlock isBlockPanel isPanelOpened={false} />
              </Col>
            </AddFundsFormRow>
          )}
          <AddFundsFormRow>
            <Col xs={12}>
              <Input
                placeholder={t("purchase.creditCard.name")}
                name="cardDetails.name"
                value={values.cardDetails.name}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={cardDetails.name}
                invalid={touched.cardDetails.name && !!cardDetails.name}
              />
            </Col>
          </AddFundsFormRow>
          <AddFundsFormRow>
            <Col xs={12}>
              <InputMask
                placeholder={t("purchase.creditCard.number")}
                name="cardDetails.number"
                value={values.cardDetails.number}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={cardDetails.number}
                invalid={touched.cardDetails.number && !!cardDetails.number}
                mask={"9999 9999 9999 9999"}
              >
                {/* @ts-ignore */}
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Col>
          </AddFundsFormRow>
          <AddFundsFormRow>
            <Col xs={6}>
              <InputMask
                placeholder={t("purchase.creditCard.expiration")}
                name="cardDetails.expiration"
                value={values.cardDetails.expiration}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={cardDetails.expiration}
                invalid={
                  touched.cardDetails.expiration && !!cardDetails.expiration
                }
                mask={"99/9999"}
              >
                {/* @ts-ignore */}
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Col>
            <Col xs={6}>
              <Input
                placeholder={t("purchase.creditCard.cvv")}
                name="cardDetails.cvv"
                value={values.cardDetails.cvv}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={cardDetails.cvv}
                invalid={touched.cardDetails.cvv && !!cardDetails.cvv}
              />
            </Col>
          </AddFundsFormRow>
        </Container>
        <Container fluid>
          <AddFundsFormRow>
            <Col xs={12}>
              <Select
                placeholder={t("auth.country")}
                name="address.country"
                options={getCountriesOptions(currentLocale)}
                value={values.address.country}
                onBlur={handleBlur}
                onChange={handleCountryChange}
              />
            </Col>
          </AddFundsFormRow>
          <AddFundsFormRow>
            <Col xs={12}>
              <Input
                placeholder={t("auth.address1")}
                name="address.address1"
                value={values.address.address1}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={address.address1}
                invalid={touched.address.address1 && !!address.address1}
              />
            </Col>
          </AddFundsFormRow>
          <AddFundsFormRow>
            <Col xs={12}>
              <Input
                placeholder={t("auth.address2")}
                name="address.address2"
                value={values.address.address2}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={address.address2}
                invalid={touched.address.address2 && !!address.address2}
              />
            </Col>
          </AddFundsFormRow>
          <AddFundsFormRow>
            <Col xs={12}>
              <Input
                placeholder={t("auth.city")}
                name="address.city"
                value={values.address.city}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={address.city}
                invalid={touched.address.city && !!address.city}
              />
            </Col>
          </AddFundsFormRow>
          <AddFundsFormRow>
            <Col xs={6}>
              <Input
                placeholder={t("auth.stateOrRegion.US")}
                name="address.state"
                value={values.address.state}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={address.state}
                invalid={touched.address.state && !!address.state}
              />
            </Col>
            <Col xs={6}>
              <Input
                placeholder={t("auth.postalCode")}
                name="address.postalCode"
                value={values.address.postalCode}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={address.postalCode}
                invalid={touched.address.postalCode && !!address.postalCode}
              />
            </Col>
          </AddFundsFormRow>
        </Container>
      </BooksAddFundsFormWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    currentLocale: authSelectLocale,
  }),
);

export default withConnect(BooksAddFundsForm);
