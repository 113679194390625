import PreLoader from "components/PreLoader/PreLoader";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Spinner = styled(PreLoader)`
  position: absolute;
  width: 20px;
  height: 14px;
  transform-origin: 0 10px;
  transform: scale(0.5);
`;

export const Header = styled.div`
  margin-bottom: 35px;
`;
