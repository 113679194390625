import Icon from "components/Icon/Icon";
import Tooltip from "components/Tooltip/Tooltip";
import styled from "styled-components";

interface RequestsProps {
  $fullWidth: boolean;
}

export const Requests = styled.div<RequestsProps>`
  display: flex;

  ${({ $fullWidth }) => ($fullWidth ? "width: 100%;" : null)}

  ${({ theme }) => theme.md`
        justify-content: flex-end;
      `}
`;

interface StorageIconProps {
  $isProcessed: boolean;
}

export const StorageIcon = styled(Icon)<StorageIconProps>`
  height: 16px;
  width: 16px;
  margin: 5px 10px;
  background-color: ${({ theme, $isProcessed }) =>
    $isProcessed ? theme.secondary : theme.lightgrey};

  ${({ theme }) => theme.sm`
    height: 12px;
    width: 12px;
    margin: 5px;
  `}
`;

export const ProcessedIcon = styled(Icon)`
  height: 20px;
  width: 20px;
  margin-bottom: 10px;
`;

export const ProcessedContainer = styled.div`
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Status = styled.div`
  margin-bottom: 10px;
`;

interface ServiceTooltipProps {
  $zIndex: number;
}

export const ServiceTooltip = styled(Tooltip)<ServiceTooltipProps>`
  z-index: ${({ $zIndex }) => $zIndex} !important;
`;
