import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import formatter from "../../utils/formatter";

interface PackageSizesProps {
  width: number;
  height: number;
  depth: number;
  units: string;
}

const PackageSizes: FC<PackageSizesProps> = ({
  width,
  height,
  depth,
  units,
}) => {
  const { t } = useTranslation("common");

  return (
    <Text>
      {formatter.size(width)}
      <Divider />
      {formatter.size(height)}
      <Divider />
      {formatter.size(depth)} {t(`units.${units}.secondaryShort`)}
    </Text>
  );
};

export default memo(PackageSizes);

const Text = styled.span`
  color: black !important;
`;

const Divider = styled.span`
  &:before {
    content: "x";
  }
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.5);

  ${(props) => props.theme.xs`
    margin: 0 2px;
  `}
`;
