import React, { FC, memo } from "react";
import Slider, { SliderProps } from "rc-slider";
import Tooltip from "rc-tooltip";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { LabelText } from "../../styles/formStyles";
import { RangeLabels, RangeWrapper, Wrapper } from "./RangeSlider.styles";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap_white.css";

interface RangeSliderProps extends SliderProps {
  label?: string;
  value: number[];
  units: string;
  className?: string;
}

const RangeSlider: FC<RangeSliderProps> = ({
  label = "",
  value,
  min,
  max,
  units = "",
  className = "",
  ...props
}) => {
  const { t } = useTranslation("common");
  const tipFormatter = (value: number): string => `${value} ${units}`;

  return (
    <Wrapper className={className}>
      {label && <LabelText>{label}</LabelText>}
      <RangeLabels>
        <span>{t("common.from")}</span>
        <span>{t("common.to")}</span>
      </RangeLabels>
      <RangeWrapper $isActive={!!value.length}>
        <Slider
          value={value}
          min={min}
          max={max}
          handleRender={(node, handleProps) => {
            return (
              <Tooltip
                overlayStyle={{ minHeight: "auto" }}
                overlay={tipFormatter(handleProps.value)}
                placement={isMobile ? "top" : "bottom"}
              >
                {node}
              </Tooltip>
            );
          }}
          range
          {...props}
        />
      </RangeWrapper>
    </Wrapper>
  );
};

export default memo(RangeSlider);
