import React, { FC } from "react";
import packageIcon from "components/Icon/icons/package.svg";
import { getAddonsIconsImage } from "containers/Delivery/deliveryHelper";
import { IconType } from "enums/Icon";
import { List } from "immutable";
import { requestNames } from "reduxFolder/reducers/serviceRequests";
import {
  HeadTextCompact,
  ItemCompact,
  ItemIcon,
  ItemLogo,
  ListItemCompact,
  ServiceRequestIcon,
} from "../SideAddonsInfo.styles";

interface SideAddonItemProps {
  addons: any;
  cartItem: any;
  consolidationAddons?: any;
  itemsConsolidationAddons?: any;
  activeItem: { value: string; label: string };
  onActiveItemChange: (value: any) => void;
  itemAddons: string[];
}

const SideAddonItem: FC<SideAddonItemProps> = ({
  itemAddons,
  addons,
  cartItem,
  activeItem,
  onActiveItemChange,
}) => {
  const filteredItemAddons = itemAddons.filter((x) =>
    addons.some((a: any) => a.get("id") === x),
  );
  const allAddons = addons.size ? List(addons) : List();
  const logoUrl = cartItem.get("emblem_thumb_url") || packageIcon;
  const serviceRequests = cartItem.get("service_requests", List());
  const isSelected = activeItem.value === cartItem.get("id");

  let mainDescription = cartItem.get("description");
  if (!mainDescription) {
    mainDescription = cartItem.get("shipment_tracking_number");
  }

  const handleCartItemClick = () => {
    const nextSelected = isSelected
      ? { value: "all" }
      : {
          value: cartItem.get("id"),
          label: cartItem.get("shipment_tracking_number"),
          image: cartItem.get("emblem_thumb_url"),
          description: cartItem.get("customer_comment"),
          serviceRequests: cartItem.get("service_requests"),
        };
    onActiveItemChange(nextSelected);
  };

  return (
    <ItemCompact onClick={handleCartItemClick} isSelected={isSelected}>
      <ItemLogo image={logoUrl} size={20} />
      <HeadTextCompact>{mainDescription}</HeadTextCompact>
      {serviceRequests.map((request: any) => {
        const requestType =
          requestNames[
            request
              .getIn(["service_request_type", "type"])
              .toLowerCase() as keyof typeof requestNames
          ];

        return (
          <ListItemCompact key={request.get("id")}>
            <ServiceRequestIcon type={requestType as IconType} />
          </ListItemCompact>
        );
      })}
      {filteredItemAddons.map((id: string | number) => (
        <ListItemCompact key={id}>
          <ItemIcon>{getAddonsIconsImage(id, allAddons, 22)}</ItemIcon>
        </ListItemCompact>
      ))}
    </ItemCompact>
  );
};

export default React.memo(SideAddonItem);
