import { PageWrapper } from "layouts/Layout.styles";
import { fadeInLeft, fadeOutRight } from "react-animations";
import styled from "styled-components";
import { HeaderButton } from "styles/parcelsStyles";

export const OutgoingWrapper = styled(PageWrapper)`
  position: absolute;
  width: 100%;
  animation: none;

  &.page-enter {
    animation: ${fadeInLeft} 0.22s ease;
  }

  &.page-exit {
    animation: ${fadeOutRight} 0.22s ease;
  }

  section:last-child {
    margin-bottom: 20px;
  }
`;

export const PayButton = styled(HeaderButton)`
  padding: 8px;
  margin: 0 10px;
`;
