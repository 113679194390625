import React, { FC, useCallback, useEffect } from "react";
import {
  BackButton,
  ContentWrapper,
  Heading,
  LeftArrowIcon,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonType, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { useFormik } from "formik";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { DeliveryMethodsFormProps } from "types/OutgoingForms/deliveryMethods";
import Button from "../../../../components/Button/Button";
import { setDeliveryMethod as setCartDeliveryMethod } from "../../../../reduxFolder/reducers/cart";
import {
  getDeliveryMethods,
  updateDeliveryMethod,
} from "../../../../reduxFolder/reducers/outgoing";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  outgoingSelectDeliveryMethods,
  outgoingSelectDetailedItems,
  outgoingSelectDetailedLoading,
} from "../../../../reduxFolder/selectors/outgoingSelectors";
import DeliveryList from "../../../Delivery/components/DeliveryList/DeliveryList";
import {
  ButtonsWrapper,
  ContentContainer,
  Form,
  Spinner,
} from "./DeliveryMethodsPanel.styles";
import { deliveryMethodsFormik } from "./helperDeliveryMethodsFormik";

const DeliveryMethodsPanel: FC<DeliveryMethodsFormProps> = React.memo(
  ({
    isLoading,
    getDeliveryMethods,
    updateDeliveryMethod,
    setCartDeliveryMethod,
    openRightPanel,
    panelData,
    deliveryMethods,
    detailedItem,
  }) => {
    const { t } = useTranslation("common");
    const {
      handleSubmit,
      values: { deliveryName },
      isValid,
      isSubmitting,
      setFieldValue,
    } = useFormik(
      deliveryMethodsFormik(
        panelData,
        updateDeliveryMethod,
        setCartDeliveryMethod,
        openRightPanel,
      ),
    );

    const id = panelData.get("detailedItemID");
    const isCartVariant = panelData.get("isCartVariant");
    const detailed = isCartVariant
      ? panelData.get("cart")
      : detailedItem.get(id, Map());

    const country = panelData.get("deliveryCountry");
    const handleSelect = useCallback(
      (name: string) => setFieldValue("deliveryName", name),
      [setFieldValue],
    );

    const handleDeliverySubmit = useCallback(
      async (name: string) => {
        handleSubmit();
      },
      [setFieldValue, handleSubmit],
    );

    const handleBack = useCallback(
      () => openRightPanel(panelData.get("returnPreviousPanel"), panelData),
      [openRightPanel, panelData],
    );

    useEffect(() => {
      getDeliveryMethods(id, country);
    }, []);

    return (
      <ContentWrapper>
        <Heading $isBordered>
          <BackButton color={ButtonColor.Black50} onClick={handleBack}>
            <LeftArrowIcon type={IconType.Arrow} />
            {detailed.get("sku")}:
          </BackButton>
          {t("parcels.shippingMethod")}
        </Heading>
        <ContentContainer>
          <Form onSubmit={handleSubmit}>
            <Spinner isActive={isLoading} />
            {!isLoading && (
              <DeliveryList
                deliveryMethods={deliveryMethods}
                selectedDelivery={deliveryName}
                onSelect={handleSelect}
                onSubmit={handleDeliverySubmit}
              />
            )}
            <ButtonsWrapper>
              <Button onClick={handleBack}>{t("common.cancel")}</Button>
              <Button
                isLoading={isSubmitting}
                disabled={!isValid || isSubmitting}
                type={ButtonType.Submit}
                color={ButtonColor.Primary}
                variant={ButtonVariant.Filled}
              >
                {t("common.saveChanges")}
              </Button>
            </ButtonsWrapper>
          </Form>
        </ContentContainer>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    deliveryMethods: outgoingSelectDeliveryMethods,
    isLoading: outgoingSelectDetailedLoading,
    detailedItem: outgoingSelectDetailedItems,
  }),
  {
    openRightPanel,
    getDeliveryMethods,
    updateDeliveryMethod,
    setCartDeliveryMethod,
  },
);

export default withConnect(DeliveryMethodsPanel);
