import {
  FC,
  FormEvent,
  memo,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button } from "components/Button";
import { ButtonColor, ButtonVariant, IconType } from "enums";
import { isMobile } from "react-device-detect";
import { FocusOn } from "react-focus-on";
import { useTranslation } from "react-i18next";
import { breakpoints } from "styles";
import {
  Filter,
  FilterTooltipBody,
  InnerTitle,
  Overlay,
  ResetFilter,
  ResetIcon,
  SearchTooltip,
  SubmitWrapper,
} from "./Filters.styles";

interface SearchFilterProps {
  isActive?: boolean;
  isApplyDisabled?: boolean;
  activeFilterStyleOverrides?: string[];
  innerTitle: string;
  title: ReactNode | string;
  children: ReactNode;
  setDefault: () => void;
  dropInnerValue: () => void;
  applyInnerValue: () => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}

const SearchFilterInner: FC<SearchFilterProps> = ({
  handleSubmit,
  setDefault,
  applyInnerValue,
  dropInnerValue,
  children,
  isActive = false,
  isApplyDisabled,
  innerTitle,
  title,
  activeFilterStyleOverrides,
}) => {
  const { t } = useTranslation("common");
  const [isOpen, setOpened] = useState(false);

  const submitFilter = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      setOpened(false);
      event.stopPropagation();
      await Promise.resolve();
      handleSubmit();
    },
    [handleSubmit],
  );

  const applyFilter = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      applyInnerValue();
      submitFilter(event);
    },
    [applyInnerValue, submitFilter],
  );

  const dropFilter = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      setDefault();
      submitFilter(event);
    },
    [setDefault, submitFilter],
  );

  const closeFilter = useCallback(() => {
    dropInnerValue();
    setOpened(false);
  }, [dropInnerValue]);

  const toggleFilter = useCallback(() => {
    if (isOpen) dropInnerValue();
    setOpened(!isOpen);
  }, [isOpen, dropInnerValue]);

  const listenKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.keyCode === 27) toggleFilter();
      if (event.key === "Enter" || event.keyCode === 13)
        applyFilter(event as unknown as MouseEvent<HTMLButtonElement>);
    },
    [applyFilter, toggleFilter],
  );

  useEffect(() => {
    const handler = listenKeyboard;

    if (isOpen) window.addEventListener("keydown", handler, true);
    if (!isOpen) window.removeEventListener("keydown", handler, true);

    return () => {
      window.removeEventListener("keydown", handler, true);
    };
  }, [isOpen, applyInnerValue, dropInnerValue, listenKeyboard]);

  const isMobileSearch = isMobile && window.innerWidth <= +breakpoints.xs;

  return (
    <SearchTooltip
      $isMobile={isMobileSearch}
      isOpen={isOpen}
      preferPlace="below"
      body={
        <FocusOn autoFocus={false} enabled={isOpen}>
          <FilterTooltipBody>
            <InnerTitle>{innerTitle}</InnerTitle>
            {children}
            <SubmitWrapper>
              <Button
                onClick={applyFilter}
                color={ButtonColor.Primary}
                variant={ButtonVariant.Filled}
                disabled={isApplyDisabled}
              >
                {t("parcels.search.applyFilter")}
              </Button>
            </SubmitWrapper>
          </FilterTooltipBody>
          {isMobileSearch && <Overlay onClick={toggleFilter} />}
        </FocusOn>
      }
      onOuterAction={closeFilter}
      tipSize={10}
    >
      <Filter
        $isActive={isActive}
        $isOpen={isOpen}
        onClick={toggleFilter}
        $activeFilterStyleOverrides={activeFilterStyleOverrides}
      >
        {title}
        <ResetFilter type="button" onClick={dropFilter}>
          <ResetIcon type={IconType.Close} />
        </ResetFilter>
      </Filter>
    </SearchTooltip>
  );
};

export const SearchFilter = memo(SearchFilterInner);
