import React, { FC, MouseEvent, useCallback } from "react";
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "enums/Button";
import { IconType } from "enums/Icon";
import { useFormik } from "formik";
import { ProgressDeliverySetAccessFunc } from "hooks/useProgressDelivery";
import { Map } from "immutable";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { SetDeliveryMethodDto } from "types/api/cart";
import { OpenRightPanel } from "types/common/rightPanel";
import * as yup from "yup";
import Button from "../../../../components/Button/Button";
import { setDeliveryMethod } from "../../../../reduxFolder/reducers/cart";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { cartSelectCartDeliveryMethod } from "../../../../reduxFolder/selectors/cartSelectors";
import {
  servicesSelectDelivery,
  servicesSelectLoading,
} from "../../../../reduxFolder/selectors/servicesSelectors";
import { toastResponseError } from "../../../../utils/responseMessageHelper";
import {
  BlockContent,
  Content,
  DeliveryBlock,
  Heading,
  SubmitWrapper,
} from "../../styles/Delivery.styles";
import DeliveryList from "../DeliveryList/DeliveryList";
import ScrollableForm from "../ScrollableForm";
import { HelpButton, HelpIcon, Spinner } from "./ChooseForm.styles";

interface ChooseDeliveryFormProps {
  setAccess: ProgressDeliverySetAccessFunc;
  cartDelivery: any;
  deliveryMethods: any;
  isLoading: boolean;
  openRightPanel: OpenRightPanel;
  setDeliveryMethod: (data: SetDeliveryMethodDto) => Promise<void>;
}

const ChooseDeliveryForm: FC<ChooseDeliveryFormProps> = React.memo(
  ({
    deliveryMethods,
    cartDelivery,
    setDeliveryMethod,
    setAccess,
    openRightPanel,
    isLoading,
  }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const {
      handleSubmit,
      isSubmitting,
      setFieldValue,
      values: { deliveryName },
    } = useFormik({
      initialValues: {
        deliveryName: cartDelivery,
      },
      validationSchema: () =>
        yup.object().shape({
          deliveryName: yup.string().required(),
        }),
      onSubmit: (values) => {
        if (cartDelivery !== values.deliveryName) {
          setDeliveryMethod({
            preferred_carrier: values.deliveryName,
          })
            .then(() => {
              setAccess("packing");
              navigate("/shipping/flow/delivery/packing");
            })
            .catch(toastResponseError);
          return;
        }

        setAccess("packing");
        navigate("/shipping/flow/delivery/packing");
      },
    });

    const handleSelect = useCallback(
      (name: string) => {
        setFieldValue("deliveryName", name);
      },
      [setFieldValue],
    );
    const handleDeliverySubmit = useCallback(
      async (name: string) => {
        await setFieldValue("deliveryName", name);
        handleSubmit();
      },
      [setFieldValue, handleSubmit],
    );

    const handleHelpClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        openRightPanel(
          "HELP_PANEL",
          Map({
            preventReLoading: true,
            hideBackButton: true,
            showHelpersCode: "shipping_method",
          }),
        );
      },
      [openRightPanel],
    );

    return (
      <Col lg={4} md={6} sm={12} offset={{ md: 3, lg: 4, sm: 12 }}>
        <Content>
          <DeliveryBlock>
            <form onSubmit={handleSubmit}>
              <Heading>
                {t("shipping.selectDelivery")}:
                <HelpButton onClick={handleHelpClick}>
                  <HelpIcon type={IconType.Help} />
                </HelpButton>
              </Heading>
              <BlockContent>
                <ScrollableForm>
                  <Spinner isActive={isLoading} />
                  {!isLoading && (
                    <DeliveryList
                      deliveryMethods={deliveryMethods}
                      selectedDelivery={deliveryName}
                      onSelect={handleSelect}
                      onSubmit={handleDeliverySubmit}
                    />
                  )}
                </ScrollableForm>
                <SubmitWrapper>
                  <Button
                    disabled={!deliveryName || isSubmitting}
                    isLoading={isSubmitting}
                    type={ButtonType.Submit}
                    size={ButtonSize.Large}
                    color={ButtonColor.Primary}
                    variant={ButtonVariant.Filled}
                  >
                    {t("common.nextStep")}
                  </Button>
                </SubmitWrapper>
              </BlockContent>
            </form>
          </DeliveryBlock>
        </Content>
      </Col>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    deliveryMethods: servicesSelectDelivery,
    cartDelivery: cartSelectCartDeliveryMethod,
    isLoading: servicesSelectLoading,
  }),
  { setDeliveryMethod, openRightPanel },
);

export default withConnect(ChooseDeliveryForm);
