import React, { FC, memo, useCallback } from "react";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import ServiceLogo from "../../../../components/ServiceLogo/ServiceLogo";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  panelSelectData,
  panelSelectOpened,
} from "../../../../reduxFolder/selectors/rightPanelSelectors";
import { Currency, ParcelCol } from "../../../../styles/parcelsStyles";
import formatter from "../../../../utils/formatter";
import { HistoryRow, Price } from "./WeekComponents.styles";

interface WeekItemProps {
  units: string;
  item: any;
  selectedYear: string | number;
  panelData: any;
  isPanelOpened: boolean;
  openRightPanel: OpenRightPanel;
}

const WeekItem: FC<WeekItemProps> = React.memo(
  ({ item, units, openRightPanel, isPanelOpened, panelData, selectedYear }) => {
    const { t } = useTranslation("common");
    const shippingMethod = item.get("shipping_method");
    const weekNumber = item.get("week_number");
    const isSelectedShipment =
      isPanelOpened &&
      panelData.get("shippingMethod") === shippingMethod &&
      panelData.get("weekNumber") === weekNumber;
    const clickWeekItem = useCallback(
      () =>
        openRightPanel(
          "WEEK_ITEM",
          Map({ shippingMethod, weekNumber, selectedYear }),
        ),
      [openRightPanel, item],
    );

    return (
      <HistoryRow selected={isSelectedShipment} onClick={clickWeekItem}>
        <ParcelCol xs={4} lg={3} offset={{ lg: 3 }}>
          <ServiceLogo serviceKey={shippingMethod} />
          {shippingMethod}
        </ParcelCol>
        <ParcelCol xs={3} xl={2}>
          {item.get("total_parcels")}{" "}
          {item.get("total_parcels") === 1
            ? t("parcels.parcel")
            : t("parcels.parcels")}
        </ParcelCol>
        <ParcelCol xs={3} sm={2} md={3} lg={2}>
          {item.get("total_weight")} {t(`units.${units}.primaryShort`)}
        </ParcelCol>
        <ParcelCol xs={2} lg={1}>
          <Price>
            <Currency>$</Currency> {formatter.currency(item.get("total_price"))}
          </Price>
        </ParcelCol>
      </HistoryRow>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
    panelData: panelSelectData,
  }),
  { openRightPanel },
);

export default withConnect(WeekItem);
