import { Content } from "containers/RightPanel/RightPanel.styles";
import styled, { css } from "styled-components";

export const tabsViewStyles = css`
  & > button {
    padding: 15px 0 25px 0;
  }
`;

export const ContentContainer = styled(Content)`
  & > div:first-child {
    display: flex;
    flex-wrap: wrap;
    padding: 0 !important;
  }

  & > div > button {
    margin: auto auto 0 auto;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;
