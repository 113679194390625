import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 15px;
  padding: 16px 0;
  animation: 0.6s ${keyframes`${fadeIn}`};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  ${(props) => props.theme.xs`
    justify-content: space-between;
  `}
`;

export const HeaderTitle = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 700;
`;

export const SelectYearButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 2px 14px;
  margin-left: 6px;

  &:hover i {
    background-color: rgba(200, 201, 204, 0.5);
  }

  ${(props) => props.theme.xs`
    padding: 2px 0;
    margin-left: 0;
  `}
`;

interface CollapseIconProps {
  $isOpened: boolean;
}

export const CollapseIcon = styled(Icon)<CollapseIconProps>`
  transform: ${({ $isOpened }) => `rotate(${$isOpened ? "90deg" : "-90deg"})`};
  transition:
    transform 0.3s ease-in-out,
    background-color 0.2s ease-in-out;
  width: 14px;
  height: 14px;
  margin-left: 8px;
`;

export const YearsWrapper = styled.div`
  width: 100%;
  padding-top: 6px;
`;

interface YearButtonProps {
  $isActive: boolean;
}

export const YearButton = styled(Button)<YearButtonProps>`
  padding: 4px 10px;

  ${({ $isActive, theme }) =>
    $isActive &&
    `
    color: ${theme.primary};
    pointer-events: none;
  `}
`;
