import React, { FC, ReactNode } from "react";
import { Col } from "react-grid-system";
import styled from "styled-components";
import { SideContent, SideWrapper } from "../styles/Delivery.styles";

interface LeftSideContentProps {
  children: ReactNode;
}

const LeftSideContent: FC<LeftSideContentProps> = ({ children }) => (
  <SideCol md={3} lg={4} sm={12}>
    <SideContent>
      <SideWrapper>{children}</SideWrapper>
    </SideContent>
  </SideCol>
);

export default React.memo(LeftSideContent);

const SideCol = styled(Col)`
  display: block;
`;
