// @ts-nocheck
import React, { ChangeEvent, FC } from "react";
import { isMobile } from "react-device-detect";
import { Props as ReactSelectProps } from "react-select";
import { Label, LabelText } from "../../styles/formStyles";
import DropdownIndicator from "./components/DropdownIndicator";
import MenuList from "./components/MenuList";
import Option from "./components/Option";
import SingleValue from "./components/SingleValue";
import { CreatableSelect, PlainSelect } from "./Select.styles";

interface SelectProps extends ReactSelectProps {
  label?: string;
  className?: string;
  creatable?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  minHeight?: number;
  placeholder?: string;
  tooltip?: string;
  onClick?: (event: ChangeEvent) => void;
  onChange?: (newValue: string, actionMeta: ActionMeta<unknown>) => void;
}

const Select: FC<SelectProps> = ({
  label = "",
  invalid = false,
  creatable = false,
  disabled = false,
  className = "",
  minHeight,
  placeholder = "",
  ...otherProps
}) => {
  const selectProps = {
    classNamePrefix: "react-select",
    components: {
      DropdownIndicator,
      Option,
      SingleValue,
      MenuList,
    },
    isDisabled: disabled,
    $invalid: invalid,
    isSearchable: !isMobile,
    $minHeight: minHeight,
    placeholder: placeholder,
    ...otherProps,
  };

  return (
    <Label $invalid={invalid} className={className}>
      {label && <LabelText>{label}</LabelText>}
      {creatable ? (
        <CreatableSelect {...selectProps} />
      ) : (
        <PlainSelect {...selectProps} />
      )}
    </Label>
  );
};

export default React.memo(Select);
