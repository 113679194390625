import styled from "styled-components";
import "rc-slider/assets/index.css";

export const Wrapper = styled.label`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: ${({ theme }) => theme.black50};

  ${({ theme }) => theme.xs`
    flex-direction: column-reverse;
  `}
`;

interface RangeWrapperProps {
  $isActive: boolean;
}

export const RangeWrapper = styled.div<RangeWrapperProps>`
  padding: 0 10px 15px 10px;
  color: ${(props) => props.theme.black50};

  .rc-slider-tooltip {
    z-index: 2;
  }

  .rc-slider .rc-slider-rail {
    height: 6px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.lightgrey03};
  }

  .rc-slider .rc-slider-track {
    height: 6px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.primaryPale};
  }

  .rc-slider .rc-slider-step {
    height: 6px;
    border-radius: 3px;
  }

  .rc-slider .rc-slider-handle {
    width: 16px;
    height: 16px;
    background-color: ${({ $isActive, theme }) =>
      $isActive ? theme.primaryLight : theme.white};
    border-color: ${({ $isActive, theme }) =>
      $isActive ? theme.primaryLight : theme.white};
    box-shadow: 0 1px 6px 0 ${(props) => props.theme.lightgrey};
  }

  .rc-slider-handle:focus {
    box-shadow: 0 0 0 4px ${(props) => props.theme.primaryPale};
  }

  ${({ theme }) => theme.xs`
    padding: 20px 25px 10px 25px;
  `}
`;

export const RangeLabels = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.3);
`;
