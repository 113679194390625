import React, { FC, memo } from "react";
import { IconType } from "enums/Icon";
import { DeleteButton, DeleteIcon } from "../CustomDeclarationV2.styles";

interface DeleteRowButtonProps {
  onClick: () => void;
}

const DeleteRowButton: FC<DeleteRowButtonProps> = ({ onClick }) => {
  return (
    <DeleteButton onClick={onClick} tabIndex={-1}>
      <DeleteIcon type={IconType.Delete} />
    </DeleteButton>
  );
};

export default memo(DeleteRowButton);
