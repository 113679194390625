import React, { FC, useEffect, useRef } from "react";
import Icon from "components/Icon/Icon";
import PackagesList from "components/PackagesList/PackagesList";
import { IconType } from "enums/Icon";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getDetailedShipment } from "reduxFolder/reducers/historyReducer";
import { openRightPanel } from "reduxFolder/reducers/rightPanel";
import { historySelectFullDetailedConsolidation } from "reduxFolder/selectors/historySelector";
import { panelSelectData } from "reduxFolder/selectors/rightPanelSelectors";
import { createStructuredSelector } from "reselect";
import {
  ExcessMarker,
  HeaderTitle,
  InnerSection,
  Section,
  SectionContent,
  SectionHeader,
} from "styles/outgoingStyles";
import { theme } from "styles/styles";
import { OpenRightPanel } from "types/common/rightPanel";

interface ParcelInfoTabItemsListProps {
  isExcessItems?: boolean;
  items: any;
  panelData: any;
  getDetailedShipment: (id: number) => Promise<void>;
  parcel: any;
  openRightPanel: OpenRightPanel;
}

const ParcelInfoTabItemsList: FC<ParcelInfoTabItemsListProps> = React.memo(
  ({
    items,
    isExcessItems = false,
    panelData,
    getDetailedShipment,
    parcel,
    openRightPanel,
  }) => {
    const { t } = useTranslation("common");
    const itemsCount = (!!items && items.count()) || 0;
    const localizedTitle = isExcessItems
      ? t("parcels.excessPackagesIncluded")
      : t("parcels.packagesIncluded");
    const trackNumberColor = isExcessItems ? theme.red : theme.black;
    const foundPackageId = panelData.get("foundPackageId", 0);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (contentRef.current && foundPackageId)
        contentRef.current.scrollIntoView();
    }, [contentRef.current, foundPackageId]);

    const clickPackagesItem = (item: any) => {
      openRightPanel(
        "STORAGE_ITEM",
        panelData.merge(
          Map({
            readOnly: true,
            id: item.get("shipment_id"),
            originalItem: item,
            consolidationId: parcel.get("id"),
            consolidationSku: parcel.get("sku"),
            emblem_thumb_url: item.get("emblem_thumb_url"),
            description: item.get("shipment_tracking_number"),
            returnPreviousPanel: "PARCEL_DETAILED",
            rootReturnPanel: "WEEK_ITEM",
            getShipment: getDetailedShipment,
            shippingMethod: panelData.get("shippingMethod"),
            updateComment: () => {},
          }),
        ),
      );
    };

    return !itemsCount ? null : (
      <Section ref={contentRef}>
        <SectionHeader>
          <HeaderTitle>
            <Icon type={IconType.Package} />
            {`${localizedTitle} (${itemsCount}):`}
            {isExcessItems ? <ExcessMarker /> : null}
          </HeaderTitle>
        </SectionHeader>
        <InnerSection>
          <SectionContent>
            <PackagesList
              extended
              trackNumberColor={trackNumberColor}
              items={items}
              foundItemId={foundPackageId}
              handleClick={clickPackagesItem}
              serviceRequestPanelData={{
                detailedItemID: parcel.get("id"),
                consolidationSku: parcel.get("sku"),
                returnPreviousPanel: panelData.get("returnPreviousPanel"),
                getShipment: getDetailedShipment,
                readOnly: true,
              }}
            />
          </SectionContent>
        </InnerSection>
      </Section>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    parcel: historySelectFullDetailedConsolidation,
    panelData: panelSelectData,
  }),
  {
    getDetailedShipment,
    openRightPanel,
  },
);

export default withConnect(ParcelInfoTabItemsList);
