import React, { FC, memo, useCallback } from "react";
import { AddonItem } from "components/AddonsList/AddonsList.styles";
import RadioButton from "components/RadioButton/RadioButton";
import { List } from "immutable";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getAddonsIconsImage } from "../deliveryHelper";

interface OutgoingConsolidationAddonsListProps {
  addons?: any;
  selectedCodes: any;
  setFormValues: (values: any) => void;
}

const OutgoingConsolidationAddonsList: FC<
  OutgoingConsolidationAddonsListProps
> = ({ addons = List(), selectedCodes, setFormValues }) => {
  const { t } = useTranslation("common");

  const handleChange = useCallback(
    (code: string, operation: string) => () => {
      const isOperationSelect = operation === "select";
      setFormValues(selectedCodes.set(code, isOperationSelect));
    },
    [setFormValues],
  );

  return addons.map((addon: any) => {
    const code = addon.get("code");
    const icon = getAddonsIconsImage(addon.get("id"), addons);
    const addonId = addon.get("id");
    const checked = selectedCodes.get(code);

    return (
      <AddonItem $isSelected={!!checked} key={addonId}>
        {icon}
        <AddonDescription>{addon.get("description")}</AddonDescription>
        <RadioButtonsGroup>
          <RadioButton
            label={t("auth.yes")}
            value={addonId}
            checked={!!checked}
            onClick={handleChange(code, "select")}
          />
          <RadioButton
            label={t("auth.no")}
            value={undefined}
            checked={!checked && checked !== undefined}
            onClick={handleChange(code, "deselect")}
          />
        </RadioButtonsGroup>
      </AddonItem>
    );
  });
};

export default memo(OutgoingConsolidationAddonsList);

const AddonDescription = styled.div`
  margin-left: 8px;
`;

const RadioButtonsGroup = styled.div`
  display: flex;
  margin: 0 15px 0 auto;

  label:not(:last-child) {
    margin-right: 18px;
  }
`;
