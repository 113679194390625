import React, { FC, MouseEvent, useCallback } from "react";
import { ButtonColor } from "enums/Button";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import ServiceLogo from "../../../../components/ServiceLogo/ServiceLogo";
import useProgressDelivery from "../../../../hooks/useProgressDelivery";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { cartSelectCart } from "../../../../reduxFolder/selectors/cartSelectors";
import { authSelectFfid } from "../../../../reduxFolder/selectors/globalSelectors";
import {
  panelSelectData,
  panelSelectOpened,
} from "../../../../reduxFolder/selectors/rightPanelSelectors";
import { ParcelButton, ParcelRow } from "../../../../styles/parcelsStyles";
import formatter from "../../../../utils/formatter";
import { OutgoingCol, Step } from "./NotFinished.styles";

interface NotFinishedProps {
  units: string;
  userFfid: string;
  panelData: any;
  parcel: any;
  cart: any;
  isPanelOpened?: boolean;
  openRightPanel: OpenRightPanel;
  handleContinue: (event: MouseEvent) => void;
  handleCancel: (event: MouseEvent) => void;
}

const NotFinished: FC<NotFinishedProps> = React.memo(
  ({
    openRightPanel,
    parcel,
    cart,
    units,
    userFfid,
    panelData,
    isPanelOpened,
    handleContinue,
    handleCancel,
  }) => {
    const { t } = useTranslation("common");
    const [access] = useProgressDelivery(userFfid);
    const currentStep = access.filter((value) => value).size;
    const deliveryName = parcel.get("delivery");
    const hasDelivery = deliveryName || t("parcels.notSelectedYet");
    const weight = formatter.weight(parcel.get("weight"));
    const isSelectedShipment =
      isPanelOpened &&
      panelData &&
      panelData.get("detailedItemID") === parcel.get("id");

    const clickParcel = useCallback(
      () =>
        openRightPanel(
          "NOT_FINISHED",
          Map({
            detailedItemID: cart.get("id"),
            parcel: cart,
          }),
        ),
      [openRightPanel, parcel, cart],
    );

    return (
      <ParcelRow selected={isSelectedShipment} onClick={clickParcel}>
        <OutgoingCol xs={3} sm={2} xl={1}>
          {parcel.get("sku")}
        </OutgoingCol>
        <OutgoingCol xs={6} sm={3} xl={2}>
          <ServiceLogo serviceKey={deliveryName} />
          {hasDelivery}
        </OutgoingCol>
        <OutgoingCol xs={3} sm={2} md={1}>
          {`${weight} ${t(`units.${units}.primaryShort`)}`}
        </OutgoingCol>
        <OutgoingCol sm={1} className="hidden-xs">
          <Step title={t(`shipping.steps.${currentStep}`)}>{currentStep}</Step>
        </OutgoingCol>
        <OutgoingCol sm={4} md={5} xl={6} className="hidden-xs">
          <ParcelButton color={ButtonColor.Primary} onClick={handleContinue}>
            {t("common.continueWithProcessing")}
          </ParcelButton>
          <ParcelButton color={ButtonColor.Red} onClick={handleCancel}>
            {t("common.cancelAndDelete")}
          </ParcelButton>
        </OutgoingCol>
      </ParcelRow>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    userFfid: authSelectFfid,
    isPanelOpened: panelSelectOpened,
    panelData: panelSelectData,
    cart: cartSelectCart,
  }),
  { openRightPanel },
);

export default withConnect(NotFinished);
