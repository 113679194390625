import React, { FC } from "react";
import {
  components,
  SingleValueProps as ReactSingleValueProps,
} from "react-select";
import { SingleWrapper, ValueLogo } from "./SelectComponents.styles";

const SingleValue: FC<ReactSingleValueProps> = React.memo(({
  selectProps,
  ...otherProps
}) => {
  const { inputValue, value = {} } = selectProps;
  //@ts-ignore
  const image = !inputValue && value && value?.image;

  return (
    <SingleWrapper $hasImage={!!image}>
      <ValueLogo image={!!image ? image : ""} />
      <components.SingleValue selectProps={selectProps} {...otherProps} />
    </SingleWrapper>
  );
});

export default SingleValue;
