import React, { FC, ReactElement, ReactNode } from "react";
import Tooltip from "components/Tooltip/Tooltip";
import { CheckboxLine } from "../CheckBox.style";

interface CheckboxTooltipWrapperProps {
  tooltipContent: ReactElement | null;
  children: ReactNode;
}

const CheckboxTooltipWrapper: FC<CheckboxTooltipWrapperProps> = ({
  tooltipContent,
  children,
}) =>
  tooltipContent ? (
    <Tooltip body={tooltipContent}>
      <CheckboxLine>{children}</CheckboxLine>
    </Tooltip>
  ) : (
    <>{children}</>
  );

export default CheckboxTooltipWrapper;
