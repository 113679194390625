import React, { FC, lazy } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import useProgressDelivery from "../../hooks/useProgressDelivery";
import {
  authSelectAllowExtendedTools,
  authSelectFfid,
} from "../../reduxFolder/selectors/globalSelectors";
import App from "../App";
import BooksAddFunds from "../Books/BooksAddFunds";
import BooksRecipients from "../Books/BooksRecipients";
import BooksTransactionsHistory from "../Books/BooksTransactionsHistory";
import { deliveryRoutes } from "../Delivery/deliveryRoutes";
import Expected from "../Expected/Expected";
import History from "../History/History";
import Inventory from "../Inventory/Inventory";
import Outgoing from "../Outgoing/Outgoing";
import Storage from "../Storage/Storage";
import AuthorizedRoute from "./components/AuthorizedRoute";
import ConditionalRoute from "./components/ConditionalRoute";
import PublicRoute from "./components/PublicRoute";

const Account = lazy(() => import("../Account/Account"));
const SignIn = lazy(() => import("../SignIn/SignIn"));
const PasswordReset = lazy(() => import("../PasswordReset/PasswordReset"));
const PasswordChange = lazy(() => import("../PasswordChange/PasswordChange"));
const Parcels = lazy(() => import("../Parcels/Parcels"));
const LogOut = lazy(() => import("../LogOut/LogOut"));
const Page404 = lazy(() => import("../Page404/Page404"));
const SignUp = lazy(() => import("../SignUp/SignUp"));
const Delivery = lazy(() => import("../Delivery/Delivery"));
const Dashboard = lazy(() => import("../Dashboard/Dashboard"));
const Impersonate = lazy(() => import("../Impersonate/Impersonate"));
const Books = lazy(() => import("../Books/Books"));
const Tools = lazy(() => import("../Tools/Tools"));

interface AppRoutesProps {
  allowExtendedTools: boolean;
  userFfid: string;
}

const AppRoutes: FC<AppRoutesProps> = React.memo(
  ({ allowExtendedTools, userFfid, ...rest }) => {
    const [access, setAccess] = useProgressDelivery(userFfid);

    return (
      <Routes>
        <Route path="/" element={<App />}>
          <Route element={<PublicRoute />}>
            <Route path="signin" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="password-reset" element={<PasswordReset />} />
            <Route path="password-change" element={<PasswordChange />} />
            <Route path="logout" element={<LogOut />} />
            <Route path="impersonate" element={<Impersonate />} />
          </Route>

          <Route element={<AuthorizedRoute />}>
            <Route index element={<Dashboard />} />
            <Route path="account" element={<Account />} />
            <Route path="parcels" element={<Parcels />}>
              <Route index element={<Storage />} />
              <Route path="inventory/:id" element={<Inventory />} />
              <Route path="expected" element={<Expected />} />
              <Route
                path="outgoing/:id?/:payment_result?"
                element={<Outgoing />}
              />
              <Route path="history" element={<History />} />
            </Route>
            <Route path="books" element={<Books />}>
              <Route index element={<BooksTransactionsHistory />} />
              <Route path="add-funds" element={<BooksAddFunds />} />
              <Route path="recipients" element={<BooksRecipients />} />
            </Route>
          </Route>

          <Route
            element={<AuthorizedRoute withMenu={false} redirect="/parcels" />}
          >
            <Route path="shipping" element={<Delivery />}>
              {deliveryRoutes.map(
                ({ DeliveryComponent, path, redirect, key }) => (
                  <Route
                    key={path}
                    element={
                      <ConditionalRoute
                        condition={key ? access.get("delivery") : true}
                        redirect={redirect}
                      />
                    }
                  >
                    <Route
                      path={path}
                      element={
                        <DeliveryComponent
                          setAccess={setAccess}
                          //@ts-ignore
                          access={access}
                        />
                      }
                    />
                  </Route>
                ),
              )}
            </Route>
          </Route>

          <Route element={<AuthorizedRoute condition={!allowExtendedTools} />}>
            <Route path="tools" element={<Tools />} />
          </Route>

          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    allowExtendedTools: authSelectAllowExtendedTools,
    userFfid: authSelectFfid,
  }),
);

export default withConnect(AppRoutes);
