import React, { FC, useCallback } from "react";
import {
  Content,
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonVariant } from "enums/Button";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import ServiceLogo from "../../../../components/ServiceLogo/ServiceLogo";
import { confirmationAll } from "../../../../reduxFolder/reducers/purchase";
import { authSelectUnits } from "../../../../reduxFolder/selectors/globalSelectors";
import { outgoingSelectPaymentData } from "../../../../reduxFolder/selectors/outgoingSelectors";
import { purchaseAllIsLoading } from "../../../../reduxFolder/selectors/purchaseSelectors";
import { ButtonSection, PayButton } from "../../../../styles/outgoingStyles";
import { Currency } from "../../../../styles/parcelsStyles";
import {
  Footer,
  Parcel,
  ParcelText,
  ParcelTitle,
  Spinner,
} from "./PayAllPanel.styles";

interface PayAllPanelProps {
  data: any;
  units: string;
  isLoadingAllPurchase?: boolean;
  confirmationAll: () => void;
}

const PayAllPanel: FC<PayAllPanelProps> = React.memo(
  ({ data, units, confirmationAll, isLoadingAllPurchase }) => {
    const { t } = useTranslation("common");
    const items = data.get("items");
    const isLoading = data.get("isLoading");
    const totalPrice = data.get("totalPrice");

    const handlePayAll = useCallback(() => {
      confirmationAll();
    }, [confirmationAll]);

    const renderLoading = () => {
      return <Spinner isActive={isLoading} />;
    };

    const renderParcelsList = () => {
      return items.map((item: any, index: number) => {
        const deliveryName = item.get("preferred_carrier");
        const weight =
          item.get("total_weight") &&
          Number(item.get("total_weight")).toFixed(2);

        return (
          <Parcel key={item.get("id")}>
            <ParcelTitle>
              {`${index + 1} ${item.get("sku")}`}
              <span>
                <Currency>$</Currency> {item.getIn(["estimate", "total"], 0)}
              </span>
            </ParcelTitle>
            <ParcelText>
              <ServiceLogo serviceKey={deliveryName} />
              {`${deliveryName}, ${weight} ${t(`units.${units}.primaryShort`)}`}
            </ParcelText>
          </Parcel>
        );
      });
    };

    return (
      <ContentWrapper>
        <Heading $isBordered>{t("common.payAll")}</Heading>
        <Content>{isLoading ? renderLoading() : renderParcelsList()}</Content>
        <Footer>
          <ButtonSection>
            <PayButton
              variant={ButtonVariant.Filled}
              disabled={isLoadingAllPurchase}
              isLoading={isLoadingAllPurchase}
              onClick={handlePayAll}
              color={ButtonColor.Secondary}
            >
              {t("common.payAll")} (${totalPrice})
            </PayButton>
          </ButtonSection>
        </Footer>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    data: outgoingSelectPaymentData,
    units: authSelectUnits,
    isLoadingAllPurchase: purchaseAllIsLoading,
  }),
  {
    confirmationAll,
  },
);

export default withConnect(PayAllPanel);
