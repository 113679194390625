import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { authSelectUser } from "../../reduxFolder/selectors/globalSelectors";
import { ProfileLogo } from "./ProfileLink.styles";

interface ProfileLinkProps {
  user: any;
}

const ProfileLink: FC<ProfileLinkProps> = React.memo(({ user }) => {
  const { t } = useTranslation("common");
  let title = user.get("first_name", " ")[0] + user.get("last_name", " ")[0];
  return (
    <ProfileLogo title={t("pages.account")} to="/account">
      {title}
    </ProfileLogo>
  );
});

const withConnect = connect(
  createStructuredSelector({
    user: authSelectUser,
  }),
);

export default withConnect(ProfileLink);
