import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IconType } from "enums/Icon";
import { FormikProps } from "formik";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { ExpectedSearchValues } from "types/ExpectedForms/Search";
import { StorageSearchValues } from "types/StorageForms/Search";
import useFocus from "../../hooks/useFocus";
import { AddressSearchValues } from "../Addresses/components/AddressSearch";
import Icon from "../Icon/Icon";
import Input from "../Input/Input";
import { InputWrapper, SearchButton } from "./SearchInput.styles";

interface SearchInputProps<T> {
  name: string;
  value: string;
  placeholder: string;
  initialValue: string;
  handleSubmit: FormikProps<T>["handleSubmit"];
  setFieldValue: FormikProps<T>["setFieldValue"];
  type?: "number";
}

type FormTypes =
  | AddressSearchValues
  | ExpectedSearchValues
  | StorageSearchValues;

const SearchInput = <T extends FormTypes>({
  value,
  initialValue,
  name,
  placeholder,
  setFieldValue,
  handleSubmit,
  type,
  ...rest
}: SearchInputProps<T>) => {
  const { t } = useTranslation("common");
  const { isFocused, onFocus, onBlur } = useFocus();
  const [innerValue, setInnerValue] = useState(value);
  const isDirty = !isEqual(initialValue, value) && isEqual(innerValue, value);
  const iconType = isDirty ? IconType.Close : IconType.Search;

  useEffect(() => {
    setInnerValue(value);
  }, [value, setInnerValue]);

  const handleInnerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInnerValue(event.target.value);
  };

  const applyValue = useCallback(
    async (value: string) => {
      setFieldValue(name, value);
      await Promise.resolve();
      handleSubmit();
    },
    [handleSubmit, setFieldValue],
  );

  const handleButtonClick = useCallback(
    () => (isDirty ? applyValue(initialValue) : applyValue(innerValue)),
    [isDirty, applyValue, initialValue, innerValue],
  );

  const handleEnterPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && isFocused && innerValue !== value) {
        event.preventDefault();
        applyValue(innerValue);
      }
    },
    [applyValue, innerValue, value, isFocused],
  );

  return (
    <InputWrapper $type={type}>
      <Input
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={handleEnterPress}
        value={innerValue}
        name={name}
        onChange={handleInnerChange}
        placeholder={placeholder}
        type={type}
        {...rest}
      />
      {type !== "number" && (
        <SearchButton
          title={t("common.search")}
          aria-label="search"
          onClick={handleButtonClick}
        >
          <Icon type={iconType} />
        </SearchButton>
      )}
    </InputWrapper>
  );
};

export default React.memo(SearchInput) as typeof SearchInput;
