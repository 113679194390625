import {
  SearchRecipientsFormValues,
  SearchRecipientsHandleSubmitHelpers,
} from "types/books";

export const searchRecipientsFormik = {
  mapPropsToValues: () => ({ address: "" }),
  handleSubmit: (
    values: SearchRecipientsFormValues,
    { props: { getAddresses } }: SearchRecipientsHandleSubmitHelpers,
  ) => {
    const data = values.address ? { search_in_address: values.address } : {};

    getAddresses(data);
  },

  displayName: "RecipientsSearch",
};
