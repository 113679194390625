import { FormikHelpers, FormikProps } from "formik";
import { TFunction } from "i18next";
import { Map } from "immutable";
import { AddressFormValues } from "types/address";
import { CommonOption } from "types/common";
import { OpenRightPanel } from "types/common/rightPanel";
import * as yup from "yup";
import { getSelectValue } from "../../containers/Account/settingsFormHelper";
import {
  getCountriesOptions,
  getCountryByCode,
} from "../../containers/SignUp/signUpHelper";
import { toastResponseError } from "../../utils/responseMessageHelper";

export const englishAndCyrillicOnlyRegEx = /^[\x20-\x7E\u0430-\u044f]*$/i;
export const withAlphabeticRegEx = /^(?=.*?[a-zA-Z\u0430-\u044f])/g;
const houseRegEx = /^[a-zA-Z0-9/]*$/i;

function isFieldValid(
  fieldSelector: (values: FormValuesErrors) => string | undefined,
  values: AddressFormValues,
  errors: FormikProps<AddressFormValues>["errors"],
) {
  const value = fieldSelector(values);
  const error = fieldSelector(errors);

  return !!value && value !== "" && !error;
}

export const phoneNumberFormatChars = {
  "9": "[0-9]",
  a: "[A-Za-z]",
  "*": "[A-Za-z0-9]",
  r: "[7-8]",
};
export const phoneNumbersMasks = {
  UA: "+38 (099) 999 9999",
  BY: "+375 (999) 999 999",
  KG: "+\\9\\96 (999) 999 999",
  KZ: "+7 (999) 999 9999",
  RU: "+7 (999) 999 9999",
  US: "(999) 999 9999",
  // Thanks to the international phone numbering plan (ITU-T E. 164), phone numbers cannot contain more than 15 digits.
  GENERIC: "+999999999999999",
};
export const supportedCountryPhoneMasks = ["UA", "BY", "KG", "KZ", "RU", "US"];

export const getShouldShowNativeSections = (country: CommonOption) =>
  country &&
  country.value &&
  country.value !== "US" &&
  supportedCountryPhoneMasks.indexOf(country.value) !== -1;

export const addressForm = (
  t: TFunction,
  panelData: any,
  openRightPanel: OpenRightPanel,
  closeRightPanel: () => void,
  createAddress: (data: any) => Promise<any>,
  updateAddress: (
    id: string,
    data: { destination_address: any },
  ) => Promise<any>,
  detailedItems: any,
  currentLocale: string,
) => {
  const getInitialValues = () => {
    const detailedItemID = panelData.get("detailedItemID");
    const address = panelData.get("address");
    const values = detailedItemID ? detailedItems.get(detailedItemID) : address;
    const getValue = (name: string) => (values ? values.get(name, "") : "");
    const country =
      values &&
      values.get("country") &&
      getSelectValue(values.get("country"), getCountriesOptions(currentLocale));

    return {
      address: {
        country: country
          ? country
          : getCountryByCode(getCountriesOptions(currentLocale), "US"),
        city: getValue("city"),
        state: getValue("state"),
        postalCode: getValue("postal_code") || getValue("zip_code"),
        taxId: getValue("tax_id"),
        street: getValue("street"),
        house: getValue("house"),
        hull: getValue("hull"),
        flat: getValue("flat"),
        firstName: getValue("first_name"),
        lastName: getValue("last_name"),
        phone: getValue("phone"),
        originName: getValue("origin_name"),
        originAddress1: getValue("origin_address_1"),
        originState: getValue("origin_state"),
        originCity: getValue("origin_city"),
        originZipCode: getValue("origin_zip_code"),
        address1: getValue("address_1"),
        address2: getValue("address_2"),
      },
      native: {
        firstName: getValue("translated_first_name"),
        lastName: getValue("translated_last_name"),
        state: getValue("region_ru"),
        city: getValue("city_ru"),
        street: getValue("address_translated"),
      },
    };
  };

  const initialValues = getInitialValues();

  return {
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: () =>
      yup.object().shape({
        address: yup.object().shape({
          country: yup
            .object()
            .required(t("error.required", { field: t("auth.country") })),
          city: yup
            .string()
            .matches(
              englishAndCyrillicOnlyRegEx,
              t("error.englishAndCyrillicOnly"),
            )
            .matches(withAlphabeticRegEx, t("error.withAlphabetic"))
            .required(t("error.required", { field: t("auth.city") })),
          state: yup
            .string()
            .matches(
              englishAndCyrillicOnlyRegEx,
              t("error.englishAndCyrillicOnly"),
            ),
          street: yup.string().when("country", {
            is: (country: CommonOption) => {
              return getShouldShowNativeSections(country);
            },
            then: () =>
              yup
                .string()
                .matches(
                  englishAndCyrillicOnlyRegEx,
                  t("error.englishAndCyrillicOnly"),
                )
                .matches(withAlphabeticRegEx, t("error.withAlphabetic"))
                .required(t("error.required", { field: t("auth.street") })),
            otherwise: () => yup.string(),
          }),
          house: yup.string().when("country", {
            is: (country: CommonOption) => {
              return getShouldShowNativeSections(country);
            },
            then: () =>
              yup
                .string()
                .matches(houseRegEx, t("error.validHouseNumber"))
                .required(t("error.required", { field: t("auth.house") })),
            otherwise: () => yup.string(),
          }),
          flat: yup
            .string()
            .matches(
              englishAndCyrillicOnlyRegEx,
              t("error.englishAndCyrillicOnly"),
            ),
          postalCode: yup
            .string()
            .trim()
            .required(t("error.required", { field: t("auth.postalCode") })),
          taxId: yup
            .string()
            .trim()
            .matches(/^[\d-]*$/, t("error.onlyNumber")),
          originName: yup
            .string()
            .matches(
              englishAndCyrillicOnlyRegEx,
              t("error.englishAndCyrillicOnly"),
            )
            .matches(withAlphabeticRegEx, t("error.withAlphabetic")),
          originAddress1: yup
            .string()
            .matches(
              englishAndCyrillicOnlyRegEx,
              t("error.englishAndCyrillicOnly"),
            )
            .matches(withAlphabeticRegEx, t("error.withAlphabetic")),
          originState: yup
            .string()
            .matches(
              englishAndCyrillicOnlyRegEx,
              t("error.englishAndCyrillicOnly"),
            ),
          originCity: yup
            .string()
            .matches(
              englishAndCyrillicOnlyRegEx,
              t("error.englishAndCyrillicOnly"),
            )
            .matches(withAlphabeticRegEx, t("error.withAlphabetic")),
          originZipCode: yup
            .string()
            .matches(/^[\d-]*$/, t("error.onlyNumber")),
          firstName: yup
            .string()
            .matches(
              englishAndCyrillicOnlyRegEx,
              t("error.englishAndCyrillicOnly"),
            )
            .matches(withAlphabeticRegEx, t("error.withAlphabetic"))
            .required(t("error.required", { field: t("auth.firstName") })),
          lastName: yup
            .string()
            .matches(
              englishAndCyrillicOnlyRegEx,
              t("error.englishAndCyrillicOnly"),
            )
            .matches(withAlphabeticRegEx, t("error.withAlphabetic"))
            .required(t("error.required", { field: t("auth.lastName") })),
          phone: yup.string().when("country", {
            is: (country: CommonOption) => {
              return (
                country &&
                country.value &&
                supportedCountryPhoneMasks.indexOf(country.value) !== -1
              );
            },
            then: () =>
              yup
                .string()
                .matches(
                  /^(\+\d+\s)?\(?\d+\)?(-?\s?\d+)+$/,
                  t("error.phoneNumberIsNotFully"),
                )
                .required(t("error.required", { field: t("auth.phone") })),
            otherwise: () =>
              yup
                .string()
                .required(t("error.required", { field: t("auth.phone") })),
          }),
          address1: yup.string().when("country", {
            is: (country: CommonOption) => {
              return !getShouldShowNativeSections(country);
            },
            then: () =>
              yup
                .string()
                .matches(
                  englishAndCyrillicOnlyRegEx,
                  t("error.englishAndCyrillicOnly"),
                )
                .matches(withAlphabeticRegEx, t("error.withAlphabetic"))
                .required(t("error.required", { field: t("auth.address1") })),
            otherwise: () => yup.string(),
          }),
          address2: yup
            .string()
            .matches(
              englishAndCyrillicOnlyRegEx,
              t("error.englishAndCyrillicOnly"),
            )
            .matches(withAlphabeticRegEx, t("error.withAlphabetic")),
        }),
        native: yup.object().when("country", {
          is: (country: CommonOption) => {
            return getShouldShowNativeSections(country);
          },
          then: () => yup.object(),
          otherwise: () => yup.object(),
        }),
      }),
    onSubmit: (
      values: AddressFormValues,
      { setSubmitting }: FormikHelpers<AddressFormValues>,
    ) => {
      const {
        address: {
          country,
          city,
          state,
          postalCode,
          taxId,
          originName,
          originAddress1,
          originState,
          originCity,
          originZipCode,
          street,
          house,
          hull,
          flat,
          firstName,
          lastName,
          phone,
          address1,
          address2,
        },
        native,
      } = values;
      const addressID = panelData.get("addressID");
      const isEditForm = panelData.get("isEditForm");
      const update = panelData.get("updateAddress") || updateAddress;
      const returnPreviousPanel = panelData.get("returnPreviousPanel");
      const onSubmit = isEditForm
        ? update.bind(null, addressID)
        : createAddress;

      onSubmit({
        destination_address: {
          first_name: firstName,
          last_name: lastName,
          street,
          house,
          hull,
          flat,
          country: country.value,
          state,
          city,
          zip_code: postalCode,
          tax_id: taxId,
          origin_name: originName,
          origin_address_1: originAddress1,
          origin_state: originState,
          origin_city: originCity,
          origin_zip_code: originZipCode,
          phone,
          address_1: address1,
          address_2: address2,
          translated_first_name: native.firstName,
          translated_last_name: native.lastName,
          region_ru: native.state,
          city_ru: native.city,
          address_translated: native.street,
        },
      })
        .then(() =>
          returnPreviousPanel
            ? openRightPanel(
                returnPreviousPanel,
                panelData.get("initialPanelData", Map()),
              )
            : closeRightPanel(),
        )
        .catch((response: string) => toastResponseError(response))
        .finally(() => setSubmitting(false));
    },
  };
};

type FormValuesErrors =
  | AddressFormValues
  | FormikProps<AddressFormValues>["errors"];

export const getAreAllRequiredFieldsValid = (
  values: AddressFormValues,
  errors: FormikProps<AddressFormValues>["errors"],
) => {
  const requiredFieldsSelectors = [
    (x: FormValuesErrors) => x.address && x.address.city,
    (x: FormValuesErrors) => x.address && x.address.street,
    (x: FormValuesErrors) => x.address && x.address.house,
    (x: FormValuesErrors) => x.address && x.address.postalCode,
    (x: FormValuesErrors) => x.address && x.address.firstName,
    (x: FormValuesErrors) => x.address && x.address.lastName,
    (x: FormValuesErrors) => x.address && x.address.phone,
    (x: FormValuesErrors) => x.native && x.native.firstName,
    (x: FormValuesErrors) => x.native && x.native.lastName,
    (x: FormValuesErrors) => x.native && x.native.city,
    (x: FormValuesErrors) => x.native && x.native.street,
  ];

  for (const fieldSelector of requiredFieldsSelectors) {
    if (!isFieldValid(fieldSelector, values, errors)) return false;
  }

  return true;
};
