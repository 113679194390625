import React, { FC, memo, ReactNode } from "react";
import { ITooltip, Tooltip as ReactTooltip } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

interface TooltipProps extends ITooltip {
  children: ReactNode;
  body: ReactNode | null;
}

const Tooltip: FC<TooltipProps> = ({
  children,
  body,
  place = "bottom",
  ...otherProps
}) => {
  const tooltipId = uuidv4();

  return (
    <>
      <div data-tooltip-id={tooltipId}>{children}</div>
      <ReactTooltip id={tooltipId} place={place} {...otherProps}>
        {body}
      </ReactTooltip>
    </>
  );
};

export default memo(Tooltip);
