import React, { FC } from "react";
import { Map } from "immutable";
import { connect } from "react-redux";
import { Waypoint } from "react-waypoint";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import {
  closeRightPanel,
  openRightPanel,
} from "../../../../reduxFolder/reducers/rightPanel";
import { setSelectedExpectedItemId } from "../../../../reduxFolder/reducers/storage";
import { panelSelectOpened } from "../../../../reduxFolder/selectors/rightPanelSelectors";
import { selectedExpectedItemId } from "../../../../reduxFolder/selectors/storageSelectors";
import ExpectedItemRow from "./ExpectedItemRow";
import { Spinner, Wrapper } from "./ExpectedList.styles";

interface ExpectedListProps {
  parcels: any;
  shops: any;
  openRightPanel: OpenRightPanel;
  onPagination: () => void;
  disabled: boolean;
  totalAmount: number;
  selectedExpectedItemId: string | null;
  setSelectedExpectedItemId: (id: string | null) => void;
  isPanelOpened: boolean;
}

const ExpectedList: FC<ExpectedListProps> = React.memo(
  ({
    parcels,
    openRightPanel,
    onPagination,
    disabled,
    totalAmount,
    shops,
    selectedExpectedItemId,
    setSelectedExpectedItemId,
    isPanelOpened,
  }) => {
    const hasMore = totalAmount > parcels.size;

    const handleFocusExpectedItemInPanel = (parcel: any) => {
      const id = parcel.get("id");

      if (selectedExpectedItemId !== id) {
        setSelectedExpectedItemId(id);
      }
      openRightPanel(
        "EXPECTED_ITEM",
        Map({
          selectedExpectedItem: parcel,
          isEditForm: true,
        }).merge(parcel),
      );
    };

    return (
      <Wrapper>
        {parcels.map((parcel: any) => (
          <ExpectedItemRow
            onFocusExpectedItemInPanel={handleFocusExpectedItemInPanel}
            parcel={parcel}
            selectedParcel={selectedExpectedItemId}
            key={parcel.get("id")}
            disabled={disabled}
            shops={shops}
            isPanelOpened={isPanelOpened}
          />
        ))}
        {hasMore && (
          <>
            <Waypoint onEnter={onPagination} />
            <Spinner key={0} isActive />
          </>
        )}
      </Wrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    selectedExpectedItemId: selectedExpectedItemId,
    isPanelOpened: panelSelectOpened,
  }),
  {
    openRightPanel,
    setSelectedExpectedItemId,
  },
);

export default withConnect(ExpectedList);
