import Icon from '../components/Icon/Icon';
import TextArea from '../components/TextArea/TextArea';
import styled from 'styled-components';

export const MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1.67;
  color: ${props => props.theme.black50};
  padding: 15px 25px;
  margin: 25px 0;
  background-color: rgba(225, 226, 230, 0.25);
  text-align: center;
  min-height: 250px;
`;

export const Message = styled.p`
  margin: 0;
  width: 85%;
`;

export const Paragraph = styled.p`
  color: ${props => props.theme.black50};
  line-height: 1.45;
  margin: 0 0 15px 0;
`;

export const Title = styled.div`
  margin-left: 10px;
`;

export const Comment = styled(TextArea)`
  width: 100%;

  & > textarea {
    min-height: 85px;
  }
`;

export const Form = styled.form`
  height: 100%;
  display: flex;
  align-content: space-between;
  flex-direction: column;
`;

export const MessageIcon = styled(Icon)`
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  background-color: rgba(200, 201, 204, 0.5);
`;

export const PriceWrapper = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid ${({ theme }) => theme.lightgrey};

  ${props => props.theme.xs`
    padding: 20px 0;
  `}
`;
