import { initialState } from '../reducers/purchase';
import { peek, selector } from '../selectorHelpers';

export const purchaseSelect = (state) => state.get('purchase', initialState);

export const purchaseSelectId = selector(purchaseSelect, peek('id'));
export const purchaseAllIsLoading = selector(
  purchaseSelect,
  peek('isAllLoading'),
);
