import React, { FC, memo, ReactElement, ReactNode, useCallback } from "react";
import { ScrollbarProps, Scrollbars } from "react-custom-scrollbars-2";
import styled from "styled-components";

interface ScrollableContentProps extends ScrollbarProps {
  children: ReactNode | ReactElement;
  isScrollDisabled?: boolean;
}

const ScrollableContent: FC<ScrollableContentProps> = ({
  children,
  ...otherProps
}) => {
  const renderThumb = useCallback(() => <CustomScroll />, []);

  return (
    <StyledScrollBars
      autoHide
      autoHideTimeout={800}
      renderThumbVertical={renderThumb}
      {...otherProps}
    >
      {children}
    </StyledScrollBars>
  );
};

export default memo(ScrollableContent);

const CustomScroll = styled.div`
  z-index: 1;
  cursor: pointer;
  border-radius: 4px;
  background-color: #e1e2e6;
`;

export const StyledScrollBars = styled(Scrollbars)``;
