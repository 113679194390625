import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { AuthSignInDto, AuthSignUpResponseDto } from "types/api/auth";
import { SignInFormValues } from "types/signInForm";
import * as yup from "yup";
import { toastResponseError } from "../../utils/responseMessageHelper";

export const signInFormik = (
  t: TFunction,
  handleSignIn: (data: AuthSignInDto) => Promise<AuthSignUpResponseDto>,
  redirect: () => void,
) => ({
  validateOnChange: false,
  validateOnBlur: false,
  initialValues: { email: "", password: "" },
  validationSchema: () =>
    yup.object().shape({
      email: yup
        .string()
        .trim()
        .email(t("error.invalid", { field: t("auth.email") }))
        .required(t("error.required", { field: t("auth.email") })),
      password: yup
        .string()
        .trim()
        .required(t("error.required", { field: t("auth.password") })),
    }),
  onSubmit: (
    values: SignInFormValues,
    { setSubmitting }: FormikHelpers<SignInFormValues>,
  ) =>
    handleSignIn(values)
      .then(() => redirect())
      .catch((error: Error) => {
        toastResponseError(error);
      })
      .finally(() => setSubmitting(false)),
});
