import { Map } from 'immutable';
import { combineActions, createAction, handleActions } from 'redux-actions';

export const initialState = Map({
  id: null,
  isAllLoading: false,
});

// const
export const CHECKOUT = 'purchase/CHECKOUT';
export const CLEAR_PURCHASE = 'purchase/CLEAR_PURCHASE';
export const CHECKOUT_PAYPAL = 'purchase/CHECKOUT_PAYPAL';
export const CHECKOUT_CREDIT_CARD = 'purchase/CHECKOUT_CREDIT_CARD';
export const COMPLETE_PAYPAL = 'purchase/COMPLETE_PAYPAL';
// purchase all
export const CONFIRMATION_ALL = 'purchase_all/CONFIRMATION';
export const COMPLETE_ALL_PAYPAL = 'purchase_all/COMPLETE_PAYPAL';

// actions
export const checkout = createAction(CHECKOUT);
export const clearPurchase = createAction(CLEAR_PURCHASE);
export const completePayPalPayment = createAction(COMPLETE_PAYPAL);
export const checkoutPayPal = createAction(CHECKOUT_PAYPAL);
export const checkoutCreditCard = createAction(CHECKOUT_CREDIT_CARD);

// actions for purchase all
export const confirmationAll = createAction(CONFIRMATION_ALL);
export const completeAllPayPal = createAction(COMPLETE_ALL_PAYPAL);

// reducer
const purchaseReducer = handleActions(
  {
    [combineActions(
      CHECKOUT,
      COMPLETE_PAYPAL,
      CHECKOUT_PAYPAL,
      CHECKOUT_CREDIT_CARD,
    )]: (state, { payload }) => state.set('id', payload.id),
    [combineActions(CONFIRMATION_ALL, COMPLETE_ALL_PAYPAL)]: (state) =>
      state.set('isAllLoading', true),

    [CLEAR_PURCHASE]: () => initialState,
  },

  initialState,
);

export default purchaseReducer;
