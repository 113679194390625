import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

export const DateFilterWrapperAnimator = styled.div`
  animation: 0.3s ${keyframes`${fadeIn}`};
`;
export const DateFilterWrapper = styled.div`
  background: white;

  position: absolute;
  top: 52px;
  z-index: 2;
  left: 5px;
  right: 5px;

  display: flex;
  flex-direction: column;
`;

export const DateRangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const DateRangeHeader = styled.div`
  margin: 5px;

  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: center;

  border-bottom: 2px solid ${(props) => props.theme.lightgrey};
  padding: 10px;
`;

export const DateLabel = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.primary};
  font-size: 17px;
  padding: 5px 30px;
`;

export const DateRangeArrow = styled(Icon)`
  width: 20px;
  background-color: #485179;
`;

export const DateRangeFooter = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const DateRangeApplyButton = styled(Button)`
  height: 46px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
`;
