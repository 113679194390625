import React, { FC, MouseEvent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { panelSelectOpened } from "../../../../reduxFolder/selectors/rightPanelSelectors";
import {
  TableCol,
  TableRow,
  WrapperRecipientItemRow,
} from "../../styles/BookRecipients.style";

interface BooksRecipientsItemRow {
  item: any;
  disabled: boolean;
  onFocusRecipientItemInPanel: (e: MouseEvent, item: any) => void;
  isPanelOpened: boolean;
  selectedRecipientId: string;
}

const BooksRecipientsItemRow: FC<BooksRecipientsItemRow> = React.memo(
  ({
    item,
    disabled,
    onFocusRecipientItemInPanel,
    isPanelOpened,
    selectedRecipientId,
  }) => {
    const recipientName = `${item.get("first_name")} ${item.get("last_name")}`;
    const country = item.get("country");
    const zipCode = item.get("zip_code");
    const city = item.get("city");
    const phone = item.get("phone");
    const address1 = item.get("address_1");

    const isSelectedRecipient =
      isPanelOpened && selectedRecipientId === item.get("id");

    const recipientInfo = `${address1}, ${city}, ${zipCode}, ${country}, ${phone}`;

    return (
      <>
        <WrapperRecipientItemRow disabled={disabled}>
          <TableRow
            onClick={(e) => onFocusRecipientItemInPanel(e, item)}
            selected={!!isSelectedRecipient}
          >
            <TableCol xs={6} md={3}>
              {recipientName}
            </TableCol>
            <TableCol xs={6} md={9}>
              {recipientInfo}
            </TableCol>
          </TableRow>
        </WrapperRecipientItemRow>
      </>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
  }),
);

export default withConnect(BooksRecipientsItemRow);
