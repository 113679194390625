import { fromJS, List } from 'immutable';
import { combineActions, createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = fromJS({
  allDeliveryMethods: List(),
  deliveryMethods: List(),
  packingMethods: List(),
  addonsMethods: List(),
  consolidationAddonsMethods: List(),
  isLoading: false,
});

// const
export const GET_DELIVERY_METHODS = 'services/GET_DELIVERY_METHODS';
export const GET_ALL_DELIVERY_METHODS = 'services/GET_ALL_DELIVERY_METHODS';
export const GET_PACKING_METHODS = 'services/GET_PACKING_METHODS';
export const GET_ADDONS_METHODS = 'services/GET_ADDONS_METHODS';
export const GET_CONSOLIDATION_ADDONS_METHODS =
  'services/GET_CONSOLIDATION_ADDONS_METHODS';

// action creators
export const getDeliveryMethods = createAction(
  GET_DELIVERY_METHODS,
  api.cart.getDeliveryMethods,
);
export const getAllDeliveryMethods = createAction(
  GET_ALL_DELIVERY_METHODS,
  api.cart.getAllDeliveryMethods,
);
export const getPackingMethods = createAction(
  GET_PACKING_METHODS,
  api.cart.getPackingMethods,
);
export const getAddonsMethods = createAction(
  GET_ADDONS_METHODS,
  api.cart.getAddonsMethods,
);
export const getConsolidationAddonsMethods = createAction(
  GET_CONSOLIDATION_ADDONS_METHODS,
  api.cart.getConsolidationAddonsMethods,
);

const normalizePackingMethods = (data) =>
  data
    .sort((a, b) => a.get('price_in_cents') - b.get('price_in_cents'))
    .map((packing) =>
      packing.set('logo_url', packing.get('icon_url')).delete('icon_url'),
    );

// reducer
const servicesReducer = handleActions(
  {
    [`${GET_ALL_DELIVERY_METHODS}_SUCCESS`]: (state, { payload }) =>
      state
        .set('allDeliveryMethods', payload.get('data'))
        .set('isLoading', false),

    [`${GET_DELIVERY_METHODS}_SUCCESS`]: (state, { payload }) =>
      state.set('deliveryMethods', payload.get('data')).set('isLoading', false),

    [`${GET_PACKING_METHODS}_SUCCESS`]: (state, { payload }) =>
      state
        .set('packingMethods', normalizePackingMethods(payload.get('data')))
        .set('isLoading', false),

    [`${GET_ADDONS_METHODS}_SUCCESS`]: (state, { payload }) =>
      state.set('addonsMethods', payload.get('data')).set('isLoading', false),

    [`${GET_CONSOLIDATION_ADDONS_METHODS}_SUCCESS`]: (state, { payload }) => {
      return state
        .set('consolidationAddonsMethods', payload.get('data'))
        .set('isLoading', false);
    },

    [combineActions(
      `${GET_DELIVERY_METHODS}_REQUEST`,
      `${GET_ALL_DELIVERY_METHODS}_REQUEST`,
      `${GET_PACKING_METHODS}_REQUEST`,
      `${GET_ADDONS_METHODS}_REQUEST`,
      `${GET_CONSOLIDATION_ADDONS_METHODS}_REQUEST`,
    )]: (state) => state.set('isLoading', true),

    [combineActions(
      `${GET_DELIVERY_METHODS}_ERROR`,
      `${GET_ALL_DELIVERY_METHODS}_ERROR`,
      `${GET_PACKING_METHODS}_ERROR`,
      `${GET_ADDONS_METHODS}_ERROR`,
      `${GET_CONSOLIDATION_ADDONS_METHODS}_ERROR`,
    )]: (state) => state.set('isLoading', false),
  },
  initialState,
);

export default servicesReducer;
