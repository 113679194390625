import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { List } from "immutable";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import injectReducer from "../../reduxFolder/injectReducer";
import booksReducer, {
  getBooksTransactionsHistory,
} from "../../reduxFolder/reducers/books";
import {
  booksTransactionsSelectHistoryItems,
  booksTransactionsSelectIsLoading,
  booksTransactionsSelectParams,
  booksTransactionsSelectTotalAmount,
} from "../../reduxFolder/selectors/booksSelectors";
import { panelSelectOpened } from "../../reduxFolder/selectors/rightPanelSelectors";
import { ListHeader } from "../../styles/parcelsStyles";
import { BooksTransactionsHistorySearchValues } from "../../types/BooksForms/Search";
import BooksTransactionsHistoryList from "./components/BooksTransactionsHistoryList/BooksTransactionsHistoryList";
import { CompactSearch, Search } from "./components/Search";
import { SearchPlaceholder } from "./components/SearchPlaceholder";
import { booksTransactionsHistorySearchFormik } from "./helpers/booksTransactionsHistorySearchFormik";
import {
  StorageHeading,
  StyledGrid,
  Wrapper,
} from "./styles/BookRecipients.style";

interface BooksTransactionsHistoryProps {
  historyItems: List<any>;
  isLoading: boolean;
  getBooksTransactionsHistory: (params?: any) => void;
  params: any;
  totalAmount: number;
  isPanelOpened: boolean;
}

const BooksTransactionsHistory: FC<BooksTransactionsHistoryProps> = ({
  historyItems,
  isLoading,
  getBooksTransactionsHistory,
  params,
  totalAmount,
  isPanelOpened,
}) => {
  const [handleResetCompactFunction, setHandleResetCompactFunction] =
    useState<Function>(() => null);

  const hasItems = historyItems && !!historyItems.size;
  const hasFilters = !!params.filter;

  useEffect(() => {
    getBooksTransactionsHistory();
  }, [getBooksTransactionsHistory]);

  const handleGetBooksTransactionsHistory = (values: any) => {
    return getBooksTransactionsHistory(values);
  };

  const formOptions = useFormik<BooksTransactionsHistorySearchValues>(
    booksTransactionsHistorySearchFormik(handleGetBooksTransactionsHistory),
  );

  const onPagination = useCallback(
    () =>
      !isLoading &&
      getBooksTransactionsHistory(
        params.update("page", (page: number) => page + 1),
      ),
    [getBooksTransactionsHistory, isLoading, params],
  );

  const handleResetCompactClick = () => {
    if (!handleResetCompactFunction) return;
    handleResetCompactFunction();
  };

  const handleSetResetCompactFunction = (func: Function) =>
    setHandleResetCompactFunction(func);

  return (
    <Wrapper>
      <StyledGrid fluid>
        <LoadingBar isLoading={isLoading} />
        <StorageHeading>
          <Hidden xs>
            <Search formOptions={formOptions} isPanelOpened={isPanelOpened} />
          </Hidden>
          <Visible xs>
            <CompactSearch
              formOptions={formOptions}
              handleSetResetCompactFunction={handleSetResetCompactFunction}
            />
          </Visible>
        </StorageHeading>
        {hasItems && (
          <BooksTransactionsHistoryList
            historyItems={historyItems}
            onPagination={onPagination}
            totalAmount={totalAmount}
            disabled={isLoading}
          />
        )}
        {!hasItems && !isLoading && (
          <SearchPlaceholder
            {...(hasFilters && { handleReset: handleResetCompactClick })}
          />
        )}
      </StyledGrid>
    </Wrapper>
  );
};

const withConnect = connect(
  createStructuredSelector({
    historyItems: booksTransactionsSelectHistoryItems,
    isLoading: booksTransactionsSelectIsLoading,
    params: booksTransactionsSelectParams,
    totalAmount: booksTransactionsSelectTotalAmount,
    isPanelOpened: panelSelectOpened,
  }),
  {
    getBooksTransactionsHistory,
  },
);

const withBooksReducer = injectReducer({
  key: "books",
  reducer: booksReducer,
});

const enhance = compose(withBooksReducer, withConnect, memo);

export default enhance(BooksTransactionsHistory);
