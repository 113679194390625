import { useEffect, useRef } from "react";
import { toastResponseError } from "../utils/responseMessageHelper";

const useFetchOnceEffect = (
  condition: boolean,
  fetchFunction: () => Promise<any> | void,
): void => {
  const hasFetched = useRef(false);

  useEffect(() => {
    if (condition && !hasFetched.current) {
      hasFetched.current = true;
      if (typeof fetchFunction === "function") {
        try {
          const result = fetchFunction();
          if (result instanceof Promise) {
            result.catch(toastResponseError);
          }
        } catch (error: unknown) {
          toastResponseError(error);
        }
      }
    }
  }, [condition, fetchFunction]);
};

export default useFetchOnceEffect;
