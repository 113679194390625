export const getTranslatedProp = (item, propName, currentLocale) => {
  const locale = currentLocale.toLowerCase();
  let retVal;

  if (locale === 'en') {
    retVal = item.get(`${propName}_en`);
  } else if (locale === 'ru') {
    retVal = item.get(`${propName}_ru`);
  }

  if (!retVal) {
    retVal = item.get(propName);
  }

  return retVal;
};
