import { initialState } from '../reducers/dashboard';
import { peek, selector } from '../selectorHelpers';

export const selectDashboard = (state) => state.get('dashboard', initialState);

export const dashboardSelectData = selector(selectDashboard, peek('data'));
export const dashboardSelectAddress = selector(
  selectDashboard,
  peek('data', 'warehouse_address'),
);
export const dashboardSelectUserFfid = selector(
  selectDashboard,
  peek('data', 'user_ffid'),
);
export const dashboardSelectLoading = selector(
  selectDashboard,
  peek('isLoading'),
);
export const dashboardSelectFAQ = selector(selectDashboard, peek('faq'));
export const dashboardSelectIsFAQLoading = selector(
  selectDashboard,
  peek('isFAQLoading'),
);
// KB Helpers
export const dashboardSelectKbHelpers = selector(
  selectDashboard,
  peek('kbHelpers'),
);
export const dashboardSelectIsKbHelpersLoading = selector(
  selectDashboard,
  peek('isKbHelpersLoading'),
);
