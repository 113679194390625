import React, { FC, memo } from "react";
import { IconType } from "enums/Icon";
import { Visible } from "react-grid-system";
import Icon from "../../components/Icon/Icon";
import BasicHeader from "./components/BasicHeader";
import {
  BasicHeaderWrapper,
  Header,
  HeaderWrapper,
  MobileMenuButton,
} from "./HeaderContainer.styles";

interface HeaderContainersProps {
  toggleMenu: () => void;
  isMenuOpen?: boolean;
  withMenu?: boolean;
}

const HeaderContainer: FC<HeaderContainersProps> = ({
  toggleMenu,
  withMenu,
  isMenuOpen,
}) => (
  <HeaderWrapper>
    <Header>
      {withMenu && (
        <Visible sm xs>
          <MobileMenuButton aria-label="open-menu" onClick={toggleMenu}>
            <Icon type={IconType.MenuBurger} />
          </MobileMenuButton>
        </Visible>
      )}
      <BasicHeaderWrapper hidden={isMenuOpen}>
        <BasicHeader />
      </BasicHeaderWrapper>
    </Header>
  </HeaderWrapper>
);

export default memo(HeaderContainer);
