import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { authSelectUnits } from "../../../../reduxFolder/selectors/globalSelectors";
import {
  storageSelectSelected,
  storageSelectSelectedWeight,
} from "../../../../reduxFolder/selectors/storageSelectors";
import { Wrapper } from "./SelectedInfo.styles";

interface SelectedInfoProps {
  units: string;
  className?: string;
  weight?: string | number;
  selectedParcelsNumber?: number;
  selectedWeight: string | number;
  selectedParcels: any;
}

const SelectedInfo: FC<SelectedInfoProps> = React.memo(
  ({
    selectedParcels,
    selectedWeight,
    weight: weightProp,
    selectedParcelsNumber: selectedParcelsNumberProp,
    units,
    className = "",
  }) => {
    const { t } = useTranslation("common");
    const weight = weightProp ? weightProp : selectedWeight;
    const number = selectedParcelsNumberProp
      ? selectedParcelsNumberProp
      : selectedParcels.size;

    return (
      <Wrapper className={className}>
        <span>
          {`${t("parcels.selectedAmount")}: `}
          <b>{number}</b>
        </span>
        <span>
          {`${t("parcels.weight")}: `}
          <b>{`${weight} ${t(`units.${units}.primaryShort`)}`}</b>
        </span>
      </Wrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    units: authSelectUnits,
    selectedParcels: storageSelectSelected,
    selectedWeight: storageSelectSelectedWeight,
  }),
);

export default withConnect(SelectedInfo);
