import React, { FC, memo, useCallback, useState } from "react";
import { ButtonSize } from "enums/Button";
import { IconType } from "enums/Icon";
import { useTranslation } from "react-i18next";
import ImmutableT from "react-immutable-proptypes";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { Currency } from "styles/parcelsStyles";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { updateRequireInsurance } from "../../../../reduxFolder/reducers/outgoing";
import { authSelectUnits } from "../../../../reduxFolder/selectors/globalSelectors";
import { outgoingSelectLoadingInsurance } from "../../../../reduxFolder/selectors/outgoingSelectors";
import {
  DashConnector,
  InsuranceButton,
  InsuranceCheckBoxContainer,
  InsuranceConfirmation,
  InsuranceHelpIcon,
  InsurancePriceWrapper,
  PriceItem,
  PriceWrapper,
  Separator,
  SummaryCurrency,
  SummaryPriceItem,
  SummaryPriceWrapper,
  TooltipInfo,
  TotalPriceItem,
  Wrapper,
} from "./PriceList.styles";

interface PriceListProps {
  units: string;
  parcel: any;
  isLoadingUpdateInsurance?: boolean;
  updateRequireInsurance: (id: string, data: any) => Promise<any>;
}

const PriceList: FC<PriceListProps> = React.memo(
  ({
    units,
    parcel,
    isLoadingUpdateInsurance = false,
    updateRequireInsurance,
  }) => {
    const { t } = useTranslation("common");
    const deliveryName = parcel.get("preferred_carrier");
    const deliveryCost = parcel.getIn(["estimate", "price"], 0);
    const packingCost = parcel.getIn(["estimate", "optional_lines_cost"], 0);
    const addonsCost = parcel.getIn(["estimate", "items_addons_cost"], 0);
    const serviceRequestsCost = parcel.getIn(
      ["estimate", "service_requests_cost"],
      0,
    );
    const totalCosts = parcel.getIn(["estimate", "total"], 0);
    const userBalance = parcel.getIn(["estimate", "user_balance"], 0);
    const totalToPay = parcel.getIn(["estimate", "user_total"], 0);
    const state = parcel.get("state");
    const isPaid = state === "paid" || state === "shipped";

    const userBalanceToShow = Math.min(userBalance, totalCosts);
    const totalToPayToShow = Math.max(totalToPay, 0);

    const hasPackingCosts = packingCost > 0;
    const hasAddonsCosts = addonsCost > 0;
    const hasServiceRequestsCost = serviceRequestsCost > 0;
    const shouldShowUserBalance = userBalance !== 0;

    const weight =
      parcel.get("weight") && Number(parcel.get("weight")).toFixed(2);
    const isRequireInsurance = parcel.getIn(
      ["estimate", "require_insurance"],
      false,
    );
    const [showInsuranceConfirmation, setShowInsuranceConfirmation] =
      useState(false);
    const isEditDisabled = isPaid || showInsuranceConfirmation;

    const onChangeInsurance = () => {
      if (isRequireInsurance === true) {
        updateRequireInsurance(parcel.get("id"), {
          require_insurance: false,
        });
      } else {
        setShowInsuranceConfirmation(true);
      }
    };

    const handleInsuranceCancel = useCallback(
      () => setShowInsuranceConfirmation(false),
      [],
    );

    const handleInsuranceConfirm = useCallback(() => {
      updateRequireInsurance(parcel.get("id"), {
        require_insurance: true,
      }).then(() => setShowInsuranceConfirmation(false));
    }, []);

    const renderPriceItem = (title: string, amount: number) => {
      return (
        <PriceItem>
          {title}
          <DashConnector />
          <PriceWrapper>
            <Currency>$</Currency>
            {amount}
          </PriceWrapper>
        </PriceItem>
      );
    };

    const renderSummaryPriceItem = (
      title: string,
      amount: number,
      isNegative?: boolean,
    ) => {
      return (
        <SummaryPriceItem $isHighlighted={!!isNegative}>
          {title}
          <SummaryPriceWrapper $isHighlighted={!!isNegative}>
            <SummaryCurrency>
              {isNegative && amount > 0 ? "-$" : "$"}
            </SummaryCurrency>
            {Math.abs(amount)}
          </SummaryPriceWrapper>
        </SummaryPriceItem>
      );
    };

    return (
      <Wrapper>
        <PriceItem>
          {`${deliveryName} / ${weight} ${t(`units.${units}.primaryShort`)}`}
          <DashConnector />
          <PriceWrapper>
            <Currency>$</Currency>
            {deliveryCost}
          </PriceWrapper>
        </PriceItem>
        <PriceItem>
          <InsuranceCheckBoxContainer>
            <Checkbox
              label={t("parcels.insurance")}
              checked={isRequireInsurance}
              disabled={isEditDisabled}
              isLoading={isLoadingUpdateInsurance}
              onChange={onChangeInsurance}
            />

            <Tooltip
              body={
                <TooltipInfo>
                  {t("parcels.insuranceMessage")}
                  <Link to="/">{t("common.learnMore")}</Link>
                </TooltipInfo>
              }
            >
              <InsuranceHelpIcon type={IconType.Help} />
            </Tooltip>
          </InsuranceCheckBoxContainer>
          {showInsuranceConfirmation && (
            <InsuranceConfirmation>
              <InsuranceButton
                size={ButtonSize.Small}
                $isConfirm={false}
                onClick={handleInsuranceCancel}
              >
                {t("common.no")}
              </InsuranceButton>
              <InsuranceButton
                size={ButtonSize.Small}
                $isConfirm={true}
                isLoading={isLoadingUpdateInsurance}
                onClick={handleInsuranceConfirm}
              >
                {t("common.yes")}
              </InsuranceButton>
            </InsuranceConfirmation>
          )}
          <InsurancePriceWrapper>
            <Currency>$</Currency>
            {parcel.getIn(["estimate", "insurance"], 0)}
          </InsurancePriceWrapper>
        </PriceItem>
        {hasPackingCosts &&
          renderPriceItem(t("parcels.packingGrade"), packingCost)}
        {hasServiceRequestsCost &&
          renderPriceItem(
            t("parcels.packingServiceRequests"),
            serviceRequestsCost,
          )}
        {hasAddonsCosts &&
          renderPriceItem(t("parcels.packingOptions"), addonsCost)}

        <Separator />

        {shouldShowUserBalance &&
          renderSummaryPriceItem(
            t("parcels.total", { amount: totalCosts }),
            totalCosts,
          )}
        {!isPaid && (
          <>
            {shouldShowUserBalance &&
              renderSummaryPriceItem(
                t("parcels.balance"),
                userBalanceToShow,
                true,
              )}
            <TotalPriceItem>
              {t("parcels.totalToPay")}
              <DashConnector />
              <PriceWrapper>
                <Currency>$</Currency>
                {totalToPayToShow}
              </PriceWrapper>
            </TotalPriceItem>
          </>
        )}
      </Wrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    units: authSelectUnits,
    isLoadingUpdateInsurance: outgoingSelectLoadingInsurance,
  }),
  {
    updateRequireInsurance,
  },
);

export default withConnect(PriceList);
