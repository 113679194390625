// utils
import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const defaultParams = fromJS({
  page: 1,
  filter: null,
});

export const initialState = fromJS({
  isLoading: false,
  isLoadingAddFunds: false,
  totalAmount: 0,
  hasItems: false,
  historyItems: [],
  params: defaultParams,
  selectedRecipientId: null,
  amountFunds: '',
});

// const
export const GET_BOOKS_TRANSACTIONS_HISTORY =
  'books/GET_BOOKS_TRANSACTIONS_HISTORY';
export const GET_BOOKS_RECIPIENTS = 'books/GET_BOOKS_RECIPIENTS';
export const FETCH_BOOKS_TRANSACTIONS_HISTORY =
  'books/FETCH_BOOKS_TRANSACTIONS_HISTORY';
export const CATCH_BOOKS_TRANSACTIONS_ERROR =
  'books/CATCH_BOOKS_TRANSACTIONS_ERROR';
export const UPDATE_BOOKS_TRANSACTIONS_HISTORY =
  'books/UPDATE_BOOKS_TRANSACTIONS_HISTORY';
export const SET_SELECTED_RECIPIENT_ID = 'books/SET_SELECTED_RECIPIENT_ID';
export const CREATE_RECHARGE_BALANCE = 'books/CREATE_RECHARGE_BALANCE';
export const SET_AMOUNT_FUNDS = 'books/SET_AMOUNT_FUNDS';

// action creators
export const getBooksTransactionsHistory = createAction(
  GET_BOOKS_TRANSACTIONS_HISTORY,
  (data = defaultParams) => data,
);
export const fetchBooksTransactions = createAction(
  FETCH_BOOKS_TRANSACTIONS_HISTORY,
);
export const catchBooksTransactionsError = createAction(
  CATCH_BOOKS_TRANSACTIONS_ERROR,
);
export const updateBooksTransactionsHistory = createAction(
  UPDATE_BOOKS_TRANSACTIONS_HISTORY,
);
export const setSelectedRecipientId = createAction(SET_SELECTED_RECIPIENT_ID);
export const createRechargeBalance = createAction(
  CREATE_RECHARGE_BALANCE,
  ({ data }) => ({
    promise: api.recharge.rechargeBalance({ data }),
  }),
);
export const updateAmountFunds = createAction(SET_AMOUNT_FUNDS);

// helpers
export const normalizeItems = (items) =>
  items.map((item) => {
    return item;
  });

const booksReducer = handleActions(
  {
    [GET_BOOKS_TRANSACTIONS_HISTORY]: (state, { payload }) =>
      payload.size ? state : state.set('historyItems', null),

    [FETCH_BOOKS_TRANSACTIONS_HISTORY]: (state, { payload }) => {
      return state.set('isLoading', true);
    },

    [UPDATE_BOOKS_TRANSACTIONS_HISTORY]: (state, { payload }) => {
      const isPagination =
        state.getIn(['params', 'page']) < payload.getIn(['params', 'page']);
      const headers = payload.get('headers');
      const totalAmountHeader = headers.find((x) => x.get(0) === 'total');
      const totalAmountFromHeader = totalAmountHeader
        ? parseInt(totalAmountHeader.get(1))
        : state.get('totalAmount');

      const normalizedItems = normalizeItems(payload.get('data'));
      const hasItems = !!normalizedItems.size;

      return state
        .update('historyItems', (items) =>
          isPagination ? items.concat(normalizedItems) : normalizedItems,
        )
        .set('totalAmount', totalAmountFromHeader)
        .set('hasItems', hasItems)
        .set('params', payload.get('params'))
        .set('isLoading', false);
    },

    [CATCH_BOOKS_TRANSACTIONS_ERROR]: (state) => state.set('isLoading', false),

    [SET_SELECTED_RECIPIENT_ID]: (state, { payload }) =>
      state.set('selectedRecipientId', payload),

    [SET_AMOUNT_FUNDS]: (state, { payload }) =>
      state.set('amountFunds', payload),
  },

  initialState,
);

export default booksReducer;
