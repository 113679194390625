import React, { FC, useCallback, useEffect, useRef } from "react";
import {
  BackArrow,
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonType, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { useFormik } from "formik";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { CloseRightPanel, OpenRightPanel } from "types/common/rightPanel";
import Tab from "../../../containers/RightPanel/components/Tab";
import TabsView from "../../../containers/RightPanel/components/TabsView";
import {
  createAddress,
  updateAddress,
} from "../../../reduxFolder/reducers/destinations";
import {
  closeRightPanel,
  openRightPanel,
} from "../../../reduxFolder/reducers/rightPanel";
import { authSelectLocale } from "../../../reduxFolder/selectors/globalSelectors";
import {
  outgoingSelectDetailedItems,
  outgoingSelectDetailedLoading,
} from "../../../reduxFolder/selectors/outgoingSelectors";
import Button from "../../Button/Button";
import Tooltip from "../../Tooltip/Tooltip";
import {
  addressForm,
  getAreAllRequiredFieldsValid,
  supportedCountryPhoneMasks,
} from "../addressFormHelper";
import Address from "./Address";
import AddressCustomSender from "./AddressCustomSender";
import {
  ButtonsWrapper,
  Content,
  CustomSenderErrorMsg,
  FormWrapper,
  InfoText,
  InfoWrapper,
  Spinner,
} from "./AddressForm.styles";

interface AddressFormProps {
  currentLocale: string;
  isOpen: boolean;
  openRightPanel: OpenRightPanel;
  closeRightPanel: CloseRightPanel;
  isLoadingDetailedItem?: boolean;
  panelData: any;
  createAddress: (data: any) => Promise<any>;
  updateAddress: (
    id: string,
    data: { destination_address: any },
  ) => Promise<any>;
  detailedItems: any;
}

const AddressForm: FC<AddressFormProps> = ({
  panelData,
  isLoadingDetailedItem = false,
  closeRightPanel,
  openRightPanel,
  isOpen,
  currentLocale,
  detailedItems,
  createAddress,
  updateAddress,
}) => {
  const { t } = useTranslation("common");

  const formProps = useFormik(
    addressForm(
      t,
      panelData,
      openRightPanel,
      closeRightPanel,
      createAddress,
      updateAddress,
      detailedItems,
      currentLocale,
    ),
  );

  const { values, errors, handleSubmit, handleReset, isSubmitting } = formProps;

  const formRef = useRef(null);
  const isEditForm = panelData.get("isEditForm");
  const showDetailsTabRecipientEditForm = panelData.get("isRecipientEditForm");
  const returnPreviousPanel = panelData.get("returnPreviousPanel");
  const handleBack = useCallback(
    () =>
      openRightPanel(
        returnPreviousPanel,
        panelData.get("initialPanelData", Map()),
      ),
    [panelData, returnPreviousPanel, openRightPanel],
  );
  const country = values.address.country;
  const showNativeSections =
    country &&
    country.value !== "US" &&
    supportedCountryPhoneMasks.indexOf(country.value ?? "") !== -1;

  useEffect(() => {
    if (!isOpen) handleReset(null);
  }, [isOpen]);

  const areAllRequiredFieldsValid = getAreAllRequiredFieldsValid(
    values,
    errors,
  );

  return (
    <ContentWrapper>
      <Heading>
        {returnPreviousPanel && (
          <button onClick={handleBack}>
            <BackArrow type={IconType.Arrow} />
          </button>
        )}
        {isEditForm ? t("account.addressEditing") : t("account.addingAddress")}
      </Heading>
      <Spinner isActive={isLoadingDetailedItem} />
      {!isLoadingDetailedItem && (
        <FormWrapper ref={formRef} onSubmit={handleSubmit}>
          <TabsView openTab={panelData.get("openTab")}>
            <Tab title={t("account.address")}>
              <Content>
                <Address
                  {...formProps}
                  currentLocale={currentLocale}
                  showNativeSections={showNativeSections}
                />
              </Content>
            </Tab>
            {showNativeSections && (
              <Tab
                title={t("account.customSenderTitle")}
                tabHeader={
                  areAllRequiredFieldsValid ? (
                    t("account.customSenderTitle")
                  ) : (
                    <Tooltip
                      body={
                        <CustomSenderErrorMsg>
                          {t("account.customSenderDisabledTooltip")}
                        </CustomSenderErrorMsg>
                      }
                    >
                      {t("account.customSenderTitle")}
                    </Tooltip>
                  )
                }
                disabled={!areAllRequiredFieldsValid}
              >
                <Content>
                  <InfoWrapper>
                    <InfoText>{t("account.customSenderDesc")}</InfoText>
                  </InfoWrapper>
                  <AddressCustomSender {...formProps} />
                </Content>
              </Tab>
            )}
            {showDetailsTabRecipientEditForm && (
              <Tab title={t("account.details")}>
                <Content></Content>
              </Tab>
            )}
          </TabsView>
          <ButtonsWrapper>
            <Button
              onClick={returnPreviousPanel ? handleBack : closeRightPanel}
            >
              {t("common.cancel")}
            </Button>
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting}
              type={ButtonType.Submit}
              color={ButtonColor.Primary}
              variant={ButtonVariant.Filled}
            >
              {isEditForm
                ? t("common.saveChanges")
                : t("common.addAddress", { word: t("common.new") })}
            </Button>
          </ButtonsWrapper>
        </FormWrapper>
      )}
    </ContentWrapper>
  );
};

const withConnect = connect(
  createStructuredSelector({
    detailedItems: outgoingSelectDetailedItems,
    isLoadingDetailedItem: outgoingSelectDetailedLoading,
    currentLocale: authSelectLocale,
  }),
  {
    openRightPanel,
    closeRightPanel,
    createAddress,
    updateAddress,
  },
);

export default withConnect(AddressForm);
