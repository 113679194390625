import React, { FC, useCallback } from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import AddonsSummary, {
  AddonsSummaryShowSection,
} from "../../../../components/AddonsSummary/AddonsSummary";
import {
  cartSelectCartConsolidationItems,
  cartSelectCartDeliveryMethod,
  cartSelectCartItems,
  cartSelectCartPacking,
} from "../../../../reduxFolder/selectors/cartSelectors";
import {
  servicesSelectAddons,
  servicesSelectConsolidationAddons,
  servicesSelectDelivery,
  servicesSelectPackingMethods,
} from "../../../../reduxFolder/selectors/servicesSelectors";
import { SideItem, SideItemHead } from "../../styles/Delivery.styles";
import { ScrollableWrapper } from "../SideAddons/SideAddonsInfo/SideAddonsInfo.styles";
import {
  AddonsWrapper,
  ChangeLink,
  Logo,
  SideDescription,
  SideParagraph,
} from "./Summary.styles";

interface SummaryBlockProps {
  items: any;
  consolidationItems: any;
  packing: any;
  packingMethods: any;
  deliveryMethods: any;
  addons: any;
  consolidationAddons: any;
  userFfid?: number;
  delivery?: string;
  className?: string;
}

const SummaryBlock: FC<SummaryBlockProps> = React.memo(
  ({
    deliveryMethods,
    packingMethods,
    addons,
    consolidationAddons,
    items,
    consolidationItems,
    delivery,
    packing,
    className = "",
  }) => {
    const { t } = useTranslation("common");
    const { pathname } = useLocation();
    const hasPacking = !!(packing.size && packingMethods.size);
    const hasAddons = !!(consolidationItems && consolidationItems.size);
    const hasConsolidationOptions = !!items.find(
      (item: any) => !!item.get("addons").size,
    );
    const isShowAddons = pathname === "/shipping/summary" && hasAddons;
    const isConsolidationOptions =
      pathname === "/shipping/summary" && hasConsolidationOptions;

    const rendererDelivery = useCallback(() => {
      const deliveryMethod = deliveryMethods.find(
        (method: any) => method.get("name") === delivery,
      );

      return deliveryMethod ? (
        <SideDescription>
          <Logo logoUrl={deliveryMethod.get("logo_url")} />
          <SideParagraph>{deliveryMethod.get("name")}</SideParagraph>
        </SideDescription>
      ) : null;
    }, [delivery, deliveryMethods]);

    const rendererPacking = useCallback(() => {
      const packingMethod = packingMethods.find(
        (method: any) => method.get("code") === packing.get(0),
      );

      return packingMethod ? (
        <SideDescription>
          <Logo logoUrl={packingMethod.get("logo_url")} />
          <SideParagraph>{packingMethod.get("title")}</SideParagraph>
        </SideDescription>
      ) : null;
    }, [packing, packingMethods]);

    return (
      <Collapse isOpened className={className}>
        {delivery && (
          <SideItem>
            <SideItemHead>
              {t("shipping.delivery")}:
              <ChangeLink
                to={"/shipping/flow/delivery"}
                state={{ prevPath: pathname }}
              >
                {t("common.change")}
              </ChangeLink>
            </SideItemHead>
            {rendererDelivery()}
          </SideItem>
        )}
        {hasPacking && (
          <SideItem>
            <SideItemHead>
              {t("shipping.packing")}:
              <ChangeLink
                to={"/shipping/flow/delivery/packing"}
                state={{ prevPath: pathname }}
              >
                {t("common.change")}
              </ChangeLink>
            </SideItemHead>
            {rendererPacking()}
          </SideItem>
        )}
        {isShowAddons && (
          <AddonsWrapper>
            <SideItemHead>
              {t("shipping.optionsForAllPackages")}:
              <ChangeLink
                to={"/shipping/flow/delivery/additional"}
                state={{ prevPath: pathname }}
              >
                {t("common.change")}
              </ChangeLink>
            </SideItemHead>
            <ScrollableWrapper autoHeight autoHeightMax={250}>
              <AddonsSummary
                addons={addons}
                consolidationAddons={consolidationAddons}
                items={items}
                consolidationItems={consolidationItems}
                sectionVisibility={AddonsSummaryShowSection.ONLY_ADDONS}
              />
            </ScrollableWrapper>
          </AddonsWrapper>
        )}
        {isConsolidationOptions && (
          <AddonsWrapper>
            <SideItemHead>
              {t("shipping.optionsForIndividualItems")}:
              <ChangeLink
                to={"/shipping/flow/delivery/additional"}
                state={{ prevPath: pathname }}
              >
                {t("common.change")}
              </ChangeLink>
            </SideItemHead>
            <ScrollableWrapper autoHeight autoHeightMax={250}>
              <AddonsSummary
                addons={addons}
                consolidationAddons={consolidationAddons}
                items={items}
                consolidationItems={consolidationItems}
                sectionVisibility={
                  AddonsSummaryShowSection.ONLY_CONSOLIDATION_OPTIONS
                }
              />
            </ScrollableWrapper>
          </AddonsWrapper>
        )}
      </Collapse>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    deliveryMethods: servicesSelectDelivery,
    packingMethods: servicesSelectPackingMethods,
    addons: servicesSelectAddons,
    consolidationAddons: servicesSelectConsolidationAddons,
    items: cartSelectCartItems,
    consolidationItems: cartSelectCartConsolidationItems,
    delivery: cartSelectCartDeliveryMethod,
    packing: cartSelectCartPacking,
  }),
  null,
);

export default withConnect(SummaryBlock);
