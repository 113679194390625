import React, { FC } from "react";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "enums/Button";
import { useFormik } from "formik";
import { Collapse } from "react-collapse";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  consolidateCart,
  dropCart,
  setPartnerId,
} from "reduxFolder/reducers/cart";
import {
  cartSelectCustomInformation,
  cartSelectSku,
} from "reduxFolder/selectors/cartSelectors";
import { authSelectAllowSearchByLocation } from "reduxFolder/selectors/globalSelectors";
import { createStructuredSelector } from "reselect";
import formatter from "utils/formatter";
import { toastResponseError } from "utils/responseMessageHelper";
import {
  Content,
  Heading,
  SideItem,
  SideItemHead,
  SubmitWrapper,
} from "../../styles/Delivery.styles";
import TotalPackingCost from "../TotalPackingCost/TotalPackingCost";
import {
  BlockContent,
  ChangeLink,
  CustomsDeclarationSummary,
  HeadingWrapper,
  PartnerInputWrapper,
  StatValue,
  StyledForm,
  SummarySecond,
  TotalCustomsPrice,
  TwoColumnsLayout,
  Wrapper,
} from "./Summary.styles";
import { Summary } from "./SummaryForm.styles";

interface SummaryFormProps {
  customInformation: any;
  sku: string;
  allowSearchByLocation: boolean;
  setPartnerId: (id: string) => void;
  consolidateCart: () => Promise<void>;
  dropCart: () => void;
}

const SummaryForm: FC<SummaryFormProps> = React.memo(
  ({
    customInformation,
    sku,
    allowSearchByLocation,
    setPartnerId,
    consolidateCart,
    dropCart,
  }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const shouldShowPartnerField = allowSearchByLocation === true;

    const { values, handleChange, handleSubmit, isSubmitting } = useFormik({
      initialValues: { partnerId: "" },
      onSubmit: (values, { setSubmitting }) => {
        const { partnerId } = values;

        const needToSendPartnerId =
          allowSearchByLocation && partnerId && partnerId.length > 0;
        const setPartnerIdIfNeed = needToSendPartnerId
          ? setPartnerId(partnerId)
          : null;

        Promise.all([setPartnerIdIfNeed, consolidateCart()])
          .then(() => {
            dropCart();
            navigate("/parcels/outgoing");
          })
          .catch((error) => toastResponseError(error))
          .finally(() => setSubmitting(false));
      },
    });

    const renderSummary = () => {
      return (
        <>
          {sku}&nbsp;{t("shipping.summary")}:
        </>
      );
    };

    const renderPartnerId = () => {
      return (
        <HeadingWrapper>
          <PartnerInputWrapper>
            <Input
              name="partnerId"
              placeholder={"Partner ID"}
              value={values.partnerId}
              onChange={handleChange}
            />
          </PartnerInputWrapper>
          {renderSummary()}
        </HeadingWrapper>
      );
    };

    const rendererCustomsDeclarationSummary = () => {
      const totalQuantity = customInformation.reduce(
        (total: number, customsRow: any) => total + customsRow.get("quantity"),
        0,
      );
      const totalValue = customInformation.reduce(
        (total: number, customsRow: any) => total + customsRow.get("value"),
        0,
      );

      return (
        <CustomsDeclarationSummary>
          <StatValue>
            <strong>{customInformation.size}&nbsp;</strong>
            <span>
              {customInformation.size === 1
                ? t("shipping.singularCustomsSummary")
                : t("shipping.pluralCustomsSummary")}
            </span>
          </StatValue>
          <StatValue>
            <span>{t("shipping.quantityCustomsSummary")}</span>
            <strong>&nbsp;{totalQuantity}</strong>
          </StatValue>
          <TotalCustomsPrice>{`$${formatter.currency(
            totalValue,
          )}`}</TotalCustomsPrice>
        </CustomsDeclarationSummary>
      );
    };

    return (
      <Col lg={4} md={6} sm={12} offset={{ md: 3, lg: 4, sm: 12 }}>
        <Content>
          <Wrapper>
            <StyledForm onSubmit={handleSubmit}>
              <Heading>
                {shouldShowPartnerField ? renderPartnerId() : renderSummary()}
              </Heading>
              <TwoColumnsLayout>
                <BlockContent>
                  <Summary />
                </BlockContent>
                <BlockContent>
                  <SummarySecond>
                    <Collapse isOpened>
                      <SideItem>
                        <SideItemHead>
                          {t("parcels.customsDeclarations")}:
                          <ChangeLink
                            to={"/shipping/flow/customs-data"}
                            state={{ prevPath: pathname }}
                          >
                            {t("common.change")}
                          </ChangeLink>
                        </SideItemHead>
                        {rendererCustomsDeclarationSummary()}
                      </SideItem>
                      <TotalPackingCost />
                    </Collapse>
                    <SubmitWrapper>
                      <Button
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        type={ButtonType.Submit}
                        size={ButtonSize.Large}
                        color={ButtonColor.Primary}
                        variant={ButtonVariant.Bordered}
                      >
                        {t("shipping.payAfterPacking")}
                      </Button>
                    </SubmitWrapper>
                  </SummarySecond>
                </BlockContent>
              </TwoColumnsLayout>
            </StyledForm>
          </Wrapper>
        </Content>
      </Col>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    sku: cartSelectSku,
    allowSearchByLocation: authSelectAllowSearchByLocation,
    customInformation: cartSelectCustomInformation,
  }),
  { consolidateCart, dropCart, setPartnerId },
);

export default withConnect(SummaryForm);
