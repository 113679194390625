import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import styled from "styled-components";
import { Form } from "styles/formStyles";

interface StyledFormProps {
  $noPadding: boolean;
}

export const StyledForm = styled(Form)<StyledFormProps>`
  border: none;
  justify-content: flex-start;
  ${({ $noPadding }) => ($noPadding ? "padding: 0;" : "")}
`;

export const primaryCellWidth = "220px";
export const secondaryCellWidth = "82px";

interface StyledTableProps {
  disabled: boolean;
  $isWizardStep: boolean;
  $readOnly: boolean;
  $isShowHSCodeColumn: boolean;
}

export const StyledTable = styled.table<StyledTableProps>`
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
  ${({ disabled }) => (disabled ? "pointer-events: none; opacity: 0.5;" : "")}
  overflow-y: auto;
  height: ${({ $isWizardStep }) => ($isWizardStep ? "400px" : "100%")};
  min-height: 400px;

  display: block;

  thead th {
    ${({ theme, $isShowHSCodeColumn }) => `padding: 10px 0;
  border: 1px solid ${theme.grey};
  width: ${$isShowHSCodeColumn ? "50px" : secondaryCellWidth};

  &:nth-child(1) {
    width: ${$isShowHSCodeColumn ? "170px" : primaryCellWidth};
  }

  &:nth-child(2) {
    width: ${$isShowHSCodeColumn ? "100px" : secondaryCellWidth};
}
  `}
  }

  thead tr {
    display: block;
  }

  tbody {
    display: block;

    ${({ $readOnly }) => ($readOnly ? "pointer-events: none;" : "")}
  }

  tbody td {
    ${({ $isShowHSCodeColumn }) => `
  width: ${$isShowHSCodeColumn ? "50px" : secondaryCellWidth};

  &:nth-child(1) {
    width: ${$isShowHSCodeColumn ? "170px" : primaryCellWidth};
  }

  &:nth-child(2) {
    width: ${$isShowHSCodeColumn ? "100px" : secondaryCellWidth};
  `}
  }

  thead {
    position: sticky;
    z-index: 2;
    background: white;
    display: block;
  }

  thead {
    inset-block-start: 0; /* "top" */
  }
`;

export const DeleteButton = styled(Button)`
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 7px;
  padding: 5px;
`;

interface SaveButtonProps {
  $alignCenter: boolean;
}

export const SaveButton = styled(Button)<SaveButtonProps>`
  ${({ $alignCenter }) => ($alignCenter ? "margin: auto;" : "")}
`;

export const DeleteIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.red};
`;

export const Row = styled.tr`
  &:hover {
    ${DeleteButton} {
      display: block !important;
    }
  }
`;

export const LastRow = styled.tr`
  input {
    background-color: ${({ theme }) => theme.almostWhiteDarker} !important;
  }
`;

interface CellProps {
  size?: string;
  $invalid?: boolean;
}

export const Cell = styled.td<CellProps>`
  position: relative;
  padding: 0;
  ${({ theme, size }) => `
    border: 1px solid ${theme.grey};
    width: ${size === "large" ? primaryCellWidth : secondaryCellWidth};
  `}

  input {
    ${({ theme, $invalid }) => `
      border: ${$invalid ? `1px solid ${theme.red}` : "none"};
    `}
    box-shadow: none;
    padding-right: 10px;
  }
`;
