import { PageWrapper } from "layouts/Layout.styles";
import { fadeInLeft, fadeOutRight } from "react-animations";
import { Container } from "react-grid-system";
import styled from "styled-components";

export const Wrapper = styled(PageWrapper)`
  position: absolute;
  width: 100%;
  animation: none;

  &.page-enter {
    animation: ${fadeInLeft} 0.22s ease;
  }

  &.page-exit {
    animation: ${fadeOutRight} 0.22s ease;
  }
`;

export const StyledGrid = styled(Container)`
  position: relative;
`;

export const StorageHeading = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const SelectAllSwitchWrapper = styled.div`
  margin-right: 8px;
  transform: translateY(4px);
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  height: 80px;
`;

export const Buttons = styled.div`
  padding: 20px 15px;
  background-color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  left: 80px;
  bottom: 0;
  width: 100%;

  & > div {
    display: flex;
    margin: 0 10px;
  }

  & > button {
    margin: 0 10px;
    height: 41px;
    min-width: 150px;
  }

  ${(props) => props.theme.sm`
    left: 0;

    &::before {
      left: 0;
    }
  `}

  ${(props) => props.theme.xs`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    button {
      margin: 5px;
    }
  `}
`;
