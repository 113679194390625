import styled from "styled-components";
import done from "../Icon/icons/checkbox-select.svg";
import PreLoader from "../PreLoader/PreLoader";

interface CheckboxLabelProps {
  $invalid: boolean;
  onClick?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
}

interface CheckboxCustomProps {
  disabled: boolean;
  $customPaddingValue?: number;
}

export const Spinner = styled(PreLoader)`
  position: absolute;
  width: 20px;
  height: 14px;
  transform-origin: 0 10px;
  transform: scale(0.3);
  left: initial;
  right: 100%;
  top: -7px;
  margin-right: 4px;
`;

export const CheckboxField = styled.input`
  display: none;
`;

export const CheckboxText = styled.div`
  line-height: 18px;
  margin-left: 8px;
`;

export const CheckboxLabel = styled.label<CheckboxLabelProps>`
  display: inline-flex;
  align-items: center;
  position: relative;
  color: ${(props) => props.theme.black50};

  ${CheckboxField}:checked ~ div${CheckboxText} {
    color: ${(props) => props.theme.black};
  }

  ${({ theme, $invalid }) => ($invalid ? `color: ${theme.red};` : "")}
`;

export const CheckboxLine = styled.label`
  display: inline-flex;
  align-items: center;
`;

export const CheckboxCustom = styled.div<CheckboxCustomProps>`
  position: relative;
  width: 18px;
  height: 18px;
  min-width: 18px;
  ${({ theme, disabled, $customPaddingValue }) => `
    cursor: ${disabled ? "default" : "pointer"};
    border: 1px solid ${theme.grey};
    background-color: ${disabled ? theme.black05 : theme.white};
    box-shadow: inset 0 1px 3px 0 ${theme.lightgrey};
    transition: all 0.15s ease-in-out;
    ${$customPaddingValue ? `margin: 0 ${$customPaddingValue}px;` : ""}
  `}

  ${({ $customPaddingValue }) => `
    &::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      ${$customPaddingValue ? `padding: ${$customPaddingValue}px;` : ""}
    }
  `}

  ${CheckboxField}:checked + & {
    ${({ theme, disabled }) =>
      !disabled
        ? `
        box-shadow: none;       
        background-color: ${theme.primary};
        border: 1px solid ${theme.primary};
      `
        : `
        background-color: rgba(0, 0, 0, 0.2);
      `}
  }

  ${CheckboxField}:checked + &::before {
    background-image: url(${done});
    background-repeat: no-repeat;
    ${({ $customPaddingValue }) =>
      $customPaddingValue
        ? `background-position: ${$customPaddingValue}px;`
        : ""}
  }
`;

export const CheckboxIcon = styled.span`
  display: flex;
  margin-left: 16px;
`;
