import React, { FC, memo } from "react";
import { Hidden, Visible } from "react-grid-system";
import { capitalized } from "../../../../../utils/common";
import { useFormattedDate } from "../../../../../utils/formatDate";
import {
  MainCol,
  TableCol,
  TableRow,
  Wrapper,
} from "./BooksTransactionsHistoryItemRow.styles";

interface BooksTransactionsHistoryItemRowProps {
  item: any;
  disabled: boolean;
}

const BooksTransactionsHistoryItemRow: FC<
  BooksTransactionsHistoryItemRowProps
> = ({ item, disabled }) => {
  const getType = () => item.get("account_type").replace(/_/g, " ");
  const created = useFormattedDate({
    date: item.get("created_at"),
  });

  return (
    <Wrapper disabled={disabled} key={item.get("id")}>
      <TableRow align="center">
        {/* Mobile version of rows */}
        <Visible xs>
          <TableCol xs={12}>{item.get("description")}</TableCol>
          <TableCol xs={12}>
            <TableCol xs={3}>{capitalized(getType())}</TableCol>
            <TableCol xs={3}>{`Amount:${item.get("amount")}`}</TableCol>
            <TableCol xs={4}>{created}</TableCol>
            <TableCol xs={2}>{item.get("transaction_type")}</TableCol>
          </TableCol>
        </Visible>
        {/* Desktop version of rows */}
        <Hidden xs>
          <MainCol xs={12}>
            <TableCol sm={2} md={2}>
              {capitalized(getType())}
            </TableCol>
            <TableCol sm={6} md={6}>
              {item.get("description")}
            </TableCol>
            <TableCol sm={1} md={1}>
              {item.get("amount")}
            </TableCol>
            <TableCol sm={2} md={2}>
              {created}
            </TableCol>
            <TableCol sm={1} md={1}>
              {item.get("transaction_type")}
            </TableCol>
          </MainCol>
        </Hidden>
      </TableRow>
    </Wrapper>
  );
};

export default memo(BooksTransactionsHistoryItemRow);
