import { FormikHelpers } from "formik";
import { Map } from "immutable";
import { OpenRightPanel } from "types/common/rightPanel";
import { PackingGradeFormValues } from "types/OutgoingForms/packingGradePanel";
import { toastResponseError } from "utils/responseMessageHelper";
import * as yup from "yup";

export const packingGradePanelFormik = (
  panelData: any,
  openRightPanel: OpenRightPanel,
  consolidationAddons: any,
  cart: any,
  updateConsolidationAddons: (id: string, data: any) => Promise<any>,
  updateConsolidationOptionalLineItems: (id: string, data: any) => Promise<any>,
  setCartPackingMethods: (data: any) => Promise<any>,
  setCartConsolidationAddonsMethods: (data: any) => Promise<any>,
) => ({
  initialValues: {
    packingType: panelData.get("packingCode"),
    packingMethods: panelData.get("packingMethods"),
    selectedAddonCodes: ((selectedConsolidationAddons) => {
      const packingCode = cart.getIn(["optional_line_item_codes", 0]);
      return consolidationAddons.reduce((selectedValues: any, addon: any) => {
        const code = addon.get("code");
        const currentSelected = selectedConsolidationAddons.includes(code);
        return packingCode
          ? selectedValues.set(code, currentSelected)
          : selectedValues;
      }, Map());
    })(panelData.get("selectedConsolidationAddons")),
  },

  validationSchema: () =>
    yup.object().shape({
      packingType: yup.string().required(),
      selectedAddonCodes: yup
        .object()
        .test("areSelectedAddonCodesValid", (value, params) => {
          if (!consolidationAddons && !value) {
            return false;
          }
          for (let i = 0; i < consolidationAddons.count(); i++) {
            const addonItem = consolidationAddons.get(i);
            if (
              Map.isMap(value) &&
              value.get(addonItem.get("code")) === undefined
            ) {
              return false;
            }
          }

          return true;
        }),
    }),

  onSubmit: (
    values: PackingGradeFormValues,
    { setSubmitting }: FormikHelpers<PackingGradeFormValues>,
  ) => {
    const id = panelData.get("detailedItemID");
    const isCartVariant = panelData.get("isCartVariant");
    const { selectedAddonCodes, packingType } = values;

    const selectedAddonsIds = consolidationAddons.reduce(
      (list: any[], addon: any) => {
        const code = addon.get("code");
        if (selectedAddonCodes.get(code) === true) {
          list.push(addon.get("id"));
        }
        return list;
      },
      [],
    );

    const requests = isCartVariant
      ? Promise.all([
          setCartPackingMethods({
            optional_line_item_codes: [packingType],
          }),
          setCartConsolidationAddonsMethods({ addons_ids: selectedAddonsIds }),
        ])
      : Promise.all([
          updateConsolidationAddons(id, { addons_ids: selectedAddonsIds }),
          updateConsolidationOptionalLineItems(id, {
            optional_line_item_codes: [packingType],
          }),
        ]);

    requests
      .then(() => {
        if (panelData.get("setAccess")) {
          panelData.get("setAccess")(selectedAddonsIds);
        }
        openRightPanel(panelData.get("returnPreviousPanel"), panelData);
      })
      .catch((response) => toastResponseError(response))
      .finally(() => setSubmitting(false));
  },
});
