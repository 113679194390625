import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import Logo from "components/Logo/Logo";
import { TooltipBody, TooltipWrapper } from "components/Tooltip/Tooltip.styles";
import { fadeInRight } from "react-animations";
import styled, { keyframes } from "styled-components";

export const Overlay = styled.div`
  display: block;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: -100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
`;

interface SearchTooltipProps {
  $isMobile: boolean;
}

export const SearchTooltip = styled(TooltipWrapper)<SearchTooltipProps>`
  ${({ $isMobile }) =>
    $isMobile &&
    `top: -10px !important;
     position: fixed !important;
  
   .Popover-tipShape {
     display: none;
   }`}
`;

export const FilterTooltipBody = styled(TooltipBody)`
  padding: 0 0 20px 0;
  max-width: 75vw;
  width: 270px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  color: ${(props) => props.theme.black50};

  ${(props) => props.theme.xs`
    width: 100vw;
    max-width: 100vw;
  `}
`;

export const InnerTitle = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 15px 15px 20px 15px;
  color: ${(props) => props.theme.black};
  border-bottom: 1px solid ${(props) => props.theme.lightgrey};
`;

export const ResetFilter = styled.button`
  &:hover i {
    background-color: ${({ theme }) => theme.black};
  }
`;

interface FilterProps {
  $isOpen: boolean;
  $isActive: boolean;
  $activeFilterStyleOverrides?: string[];
}

export const Filter = styled.div<FilterProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  margin: 0 5px;
  color: ${({ theme }) => theme.black50};
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.primaryPale : theme.lightgrey03};

  &:hover {
    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.primaryPale : theme.lightgrey};
  }

  ${({ theme, $isOpen, $isActive }) =>
    $isOpen && !$isActive && `background-color: ${theme.lightgrey};`}

  ${ResetFilter} {
    display: ${({ $isActive }) => !$isActive && "none"};
  }

  ${(props) => props.theme.sm`
     margin: 5px;
  `}

  ${(props) => props.theme.xs`
    font-size : 13px;
  `}
  
  ${({ $isActive, $activeFilterStyleOverrides }) =>
    $isActive ? $activeFilterStyleOverrides : ""}
`;

export const ResetIcon = styled(Icon)`
  margin-left: 10px;
  width: 14px;
  height: 14px;
  transition: background-color 0.3s;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  transition: padding 0.2s ease-in-out;

  & > *:nth-child(n + 1) {
    margin-left: 5px;
  }
`;

export const Content = styled.div`
  flex: 1;
`;

export const FiltersContainer = styled.div`
  display: flex;
  height: 34px;
  background-color: ${(props) => props.theme.white};
  transition: display 0.3s;
  align-items: stretch;

  & > * {
    &:nth-child(n + 2) {
      border-left: none;
    }
  }
`;

interface FilterButtonProps {
  selected?: boolean;
}

export const FilterButton = styled(Button)<FilterButtonProps>`
  display: flex;
  align-items: center;
  height: 34px;

  padding: 6px;
  border: solid 1px ${(props) => props.theme.grey};
  background-color: ${(props) =>
    props.selected ? props.theme.primary10 : props.theme.white};
  transition: display 0.3s;
  min-width: max-content;
  justify-content: center;

  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.black};

  &:hover {
    background-color: ${(props) => props.theme.lightgrey03};
    color: ${(props) => props.theme.black50};
  }
`;

export const FilterSegmentButton = styled(FilterButton)`
  width: calc(100% / 3);
`;

export const FiltersToggleButton = styled(FilterButton)`
  width: 45px;
  position: relative;
`;

export const FilterLogo = styled(Logo)`
  margin-right: 10px;
`;

export const MenuIcon = styled(Icon)`
  height: 15px;
  width: 6px;
  margin-left: 5px;
  transition: background-color 0.3s;
  background-color: ${(props) => props.theme.black50};
`;

export const FilterIcon = styled(Icon)`
  height: 15px;
  width: 15px;
`;

export const FilterCounter = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  background: #3e82f7;
  border-radius: 8px;
  top: -8px;
  right: -8px;
  color: white;
  font-size: 12px;
  line-height: 16px;
`;

export const ResetFilterButton = styled(Button)`
  margin: 0 5px;
  padding: 9px 5px;
`;

export const TooltipContent = styled.div`
  padding: 20px;
  width: 100%;

  ${({ theme }) => theme.xs`
    padding: 20px 35px
  `}
`;

interface SearchWrapperProps {
  $isOpen: boolean;
  $isPanelOpened: boolean;
}

export const SearchWrapper = styled.div<SearchWrapperProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  transition: padding 0.2s ease-in-out;

  ${({ theme, $isOpen }) =>
    $isOpen &&
    theme.sm`
      flex-wrap: wrap;
    `}

  ${({ theme, $isPanelOpened }) =>
    $isPanelOpened &&
    theme.range(
      "padding-right",
      "370px",
      "410px",
      `${theme.breakpoints.lg}px`,
      `${theme.breakpoints.xl}px`,
    )}

  ${({ theme }) => theme.xs`
    padding: 0;
  `}
`;

export const CloseIcon = styled(Icon)`
  height: 12px;
  width: 12px;
  transition: background-color 0.3s;
`;

export const CloseButton = styled.button`
  padding: 5px;

  &:hover ${CloseIcon} {
    background-color: ${(props) => props.theme.black};
  }
`;

export const SearchFilterButton = styled(Button)`
  display: flex;
  align-items: center;
  height: 34px;
  padding: 6px;
  border: solid 1px ${(props) => props.theme.grey};
  background-color: ${(props) => props.theme.white};
  transition: display 0.3s;
  min-width: max-content;

  &:hover {
    background-color: ${(props) => props.theme.lightgrey03};
    color: ${(props) => props.theme.black50};
  }

  ${(props) => props.theme.sm`
    margin-left: 5px;
  `};
`;

interface ButtonsWrapperProps {
  $isOpen: boolean;
}

export const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;

  ${({ theme, $isOpen }) =>
    $isOpen &&
    theme.sm`
    width: 100%;
    justify-content: flex-end;`}
`;

export const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: fit-content;
  animation: 0.2s ${keyframes`${fadeInRight}`};

  ${(props) => props.theme.sm`
    flex-wrap: wrap;
  `}
`;

export const CloseIconWrapper = styled.div`
  padding: 4px;
  position: absolute;
  right: 6px;
`;

export const FilterFullWidthButton = styled(FilterButton)`
  flex: 1;
  position: relative;
`;
