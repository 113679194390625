import React, {
  FC,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { List, Map } from "immutable";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button/Button";
import {
  HeaderWrapper,
  ParcelsWrapper,
} from "../../../../styles/parcelsStyles";
import {
  ButtonWrapper,
  ClickableDiv,
  CollapseWrapper,
} from "./OutgoingSection.styles";

interface OutgoingSectionProps {
  fetchData: (data: any) => void;
  itemRenderer: (item: ReactElement | ReactNode) => ReactElement | ReactNode;
  isCollapseOpen?: boolean;
  children: ReactNode;
  data: any;
  countItemsPerSection?: number;
}

const OutgoingSection: FC<OutgoingSectionProps> = ({
  data,
  isCollapseOpen = false,
  fetchData,
  children,
  itemRenderer,
  countItemsPerSection = 10,
}) => {
  const { t } = useTranslation("common");
  const [isOpened, setCollapseOpened] = useState(isCollapseOpen);
  const items = data.get("items", List());
  const type = data.get("type");
  const currentPage = data.get("page") || 1;
  const total = parseInt(data.get("total", items.size), 10);
  const isLoading = data.get("isLoading", false);
  const loadData = (page: number) =>
    Map({
      type,
      params: {
        page: page,
        per_page: countItemsPerSection,
      },
    });

  const sortedItems = useMemo(
    () =>
      items.sort(
        (a: any, b: any) =>
          +new Date(b.get("created_at")) - +new Date(a.get("created_at")),
      ),
    [items],
  );

  useEffect(() => {
    fetchData(loadData(1));
  }, []);

  const handleLoadMore = useCallback(() => {
    fetchData(loadData(currentPage + 1));
  }, [fetchData, loadData, currentPage]);
  const toggleCollapse = () => setCollapseOpened(!isOpened);

  const headerChildern = () => {
    return <>{children}</>;
  };

  if (!items.size) return null;

  return (
    <section>
      <HeaderWrapper>
        <ClickableDiv onClick={toggleCollapse}>{headerChildern()}</ClickableDiv>
      </HeaderWrapper>
      <CollapseWrapper isOpened={!!isOpened}>
        <ParcelsWrapper>
          {sortedItems.map((item: ReactElement) => itemRenderer(item))}
        </ParcelsWrapper>
        <ButtonWrapper>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            hidden={sortedItems.size === total}
            onClick={handleLoadMore}
          >
            {t("parcels.loadMore")}
          </Button>
        </ButtonWrapper>
      </CollapseWrapper>
    </section>
  );
};

export default memo(OutgoingSection);
