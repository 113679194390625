import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { BooksHeaderLink, BooksHeaderWrapper } from "./BooksHeader.styles";

const BooksHeader: FC = () => {
  const { t } = useTranslation("common");
  return (
    <BooksHeaderWrapper>
      <BooksHeaderLink title={t("books.addFunds")} end to="/books/add-funds">
        {t("books.addFunds")}
      </BooksHeaderLink>
      <BooksHeaderLink title={t("books.transactions")} end to="/books">
        {t("books.transactions")}
      </BooksHeaderLink>
      <BooksHeaderLink title={t("books.recipients")} end to="/books/recipients">
        {t("books.recipients")}
      </BooksHeaderLink>
    </BooksHeaderWrapper>
  );
};

export default memo(BooksHeader);
