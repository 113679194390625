import React, { FC, useCallback, useMemo } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { ActionMeta } from "react-select";
import { ExpectedItemsPanelFormValues } from "types/ExpectedForms/ExpectedItemsPanel";
import Input from "../../../../components/Input/Input";
import Select from "../../../../components/Select/Select";
import { FieldWrapper } from "../../../../styles/formStyles";

interface ExpectedParcelFormProps
  extends FormikProps<ExpectedItemsPanelFormValues> {
  shops: any;
}

const ExpectedParcelForm: FC<ExpectedParcelFormProps> = ({
  setFieldValue,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  shops,
}) => {
  const { t } = useTranslation("common");

  const shopOptions = useMemo(
    () =>
      shops
        .map((shop: any) => {
          const name = shop.get("name");
          const image = shop.get("thumb_image_url");
          return { label: name, value: name, image };
        })
        .sort((a: any, b: any) => a.label.localeCompare(b.label)),
    [shops],
  );

  const handleChangeSelect = useCallback(
    (selectedOption: unknown, context: ActionMeta<unknown>) => {
      if (context.name) {
        setFieldValue(context.name, selectedOption);
      }
    },

    [setFieldValue],
  );

  return (
    <div>
      <FieldWrapper>
        <Input
          placeholder={t("expectedParcel.trackingNumber")}
          name="trackingNumber"
          value={values.trackingNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          tooltip={errors.trackingNumber}
          invalid={errors.trackingNumber && touched.trackingNumber}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Input
          placeholder={t("expectedParcel.orderNumber")}
          name="orderNumber"
          value={values.orderNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          tooltip={errors.orderNumber}
          invalid={errors.orderNumber && touched.orderNumber}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Select
          placeholder={`${t("parcels.search.shop")}:`}
          name="storeName"
          value={values.storeName}
          onChange={handleChangeSelect}
          options={shopOptions}
          onBlur={handleBlur}
          tooltip={errors.storeName as string}
          invalid={!!errors.storeName && !!touched.storeName}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Input
          placeholder={t("expectedParcel.customerComment")}
          name="customerComment"
          value={values.customerComment}
          onChange={handleChange}
          onBlur={handleBlur}
          tooltip={errors.customerComment}
          invalid={errors.customerComment && touched.customerComment}
        />
      </FieldWrapper>
    </div>
  );
};

export default React.memo(ExpectedParcelForm);
