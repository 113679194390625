import { Col } from 'react-grid-system';
import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';

export const ColWrapper = styled(Col)`
  && {
    padding: 0;
    display: flex;
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  height: calc(100vh - 200px);
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: 0.8s ${keyframes`${fadeIn}`};
  margin-bottom: 20px;
  flex-direction: column;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  height: 100%;
  width: 100%;
  max-width: 410px;

  & > button {
    font-size: 16px;
    margin-top: 15px;
  }

  &.separator::after {
    content: '';
    width: 2px;
    height: 300px;
    border-radius: 2px;
    background-color: #f2f2f2;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  ${(props) => props.theme.sm`
    padding: 15px 5px;
    max-width: 330px;

    &.separator::after {
      width: 300px;
      height: 2px;
      bottom: 0;
      top: initial;
      right: initial;
    }
  `}
`;

export const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.45;
  color: rgba(0, 0, 0, 0.25);
  margin: 10px 0;
`;

export const PlaceholderIcon = styled.img``;

export const PlaceholderTitle = styled.h2`
  font-size: 18px;
  line-height: 1.45;
`;
