import { FC } from "react";
import { fadeIn } from "react-animations";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import {
  DeliveryBlock,
  SideBlock,
  SideItemHead,
} from "../../styles/Delivery.styles";
import ServicesLogo from "../ServicesLogo";
import { ScrollableWrapper } from "../SideAddons/SideAddonsInfo/SideAddonsInfo.styles";
import SummaryBlock from "./SummaryBlock";

export const StyledForm = styled.form`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: initial !important;
  }
`;

export const TwoColumnsLayout = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 20px;
  }

  & > div {
    min-width: 400px;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      min-width: 360px;
    }
  }
`;

export const CustomsDeclarationSummary = styled.div`
  display: flex;
  padding: 0;
  gap: 8px;
`;

export const TotalCustomsPrice = styled.strong`
  color: ${({ theme }) => theme.red};
`;

export const Wrapper = styled(DeliveryBlock)`
  align-items: center;

  button {
    width: 100%;
  }
`;

export const StatValue = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
`;

export const BlockContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid #e1e2e6;
  padding: 10px 20px 0;
`;

export const SummarySecond = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${ScrollableWrapper} {
    &::after {
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        #ffffff
      );
    }
  }

  ${SideBlock} {
    width: 100%;
    padding: 10px 20px 0;

    ${(props) => props.theme.xs`
      padding: 0;
    `}
  }

  .ReactCollapse--content > ${SideBlock}:first-of-type {
    border: none;
  }
`;

export const ChangeLink = styled(Link)`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.primary05};
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PartnerInputWrapper = styled.div`
  margin-right: 10px;
`;

export const SideDescription = styled.div`
  display: flex;
  align-items: center;
`;

interface LogoProps {
  logoUrl?: string;
}

const LogoComponent: FC<LogoProps> = (props) => (
  <ServicesLogo size={20} {...props} />
);

export const Logo = styled(LogoComponent)`
  margin-right: 5px;
`;

export const SideParagraph = styled.p`
  margin: 0;
  margin-left: 3px;
  animation: 0.8s ${keyframes`${fadeIn}`};
`;

export const AddonsWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;

  div {
    border: none;
  }

  ${SideItemHead} {
    border-top: 1px solid ${(props) => props.theme.lightgrey};
  }

  ${(props) => props.theme.sm`
    padding: 20px 0;
    margin: 0 20px;
    width: calc(100% - 40px);
  `}
`;
