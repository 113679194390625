import React, { FC } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { servicesSelectAllServices } from "../../reduxFolder/selectors/servicesSelectors";
import NoPackingIcon from "../Icon/icons/not-selected-delivery.svg";
import Logo from "../Logo/Logo";

interface ServiceLogoProps {
  serviceKey: string;
  services: any;
}

const ServiceLogo: FC<ServiceLogoProps> = React.memo(
  ({ serviceKey, services, ...otherProps }) => {
    const service = services.find((service: any) =>
      [service.get("name", null), service.get("code", null)].includes(
        serviceKey,
      ),
    );
    const serviceLogo = service && service.get("logo_url");
    const logoProps = serviceLogo
      ? { image: serviceLogo, size: 30 }
      : { image: NoPackingIcon, size: 20 };

    return <Logotype {...logoProps} {...otherProps} />;
  },
);

const withConnect = connect(
  createStructuredSelector({
    services: servicesSelectAllServices,
  }),
  null,
);

export default withConnect(ServiceLogo);

const Logotype = styled(Logo)`
  margin-right: 10px;
  border-radius: initial;
  ${(props) => props.theme.xs`
    margin-right: 5px;
  `}
`;
