/**
 * Create the store with dynamic reducers
 */

import I, { fromJS, Map } from 'immutable';
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import { createPromise } from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState = Map()) => {
  // Create the store with two middlewares
  // 1. sagaMiddleware: s redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state

  const middlewares = [
    createPromise({
      promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'ERROR'],
    }),
    sagaMiddleware,
  ];

  const enhancers = [applyMiddleware(...middlewares)];
  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          serialize: {
            immutable: I,
          },
        })
      : compose;

  /* eslint-enable */
  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers),
  );

  sagaMiddleware.run(rootSaga);
  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
};

const initialState = Map();
const store = configureStore(initialState);
// sagaMiddleware.run(rootSaga);

export default store;
