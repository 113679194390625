import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import T from "prop-types";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import Button from "../../../../components/Button/Button";
import Icon from "../../../../components/Icon/Icon";
import Logo from "../../../../components/Logo/Logo";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import Select from "../../../../components/Select/Select";
import { panelSelectOpened } from "../../../../reduxFolder/selectors/rightPanelSelectors";
import { storageSelectShops } from "../../../../reduxFolder/selectors/storageSelectors";
import { formShape } from "../../../../shapes/form";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { IconType } from "enums/Icon";
import { FormikProps } from "formik";
import { ExpectedSearchValues } from "types/ExpectedForms/Search";
import { ButtonColor, ButtonVariant } from "../../../../enums/Button";
import {
  AddNewMobileButton,
  Content,
  ContentWrapper,
  FilterCounter,
  FilterIcon,
  FilterLogo,
  FiltersContainer,
  FilterSegmentButton,
  FiltersToggleButton,
  MenuIcon,
} from "./Search.styles";

interface CompactSearchProps {
  shops: any[];
  handleSetResetCompactFunction: (func: () => void) => void;
  formOptions: FormikProps<ExpectedSearchValues>;
  onAddNewClick: () => void;
}

const CompactSearch: FC<CompactSearchProps> = React.memo(
  ({ handleSetResetCompactFunction, formOptions, shops, onAddNewClick }) => {
    const { t } = useTranslation("common");

    const {
      values,
      initialValues,
      handleSubmit,
      handleChange,
      setFieldValue,
      handleReset,
    } = formOptions;
    const filterTypes = {
      shop: "shop",
    };

    const [isFilter, setIsFilter] = useState(false);
    const [openedFilter, setOpenedFilter] = useState<string | null>(null);
    const [shopValue, setShopValue] = useState<any>("");

    const shopOptions = useMemo(
      () =>
        shops
          .map((shop: any) => {
            const name = shop.get("name");
            const image = shop.get("thumb_image_url");
            return { label: name, value: name, image };
          })
          .sort((a: any, b: any) => a.label.localeCompare(b.label)),
      [shops],
    );

    const filterProps = useMemo(
      () => ({
        values,
        initialValues,
        handleSubmit,
        handleChange,
        setFieldValue,
      }),
      [values, initialValues, handleSubmit, handleChange, setFieldValue],
    );

    const toggleFilterMenu = useCallback(() => {
      setIsFilter(!isFilter);
    }, [isFilter, setIsFilter]);

    const handleSelectedFilter = useCallback(
      (filterName: string | null) => {
        if (openedFilter === filterName) {
          setOpenedFilter(null);
        } else {
          setOpenedFilter(filterName);
        }
      },
      [openedFilter, setOpenedFilter],
    );

    const handleShopValueChange = useCallback(
      (value: any) => {
        setShopValue(value);
        if (value) {
          handleSelectedFilter(null);
        }

        setFieldValue("vendor", value);
        handleSubmit();
      },
      [setShopValue, handleSelectedFilter, setFieldValue, handleSubmit],
    );

    const resetInternalValues = useCallback(() => {
      setShopValue("");
    }, [setShopValue]);

    const handleResetClick = useCallback(() => {
      handleSelectedFilter(null);
      setIsFilter(false);
      resetInternalValues();
      handleReset();
      handleSubmit();
    }, [handleReset, handleSubmit]);

    useEffect(() => {
      handleSetResetCompactFunction(() => handleResetClick);
    }, [handleResetClick]);

    const searchContent = (
      <SearchInput<ExpectedSearchValues>
        name="trackingNumber"
        value={values.trackingNumber}
        initialValue={initialValues.trackingNumber}
        placeholder={t("parcels.search.filterByTransactionNumber")}
        {...filterProps}
      />
    );

    const shopFilterButton = (showTitleOnly: boolean) => {
      const shopSelected = showTitleOnly === false && !!values.vendor;
      const shopTitle = shopSelected ? null : t("parcels.search.shop");
      const image = shopSelected && shopValue.image;
      return (
        <FilterSegmentButton
          selected={!!shopSelected}
          onClick={() => handleSelectedFilter(filterTypes.shop)}
        >
          {image ? <FilterLogo image={image} size={18} /> : null}
          {shopTitle}
        </FilterSegmentButton>
      );
    };

    const activeFiltersCount = () => {
      const activeFiltersCount = [
        values.comment,
        values.order,
        values.vendor,
      ].filter((x) => x).length;

      return activeFiltersCount === 0 ? null : activeFiltersCount;
    };

    const allFiltersContent = (
      <FiltersContainer>{shopFilterButton(false)}</FiltersContainer>
    );

    const selectedShopFilterContent = (
      <FiltersContainer>
        {shopFilterButton(true)}
        <Select
          autoFocus
          isSearchable
          defaultMenuIsOpen={true}
          value={shopValue}
          onChange={handleShopValueChange}
          minHeight={34}
          options={shopOptions}
          placeholder={`${t("parcels.search.shop")}:`}
          isClearable
          onClick={(e: ChangeEvent) => e.preventDefault()}
        />
      </FiltersContainer>
    );

    const filtersContent = () => {
      if (openedFilter === null) {
        return allFiltersContent;
      } else {
        switch (openedFilter) {
          case filterTypes.shop:
            return selectedShopFilterContent;
          default:
            return allFiltersContent;
        }
      }
    };

    return (
      <ContentWrapper>
        <Content>{isFilter ? filtersContent() : searchContent}</Content>
        <FiltersToggleButton onClick={toggleFilterMenu}>
          <FilterIcon type={IconType.Filter} />
          <MenuIcon type={IconType.Menu} />
          {activeFiltersCount() && (
            <FilterCounter>{activeFiltersCount()}</FilterCounter>
          )}
        </FiltersToggleButton>
        <AddNewMobileButton
          variant={ButtonVariant.Filled}
          color={ButtonColor.Secondary}
          onClick={onAddNewClick}
        >
          {t("common.new")}
        </AddNewMobileButton>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
    shops: storageSelectShops,
  }),
);

export default withConnect(CompactSearch);
