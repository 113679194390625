import React, {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import FileUploader from "components/FileUploader/FileUploader";
import Input from "components/Input/Input";
import { ButtonColor, ButtonType, ButtonVariant } from "enums/Button";
import { withFormik } from "formik";
import useFetchOnceEffect from "hooks/useFetchOnceEffect";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getCart,
  getDescriptionsByHsCodes,
  importCustomsDeclaration as importCustomsDeclarationCart,
} from "reduxFolder/reducers/cart";
import { getCustomsDeclarations } from "reduxFolder/reducers/outgoing";
import {
  getShipment,
  importCustomsDeclaration as importCustomsDeclarationShipment,
} from "reduxFolder/reducers/storage";
import {
  cartSelectCart,
  cartSelectLoading,
  customDeclarationsDescriptions,
} from "reduxFolder/selectors/cartSelectors";
import { createStructuredSelector } from "reselect";
import { ButtonsWrapper } from "styles/formStyles";
import { colors } from "styles/styles";
import {
  CustomDeclarationsErrorObject,
  CustomsDeclarationV2FormProps,
  ErrorsFormValues,
} from "types/customDeclaration";
import { toastResponseError } from "utils/responseMessageHelper";
import {
  Cell,
  LastRow,
  Row,
  SaveButton,
  StyledForm,
  StyledTable,
} from "../CustomDeclarationV2.styles";
import { customDeclarationForm } from "../formHelper";
import { getShowError } from "../helpers";
import CustomSelectDescription from "./CustomSelectDescription/CustomSelectDescription";
import DeleteRowButton from "./DeleteRowButton";

const defaultNewItem = {
  description: "",
  hs_code: "",
  quantity: "",
  value: "",
};

const CustomsDeclarationV2Form: FC<CustomsDeclarationV2FormProps> = React.memo(
  ({
    t,
    values,
    errors = {},
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
    onCustomsDataChange,
    dirty,
    cart,
    getCart,
    isCartLoading,
    getCustomsDeclarations,
    importCustomsDeclarationCart,
    importCustomsDeclarationShipment,
    getShipment,
    isShipmentImportCustomsDeclaration,
    itemId,
    isWizardStep = false,
    readOnly = false,
    noPadding = false,
    autoFocus = false,
    hideFileImport = false,
    isShowHSCodeColumn,
    isHSCodeValidationRequired,
    getDescriptionsByHsCodes,
    descriptionsHsCodes,
    className,
    saveButtonText,
  }) => {
    const formRef = useRef<HTMLFormElement>(null);
    const [newItem, setNewItem] = useState(defaultNewItem);
    const [inputForFocus, setInputForFocus] = useState<number | null>(null);
    const [hsCodesOptions, setHsCodesOptions] = useState(descriptionsHsCodes);
    const itemsLength = values.length;
    const consolidation_id = isWizardStep ? cart.get("id") : itemId;

    useFetchOnceEffect(!descriptionsHsCodes.size, getDescriptionsByHsCodes);

    useEffect(() => {
      if (
        formRef.current &&
        inputForFocus &&
        formRef.current.elements[inputForFocus]
      ) {
        const inputElement = formRef.current.elements[
          inputForFocus
        ] as HTMLInputElement;
        inputElement.focus();
      }
    }, [formRef, inputForFocus]);

    useEffect(() => {
      onCustomsDataChange && onCustomsDataChange(values);
    }, [values]);

    useEffect(() => {
      const errorKeys = Object.keys(errors);
      if (errorKeys.length > 0) {
        const hasError = errorKeys.some(
          (key) => errors[key as keyof typeof errors] === "Duplicate row found",
        );

        if (hasError) {
          toastResponseError(
            t("error.duplicateRowCustomData"),
            "errorDuplicate",
          );
        }
      }
    }, [errors]);

    useEffect(() => {
      if (!descriptionsHsCodes.size) return;

      const options = descriptionsHsCodes.map((description: any) => {
        const descriptionEn = description.get("description");
        const descriptionRu = description.get("description_ru");

        return {
          value: descriptionEn,
          value_ru: descriptionRu,
        };
      });

      setHsCodesOptions(options);
    }, [descriptionsHsCodes]);

    const handleNewItemChange = (
      newValue: FormEvent<HTMLInputElement>,
      fieldName: string,
    ) => {
      const inputTarget = newValue.target as HTMLInputElement;
      const nextItem = {
        ...newItem,
        [fieldName]: inputTarget.value,
      };

      const addNewRow =
        nextItem.description !== "" &&
        nextItem.quantity !== "" &&
        nextItem.value !== "";

      setNewItem(addNewRow ? defaultNewItem : nextItem);
      if (addNewRow) {
        // Focus from correct input will be lost because we are adding new item to form.values
        const focusOffset =
          fieldName === "description" ? 0 : fieldName === "quantity" ? 2 : 3; // This is 0 for description, 2 for quantity and 3 for value
        setInputForFocus(itemsLength * 4 + focusOffset);
        setFieldValue(`[${itemsLength}]`, nextItem);
      }
    };

    const handleDeleteRow = (index: number) => {
      setValues(values.filter((val, i: number) => i !== index));
    };

    const handleDescriptionChange = useCallback(
      (index: number, selectedOption: string) => {
        setFieldValue(`[${index}].description`, selectedOption);

        const hsCode = descriptionsHsCodes.find(
          (option: any) => option.get("description") === selectedOption,
        );

        if (isShowHSCodeColumn && hsCode) {
          const code = hsCode.get("code");

          setFieldValue(`[${index}].hs_code`, code);

          //focus quantity input from formRef
          setTimeout(() => {
            if (formRef.current) {
              const quantityInput = formRef.current.querySelector(
                `input[name='[${index}].quantity']`,
              ) as HTMLInputElement;
              if (quantityInput) {
                quantityInput.focus();
              }
            }
          }, 0);
        }
      },
      [setValues, values, descriptionsHsCodes, isShowHSCodeColumn],
    );

    const handleFileUpload = useCallback(
      (file: File) => {
        const importCustomsDeclaration = isShipmentImportCustomsDeclaration
          ? importCustomsDeclarationShipment
          : importCustomsDeclarationCart;

        importCustomsDeclaration({ id: consolidation_id, file })
          .then(() => {
            if (isWizardStep) {
              getCart();
            } else {
              if (isShipmentImportCustomsDeclaration) {
                getShipment(consolidation_id);
              } else {
                getCustomsDeclarations(consolidation_id);
              }
            }
          })
          .catch(toastResponseError);
      },
      [
        isWizardStep,
        consolidation_id,
        getCart,
        isShipmentImportCustomsDeclaration,
        importCustomsDeclarationCart,
        importCustomsDeclarationShipment,
      ],
    );

    return (
      <StyledForm
        onSubmit={handleSubmit}
        ref={formRef}
        $noPadding={noPadding}
        className={className}
      >
        <StyledTable
          $readOnly={readOnly}
          $isWizardStep={!!isWizardStep}
          disabled={isCartLoading}
          $isShowHSCodeColumn={!!isShowHSCodeColumn}
        >
          <thead>
            <tr>
              <th>Description</th>
              {isShowHSCodeColumn && <th>HS code</th>}
              <th>{isShowHSCodeColumn ? "Q-ty" : "Quantity"}</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {values.map((row, index: number) => (
              <Row key={`${index}${row.id ? row.id : row.tempId}`}>
                <Cell
                  $invalid={getShowError(
                    values,
                    errors as ErrorsFormValues,
                    index,
                    "description",
                  )}
                  size="large"
                >
                  <CustomSelectDescription
                    name={`[${index}].description`}
                    value={row.description ?? ""}
                    handleChange={(selectedOption: string) =>
                      handleDescriptionChange(index, selectedOption)
                    }
                    handleBlur={handleBlur}
                    initialOptions={hsCodesOptions}
                  />

                  <DeleteRowButton onClick={() => handleDeleteRow(index)} />
                </Cell>
                {isShowHSCodeColumn && (
                  <Cell
                    $invalid={getShowError(
                      values,
                      errors as ErrorsFormValues,
                      index,
                      "hs_code",
                      1,
                    )}
                  >
                    <Input
                      name={`[${index}].hs_code`}
                      value={row.hs_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Cell>
                )}
                <Cell
                  $invalid={getShowError(
                    values,
                    errors as ErrorsFormValues,
                    index,
                    "quantity",
                    1,
                  )}
                >
                  <Input
                    name={`[${index}].quantity`}
                    value={row.quantity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Cell>
                <Cell
                  $invalid={getShowError(
                    values,
                    errors as ErrorsFormValues,
                    index,
                    "value",
                    0.01,
                  )}
                >
                  <Input
                    name={`[${index}].value`}
                    value={row.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Cell>
              </Row>
            ))}
            {!readOnly && (
              <LastRow>
                <Cell>
                  <Input
                    autoFocus={autoFocus}
                    name={`[${itemsLength}].description`}
                    value={newItem.description}
                    onChange={(newValue) =>
                      handleNewItemChange(newValue, "description")
                    }
                  />
                </Cell>
                <Cell>
                  <Input
                    name={`[${itemsLength}].quantity`}
                    value={newItem.quantity}
                    onChange={(newValue) =>
                      handleNewItemChange(newValue, "quantity")
                    }
                  />
                </Cell>
                <Cell>
                  <Input
                    name={`[${itemsLength}].value`}
                    value={newItem.value}
                    onChange={(newValue) =>
                      handleNewItemChange(newValue, "value")
                    }
                  />
                </Cell>
              </LastRow>
            )}
          </tbody>
        </StyledTable>
        {!readOnly && (
          <ButtonsWrapper>
            {!hideFileImport && (
              <FileUploader
                title={t("shipping.uploadDataFromFile")}
                handleFile={handleFileUpload}
                acceptFormats=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                backgroundColor={colors.lightgrey03}
              />
            )}
            <SaveButton
              isLoading={isSubmitting}
              disabled={isSubmitting || (!isWizardStep && !dirty)}
              color={ButtonColor.Primary}
              type={ButtonType.Submit}
              variant={ButtonVariant.Filled}
              $alignCenter={hideFileImport}
            >
              {saveButtonText ? saveButtonText : t("common.save")}
            </SaveButton>
          </ButtonsWrapper>
        )}
      </StyledForm>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    cart: cartSelectCart,
    isCartLoading: cartSelectLoading,
    descriptionsHsCodes: customDeclarationsDescriptions,
  }),
  {
    importCustomsDeclarationCart,
    importCustomsDeclarationShipment,
    getCart,
    getCustomsDeclarations,
    getShipment,
    getDescriptionsByHsCodes,
  },
);

const enhance = compose(
  withConnect,
  withTranslation("common"),
  withFormik(customDeclarationForm),
);

export default enhance(CustomsDeclarationV2Form);
