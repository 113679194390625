import React, { FC, memo, useCallback } from "react";
import { СhooseAdditionalFormValues } from "containers/Delivery/components/ChooseForms/ChooseAdditionalForm/chooseAdditionalFormHelper";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { PackOptionsFormValues } from "types/StorageForms/PackOptions";
import { getAddonsIconsImage } from "../../containers/Delivery/deliveryHelper";
import Checkbox from "../Checkbox/Checkbox";
import { AddonItem, Price } from "./AddonsList.styles";

interface AddonsListProps {
  handleSelect: (id: string) => () => void;
  readOnly?: boolean;
  values: FormikProps<
    PackOptionsFormValues | СhooseAdditionalFormValues
  >["values"];
  addons?: any;
  items: any;
}

const AddonsList: FC<AddonsListProps> = ({
  addons,
  items,
  values,
  readOnly = false,
  handleSelect,
}) => {
  const { t } = useTranslation("common");

  const getChecked = (addon: any) => {
    const parcelId = values.activeItem.value;

    if (parcelId === "all")
      return !items.find((cartItem: any) => {
        const itemValue = values[cartItem.get("id")];

        return itemValue && !itemValue.includes(addon.get("id"));
      });

    return values[parcelId] && values[parcelId].includes(addon.get("id"));
  };

  const handleCheckBoxClick = useCallback(
    (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => e.preventDefault(),
    [],
  );

  return addons.map((addon: any) => {
    const isChecked = getChecked(addon);
    const icon = getAddonsIconsImage(addon.get("id"), addons, 28);
    const isPrice = parseInt(addon.get("price"), 10);
    const priceTitle = isPrice ? `$${addon.get("price")}` : t("common.free");
    const desc = addon.get("description");

    return (
      <AddonItem
        onClick={handleSelect(addon.get("id"))}
        $isSelected={isChecked}
        $readOnly={readOnly}
        key={addon.get("id")}
      >
        <Checkbox
          icon={icon}
          checked={isChecked}
          onClick={handleCheckBoxClick}
          label={desc}
        />
        <Price $isHighlighted={isPrice && isChecked}>{priceTitle}</Price>
      </AddonItem>
    );
  });
};

export default memo(AddonsList);
