import React from "react";
import { ButtonColor } from "enums/Button";
import { fadeIn } from "react-animations";
import { Col, Row } from "react-grid-system";
import styled, { keyframes } from "styled-components";
import Button, { ButtonProps } from "../components/Button/Button";
import Icon from "../components/Icon/Icon";
import Logo from "../components/Logo/Logo";
import PreLoader from "../components/PreLoader/PreLoader";

export const ListWrapper = styled.div`
  height: 100%;
`;

export const ListHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  color: ${(props) => props.theme.black50};
  padding: 10px 0;
`;

export const ListHeaderTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.black};
  margin-right: 5px;

  ${(props) => props.theme.xs`
    font-size: 13px;
  `}
`;

export const ParcelCol = styled(Col)`
  display: flex;
  padding: 0px 10px;
  align-items: center;
  word-break: break-word;
  white-space: pre-wrap;
  padding: 0px 10px;

  & > span {
    margin: 0 2px;
    color: rgba(0, 0, 0, 0.5);
  }

  img {
    margin-right: 18px;
  }

  ${(props) => props.theme.xs`
    margin-bottom: 10px;
    line-height: 18px;

    a {
      font-size: 13px;
    }
  `}
`;

const CustomStorageButton = (props: ButtonProps) => (
  <Button color={ButtonColor.Primary} {...props} />
);

export const StorageButton = styled(CustomStorageButton)`
  padding: 9px 5px;
  margin: 0 5px;

  ${(props) => props.theme.sm`
    padding 5px;
  `}
`;

interface ParcelLogoProps {
  image: string;
  [key: string]: any;
}

export const ParcelLogo = styled(({ image, ...props }: ParcelLogoProps) => (
  <Logo size={30} image={image} {...props} />
))`
  margin: 0 10px;
`;

export const ParcelIcon = styled(Icon)`
  margin: 0 20px;
`;

interface ParcelRowProps {
  checked?: boolean;
  selected?: boolean;
}

export const ParcelRow = styled(Row)<ParcelRowProps>`
  cursor: pointer;
  ${({ theme }) => theme.range("padding", "10px", "16px")};
  white-space: nowrap;
  animation: 0.3s ${keyframes`${fadeIn}`};

  &.row {
    margin: 0;
  }

  &:not(:first-child) {
    border-top: none;
  }

  ${({ theme, checked, selected }) => `
    border-top: 1px solid ${theme.lightgrey};
    border-bottom: 1px solid ${theme.lightgrey};
    background-color: ${
      (checked && "rgba(62, 130, 247, 0.1)") ||
      (selected && "rgba(228, 234, 245, 0.3)")
    };

    &:hover {
      background-color: ${checked || "rgba(228, 234, 245, 0.3)"};
    }
  `};

  ${(props) => props.theme.sm`
    padding: 15px 0 15px 0px;
    font-size: 13px;
  `}

  ${(props) => props.theme.xs`
    font-size: 12px;
    padding: 15px 0 5px 0;
  `};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  animation: 0.6s ${keyframes`${fadeIn}`};

  ${(props) => props.theme.xs`
    padding: 10px 0;
    justify-content: space-between;
  `}
`;

export const HeaderTitle = styled.span`
  font-size: 18px;
  font-weight: 700;

  i {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
  }
`;

export const HeaderButton = styled(Button)`
  letter-spacing: 0.5px;

  ${(props) => props.theme.xs`
    align-self: center;
    padding: 0 8px;
  `}
`;

export const ParcelsWrapper = styled(ListWrapper)`
  padding-bottom: 15px;
`;

export const Currency = styled.span`
  color: ${(props) => props.theme.black50};
`;

export const ParcelButton = styled(Button)`
  padding: 5px 10px;
  margin-right: 6px;
  white-space: normal;

  ${(props) => props.theme.xs`
    padding: 5px 0 5px 5px;
    margin-right: 0px;
    text-align: right;
  `};
`;

export const ChangeButton = styled(Button)`
  padding: 0;
  font-weight: normal;
  font-size: 12px;
  margin-left: 15px;
`;

export const DataSpinner = styled(PreLoader)`
  position: absolute;
  width: 20px;
  height: 14px;
  transform-origin: 0 10px;
  transform: scale(0.6);
`;

export const fadeInLeft = keyframes`
  from {
    transform: translate3d(-30%, 0, 0);
    opacity: 0;
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

export const fadeOutRight = keyframes`
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(30%, 0, 0);
  }
`;
