import React, { FC, useCallback } from "react";
import { ButtonColor, ButtonSize, ButtonVariant } from "enums/Button";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { DeleteCartResponseDto } from "types/api/cart";
import Button from "../../../../components/Button/Button";
import useProgressDelivery from "../../../../hooks/useProgressDelivery";
import { deleteCart } from "../../../../reduxFolder/reducers/cart";
import { authSelectFfid } from "../../../../reduxFolder/selectors/globalSelectors";
import { accessShape } from "../../../../shapes/delivery";
import {
  toastResponseError,
  toastResponseSuccess,
} from "../../../../utils/responseMessageHelper";
import { getLinkToStep } from "../../deliveryHelper";
import { Content, DeliveryBlock } from "../../styles/Delivery.styles";
import {
  CancelWrapper,
  DeleteButton,
  NotLeave,
  NotLeaveButton,
  Title,
} from "./CancelConfirmation.styles";

interface CancelConfirmationProps {
  deleteCart: () => Promise<DeleteCartResponseDto>;
  userFfid: string;
}

const CancelConfirmation: FC<CancelConfirmationProps> = React.memo(
  ({ userFfid, deleteCart }) => {
    const { t } = useTranslation("common");
    const [access] = useProgressDelivery(userFfid);
    const navigate = useNavigate();
    const handleSave = useCallback(
      () => navigate("/parcels/outgoing"),
      [navigate],
    );

    const handleNotLeave = () => navigate(getLinkToStep(access));

    const handleDelete = useCallback(() => {
      deleteCart()
        .then(() => {
          navigate("/parcels");
          toastResponseSuccess(t("common.deleteCartSuccess"));
        })
        .catch(toastResponseError);
    }, [deleteCart, t, navigate]);

    return (
      <Col lg={4} md={6} sm={12} offset={{ md: 3, lg: 4, sm: 12 }}>
        <Content>
          <DeliveryBlock>
            <CancelWrapper>
              <Title>{t("shipping.youAreLeaving")}</Title>
              <Button
                onClick={handleSave}
                size={ButtonSize.Large}
                color={ButtonColor.Primary}
                variant={ButtonVariant.Filled}
              >
                {t("common.saveAndLeave")}
              </Button>
              <DeleteButton
                onClick={handleDelete}
                size={ButtonSize.Large}
                color={ButtonColor.Red}
                variant={ButtonVariant.Bordered}
              >
                {t("common.deleteAndLeave")}
              </DeleteButton>
              <NotLeave>
                <NotLeaveButton
                  onClick={handleNotLeave}
                  size={ButtonSize.Large}
                  color={ButtonColor.Primary}
                  variant={ButtonVariant.Bordered}
                >
                  {t("common.doNotLeave")}
                </NotLeaveButton>
              </NotLeave>
            </CancelWrapper>
          </DeliveryBlock>
        </Content>
      </Col>
    );
  },
);

const withConnect = compose(
  connect(
    createStructuredSelector({
      userFfid: authSelectFfid,
    }),
    { deleteCart },
  ),
);

export default withConnect(CancelConfirmation);
