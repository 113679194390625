import Icon from "components/Icon/Icon";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { MessageWrapper } from "styles/serviceRequestStyles";

export const MessageBlock = styled(MessageWrapper)`
  margin-top: 0;
`;

export const IconWrapper = styled.div`
  position: relative;
`;

export const ProcessIcon = styled(Icon)`
  position: absolute;
  bottom: 85%;
  left: 90%;
  width: 15px;
  height: 15px;
`;

interface VideoPlayerProps {
  hidden?: boolean;
}

export const VideoPlayer = styled(ReactPlayer)<VideoPlayerProps>`
  width: 100% !important;
  height: 195px !important;
  margin: 10px 0;
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
`;

export const WarehouseCommentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.67;
  color: #808080;
  padding: 15px;
  margin: 10px 0;
  background-color: rgba(225, 226, 230, 0.25);
`;

export const WarehouseComment = styled.div`
  font-size: 12px;
`;

export const UserComment = styled.p`
  margin: 0 0 12px;
`;
