import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AppRoutes from './containers/Routes/AppRouterProvider';
import store from './reduxFolder/configureStore';
import * as serviceWorker from './serviceWorker';
import { theme } from './styles';
import i18nConfig from './translations/i18nConfig';

const container = document.getElementById('root');

const router = createBrowserRouter([{ path: '*', element: <AppRoutes /> }]);

const app = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18nConfig}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </ThemeProvider>
  </Provider>
);

if (container.hasChildNodes()) {
  hydrateRoot(container, app);
} else {
  const root = createRoot(container);
  root.render(app);
}

serviceWorker.register();
