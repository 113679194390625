import Icon from "components/Icon/Icon";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  gap: 15px;
`;

export const Wrapper = styled.div`
  && {
    font-size: 12px;
    flex: 0 0 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${(props) => props.theme.black50};

    span:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

export const OpenNotFinishedButton = styled("button")`
  display: flex;
  align-items: center;
  padding: 10px 0;
  text-decoration: none;
  margin-right: 20px;
  color: ${({ theme }) => theme.black50};

  &:hover {
    opacity: 0.7;
  }

  span {
    font-weight: 700;
    margin-left: 4px;
  }

  ${(props) => props.theme.md`
    margin-left: auto;
  `}

  ${(props) => props.theme.xs`
    padding: 0;

    span {
      margin-left: 0;
    }
  `}
`;

export const RightArrowIcon = styled(Icon)`
  width: 8px;
  height: 14px;
  margin-left: 6px;
  transition: opacity 0.2s;
`;
