import { useRef, useState } from "react";
import { toastResponseError } from "../utils/responseMessageHelper";

type DeleteFunctionType = (params: {
  id: string | null | number;
}) => Promise<{ value: any }>;

type SetDeletedCallbackType = (items: (string | number)[]) => void;

const useDeletedItems = (
  deleteFunction: DeleteFunctionType,
  setDeletedCallback?: SetDeletedCallbackType,
) => {
  const [deletedItems, setDeletedItems] = useState<(string | number)[]>([]);
  const [timerDeleting, setTimerDeleting] = useState<NodeJS.Timeout | null>(
    null,
  );
  const deletionRef = useRef<(string | number)[]>(deletedItems);

  const onDelete = (
    items: (string | number)[],
    id: string | null | number = null,
  ) => {
    if (deletedItems.includes(id || "")) {
      clearTimeout(timerDeleting!);
      setTimerDeleting(null);
    } else {
      const timer = setTimeout(() => {
        deleteFunction({ id })
          .then(({ value }) => {
            if (typeof setDeletedCallback === "function") {
              setDeletedCallback(
                items.filter((item) => item !== value.getIn(["data", "id"])),
              );
            }
          })
          .catch(toastResponseError)
          .finally(() => clearTimeout(timerDeleting!));
      }, 3000);

      setTimerDeleting(timer);
    }

    setDeletedItems(items);
    if (typeof setDeletedCallback === "function") setDeletedCallback(items);
  };

  const removeItem = (id: string) => {
    if (!deletedItems.includes(id)) {
      const updatedDeletedItems = [...deletedItems, id];
      deletionRef.current = updatedDeletedItems;
      onDelete(updatedDeletedItems, id);
    }
  };

  const undoItem = (id: string) => {
    const updatedDeletedItems = deletedItems.filter((item) => item !== id);
    deletionRef.current = updatedDeletedItems;
    onDelete(updatedDeletedItems, id);
  };

  return [deletedItems, removeItem, undoItem] as const;
};

export default useDeletedItems;
