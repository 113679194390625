// utils
import { List, Map } from 'immutable';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { isUnprocessedRequest } from '../../containers/Storage/components/ServiceRequests/requestHelper';
// requests
import api from '../../utils/api';
import { toastResponseError } from '../../utils/responseMessageHelper';
import {
  addParcels,
  addParcelsError,
  addParcelsSuccess,
} from '../reducers/cart';
import { openRightPanel } from '../reducers/rightPanel';
import { completeRequestTransferShipments } from '../reducers/serviceRequests';
// const

// redux
import {
  ADD_PARCELS_TO_CART,
  catchStorageError,
  defaultParams,
  defaultParamsExpected,
  fetchStorage,
  finaliseParcelsToCart,
  GET_EXPECTED_ITEMS,
  GET_INVENTORY_ITEMS,
  GET_STORAGE,
  getShipment,
  normalizeItems,
  SELECT_ALL,
  selectParcel,
  updateExpectedItemsSearch,
  updateInventoryItemsSearch,
  updateStorage,
  updateTransferringItems,
} from '../reducers/storage';
import { cartSelectForOutgoing } from '../selectors/cartSelectors';
import { selectStorage } from '../selectors/storageSelectors';

/* worker-saga */
function* getStorageTransferringItems() {
  try {
    return yield call(api.shipments.getTransferring);
  } catch (response) {
    return new Map({
      data: new List(),
    });
  }
}

function* onGetStorage({ payload }) {
  try {
    const params = payload.size ? payload : defaultParams;
    yield put(fetchStorage(params));

    const [transferringItemsResponse, response] = yield all([
      call(getStorageTransferringItems),
      call(api.shipments.getStorage, payload.toJS()),
    ]);

    yield put(updateTransferringItems(transferringItemsResponse));
    yield put(updateStorage(response.set('params', params)));
  } catch (response) {
    toastResponseError(response);
    catchStorageError(response);
  }
}

function* onGetInventoryItems({ payload }) {
  try {
    const response = yield call(api.items.getInventoryItems, payload.toJS());
    yield put(updateInventoryItemsSearch(response));
  } catch (response) {
    toastResponseError(response);
    catchStorageError(response);
  }
}

function* onGetExpectedItems({ payload }) {
  try {
    const params = payload.size ? payload : defaultParamsExpected;
    const response = yield call(api.expected.getExpectedItems, params.toJS());
    yield put(updateExpectedItemsSearch(response.set('params', params)));
  } catch (response) {
    toastResponseError(response);
    catchStorageError(response);
  }
}

function* onSelectAll() {
  const storage = yield select(selectStorage);

  const items = storage.get('items');
  const totalAmount = storage.get('totalAmount');
  const params = storage.get('params');
  const getSelectedItems = (items) =>
    items.reduce(
      (selectedItems, item) =>
        !isUnprocessedRequest(item.get('serviceRequestCount')) &&
        item.get('prohibited') !== true
          ? selectedItems.push(item.get('id'))
          : selectedItems,
      List(),
    );

  if (totalAmount === items.size) {
    yield put(selectParcel(getSelectedItems(items)));
    return null;
  }

  try {
    const fetchParams = params
      .set('page', 1)
      .setIn(['filter', 'per_page'], totalAmount);

    yield put(fetchStorage(fetchParams));
    const response = yield call(api.shipments.getStorage, fetchParams.toJS());

    yield put(updateStorage(response.set('params', fetchParams)));
    const selectedIds = getSelectedItems(normalizeItems(response.get('data')));

    yield put(selectParcel(selectedIds));
  } catch (response) {
    toastResponseError(response);
    catchStorageError(response);
  }
}

function* onAddParcelsToCart({
  payload: { id, useCartItemsApi, decant_ids, shipmentId },
}) {
  try {
    yield put(addParcels());
    const response = useCartItemsApi
      ? yield call(api.cart.addInventoryParcelItems, {
          id,
          decant_ids,
        })
      : yield call(api.cart.addParcels, { id: id });

    if (useCartItemsApi && shipmentId) {
      yield put(getShipment(shipmentId));
    }
    if (!useCartItemsApi) {
      try {
        const params = defaultParams;
        yield put(fetchStorage(params.set('isAddParcelsToCart', true)));
        const responseStorage = yield call(api.shipments.getStorage, params);
        yield put(updateStorage(responseStorage.set('params', params)));
      } catch (responseStorage) {
        toastResponseError(responseStorage);
        catchStorageError(responseStorage);
      }
    }

    const cart = yield select(cartSelectForOutgoing);
    yield put(
      openRightPanel(
        'NOT_FINISHED',
        Map({
          detailedItemID: cart.get('id'),
          parcel: cart,
        }),
      ),
    );

    yield put(addParcelsSuccess(response));
    yield put(finaliseParcelsToCart());
  } catch (response) {
    yield put(addParcelsError());
    toastResponseError(response);
  }
}

/* watcher-saga */
function* watchStorageActions() {
  yield takeLatest(GET_STORAGE, onGetStorage);
  yield takeLatest(GET_INVENTORY_ITEMS, onGetInventoryItems);
  yield takeLatest(GET_EXPECTED_ITEMS, onGetExpectedItems);
  yield takeLatest(SELECT_ALL, onSelectAll);
  yield takeLatest(ADD_PARCELS_TO_CART, onAddParcelsToCart);
}

export default watchStorageActions;
