import React, { FC } from "react";
import ProfileLink from "components/ProfileLink/ProfileLink";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import useProgressDelivery from "../../../hooks/useProgressDelivery";
import { authSelectFfid } from "../../../reduxFolder/selectors/globalSelectors";
import AccountHeader from "../../Account/components/AccountHeader";
import BooksHeader from "../../Books/components/BooksHeader/BooksHeader";
import DeliveryHeading from "../../Delivery/components/DeliveryHeading/DeliveryHeading";
import ParcelHeader from "../../Parcels/components/ParcelHeader";
import ToolsHeader from "../../Tools/components/ToolsHeader";
import { HeaderTitle } from "../HeaderContainer.styles";

type ComponentKeys = "tools" | "account" | "books" | "parcels";

const componentsMap: Record<ComponentKeys, () => JSX.Element> = {
  tools: () => <ToolsHeader />,
  account: () => <AccountHeader />,
  books: () => <BooksHeader />,
  parcels: () => <ParcelHeader />,
};

interface BasicHeaderProps {
  userFfid: string;
}

const BasicHeader: FC<BasicHeaderProps> = React.memo(({ userFfid }) => {
  const location = useLocation();
  const [access] = useProgressDelivery(userFfid);
  const { t } = useTranslation("common");
  const path = location.pathname.split("/")[1];
  const title = t(`pages.${path || "basicInformation"}`);
  const HeaderComponent = componentsMap[path as ComponentKeys]?.() ?? null;

  if (path === "shipping") return <DeliveryHeading access={access} />;

  return (
    <Wrapper>
      <HeaderTitle>{title}</HeaderTitle>
      {HeaderComponent}
      <ProfileLink />
    </Wrapper>
  );
});

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  width: calc(100% - 80px);

  ${(props) => props.theme.sm`
    width: 100%;
  `}
`;

const withConnect = connect(
  createStructuredSelector({
    userFfid: authSelectFfid,
  }),
);

export default withConnect(BasicHeader);
