import { FormikHelpers } from "formik";
import { InventoryItemCommentFormValues } from "types/OutgoingForms/InventoryItemComment";
import { toastResponseError } from "utils/responseMessageHelper";
import * as yup from "yup";

export const InventoryItemCommentFormik = (
  t: Function,
  shipment: any,
  updateComment: (id: string, data: any) => Promise<any>,
) => ({
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: false,
  initialValues: {
    comment: shipment.get("customer_comment", "") || "",
  },
  validationSchema: () =>
    yup.object().shape({
      comment: yup
        .string()
        .trim()
        .max(50, t("error.stringShorter", { length: 50 })),
    }),
  onSubmit: (
    values: InventoryItemCommentFormValues,
    { setSubmitting }: FormikHelpers<InventoryItemCommentFormValues>,
  ) => {
    const id = shipment.get("id");
    const data = {
      customer_comment: values.comment,
    };
    updateComment(id, data)
      .catch(toastResponseError)
      .finally(() => setSubmitting(false));
  },
});
