import React, { FC, memo, useCallback, useState } from "react";
import { AddonItem } from "components/AddonsList/AddonsList.styles";
import RadioButton from "components/RadioButton/RadioButton";
import { FormikProps } from "formik";
import { List } from "immutable";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getAddonsIconsImage } from "../deliveryHelper";
import { СhooseAdditionalFormValues } from "./ChooseForms/ChooseAdditionalForm/chooseAdditionalFormHelper";

interface ConsolidationAddonsListProps {
  addons?: any;
  items: any;
  values: FormikProps<СhooseAdditionalFormValues>["values"];
  setFormValues: FormikProps<СhooseAdditionalFormValues>["setValues"];
  isConsolidationAddonsDefined: boolean;
  handleChangeConsolidationAddonsDefined: (bool: boolean) => void;
}

const ConsolidationAddonsList: FC<ConsolidationAddonsListProps> = ({
  addons = List(),
  items,
  values,
  setFormValues,
  isConsolidationAddonsDefined,
  handleChangeConsolidationAddonsDefined,
}) => {
  const { t } = useTranslation("common");
  const [undefinedValues, setUndefinedValues] = useState(
    isConsolidationAddonsDefined
      ? List()
      : addons.map((addon: any) => addon.get("code")),
  );

  const getChecked = (addon: any) => (value: string) => {
    if (undefinedValues.includes(value)) {
      return undefined;
    }

    return !!items.find((cartItem: any) => {
      const itemValue = values[cartItem.get("id") as keyof typeof values];
      const selectedAddon = itemValue.find(
        (id: string) => id === addon.get("code"),
      );

      return selectedAddon === value;
    });
  };

  const handleChange = useCallback(
    (code: string, operation: string) => () => {
      const isOperationSelect = operation === "select";

      items.forEach((cartItem: any) => {
        const itemId = cartItem.get("id");
        const valuesItemId = values[itemId as keyof typeof values];

        const value =
          valuesItemId && isOperationSelect
            ? !valuesItemId.includes(code)
              ? [...valuesItemId, code]
              : valuesItemId
            : valuesItemId.filter((addonId: string) => addonId !== code);

        values[itemId as keyof typeof values] = value;
      });

      const undefinedIndex = undefinedValues.indexOf(code);
      if (undefinedIndex !== -1) {
        const updatedUndefinedValues = undefinedValues.delete(undefinedIndex);
        setUndefinedValues(updatedUndefinedValues);

        if (updatedUndefinedValues.size === 0) {
          handleChangeConsolidationAddonsDefined(true);
        }
      }

      setFormValues({ ...values });
    },
    [
      values,
      items,
      setFormValues,
      undefinedValues,
      setUndefinedValues,
      handleChangeConsolidationAddonsDefined,
    ],
  );

  return addons.map((addon: any) => {
    const isChecked = getChecked(addon);
    const icon = getAddonsIconsImage(addon.get("id"), addons);
    const addonCode = addon.get("code");
    const checkedValue = isChecked(addonCode);

    return (
      <AddonItem $isSelected={!!checkedValue} key={addonCode}>
        {icon}
        <AddonDescription>{addon.get("description")}</AddonDescription>
        <RadioButtonsGroup>
          <RadioButton
            label={t("auth.yes")}
            value={addonCode}
            checked={!!checkedValue}
            onClick={handleChange(addonCode, "select")}
          />
          <RadioButton
            label={t("auth.no")}
            value={undefined}
            checked={!checkedValue && checkedValue !== undefined}
            onClick={handleChange(addonCode, "deselect")}
          />
        </RadioButtonsGroup>
      </AddonItem>
    );
  });
};

export default memo(ConsolidationAddonsList);

const AddonDescription = styled.div`
  margin-left: 8px;
`;

const RadioButtonsGroup = styled.div`
  display: flex;
  margin: 0 15px 0 auto;

  label:not(:last-child) {
    margin-right: 18px;
  }
`;
