import { List, Map } from 'immutable';
import { combineActions, createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';
import { requestNames } from './serviceRequests';
import { normalizeImages } from './utils/helpers';

export const initialState = Map({
  isLoading: false,
  isDetailedLoading: false,
  isLoadingDetailedShipment: false,
  items: Map(),
  detailedItem: List(),
  detailedShipment: Map(),
  fullDetailedConsolidation: Map(),
  selectedYear: null,
  tempDetailedItemId: null,
  historyPageCount: 0,
});

// const
export const GET_HISTORY = 'history/GET_HISTORY';
export const GET_DETAILED_ITEM = 'history/GET_DETAILED_ITEM';
export const GET_DETAILED_SHIPMENT = 'history/GET_DETAILED_SHIPMENT';
export const UPDATE_COMMENT = 'history/UPDATE_COMMENT';
export const GET_CUSTOMS_DECLARATIONS = 'history/GET_CUSTOMS_DECLARATIONS';
export const GET_HISTORY_FULL_DETAILED_ITEM =
  'history/GET_HISTORY_FULL_DETAILED_ITEM';

// action creators
export const getHistory = createAction(GET_HISTORY, (data) => ({
  promise: api.history.getHistory(data.toJS()),
  data,
}));
export const getDetailedItem = createAction(
  GET_DETAILED_ITEM,
  api.history.getHistoryDetailed,
);
export const getHistoryDetailedItem = createAction(
  GET_HISTORY_FULL_DETAILED_ITEM,
  api.history.getHistoryFullDetailedItem,
);
export const getDetailedShipment = createAction(
  GET_DETAILED_SHIPMENT,
  api.shipments.getShipment,
);
export const updateComment = createAction(
  UPDATE_COMMENT,
  api.shipments.updateComment,
);
export const getCustomsDeclarations = createAction(
  GET_CUSTOMS_DECLARATIONS,
  (data) => ({
    promise: api.outgoing.getCustomsDeclarations(data),
    data,
  }),
);

// helpers
const setWeeks = (items) => {
  let weeks = Map();
  let id = 1;

  for (const item of items) {
    const weekNumber = item.get('week_number');
    const updatedItem = item.set('id', id++);

    weeks = weeks.update(weekNumber, (week = List()) => week.push(updatedItem));
  }

  return weeks;
};

const getServiceRequestCount = (serviceRequests) => {
  const serviceRequestCount = serviceRequests.reduce((acc, request) => {
    const rawRequestType = request
      .getIn(['service_request_type', 'type'])
      .toLowerCase();
    const state = request.get('processed_state');
    const requestType = requestNames[rawRequestType];
    return acc.set(requestType, state);
  }, Map());

  return serviceRequestCount;
};

const normalizeDetailed = (parcel) => {
  const updatedParcel = parcel.updateIn(['items'], (items) =>
    items.map((item) =>
      item.set(
        'serviceRequestCount',
        getServiceRequestCount(item.get('service_requests')),
      ),
    ),
  );

  return updatedParcel
    .updateIn(['consolidation_addons'], (addons) =>
      addons.map((addon) => addon.get('id')),
    )
    .updateIn(['pictures'], normalizeImages)
    .setIn(['items'], updatedParcel.get('items')); // items in parcel object include 'service_requests' which are missing in 'consolidation.itmes'
};

// reducers
const historyReducer = handleActions(
  {
    [`${GET_HISTORY}_REQUEST`]: (state, { payload }) =>
      state.set('selectedYear', payload.get('year')).set('isLoading', true),

    [`${GET_HISTORY}_SUCCESS`]: (state, { payload }) => {
      const oldArchived = payload.getIn(['data', 'archived']);
      const sortedWeeksSelectedYear = setWeeks(
        payload.getIn(['data', 'selected_year']),
      );

      const archived =
        oldArchived.size &&
        oldArchived
          .merge([List([new Date().getFullYear(), 0])])
          .sort((a, b) => b.get(0) - a.get(0));

      return state
        .setIn(['items', 'selected_year'], sortedWeeksSelectedYear)
        .setIn(['items', 'archived'], archived)
        .set('isLoading', false);
    },

    [`${GET_DETAILED_ITEM}_REQUEST`]: (state) =>
      state.set('isDetailedLoading', true),

    [`${GET_DETAILED_ITEM}_SUCCESS`]: (state, { payload }) => {
      const headersRaw = Object.fromEntries(payload.get('headers').toJS());

      const pageCount = Math.ceil(
        +headersRaw['total'] / +headersRaw['per-page'],
      );

      return state
        .set('detailedItem', payload.get('data'))
        .set('historyPageCount', pageCount)
        .set('isDetailedLoading', false);
    },

    [`${GET_HISTORY_FULL_DETAILED_ITEM}_REQUEST`]: (state) =>
      state.set('isDetailedLoading', true),

    [`${GET_HISTORY_FULL_DETAILED_ITEM}_SUCCESS`]: (state, { payload }) =>
      state
        .update('detailedItem', (prevDetailedItem) =>
          prevDetailedItem.map((item) =>
            payload.get('data').get('id') ===
            item.getIn(['consolidation', 'id'])
              ? item.setIn(
                  ['consolidation', 'items'],
                  payload.get('data').get('items'),
                )
              : item,
          ),
        )
        .set(
          'fullDetailedConsolidation',
          normalizeDetailed(payload.get('data')),
        )
        .set('isDetailedLoading', false),

    /* CUSTOM DECLARATION */
    [`${GET_CUSTOMS_DECLARATIONS}_REQUEST`]: (state, { payload }) =>
      state.set('tempDetailedItemId', payload).set('isDetailedLoading', true),

    [`${GET_CUSTOMS_DECLARATIONS}_SUCCESS`]: (state, { payload }) => {
      const itemId = state.get('tempDetailedItemId');

      return state
        .update('fullDetailedConsolidation', (item) =>
          item.get('id') === itemId
            ? item.setIn(['customs_declarations'], payload.get('data'))
            : item,
        )
        .set('isDetailedLoading', false);
    },

    /* DETAILED SHIPMENT */
    [`${GET_DETAILED_SHIPMENT}_REQUEST`]: (state) =>
      state.set('isLoadingDetailedShipment', true),

    [`${GET_DETAILED_SHIPMENT}_SUCCESS`]: (state, { payload }) => {
      const data = payload.get('data');

      return state
        .setIn(['detailedShipment', data.get('id')], data)
        .set('isLoadingDetailedShipment', false);
    },

    [`${GET_DETAILED_SHIPMENT}_ERROR`]: (state) =>
      state.set('isLoadingDetailedShipment', false),

    [`${UPDATE_COMMENT}_SUCCESS`]: (state, { payload }) => {
      const id = payload.getIn(['data', 'id']);
      const comment = payload.getIn(['data', 'customer_comment']);

      return state.setIn(['detailedShipment', id, 'customer_comment'], comment);
    },

    [combineActions(`${GET_HISTORY}_ERROR`, `${GET_DETAILED_ITEM}_ERROR`)]: (
      state,
    ) => state.set('isLoading', false).set('isLoadingDetailedItem', false),
  },
  initialState,
);

export default historyReducer;
