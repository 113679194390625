import { call, put, takeLatest } from 'redux-saga/effects';
// requests
import api from '../../utils/api';
// utils
import { toastResponseError } from '../../utils/responseMessageHelper';
// const
import {
  catchBooksTransactionsError,
  defaultParams,
  fetchBooksTransactions,
  GET_BOOKS_TRANSACTIONS_HISTORY,
  updateBooksTransactionsHistory,
} from '../reducers/books';

/* worker-saga */
function* onGetBooksTransactionsHistory({ payload }) {
  try {
    const params = payload.size ? payload : defaultParams;
    yield put(fetchBooksTransactions(params));
    const response = yield call(
      api.books.getBooksTransactionsHistory,
      payload.toJS(),
    );
    yield put(updateBooksTransactionsHistory(response.set('params', params)));
  } catch (response) {
    toastResponseError(response);
    catchBooksTransactionsError(response);
  }
}

/* watcher-saga */
function* watchBooksActions() {
  yield takeLatest(
    GET_BOOKS_TRANSACTIONS_HISTORY,
    onGetBooksTransactionsHistory,
  );
}

export default watchBooksActions;
