import React, { FC, memo, MouseEvent, useCallback } from "react";
import { ButtonColor } from "enums/Button";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { OutgoingComponentCommonProps } from "types/outgoing";
import ServiceLogo from "../../../../components/ServiceLogo/ServiceLogo";
import {
  getDetailedItem,
  updateAddress,
} from "../../../../reduxFolder/reducers/outgoing";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  panelSelectData,
  panelSelectOpened,
} from "../../../../reduxFolder/selectors/rightPanelSelectors";
import { OutgoingCol } from "../../../../styles/outgoingStyles";
import { ParcelButton } from "../../../../styles/parcelsStyles";
import { ParcelWrapper } from "./WaitingFor.styles";

interface WaitingForInformationProps extends OutgoingComponentCommonProps {
  updateAddress: (id: string, data: any) => Promise<void>;
  getDetailedItem: (id: string) => void;
}

const WaitingForInformation: FC<WaitingForInformationProps> = React.memo(
  ({
    parcel,
    units,
    updateAddress,
    openRightPanel,
    getDetailedItem,
    isPanelOpened,
    panelData,
  }) => {
    const { t } = useTranslation("common");
    const deliveryName = parcel.get("preferred_carrier");
    const isSelectedShipment =
      isPanelOpened &&
      panelData &&
      panelData.get("detailedItemID") === parcel.get("id");
    const isEditCustomData = parcel.get("is_customs_data_invalid");
    const weight =
      parcel.get("weight") && Number(parcel.get("weight")).toFixed(2);
    const openMoreInfo = useCallback(
      (event: MouseEvent, openTab?: string) => {
        event.stopPropagation();
        openRightPanel(
          "WAITING_PAYMENT",
          Map({
            detailedItemID: parcel.get("id"),
            excessItems: parcel.get("excess_items"),
            openTab: openTab,
            isEditCustomData,
          }),
        );
      },
      [openRightPanel, parcel, isEditCustomData],
    );

    const clickAddIdInformation = useCallback(
      (id: string) => (event: MouseEvent) => {
        event.stopPropagation();
        getDetailedItem(id);
        openRightPanel(
          "ADDRESS_FORM",
          Map({
            isEditForm: true,
            detailedItemID: id,
            openTab: "Custom ID",
            addressID: id,
            updateAddress,
          }),
        );
      },
      [updateAddress, getDetailedItem, openRightPanel],
    );

    const clickProhibitedGoodsFound = useCallback(
      (event: MouseEvent) => {
        event.stopPropagation();
        openRightPanel("PROHIBITED_FOUND");
      },
      [openRightPanel],
    );

    return (
      <ParcelWrapper
        selected={isSelectedShipment}
        onClick={(event) => openMoreInfo(event)}
      >
        <OutgoingCol xs={2} lg={1}>
          {parcel.get("sku")}
        </OutgoingCol>
        <OutgoingCol xs={6} sm={3} xl={2}>
          <ServiceLogo serviceKey={deliveryName} />
          {deliveryName}
        </OutgoingCol>
        <OutgoingCol xs={4} sm={3} lg={2}>
          {parcel.get("width")}
          <span>x</span>
          {parcel.get("height")}
          <span>x</span>
          {parcel.get("depth")} {t(`units.${units}.secondaryShort`)}
        </OutgoingCol>
        <OutgoingCol xs={2} sm={1}>
          {`${weight} ${t(`units.${units}.primaryShort`)}`}
        </OutgoingCol>
        <OutgoingCol xs={10} sm={3} md={4} lg={5} xl={7}>
          {parcel.get("is_passport_invalid") && (
            <ParcelButton
              color={ButtonColor.Primary}
              onClick={clickAddIdInformation(parcel.get("id"))}
            >
              {t("common.addIdInformation")}
            </ParcelButton>
          )}
          {parcel.get("is_prohibited_goods") && (
            <ParcelButton
              color={ButtonColor.Primary}
              onClick={clickProhibitedGoodsFound}
            >
              {t("common.prohibitedGoodsFound")}
            </ParcelButton>
          )}
          {isEditCustomData && (
            <ParcelButton
              color={ButtonColor.Primary}
              onClick={(event: MouseEvent) =>
                openMoreInfo(event, "Customs data")
              }
            >
              {t("common.editCustomsData")}
            </ParcelButton>
          )}
        </OutgoingCol>
      </ParcelWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
    panelData: panelSelectData,
  }),
  {
    updateAddress,
    openRightPanel,
    getDetailedItem,
  },
);

export default withConnect(WaitingForInformation);
