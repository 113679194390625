import { all } from 'redux-saga/effects';
import watchBooksActions from './booksSaga';
import watchOutgoingActions from './outgoingSaga';
import watchPurchaseActions from './purchaseSaga';
import watchStorageActions from './storageSaga';

export default function* rootSaga() {
  yield all([
    watchBooksActions(),
    watchOutgoingActions(),
    watchPurchaseActions(),
    watchStorageActions(),
  ]);
}
