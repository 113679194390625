import { PageWrapper } from "layouts/Layout.styles";
import { fadeInLeft, fadeOutRight } from "react-animations";
import { Container } from "react-grid-system";
import styled from "styled-components";

export const Wrapper = styled(PageWrapper)`
  position: absolute;
  width: 100%;
  animation: none;

  &.page-enter {
    animation: ${fadeInLeft} 0.22s ease;
  }

  &.page-exit {
    animation: ${fadeOutRight} 0.22s ease;
  }
`;

export const StyledGrid = styled(Container)`
  position: relative;
`;

export const StorageHeading = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;
