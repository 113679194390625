import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { AccountLink } from "./AccountComponents.styles";

const AccountHeader: FC = () => {
  const { t } = useTranslation("common");

  return <AccountLink to="/logout">{t("pages.signout")}</AccountLink>;
};

export default memo(AccountHeader);
