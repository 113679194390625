import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { PlaceholderIcon, Wrapper } from "../../../styles/placeholderStyles";
import placeholderIcon from "../images/inventory-placeholder.svg";
import { Message } from "../styles/Inventory.style";

export const InventoryListPlaceholder: FC = () => {
  const { t } = useTranslation("common");

  return (
    <Wrapper>
      <PlaceholderIcon
        src={placeholderIcon}
        alt={t("parcels.placeholderIcon")}
      />
      <Message>{t("parcels.search.inventoryItemsFound")}</Message>
    </Wrapper>
  );
};
