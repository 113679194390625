import i18next from 'i18next';
import en from './en.json';
import ru from './ru.json';

export const supportedLocales = ['en', 'ru'];

export const getSupportedLocale = (locale = navigator.language.slice(0, 2)) => {
  return supportedLocales.includes(locale.toLowerCase())
    ? locale.toLowerCase()
    : 'en';
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: en,
    },
    ru: {
      common: ru,
    },
  },
});

export default i18next;
