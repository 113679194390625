import React, { FC } from "react";
import { IconType } from "enums/Icon";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { cartSelectForOutgoing } from "../../../../reduxFolder/selectors/cartSelectors";
import { authSelectUnits } from "../../../../reduxFolder/selectors/globalSelectors";
import {
  Container,
  OpenNotFinishedButton,
  RightArrowIcon,
  Wrapper,
} from "./CartInfo.styles";

interface CartInfoProps {
  units: string;
  userCart: any;
  openRightPanel: OpenRightPanel;
}

const CartInfo: FC<CartInfoProps> = React.memo(
  ({ units, userCart, openRightPanel }) => {
    const { t } = useTranslation("common");

    const consolidationItem = userCart.get("items").first();
    const weight = consolidationItem.get("weight");
    const count = consolidationItem.get("items").size;

    const handleOpenNotFinished = () => {
      openRightPanel(
        "NOT_FINISHED",
        Map({
          detailedItemID: consolidationItem.get("id"),
          parcel: consolidationItem,
          openedFromStorage: true,
        }),
      );
    };

    return (
      <Container>
        <OpenNotFinishedButton onClick={handleOpenNotFinished}>
          <span>{`PK${consolidationItem.get("id")}`}</span>
          <RightArrowIcon type={IconType.RightArrow} />
        </OpenNotFinishedButton>
        <Wrapper>
          <span>
            {`${t("shipping.amountOfItemsInCart")}: `}
            <b>{count}</b>
          </span>
          <span>
            {`${t("parcels.weight")}: `}
            <b>{`${weight} ${t(`units.${units}.primaryShort`)}`}</b>
          </span>
        </Wrapper>
      </Container>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    units: authSelectUnits,
    userCart: cartSelectForOutgoing,
  }),
  {
    openRightPanel,
  },
);

export default withConnect(CartInfo);
