import color from "color";
import PreLoader from "components/PreLoader/PreLoader";
import { fadeInUp } from "react-animations";
import styled, { keyframes } from "styled-components";
import { ParcelRow } from "styles/parcelsStyles";

export const Wrapper = styled.div`
  margin: 0 10px;
  animation: 0.6s ${keyframes`${fadeInUp}`};
  position: relative;

  ${({ theme }) => `
    border-top: 1px solid ${theme.lightgrey};
  
    &:last-child {
      border-bottom: 1px solid ${theme.lightgrey};
    }
  `}

  ${(props) => props.theme.md`
    margin-bottom: 35px;
    border: none;
  `}
`;

export const TitleWeek = styled.p`
  font-weight: 700;
  font-size: 16px;
  position: absolute;
  top: 20px;
  left: 10px;
  margin: 0;

  ${(props) => props.theme.md`
    position: initial;
    margin: 7px 0 16px;
  `}
`;

export const VertLine = styled.span`
  color: ${(props) => `${color(props.theme.black).alpha(0.1)}`};
  margin: 0 6px;
`;

export const WeekDate = styled.span`
  color: ${(props) => `${color(props.theme.black).alpha(0.5)}`};
`;

export const Price = styled.div`
  margin: 0;
  font-weight: 700;
`;

export const HistoryRow = styled(ParcelRow)`
  border: none;
  justify-items: center;

  ${(props) => props.theme.md`
    justify-items: start;

    &:not(:first-child) {
      border-top: none;
    }

    border-top: 1px solid ${props.theme.lightgrey};
    border-bottom: 1px solid ${props.theme.lightgrey};
  `}
`;

export const Spinner = styled(PreLoader)`
  position: absolute;
  top: 100px;
`;
