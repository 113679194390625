import styled from "styled-components";
import Icon from "../Icon/Icon";

interface ListItemProps {
  $extended: boolean;
  $isReadOnly: boolean;
  selected: boolean;
}

interface TrackingNumberProps {
  $trackNumberColor: string;
}

export const ListWrapper = styled.ul`
  padding: 0;
  margin: 4px 0 0 0;
  width: 100%;
`;

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  width: 100%;

  ${({ $extended, theme }) =>
    $extended
      ? `border-bottom: 1px solid ${theme.whiteSmoke};`
      : `pointer-events: none;`}

  ${({ selected, $isReadOnly, theme }) =>
    selected &&
    !$isReadOnly &&
    `
    background-color: ${theme.primary10};
    border: none;
  `}

  & > div {
    ${({ $isReadOnly }) =>
      $isReadOnly &&
      `
    cursor: default;
  `}
  }
`;

export const ItemContent = styled.div`
  padding: 12px 5px 12px 8px;
  width: 100%;
  cursor: pointer;
`;

export const ItemName = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-left: 6px;
  }
`;

export const TrackingNumber = styled.div<TrackingNumberProps>`
  margin: 0 2px 0 10px;
  line-height: 18px;
  word-break: break-word;
  color: ${({ $trackNumberColor }) => $trackNumberColor};
`;

export const Comment = styled.p`
  margin: 2px 0 0 30px;
  font-size: 12px;
  width: max-content;
  color: ${({ theme }) => theme.black50};
  background-color: ${({ theme }) => theme.whiteSmoke};
  padding: 2px 6px;
  border-radius: 2px;
`;

export const DetailedButton = styled("div")`
  display: flex;
  padding: 0;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
`;

export const RightArrowIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.grey75};
  transition: opacity 0.2s;
`;

export const ServiceRequests = styled.div`
  margin-left: 16px;
`;

export const OpenItemWrapper = styled.div`
  padding: 14px 5px 14px 15px;
  cursor: pointer;
`;
