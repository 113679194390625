import { List } from "immutable";

export const getAddonsCost = (
  items: any = List(),
  addons: any = List(),
): number =>
  items.reduce((price: number, item: any) => {
    const itemAddons = item.get("addons") || List();
    itemAddons.forEach((id: string) => {
      const addon = addons.find((addon: any) => addon.get("id") === id);
      if (addon) {
        price += parseFloat(addon.get("price", 0));
      }
    });
    return price;
  }, 0);
