import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { ButtonColor } from "enums/Button";
import { IconType } from "enums/Icon";
import useDeletedItems from "hooks/useDeletedItems";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { deleteAddress } from "reduxFolder/reducers/destinations";
import { openRightPanel } from "reduxFolder/reducers/rightPanel";
import { panelSelectData } from "reduxFolder/selectors/rightPanelSelectors";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import {
  AddressButton,
  AddressButtons,
  AddressItemWrapper,
  AddressText,
  AddressTitle,
  AddressVerifyIcon,
  UndoWrapper,
} from "../Addresses.style";

interface AddressItemProps {
  returnPreviousPanel?: string;
  isHideButtons?: boolean;
  deletable?: boolean;
  editable?: boolean;
  isSelected?: boolean;
  openRightPanel: OpenRightPanel;
  onSubmit?: (id: number) => Promise<void>;
  onSelect?: (id: string) => void;
  className?: string;
  address: any;
  deleteAddress: any;
  initialPanelData?: object;
}

const AddressItem: FC<AddressItemProps> = React.memo(
  ({
    openRightPanel,
    onSelect,
    address,
    isHideButtons = false,
    deletable = false,
    editable = false,
    isSelected = false,
    returnPreviousPanel,
    initialPanelData,
    onSubmit,
    className = "",
    deleteAddress,
  }) => {
    const [deletedItems, handleRemove, handleUndo] =
      useDeletedItems(deleteAddress);
    const { t } = useTranslation("common");
    const mainRef = useRef<HTMLDivElement>(null);
    const addressID = address && address.get("id");

    const preventDoubleClickTextSelect = (event: MouseEvent) => {
      if (event.detail > 1) {
        event.preventDefault();
        handleDoubleClick();
      }
    };

    useEffect(() => {
      if (mainRef.current) {
        mainRef.current.addEventListener(
          "mousedown",
          preventDoubleClickTextSelect as unknown as EventListenerOrEventListenerObject,
          false,
        );
      }

      return () => {
        if (mainRef.current) {
          mainRef.current.removeEventListener(
            "mousedown",
            preventDoubleClickTextSelect as unknown as EventListenerOrEventListenerObject,
          );
        }
      };
    }, []);

    const editAddress = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        openRightPanel(
          "ADDRESS_FORM",
          Map({
            address,
            isEditForm: true,
            initialPanelData,
            returnPreviousPanel,
            addressID: addressID || address.get("destination_address_id"),
          }),
        );
      },
      [address, openRightPanel, addressID],
    );

    const handleSelect = useCallback(
      () => onSelect && onSelect(addressID),
      [onSelect, addressID],
    );

    const handleDoubleClick = useCallback(
      () => onSubmit && onSubmit(addressID),
      [onSubmit, addressID],
    );

    const fullName = useMemo(
      () => `${address.get("first_name")} ${address.get("last_name")}`,
      [address],
    );
    const streetPart = useMemo(() => {
      if (address.get("street")) {
        return [`${address.get("street")}`];
      } else {
        return [
          `${address.get("address_1")}`,
          `${address.get("address_2") ? `\n${address.get("address_2")}` : ""}`,
        ];
      }
    }, [address]);
    const addressText = useMemo(
      () =>
        [
          ...streetPart,
          address.get("house") &&
            `${t("abbreviations.house")} ${address.get("house")}`,
          address.get("hull") &&
            `${t("abbreviations.hull")} ${address.get("hull")}`,
          address.get("flat") &&
            `${t("abbreviations.appartement")} ${address.get("flat")}`,
          `\n${address.get("city")}`,
          address.get("zip_code") || address.get("postal_code"),
          address.get("country"),
          `\n${address.get("phone")}`,
        ]
          .filter((item) => item)
          .join(", "),
      [address, t],
    );
    const isPassportValid = useMemo(
      () =>
        !!(
          address.get("passport_full_name") &&
          address.get("passport_issued_by") &&
          address.get("passport_issued_date") &&
          address.get("passport_number") &&
          address.get("passport_serial")
        ),
      [address],
    );

    return (
      <AddressItemWrapper
        onClick={handleSelect}
        $isSelected={isSelected}
        onDoubleClick={handleDoubleClick}
        ref={mainRef}
        className={className}
      >
        {deletedItems.includes(addressID) ? (
          <UndoWrapper
            text={t("account.addressDeleted")}
            onClick={() => handleUndo(addressID)}
          />
        ) : (
          <>
            <AddressTitle>
              {fullName}
              {isPassportValid && <AddressVerifyIcon type={IconType.Id} />}
              {!isHideButtons && (
                <AddressButtons>
                  <AddressButton
                    color={ButtonColor.Primary}
                    hidden={!editable}
                    onClick={editAddress}
                  >
                    {t("common.edit")}
                  </AddressButton>
                  <AddressButton
                    color={ButtonColor.Red}
                    hidden={!deletable}
                    onClick={() => handleRemove(addressID)}
                  >
                    {t("common.remove")}
                  </AddressButton>
                </AddressButtons>
              )}
            </AddressTitle>
            <AddressText>{addressText}</AddressText>
          </>
        )}
      </AddressItemWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    panelData: panelSelectData,
  }),
  { openRightPanel, deleteAddress },
);

export default withConnect(AddressItem);
