import React, { FC, MouseEvent, useCallback } from "react";
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "enums/Button";
import { IconType } from "enums/Icon";
import { useFormik } from "formik";
import { ProgressDeliverySetAccessFunc } from "hooks/useProgressDelivery";
import { Map } from "immutable";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { SetPackingMethodDto } from "types/api/cart";
import { OpenRightPanel } from "types/common/rightPanel";
import * as yup from "yup";
import Button from "../../../../components/Button/Button";
import { setPackingMethods } from "../../../../reduxFolder/reducers/cart";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { cartSelectCartPacking } from "../../../../reduxFolder/selectors/cartSelectors";
import {
  servicesSelectLoading,
  servicesSelectPackingMethods,
} from "../../../../reduxFolder/selectors/servicesSelectors";
import { toastResponseError } from "../../../../utils/responseMessageHelper";
import {
  BlockContent,
  Content,
  DeliveryBlock,
  Heading,
  SubmitWrapper,
} from "../../styles/Delivery.styles";
import PackingList from "../PackingList/PackingList";
import ScrollableForm from "../ScrollableForm";
import { HelpButton, HelpIcon, Spinner } from "./ChooseForm.styles";

interface ChoosePackingFormProps {
  setAccess: ProgressDeliverySetAccessFunc;
  cartPacking: any;
  packingMethods: any;
  isLoading: boolean;
  openRightPanel: OpenRightPanel;
  setPackingMethods: (data: SetPackingMethodDto) => Promise<void>;
}

const ChoosePackingForm: FC<ChoosePackingFormProps> = React.memo(
  ({
    packingMethods,
    openRightPanel,
    cartPacking,
    setAccess,
    setPackingMethods,
    isLoading,
  }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const {
      isSubmitting,
      setFieldValue,
      values: { packingType },
      handleSubmit,
    } = useFormik({
      initialValues: {
        packingType: cartPacking.toJS()[0],
      },
      validationSchema: () =>
        yup.object().shape({
          packingType: yup.string().required(),
        }),
      onSubmit: (values) => {
        if (cartPacking.toJS()[0] !== values.packingType) {
          setPackingMethods({
            optional_line_item_codes: [values.packingType],
          })
            .then(() => {
              setAccess("addons");
              navigate("/shipping/flow/delivery/additional");
            })
            .catch(toastResponseError);
          return;
        }

        setAccess("addons");
        navigate("/shipping/flow/delivery/additional");
      },
    });

    const handleSelect = useCallback(
      (type: string) => {
        setFieldValue("packingType", type);
      },
      [setFieldValue],
    );

    const handlePackingSubmit = useCallback(
      async (type: string) => {
        await setFieldValue("packingType", type);
        handleSubmit();
      },
      [setFieldValue, handleSubmit],
    );

    const handleHelpClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        openRightPanel(
          "HELP_PANEL",
          Map({
            preventReLoading: true,
            hideBackButton: true,
            showHelpersCode: "packing_grade",
          }),
        );
      },
      [openRightPanel],
    );

    return (
      <Col lg={4} md={6} sm={12} offset={{ md: 3, lg: 4, sm: 12 }}>
        <Content>
          <DeliveryBlock>
            <form onSubmit={handleSubmit}>
              <Heading>
                {t("shipping.selectPacking")}:
                <HelpButton onClick={handleHelpClick}>
                  <HelpIcon type={IconType.Help} />
                </HelpButton>
              </Heading>
              <Spinner isActive={isLoading} />
              <BlockContent>
                <ScrollableForm>
                  <PackingList
                    packingMethods={packingMethods}
                    packingType={packingType}
                    onSelect={handleSelect}
                    onSubmit={handlePackingSubmit}
                  />
                </ScrollableForm>
                <SubmitWrapper>
                  <Button
                    disabled={!packingType || isSubmitting}
                    isLoading={isSubmitting}
                    type={ButtonType.Submit}
                    size={ButtonSize.Large}
                    color={ButtonColor.Primary}
                    variant={ButtonVariant.Filled}
                  >
                    {t("common.nextStep")}
                  </Button>
                </SubmitWrapper>
              </BlockContent>
            </form>
          </DeliveryBlock>
        </Content>
      </Col>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    packingMethods: servicesSelectPackingMethods,
    cartPacking: cartSelectCartPacking,
    isLoading: servicesSelectLoading,
  }),
  { setPackingMethods, openRightPanel },
);

export default withConnect(ChoosePackingForm);
