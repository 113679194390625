import { List } from 'immutable';
import { createSelector, lruMemoize } from 'reselect';
import { getTranslatedProp } from '../../translations/translationHelper';
import { initialState } from '../reducers/services';
import { peek, peekOr, selector } from '../selectorHelpers';
import { authSelectLocale } from './globalSelectors';

export const selectServices = (state) => state.get('services', initialState);

export const servicesSelectDelivery = selector(
  selectServices,
  peekOr(['deliveryMethods'], List()),
);

export const servicesSelectAllDelivery = selector(
  selectServices,
  peekOr(['allDeliveryMethods'], List()),
);

export const servicesSelectPackingMethods = createSelector(
  [selectServices, authSelectLocale],
  lruMemoize((selectServices, currentLocale) => {
    const retVal = selectServices.getIn(['packingMethods'], List());
    return retVal.map((item) => {
      const translatedTitle = getTranslatedProp(item, 'title', currentLocale);

      return item.set('title', translatedTitle);
    });
  }),
);

export const servicesSelectAddonsAll = createSelector(
  [selectServices, authSelectLocale],
  lruMemoize((selectServices, currentLocale) => {
    const retVal = selectServices.getIn(['addonsMethods'], List());
    return retVal.map((item) => {
      const translatedTitle = getTranslatedProp(
        item,
        'description',
        currentLocale,
      );

      return item.set('description', translatedTitle);
    });
  }),
);

export const servicesSelectAddons = selector(
  [servicesSelectAddonsAll],
  lruMemoize((addonsMethods) => {
    return addonsMethods.filter(
      (item) =>
        item.get('item_kind') === 'recd_as_package' ||
        item.get('item_kind') === 'all',
    );
  }),
);

export const servicesSelectAddonsForInventory = selector(
  [servicesSelectAddonsAll],
  lruMemoize((addonsMethods) =>
    addonsMethods.filter(
      (item) =>
        item.get('item_kind') === 'inventory' ||
        item.get('item_kind') === 'all',
    ),
  ),
);

export const servicesSelectConsolidationAddons = createSelector(
  [selectServices, authSelectLocale],
  lruMemoize((selectServices, currentLocale) => {
    const retVal = selectServices.getIn(['consolidationAddonsMethods'], List());
    return retVal.map((item) => {
      const translatedTitle = getTranslatedProp(
        item,
        'description',
        currentLocale,
      );

      return item.set('description', translatedTitle);
    });
  }),
);

export const servicesSelectAllServices = createSelector(
  [servicesSelectAllDelivery, servicesSelectPackingMethods],
  lruMemoize((deliveryMethods, packingMethods) =>
    deliveryMethods.merge(packingMethods),
  ),
);

export const servicesSelectLoading = selector(
  selectServices,
  peek('isLoading'),
);
