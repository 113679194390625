import React, { FC, useCallback } from "react";
import {
  BackButton,
  ContentWrapper,
  Heading,
  LeftArrowIcon,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonType, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { useFormik } from "formik";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ChangingAddressFormProps } from "types/OutgoingForms/changingAddressPanel";
import AddressSearch from "../../../../components/Addresses/components/AddressSearch";
import Button from "../../../../components/Button/Button";
import { setAddress as setCartAddress } from "../../../../reduxFolder/reducers/cart";
import { updateAddress } from "../../../../reduxFolder/reducers/outgoing";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { destinationsSelectAddresses } from "../../../../reduxFolder/selectors/destinationSelectors";
import { outgoingSelectDetailedItems } from "../../../../reduxFolder/selectors/outgoingSelectors";
import {
  AddButton,
  AddressesListWrapper,
  ButtonWrapper,
  ContentContainer,
  Form,
} from "./ChangingAddressPanel.styles";
import { changingAddressPanelFormik } from "./handleChangingAddressPanelFormik";

const ChangingAddressPanel: FC<ChangingAddressFormProps> = React.memo(
  ({
    panelData,
    destinations,
    updateAddress,
    setCartAddress,
    openRightPanel,
    detailedItem,
  }) => {
    const { t } = useTranslation("common");
    const {
      handleSubmit,
      values: { addressId },
      isSubmitting,
      setFieldValue,
    } = useFormik(
      changingAddressPanelFormik(
        panelData,
        destinations,
        updateAddress,
        setCartAddress,
        openRightPanel,
      ),
    );
    const isCartVariant = panelData.get("isCartVariant");
    const returnPreviousPanel = panelData.get("returnPreviousPanel");
    const id = panelData.get("detailedItemID");
    const detailed = isCartVariant
      ? panelData.get("cart")
      : detailedItem.get(id, Map());

    const panelProps = {
      initialPanelData: panelData,
      returnPreviousPanel: "CHANGING_ADDRESS",
    };
    const handleBack = useCallback(
      () => openRightPanel(returnPreviousPanel, panelData),
      [openRightPanel, panelData],
    );
    const handleAddressSelect = useCallback(
      (id: string | number) =>
        setFieldValue("addressId", addressId === id ? null : id),
      [addressId, setFieldValue],
    );

    return (
      <ContentWrapper>
        <Heading $isBordered>
          <BackButton color={ButtonColor.Black50} onClick={handleBack}>
            <LeftArrowIcon type={IconType.Arrow} />
            {detailed.get("sku")}:
          </BackButton>
          {t("parcels.changingAddress")}
        </Heading>
        <ContentContainer>
          <AddressSearch />
          <Form onSubmit={handleSubmit}>
            <AddressesListWrapper
              editable
              onSelect={handleAddressSelect}
              onSubmit={handleSubmit}
              selectedAddress={addressId}
              {...panelProps}
            />
            <AddButton {...panelProps} />
            <ButtonWrapper>
              <Button
                type={ButtonType.Submit}
                color={ButtonColor.Primary}
                variant={ButtonVariant.Filled}
                isLoading={isSubmitting}
                disabled={!addressId || isSubmitting}
              >
                {t("common.saveChanges")}
              </Button>
            </ButtonWrapper>
          </Form>
        </ContentContainer>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    destinations: destinationsSelectAddresses,
    detailedItem: outgoingSelectDetailedItems,
  }),
  {
    updateAddress,
    setCartAddress,
    openRightPanel,
  },
);

export default withConnect(ChangingAddressPanel);
