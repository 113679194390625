import { IconType } from "enums/Icon";
import { InputIconColor } from "enums/Input";
import styled from "styled-components";
import { Field, FieldProps } from "styles/formStyles";
import Icon from "../Icon/Icon";
import PreLoader from "../PreLoader/PreLoader";
import Tooltip from "../Tooltip/Tooltip";
import { InputProps } from "./Input";

interface HeightProps {
  height: number;
}

export const IconWrapper = styled.div<HeightProps>`
  padding: ${(props) => `${0.5 * (props.height - 20)}px`};
  cursor: pointer;
`;

export const TooltipWrapper = styled.div<HeightProps>`
  position: absolute;
  right: 0;
  bottom: 0;
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.height}px`};
`;

export const CustomTooltip = styled(Tooltip)`
  .Popover-body {
    max-width: 250px;
    text-align: center;
  }
`;

interface CustomInputFieldProps extends FieldProps, InputProps {}

export const InputField = styled.input<CustomInputFieldProps>`
  ${Field};
  padding-right: 25px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

interface InputIconProps {
  color: InputIconColor;
  type: IconType;
}

export const InputIcon = styled(Icon)<InputIconProps>`
  background-color: ${({ theme, color }) => theme[color]};
`;

interface SpinnerProps {
  height: number;
  isActive: boolean;
}

export const Spinner = styled(PreLoader)<SpinnerProps>`
  position: absolute;
  top: ${(props) => `${0.5 * (props.height - 14)}px`};
  margin-right: 5px;
  width: 20px;
  height: 14px;
  transform-origin: 0 -2px;
  transform: scale(0.3);
`;

export const EndAdornmentWrapper = styled.div`
  position: absolute;
  right: 4px;
  font-size: 14px;
`;

export const CloseIconWrapper = styled.div`
  padding: 4px;
`;

export const CloseIcon = styled(Icon)`
  height: 16px;
  width: 16px;
`;

interface LabelProps {
  $invalid: boolean;
  className: string;
}

export const Label = styled.label<LabelProps>`
  width: 100%;
  position: relative;
  display: block;
  font-size: 12px;
  color: ${({ theme, $invalid }) => ($invalid ? theme.red : theme.black50)};
`;

export const LabelText = styled.p`
  margin-top: 0;
  text-align: left;
  margin-bottom: 8px;
`;
