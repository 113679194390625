import React, { FC } from "react";
import {
  RadioButtonCustom,
  RadioButtonDefault,
  RadioButtonLabel,
  RadioButtonText,
} from "./RadioButton.styles";

interface RadioButtonProps {
  label: string | React.ReactNode;
  onClick: React.MouseEventHandler<HTMLLabelElement>;
  disabled?: boolean;
  value: string | undefined;
  checked?: boolean;
}

const RadioButton: FC<RadioButtonProps> = ({
  label,
  disabled = false,
  onClick,
  checked,
  ...otherProps
}) => (
  <RadioButtonLabel onClick={onClick}>
    <RadioButtonDefault
      type="radio"
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
      checked={checked}
      {...otherProps}
    />
    <RadioButtonCustom $disabled={disabled} />
    {label && <RadioButtonText>{label}</RadioButtonText>}
  </RadioButtonLabel>
);

export default RadioButton;
