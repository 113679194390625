// utils
import { fromJS, List, Map, updateIn } from 'immutable';
import { combineActions, createAction, handleActions } from 'redux-actions';
// api
import api from '../../utils/api';
import { SHIPMENT_TRANSFERRING_STATE } from '../../utils/common';
import formatter from '../../utils/formatter';
// const
import {
  COMPLETE_REQUEST_TRANSFER_SHIPMENTS,
  CREATE_REQUEST_TRANSFER_SHIPMENT,
  requestNames,
} from './serviceRequests';
import { normalizeImages } from './utils/helpers';

export const defaultParams = fromJS({
  page: 1,
  filter: {
    'q[date_received_at_gteq]': null,
    'q[date_received_at_lteq]': null,
    'q[tracking_number_contains]': null,
    'q[vendor_eq]': null,
    'q[location_in]': null,
    'q[weight_gteq]': null,
    'q[weight_lteq]': null,
  },
});

export const defaultParamsExpected = fromJS({
  page: 1,
});

export const initialState = fromJS({
  isLoading: false,
  isShipmentLoading: false,
  items: [],
  transferringItems: [],
  selected: [],
  totalAmount: 0,
  hasItems: false,
  totalWeight: 0,
  params: defaultParams,
  detailedShipment: {},
  tempDetailedShipmentId: null,
  isShopsLoading: false,
  shops: [],
  expectedShops: [],
  isExpectedShopsLoading: false,
  inventoryItemsSearch: [],
  expectedItemsSearch: [],
  isLoadingCustomsDeclaration: false,
  tempExpectedItemId: null,
  expectedItemsTotalAmount: 0,
});

// const
export const ADD_PARCELS_TO_CART = 'storage/ADD_PARCELS_TO_CART';
export const FINALISE_ADD_PARCELS_TO_CART =
  'storage/FINALISE_ADD_PARCELS_TO_CART';
export const GET_STORAGE = 'storage/GET_STORAGE';
export const GET_INVENTORY_ITEMS = 'storage/GET_INVENTORY_ITEMS';
export const GET_EXPECTED_ITEMS = 'storage/GET_EXPECTED_ITEMS';
export const UPDATE_INVENTORY_ITEMS_SEARCH =
  'storage/UPDATE_INVENTORY_ITEMS_SEARCH';
export const UPDATE_EXPECTED_ITEMS_SEARCH =
  'storage/UPDATE_EXPECTED_ITEMS_SEARCH';
export const CREATE_EXPECTED_ITEM = 'storage/CREATE_EXPECTED_ITEM';
export const UPDATE_EXPECTED_ITEM = 'storage/UPDATE_EXPECTED_ITEM';
export const DELETE_EXPECTED_ITEM = 'storage/DELETE_EXPECTED_ITEM';
export const SET_SELECTED_EXPECTED_ITEM_ID =
  'storage/SET_SELECTED_EXPECTED_ITEM_ID';
export const GET_CUSTOMS_DECLARATIONS_FOR_EXPECTED_ITEM =
  'storage/GET_CUSTOMS_DECLARATIONS_FOR_EXPECTED_ITEM';
export const CREATE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM =
  'storage/CREATE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM';
export const UPDATE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM =
  'storage/UPDATE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM';
export const DELETE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM =
  'storage/DELETE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM';

export const SELECT_ALL = 'storage/SELECT_ALL';
export const DESELECT_ALL = 'storage/DESELECT_ALL';
export const SELECT_PARCEL = 'storage/SELECT_PARCEL';
export const FETCH_STORAGE = 'storage/FETCH_STORAGE';
export const CATCH_STORAGE_ERROR = 'storage/FETCH_STORAGE_ERROR';
export const UPDATE_STORAGE = 'storage/UPDATE_STORAGE';
export const UPDATE_TRANSFERRING_ITEMS = 'storage/UPDATE_TRANSFERRING_ITEMS';
export const GET_SHIPMENT = 'storage/GET_SHIPMENT';
export const ADD_REQUEST = 'storage/ADD_REQUEST';
export const CREATE_CUSTOM_DECLARATION = 'storage/CREATE_CUSTOM_DECLARATION';
export const DELETE_CUSTOM_DECLARATION = 'storage/DELETE_CUSTOM_DECLARATION';
export const UPDATE_CUSTOM_DECLARATION = 'storage/UPDATE_CUSTOM_DECLARATION';
export const GET_SHOPS = 'storage/GET_SHOPS';
export const GET_EXPECTED_SHOPS = 'storage/GET_EXPECTED_SHOPS';
export const UPDATE_ADDONS = 'storage/UPDATE_ADDONS';
export const UPDATE_COMMENT = 'storage/UPDATE_COMMENT';
export const REMOVE_CONSOLIDATION_FROM_SHIPMENT =
  'storage/REMOVE_CONSOLIDATION_FROM_SHIPMENT';
export const SET_SELECTED_STORAGE_ITEM_ID =
  'storage/SET_SELECTED_STORAGE_ITEM_ID';
export const IMPORT_CUSTOMS_DECLARATION = 'storage/IMPORT_CUSTOMS_DECLARATION';
export const UPDATE_ITEM_COMMENT = 'storage/UPDATE_ITEM_COMMENT';

// action creators
export const addParcelsToCart = createAction(ADD_PARCELS_TO_CART);
export const finaliseParcelsToCart = createAction(FINALISE_ADD_PARCELS_TO_CART);
export const getStorage = createAction(
  GET_STORAGE,
  (data = defaultParams) => data,
);
export const getInventoryItems = createAction(
  GET_INVENTORY_ITEMS,
  (data) => data,
);
export const getExpectedItems = createAction(
  GET_EXPECTED_ITEMS,
  (data = defaultParamsExpected) => data,
);
export const updateInventoryItemsSearch = createAction(
  UPDATE_INVENTORY_ITEMS_SEARCH,
);
export const updateExpectedItemsSearch = createAction(
  UPDATE_EXPECTED_ITEMS_SEARCH,
);
export const createExpectedItem = createAction(
  CREATE_EXPECTED_ITEM,
  (data) => ({
    promise: api.expected.createExpectedItem({ ...data }),
    data: data,
  }),
);
export const updateExpectedItem = createAction(
  UPDATE_EXPECTED_ITEM,
  (id, data) => ({
    promise: api.expected.updateExpectedItem({ id, ...data }),
    data: data,
  }),
  // This will be passed as meta to reducer (including SUCCESS and ERROR actions)
  (id) => ({
    id: id,
  }),
);
export const deleteExpectedItem = createAction(
  DELETE_EXPECTED_ITEM,
  (id) => ({
    promise: api.expected.deleteExpectedItem(id),
    data: id,
  }), // This will be passed as meta to reducer (including SUCCESS and ERROR actions)
  (id) => ({
    id: id,
  }),
);
export const setSelectedExpectedItemId = createAction(
  SET_SELECTED_EXPECTED_ITEM_ID,
);
export const getCustomsDeclarationsForExpectedItem = createAction(
  GET_CUSTOMS_DECLARATIONS_FOR_EXPECTED_ITEM,
  (data) => ({
    promise: api.expected.getCustomsDeclarationsForExpectedItem(data),
    data,
  }),
);
export const createCustomsDeclarationForExpectedItem = createAction(
  CREATE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM,
  ({ itemId, data }) => ({
    promise: api.expected.createCustomsDeclarationForExpectedItem({
      itemId,
      data,
    }),
    data: { itemId },
  }),
);
export const updateCustomsDeclarationForExpectedItem = createAction(
  UPDATE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM,
  ({ itemId, id, data }) => ({
    promise: api.expected.updateCustomsDeclarationForExpectedItem({
      itemId,
      id,
      data,
    }),
    data: { itemId },
  }),
);
export const deleteCustomsDeclarationForExpectedItem = createAction(
  DELETE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM,
  api.expected.deleteCustomsDeclarationForExpectedItem,
);

export const selectAll = createAction(SELECT_ALL);
export const deselectAll = createAction(DESELECT_ALL);
export const selectParcel = createAction(SELECT_PARCEL);
export const fetchStorage = createAction(FETCH_STORAGE);
export const catchStorageError = createAction(CATCH_STORAGE_ERROR);
export const updateStorage = createAction(UPDATE_STORAGE);
export const updateTransferringItems = createAction(UPDATE_TRANSFERRING_ITEMS);
export const addServiceRequest = createAction(ADD_REQUEST);
export const getShipment = createAction(
  GET_SHIPMENT,
  api.shipments.getShipment,
);
export const createCustomsDeclaration = createAction(
  CREATE_CUSTOM_DECLARATION,
  ({ itemId, data }) => ({
    promise: api.shipments.createCustomsDeclaration({ itemId, data }),
    data: { itemId },
  }),
);
export const deleteCustomsDeclaration = createAction(
  DELETE_CUSTOM_DECLARATION,
  api.shipments.deleteCustomsDeclaration,
);
export const updateCustomsDeclaration = createAction(
  UPDATE_CUSTOM_DECLARATION,
  ({ itemId, id, data }) => ({
    promise: api.shipments.updateCustomsDeclaration({ itemId, id, data }),
    data: { itemId },
  }),
);
export const getShops = createAction(GET_SHOPS, api.shipments.getShops);

export const getExpectedShops = createAction(
  GET_EXPECTED_SHOPS,
  api.shipments.getExpectedShops,
);
export const updateAddons = createAction(
  UPDATE_ADDONS,
  api.shipments.updateAddons,
);
export const updateComment = createAction(
  UPDATE_COMMENT,
  api.shipments.updateComment,
);
export const updateItemComment = createAction(
  UPDATE_ITEM_COMMENT,
  (id, data) => ({
    promise: api.items.updateItemComment(id, data),
  }),
  // This will be passed as meta to reducer (including SUCCESS and ERROR actions)
  (id, data) => ({
    inventoryItemId: id,
  }),
);
export const removeConsolidationFromShipment = createAction(
  REMOVE_CONSOLIDATION_FROM_SHIPMENT,
);
export const setSelectedStorageItemId = createAction(
  SET_SELECTED_STORAGE_ITEM_ID,
);
export const importCustomsDeclaration = createAction(
  IMPORT_CUSTOMS_DECLARATION,
  ({ id, file }) => {
    const data = new FormData();
    data.append('customs_declaration_import[file]', file);
    return {
      promise: api.shipments.importCustomsDeclaration({ id, data }),
    };
  },
);

// helpers
export const normalizeItems = (items) =>
  items.map((item) => {
    const serviceRequestCount = {};

    item.get('service_requests_count').forEach((request) => {
      const reqPropName = request.get(0).toLowerCase();
      if (requestNames[reqPropName]) {
        serviceRequestCount[requestNames[reqPropName]] = request.get(1);
      }
    });

    return item
      .delete('service_requests_count')
      .set('serviceRequestCount', Map(serviceRequestCount));
  });

export const normalizeDetailedShipment = (shipment) =>
  shipment
    .update('service_requests', (requests) =>
      requests.map((request) =>
        request
          .updateIn(['service_request_type', 'type'], (type) =>
            type.toLowerCase(),
          )
          .update('pictures', (pictures) => normalizeImages(pictures)),
      ),
    )
    .update('pictures', (pictures) => {
      const serviceRequests = shipment.get('service_requests', List());
      const serviceRequest = serviceRequests.find(
        (request) =>
          request.getIn(['service_request_type', 'type']) ===
          'Additional photos',
      );
      const picturesServiceRequest = serviceRequest
        ? normalizeImages(serviceRequest.get('pictures'))
        : [];

      return pictures.merge(picturesServiceRequest);
    })
    .updateIn(['items'], (items) =>
      items.map((item) =>
        item.set('pictures', normalizeImages(item.get('pictures'))),
      ),
    );

const storageReducer = handleActions(
  {
    [GET_STORAGE]: (state, { payload }) =>
      payload.size ? state : state.set('items', null),

    [FETCH_STORAGE]: (state, { payload }) => {
      const isPagination =
        state.getIn(['params', 'page']) < payload.get('page');
      const isAllFetch =
        state.get('totalAmount') === payload.getIn(['filter', 'per_page']);
      const shouldDropSelected = payload.get('isAddParcelsToCart')
        ? false
        : !(isPagination || isAllFetch);
      const selected = shouldDropSelected ? List() : state.get('selected');

      return state.set('selected', selected).set('isLoading', true);
    },

    [FINALISE_ADD_PARCELS_TO_CART]: (state) => {
      return state.set('selected', List());
    },

    [UPDATE_STORAGE]: (state, { payload }) => {
      const isPagination =
        state.getIn(['params', 'page']) < payload.getIn(['params', 'page']);
      const headers = payload.get('headers');
      const headersArr = headers.toArray().map((arr) => arr.toArray());
      const totalAmountFromHeader = headersArr?.[4]?.[1] ?? 0;
      const totalWeightFromHeader = headersArr?.[5]?.[1] ?? 0;

      const totalAmount =
        headers && !isPagination
          ? parseInt(totalAmountFromHeader)
          : state.get('totalAmount');
      const totalWeight =
        headers && !isPagination
          ? parseFloat(totalWeightFromHeader)
          : formatter.roundTo2Decimal(
              state.get('totalWeight') + parseFloat(totalWeightFromHeader),
            );

      const normalizedItems = normalizeItems(payload.get('data'));
      const hasItems = !!normalizedItems.size;
      return state
        .update('items', (items) =>
          isPagination ? items.concat(normalizedItems) : normalizedItems,
        )
        .set('totalWeight', totalWeight)
        .set('totalAmount', totalAmount)
        .set('hasItems', hasItems)
        .set('params', payload.get('params'))
        .set('isLoading', false);
    },

    [UPDATE_TRANSFERRING_ITEMS]: (state, { payload }) => {
      const normalizedItems = normalizeItems(payload.get('data'));

      return state.set('transferringItems', normalizedItems);
    },

    [GET_INVENTORY_ITEMS]: (state, { payload }) => {
      return state.set('isLoading', true);
    },

    [GET_EXPECTED_ITEMS]: (state, { payload }) => {
      return state.set('isLoading', true);
    },

    [UPDATE_INVENTORY_ITEMS_SEARCH]: (state, { payload }) => {
      let data = payload.get('data');
      data = data.map((x) => x.update('pictures', normalizeImages));
      return state.set('inventoryItemsSearch', data).set('isLoading', false);
    },

    [UPDATE_EXPECTED_ITEMS_SEARCH]: (state, { payload }) => {
      const isPagination = payload.getIn(['params', 'page']) > 1;

      const data = payload.get('data');
      const headers = payload.get('headers');
      const totalHeader = headers.find(
        (headerArray) => headerArray.get(0) === 'total',
      );

      const totalCount = totalHeader ? parseInt(totalHeader.get(1)) : 0;

      return state
        .update('expectedItemsSearch', (expectedItems) =>
          isPagination ? expectedItems.concat(data) : data,
        )
        .set('isLoading', false)
        .set('expectedItemsTotalAmount', totalCount);
    },

    [`${CREATE_EXPECTED_ITEM}_SUCCESS`]: (state, { payload }) => {
      return state.set(
        'expectedItemsSearch',
        state.get('expectedItemsSearch').push(payload.get('data')),
      );
    },

    [`${UPDATE_EXPECTED_ITEM}_SUCCESS`]: (state, { payload, meta }) => {
      const data = payload.get('data');
      return state.set(
        'expectedItemsSearch',
        state.get('expectedItemsSearch').map((item) => {
          return item.get('id') === data.get('id') ? data : item;
        }),
      );
    },

    [`${DELETE_EXPECTED_ITEM}_SUCCESS`]: (state, { meta }) => {
      const id = meta.id;

      return updateIn(state, ['expectedItemsSearch'], (expectedItemsSearch) =>
        expectedItemsSearch.filter((item) => item.get('id') !== id),
      );
    },

    [`${GET_CUSTOMS_DECLARATIONS_FOR_EXPECTED_ITEM}_REQUEST`]: (
      state,
      { payload },
    ) =>
      state
        .set('tempExpectedItemId', payload)
        .set('isLoadingCustomsDeclaration', true),

    [`${GET_CUSTOMS_DECLARATIONS_FOR_EXPECTED_ITEM}_SUCCESS`]: (
      state,
      { payload },
    ) => {
      const itemId = state.get('tempExpectedItemId');
      return updateIn(state, ['expectedItemsSearch'], (expectedItemsSearch) =>
        expectedItemsSearch.map((item) =>
          item.get('id') !== itemId
            ? item
            : item.set('customs_declarations', payload.get('data')),
        ),
      ).set('isLoadingCustomsDeclaration', false);
    },

    [combineActions(
      `${CREATE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM}_REQUEST`,
      `${UPDATE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM}_REQUEST`,
    )]: (state, { payload }) => state.set('tempExpectedItemId', payload.itemId),

    [`${CREATE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM}_SUCCESS`]: (
      state,
      { payload },
    ) => {
      const itemId = state.get('tempExpectedItemId');

      return updateIn(state, ['expectedItemsSearch'], (expectedItemsSearch) =>
        expectedItemsSearch.map((item) => {
          if (item.get('id') === itemId) {
            const newList = new List(item.get('customs_declarations'));
            newList.push(payload.get('data'));

            const newItem = new Map(item).merge({
              customs_declarations: newList,
            });
            return newItem;
          }

          return item;
        }),
      );
    },

    [`${UPDATE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM}_SUCCESS`]: (
      state,
      { payload },
    ) => {
      const itemId = state.get('tempExpectedItemId');
      const data = payload.get('data');

      return updateIn(state, ['expectedItemsSearch'], (expectedItemsSearch) =>
        expectedItemsSearch.map((item) => {
          if (item.get('id') === itemId) {
            return item.update('customs_declarations', (declarations) =>
              declarations.map((declaration) =>
                declaration.get('id') === data.get('id') ? data : declaration,
              ),
            );
          }

          return item;
        }),
      );
    },

    [`${DELETE_CUSTOM_DECLARATION_FOR_EXPECTED_ITEM}_SUCCESS`]: (
      state,
      { payload },
    ) => {
      return state;
    },

    [CATCH_STORAGE_ERROR]: (state) => state.set('isLoading', false),

    [DESELECT_ALL]: (state) => state.set('selected', List()),

    [SELECT_PARCEL]: (state, { payload }) => {
      if (List.isList(payload)) return state.set('selected', payload);

      return state.update('selected', (selected) => {
        const index = selected.indexOf(payload);
        return index === -1 ? selected.push(payload) : selected.delete(index);
      });
    },

    /* DETAILED SHIPMENT */
    [combineActions(
      `${GET_SHIPMENT}_REQUEST`,
      `${UPDATE_ADDONS}_REQUEST`,
      `${IMPORT_CUSTOMS_DECLARATION}_REQUEST`,
    )]: (state) => state.set('isShipmentLoading', true),

    [`${GET_SHIPMENT}_SUCCESS`]: (state, { payload }) => {
      const data = normalizeDetailedShipment(payload.get('data'));

      return state
        .setIn(['detailedShipment', data.get('id')], data)
        .set('isShipmentLoading', false);
    },

    [`${UPDATE_ADDONS}_SUCCESS`]: (state, { payload }) => {
      const data = payload.get('data');

      return state
        .update('items', (items) =>
          items.map((item) =>
            item.get('id') === data.get('id')
              ? item.set('addons', data.get('addons'))
              : item,
          ),
        )
        .updateIn(['detailedShipment', data.get('id')], (shipment) =>
          shipment.set('addons', data.get('addons')),
        )
        .set('isShipmentLoading', false);
    },

    [`${UPDATE_COMMENT}_SUCCESS`]: (state, { payload }) => {
      const id = payload.getIn(['data', 'id']);
      const comment = payload.getIn(['data', 'customer_comment']);
      let index = state.get('items').findIndex((item) => item.get('id') === id);
      if (index !== -1) {
        return state
          .setIn(['items', index, 'customer_comment'], comment)
          .setIn(['detailedShipment', id, 'customer_comment'], comment);
      } else {
        index = state
          .get('transferringItems')
          .findIndex((item) => item.get('id') === id);
        return state
          .setIn(['transferringItems', index, 'customer_comment'], comment)
          .setIn(['detailedShipment', id, 'customer_comment'], comment);
      }
    },

    [`${UPDATE_ITEM_COMMENT}_SUCCESS`]: (state, { payload, meta }) => {
      const data = payload.get('data');
      const index = data
        .get('items')
        .findIndex((item) => item.get('id') === meta.inventoryItemId);
      const comment = data.getIn(['items', index, 'customer_comment']);

      return state.setIn(
        [
          'detailedShipment',
          data.get('id'),
          'items',
          index,
          'customer_comment',
        ],
        comment,
      );
    },

    [`${REMOVE_CONSOLIDATION_FROM_SHIPMENT}`]: (state, { payload }) => {
      return state
        .updateIn(['detailedShipment', payload], (shipment) =>
          shipment.delete('consolidation'),
        )
        .update('items', (items) =>
          items.map((item) =>
            item.get('id') !== payload ? item : item.delete('consolidation_id'),
          ),
        );
    },

    [combineActions(
      `${GET_SHIPMENT}_ERROR`,
      `${UPDATE_ADDONS}_ERROR`,
      `${IMPORT_CUSTOMS_DECLARATION}_SUCCESS`,
      `${IMPORT_CUSTOMS_DECLARATION}_ERROR`,
    )]: (state) => state.set('isShipmentLoading', false),

    /* SERVICE REQUESTS */
    [ADD_REQUEST]: (state, { payload }) => {
      const packageId = payload.get('packageId');
      const data = payload.get('data');
      const type = data.getIn(['service_request_type', 'type']).toLowerCase();
      const isRequestCanceled = data.get('state') === 'canceled';
      const items = state.get('items').map((item) => {
        if (item.get('id') !== packageId) return item;
        const requestName = requestNames[type];

        if (isRequestCanceled) {
          const serviceRequestCount = item
            .get('serviceRequestCount')
            .delete(requestName);

          return item.set('serviceRequestCount', serviceRequestCount);
        }

        return item.setIn(['serviceRequestCount', requestName], 'unprocessed');
      });

      return updateIn(
        state,
        ['detailedShipment', packageId, 'service_requests'],
        (service_requests) => {
          const id = data.get('id');
          const index = service_requests.findIndex(
            (request) => request.get('id') === id,
          );

          if (isRequestCanceled) return service_requests.delete(index);

          return index === -1
            ? service_requests.push(data)
            : service_requests.set(index, data);
        },
      ).set('items', items);
    },

    [`${CREATE_REQUEST_TRANSFER_SHIPMENT}_SUCCESS`]: (state, { meta }) => {
      const { ids } = meta;

      const transferredItemsList = state
        .get('items')
        .filter((x) => ids.includes(x.get('id')))
        .map((item) => item.set('state', SHIPMENT_TRANSFERRING_STATE));

      return state
        .update('items', (items) =>
          items.filter((x) => !ids.includes(x.get('id'))),
        )
        .update(
          'totalAmount',
          (totalAmount) => totalAmount - transferredItemsList.size,
        )
        .update(
          'totalWeight',
          (totalWeight) =>
            totalWeight -
            transferredItemsList.reduce((sum, x) => sum + x.get('weight'), 0),
        );
    },

    [`${COMPLETE_REQUEST_TRANSFER_SHIPMENTS}_SUCCESS`]: (state, { meta }) => {
      return state;
    },

    /* CUSTOM DECLARATION */
    [combineActions(
      `${CREATE_CUSTOM_DECLARATION}_REQUEST`,
      `${UPDATE_CUSTOM_DECLARATION}_REQUEST`,
    )]: (state, { payload }) => state.set('tempDetailedItemId', payload.itemId),

    [`${CREATE_CUSTOM_DECLARATION}_SUCCESS`]: (state, { payload }) => {
      const itemId = state.get('tempDetailedItemId');

      return updateIn(
        state,
        ['detailedShipment', itemId, 'items', 0, 'customs_declarations'],
        (declaration) => declaration.push(payload.get('data')),
      );
    },

    [`${UPDATE_CUSTOM_DECLARATION}_SUCCESS`]: (state, { payload }) => {
      const itemId = state.get('tempDetailedItemId');
      const data = payload.get('data');

      return updateIn(
        state,
        ['detailedShipment', itemId, 'items', 0, 'customs_declarations'],
        (declarations) =>
          declarations.map((declaration) =>
            declaration.get('id') === data.get('id') ? data : declaration,
          ),
      );
    },

    [`${DELETE_CUSTOM_DECLARATION}_SUCCESS`]: (state, { payload }) => {
      const id = payload.getIn(['data', 'id']);

      return updateIn(state, ['detailedShipment'], (detailedShipment) =>
        detailedShipment.map((shipment) =>
          updateIn(
            shipment,
            ['items', 0, 'customs_declarations'],
            (declarations) =>
              declarations.filter(
                (declaration) => declaration.get('id') !== id,
              ),
          ),
        ),
      );
    },

    /* VENDORS */
    [`${GET_SHOPS}_REQUEST`]: (state) => state.set('isShopsLoading', true),

    [`${GET_SHOPS}_SUCCESS`]: (state, { payload }) =>
      state.set('shops', payload.get('data')).set('isShopsLoading', false),

    [`${GET_SHOPS}_ERROR`]: (state) => state.set('isShopsLoading', false),

    [`${GET_EXPECTED_SHOPS}_REQUEST`]: (state) =>
      state.set('isExpectedShopsLoading', true),

    [`${GET_EXPECTED_SHOPS}_SUCCESS`]: (state, { payload }) =>
      state
        .set('expectedShops', payload.get('data'))
        .set('isExpectedShopsLoading', false),

    [`${GET_EXPECTED_SHOPS}_ERROR`]: (state) =>
      state.set('isExpectedShopsLoading', false),

    /* SELECTED ITEMS */
    [SET_SELECTED_STORAGE_ITEM_ID]: (state, { payload }) =>
      state.set('selectedStorageItemId', payload),

    [SET_SELECTED_EXPECTED_ITEM_ID]: (state, { payload }) =>
      state.set('selectedExpectedItemId', payload),
  },

  initialState,
);

export default storageReducer;
