import React, {
  ChangeEvent,
  FC,
  memo,
  MouseEvent,
  useRef,
  useState,
} from "react";
import CopyToClipboard from "components/CopyToClipboard/CopyToClipboard";
import { format } from "date-fns";
import { ButtonColor } from "enums/Button";
import { IconType } from "enums/Icon";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import ImageViewer2 from "../../../../components/ImageViewer2/ImageViewer2";
import { authSelectAllowExtendedTools } from "../../../../reduxFolder/selectors/globalSelectors";
import { panelSelectOpened } from "../../../../reduxFolder/selectors/rightPanelSelectors";
import { getFnsLocale } from "../../../../translations/date-fns-locale";
import {
  BackButton,
  ButtonsWrapper,
  CheckboxAreaButton,
  Comment,
  Description,
  DownArrowIcon,
  ImageSection,
  InventoryItemTr,
  LocationWrapper,
  ParcelDate,
  QuantityEdit,
  QuantityWrapper,
  StyledInput,
  UpArrowIcon,
  WeightWrapper,
} from "../../styles/Inventory.style";

interface InventoryItemRowProps {
  units: string;
  isSelected: boolean;
  allowExtendedTools: boolean;
  onChange: ({
    isSelected,
    selectedQuantity,
  }: {
    isSelected: boolean;
    selectedQuantity: any;
  }) => void;
  selectedInventoryItem: any;
  inventoryItem: any;
  isItemInCart: any;
  disabledParent: boolean;
  onFocusInventoryItemInPanel: (item: any) => void;
}

const InventoryItemRow: FC<InventoryItemRowProps> = React.memo(
  ({
    units,
    allowExtendedTools,
    disabledParent,
    inventoryItem,
    selectedInventoryItem,
    isItemInCart,
    isSelected,
    onChange,
    onFocusInventoryItemInPanel,
  }) => {
    const itemRef = useRef(null);
    const { i18n, t } = useTranslation("common");
    const [selectedQuantity, setSelectedQuantity] = useState(1);

    const item = inventoryItem.toJS();
    const pictures = item.pictures;
    const hasPictures = pictures && pictures.length > 0;
    const weight = item.weight && Number(item.weight).toFixed(2);
    const comment = item.customer_comment;

    const createdAt = inventoryItem.get("created_at", new Date());
    const locale = getFnsLocale(i18n.language);
    const currentYear = new Date().getFullYear();
    const created = format(new Date(createdAt), "dd MMMM, y", {
      locale,
    }).split(", ");
    const parcelDate =
      currentYear === parseFloat(created[1]) ? created[0] : created.join(", ");
    const isDecantItem = isItemInCart; //TODO: FIXME: Decant item will have SKU starting with letter "Q"
    const isCartableItem =
      ["Active", "InContainer", "ContainerPacked"].indexOf(
        inventoryItem.get("status"),
      ) !== -1;
    const quantity = item.decant_quantity
      ? item.decant_quantity
      : item.quantity;
    const hasQuantity = quantity > 1;
    const location = item.container ? item.container.sku : item.location;

    const handleCheckBoxClick = (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleCheckboxAreaButtonClick = (
      event: MouseEvent<HTMLDivElement>,
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isItemInCart && isCartableItem) {
        onChange({
          isSelected: !isSelected,
          selectedQuantity,
        });
      }
    };

    const handleSetQuantity = (nextValue: number) => {
      const valueToSet =
        isNaN(nextValue) || nextValue > quantity || nextValue < 1
          ? selectedQuantity
          : nextValue;
      setSelectedQuantity(valueToSet);
      onChange({
        isSelected,
        selectedQuantity: valueToSet,
      });
    };

    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
      const nextValueParsed = parseInt(event.target.value);
      handleSetQuantity(nextValueParsed);
    };

    const handleIncrease = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      handleSetQuantity(selectedQuantity + 1);
    };

    const handleDecrease = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      handleSetQuantity(selectedQuantity - 1);
    };

    const preventInputEvents = (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
    };

    return (
      <InventoryItemTr
        onClick={() => onFocusInventoryItemInPanel(inventoryItem)}
        selected={selectedInventoryItem === inventoryItem.get("id")}
      >
        <td>
          <CheckboxAreaButton onClick={handleCheckboxAreaButtonClick}>
            <Checkbox
              checked={isSelected || isItemInCart}
              onChange={handleCheckBoxClick}
              disabled={isItemInCart || !isCartableItem}
            />
          </CheckboxAreaButton>
        </td>
        <td>
          {hasPictures && (
            <ImageSection>
              <ImageViewer2 images={pictures} zoomLevel={1} hideSideBar />
            </ImageSection>
          )}
        </td>
        <td>
          <Description>
            <CopyToClipboard text={item.description}>
              {item.description}
            </CopyToClipboard>
            {comment && <Comment>{comment}</Comment>}
          </Description>
        </td>
        {hasQuantity && (
          <td>
            <QuantityWrapper>
              {isDecantItem && (
                <div>
                  {`${quantity} ${t("parcels.quantity").toLowerCase()}`}
                </div>
              )}
              {!isDecantItem && (
                <QuantityEdit>
                  <ButtonsWrapper>
                    <BackButton
                      color={ButtonColor.Black50}
                      onClick={handleIncrease}
                      disabled={selectedQuantity === quantity}
                    >
                      <UpArrowIcon type={IconType.Arrow} />
                    </BackButton>
                    <StyledInput
                      name="decantQuantity"
                      value={selectedQuantity}
                      onChange={handleQuantityChange}
                      onClick={preventInputEvents}
                    />
                    <BackButton
                      color={ButtonColor.Black50}
                      onClick={handleDecrease}
                      disabled={selectedQuantity === 1}
                    >
                      <DownArrowIcon type={IconType.Arrow} />
                    </BackButton>
                  </ButtonsWrapper>
                  <div>{` / ${quantity} in stock`}</div>
                </QuantityEdit>
              )}
            </QuantityWrapper>
          </td>
        )}
        <td>
          <WeightWrapper>
            {`${weight} ${t(`units.${units}.primaryShort`)}`}
          </WeightWrapper>
        </td>
        {item.color && <td>{item.color}</td>}
        {item.size && <td>{item.size}</td>}
        {allowExtendedTools && (
          <td>
            <LocationWrapper>{location}</LocationWrapper>
          </td>
        )}
        <td>
          <ParcelDate>{parcelDate}</ParcelDate>
        </td>
      </InventoryItemTr>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    allowExtendedTools: authSelectAllowExtendedTools,
  }),
);

export default withConnect(InventoryItemRow);
