import React, { FC } from "react";
import { IconType } from "enums/Icon";
import { components, DropdownIndicatorProps } from "react-select";
import styled from "styled-components";
import Icon from "../../Icon/Icon";

const DropdownIndicator: FC<DropdownIndicatorProps> = (props) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <SelectArrow type={IconType.Triangle} />
    </components.DropdownIndicator>
  );

export default React.memo(DropdownIndicator);

const SelectArrow = styled(Icon)`
  width: 8px;
  height: 8px;
`;
