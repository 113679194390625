import { FC } from "react";
import { DateRange, RangeKeyDict } from "react-date-range";
import { useTranslation } from "react-i18next";
import { theme } from "styles";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { ButtonColor, ButtonSize, ButtonVariant, IconType } from "enums";
import { getDayjsLocale } from "translations/dayjs-locale";
import { getFormattedDate } from "utils/formatDate";
import {
  DateFilterWrapper,
  DateFilterWrapperAnimator,
  DateLabel,
  DateRangeApplyButton,
  DateRangeArrow,
  DateRangeFooter,
  DateRangeHeader,
  DateRangeWrapper,
} from "./CompactDateRangeFilter.styles";

interface CompactDateRangeFilterProps {
  minDate: Date;
  maxDate: Date;
  selectionRange: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
  language: string;
  handleDateSelection: (range: RangeKeyDict) => void;
  hanldeApplyDateRange: () => void;
}

const CompactDateRangeFilter: FC<CompactDateRangeFilterProps> = ({
  selectionRange,
  handleDateSelection,
  hanldeApplyDateRange,
  minDate,
  maxDate,
  language,
}) => {
  const { t } = useTranslation("common");
  const locale = getDayjsLocale(language);
  const formattedDate = (date: Date) =>
    getFormattedDate(date, locale, "ddd, MMM DD");

  /* https://hypeserver.github.io/react-date-range/ */
  return (
    <DateFilterWrapper>
      <DateFilterWrapperAnimator>
        <DateRangeHeader>
          <DateLabel>{formattedDate(selectionRange.startDate)}</DateLabel>
          <DateRangeArrow type={IconType.RightArrow} />
          <DateLabel>{formattedDate(selectionRange.endDate)}</DateLabel>
        </DateRangeHeader>
        <DateRangeWrapper>
          <DateRange
            minDate={minDate}
            maxDate={maxDate}
            ranges={[selectionRange]}
            onChange={handleDateSelection}
            showMonthAndYearPickers={false}
            direction={"vertical"}
            showMonthArrow={false}
            showDateDisplay={false}
            rangeColors={[theme.primary]}
            scroll={{ enabled: true }}
          />
        </DateRangeWrapper>
        <DateRangeFooter>
          <DateRangeApplyButton
            variant={ButtonVariant.Filled}
            color={ButtonColor.Primary}
            size={ButtonSize.Large}
            onClick={hanldeApplyDateRange}
          >
            {t("parcels.search.applyFilter")}
          </DateRangeApplyButton>
        </DateRangeFooter>
      </DateFilterWrapperAnimator>
    </DateFilterWrapper>
  );
};

export default CompactDateRangeFilter;
