import Button from "components/Button/Button";
import styled from "styled-components";

export const MoreInfoButton = styled(Button)`
  display: block;
  font-size: 12px;
  padding: 4px 0;
  margin-top: 8px;
`;

export const ImageSection = styled.div`
  width: 100%;
  margin: 20px 0;
`;
