import { LeftArrowIcon } from "containers/RightPanel/RightPanel.styles";
import { fadeIn } from "react-animations";
import { Container, Row } from "react-grid-system";
import styled, { keyframes } from "styled-components";
import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import Input from "../../../components/Input/Input";
import { PageWrapper } from "../../../layouts/Layout.styles";
import {
  fadeInLeft,
  fadeOutRight,
  ListHeader,
  ListHeaderTitle,
  ParcelCol,
} from "../../../styles/parcelsStyles";

export const WeightWrapper = styled("div")`
  min-width: 80px;

  ${(props) => props.theme.md`
    min-width: 30px;
`}
`;

export const QuantityWrapper = styled("div")`
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 20px;
`;

export const QuantityEdit = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

export const StyledInput = styled(Input)`
  width: 30px;

  & input {
    height: 30px;
    padding: 10px 5px;
  }
`;

export const UpArrowIcon = styled(Icon)`
  transform: rotate(90deg);
`;

export const DownArrowIcon = styled(Icon)`
  transform: rotate(-90deg);
`;

interface BackButtonProps {
  disabled: boolean;
}

export const BackButton = styled(Button)<BackButtonProps>`
  font-size: 18px;
  display: flex;
  padding: 0;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    `i {
      opacity: 0.5;
    }`};

  ${({ disabled }) =>
    !disabled &&
    `&:hover {
      i {
        opacity: 0.5;
    }`};
`;

interface ParcelRowProps {
  selected: boolean;
  disabled?: boolean;
}

export const InventoryItemTr = styled.tr<ParcelRowProps>`
  cursor: pointer;
  ${({ theme }) => theme.range("padding", "10px", "16px")};
  white-space: nowrap;
  animation: 0.3s ${keyframes`${fadeIn}`};

  &.row {
    margin: 0;
  }

  &:not(:first-child) {
    border-top: none;
  }

  ${({ theme, selected }) => `
    border-top: 1px solid ${theme.lightgrey};
    border-bottom: 1px solid ${theme.lightgrey};
    background-color: ${selected && "rgba(228, 234, 245, 0.2)"};
  `};

  ${(props) => props.theme.md`
    font-size: 12px;
    padding: 15px 0 5px 0;
  `};

  ${({ disabled }) =>
    disabled &&
    `opacity: 0.5;
      pointer-events: none;
     `}
`;

export const CopyToClipboardButton = styled.button`
  padding: 4px 6px 2px 0;
`;

export const CheckboxAreaButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 15px;
`;

export const Description = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 17px;

  img {
    margin: 0 0 0 10px;

    &:first-child {
      margin-left: 15px;
    }
  }

  ${(props) => props.theme.sm`
    margin-left: 12px;
  `}

  ${(props) => props.theme.md`
    margin-left: 0;

    img {
      width: 12px;
      height: 12px;
      margin-left: 6px;
    }
  `}

  &[disabled] {
    color: ${(props) => props.theme.black50};
  }
`;

export const ParcelDate = styled.div`
  width: 100%;
  text-align: center;

  ${(props) => props.theme.md`
    margin-top: 20px;
    padding-left: 20px;
    margin-bottom: 15px;
`}
`;

export const ImageSection = styled.div`
  display: flex;
  height: 85px;
  width: 65px;
`;

export const Comment = styled.span`
  font-size: 12px;
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.black50};
`;

export const LocationWrapper = styled("div")`
  width: 50%;
  text-align: center;
`;

export const WrapperInventory = styled(PageWrapper)`
  position: absolute;
  width: 100%;
  animation: none;

  &.page-enter {
    animation: ${fadeInLeft} 0.22s ease;
  }

  &.page-exit {
    animation: ${fadeOutRight} 0.22s ease;
  }
`;

export const StyledGrid = styled(Container)`
  position: relative;
`;

export const StyledListHeader = styled(ListHeader)`
  align-items: center;
`;

export const StyledListHeaderTitle = styled(ListHeaderTitle)`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const StorageHeading = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const SelectAllSwitchWrapper = styled.div`
  margin-right: 8px;
  transform: translateY(4px);
`;

export const ButtonsWrapperInventory = styled.div`
  width: 100%;
  height: 80px;
`;

export const Buttons = styled.div`
  padding: 10px 30px 20px 30px;
  background-color: #fff;
  position: fixed;
  display: flex;
  left: 80px;
  bottom: 0;
  width: 100%;

  & > div {
    display: flex;
    margin: 0 10px;
  }

  & > button {
    margin: 0 10px;
    height: 41px;
    min-width: 150px;
  }

  &::before {
    content: "";
    position: fixed;
    box-shadow: 0 1px 38px 22px rgba(255, 255, 255, 0.95);
    background-color: rgba(255, 255, 255, 0.95);
    height: 72px;
    width: 100%;
    bottom: 0;
    left: 110px;
    z-index: -1;
  }

  ${(props) => props.theme.sm`
    left: 0;

    &::before {
      left: 0;
    }
  `}

  ${(props) => props.theme.xs`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    button {
      margin: 5px;
    }
  `}
`;

export const BackButtonInventory = styled(Button)`
  font-size: 18px;
  display: inline-flex;
  padding: 0;
  align-items: center;
  margin-right: 20px;

  ${(props) => props.theme.xs`
     margin-right: 8px;
`};

  &:hover {
    ${LeftArrowIcon} {
      opacity: 0.5;
    }
  }
`;

export const Message = styled.p`
  margin: 17px 0;
  font-size: 26px;
  font-weight: bold;
  color: ${(props) => props.theme.black};
`;

export const InventoryListWrapper = styled.table`
  width: 100%;
  padding-bottom: 20px;
  border-collapse: collapse;

  tr {
    width: 100%;
  }
`;
