import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { BooksSearchRecipientsProps } from "types/books";
import SearchInput from "../../../../components/SearchInput/SearchInput";

const BooksSearchRecipients: FC<BooksSearchRecipientsProps> = ({
  values,
  handleSubmit,
  setFieldValue,
  initialValues,
}) => {
  const { t } = useTranslation("common");

  return (
    <SearchInput
      name="address"
      value={values.address}
      placeholder={t("books.searchRecipients")}
      initialValue={initialValues.address}
      handleSubmit={handleSubmit}
      setFieldValue={setFieldValue}
    />
  );
};

export default BooksSearchRecipients;
