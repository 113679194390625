import PreLoader from "components/PreLoader/PreLoader";
import styled from "styled-components";

export const Spinner = styled(PreLoader)`
  position: absolute;
  top: 35%;
  width: 20px;
  height: 14px;
  transform-origin: 0 10px;
  transform: scale(0.5);
`;

export const AddressWrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
  text-align: left;
  position: relative;
  border-radius: 4px;
  width: 100%;
  max-width: 330px;
`;
