import React, { FC } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import injectReducer from "reduxFolder/injectReducer";
import booksReducer from "reduxFolder/reducers/books";
import { createStructuredSelector } from "reselect";
import { panelSelectOpened } from "../../reduxFolder/selectors/rightPanelSelectors";
import BooksAddFundsBlock from "./components/BooksAddFunds/BooksAddFundsBlock";
import { StyledGrid, Wrapper } from "./styles/BookRecipients.style";

interface BooksAddFundsProps {
  isPanelOpened: boolean;
}

const BooksAddFunds: FC<BooksAddFundsProps> = React.memo(
  ({ isPanelOpened }) => {
    return (
      <Wrapper>
        <StyledGrid fluid>
          <BooksAddFundsBlock isPanelOpened={isPanelOpened} />
        </StyledGrid>
      </Wrapper>
    );
  },
);

const withBooksReducer = injectReducer({
  key: "books",
  reducer: booksReducer,
});

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
  }),
  {},
);

const enhance = compose(withBooksReducer, withConnect);

export default enhance(BooksAddFunds);
