import React, { FC } from "react";
import ImageViewer2 from "components/ImageViewer2/ImageViewer2";
import { List } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { authSelectUnits } from "reduxFolder/selectors/globalSelectors";
import { historySelectFullDetailedConsolidation } from "reduxFolder/selectors/historySelector";
import { panelSelectData } from "reduxFolder/selectors/rightPanelSelectors";
import { createStructuredSelector } from "reselect";
import {
  InnerSection,
  SectionContent,
  SectionHeader,
} from "styles/outgoingStyles";
import formatter from "utils/formatter";
import {
  ImageSection,
  SKUTitle,
  StyledPackageSizes,
} from "../ParcelInfoTab.styles";
import ParcelInfoTabItemsList from "./ParcelInfoTabItemsList";

interface ParcelInfoTabPackageInfoProps {
  pkg: any;
  showMPSInfo: boolean;
  parcel: any;
  units: string;
  panelData: any;
}

const ParcelInfoTabPackageInfo: FC<ParcelInfoTabPackageInfoProps> = React.memo(
  ({ pkg, showMPSInfo, parcel, units, panelData }) => {
    const { t } = useTranslation("common");
    const pictures = pkg.get("pictures", List());
    const allItems = parcel.get("items", List());
    const items = allItems.filter(
      (item: any) =>
        item.getIn(["package", "sku"], parcel.get("sku")) === pkg.get("sku"),
    );
    const sku = pkg.get("sku");

    const weight = pkg.get("weight");
    const height = pkg.get("height");
    const width = pkg.get("width");
    const depth = pkg.get("depth");

    const excessItemsOriginal = panelData.get("excessItems");
    const excessItems =
      !!excessItemsOriginal &&
      excessItemsOriginal.map((item: any) => item.get("item"));

    const itemsUnitsTitle =
      items.count() === 1 ? t("parcels.item") : t("parcels.items");
    const itemsTitle = `${items.count()} ${itemsUnitsTitle}`;
    const weightTitle = `${formatter.weight(weight)} ${t(
      `units.${units}.primaryShort`,
    )}`;
    const itemsPerWeightTitle = `${itemsTitle} / ${weightTitle}`;

    const infoSection = (
      <>
        <SectionHeader>
          <SKUTitle>{sku}</SKUTitle>
        </SectionHeader>
        <SectionContent>{itemsPerWeightTitle}</SectionContent>
        <SectionContent>
          <StyledPackageSizes
            width={width}
            height={height}
            depth={depth}
            units={units}
          />
        </SectionContent>
      </>
    );

    return (
      <>
        {(showMPSInfo || !!pictures.size) && (
          <InnerSection>
            {showMPSInfo ? infoSection : null}
            <SectionContent>
              {!!pictures.size && (
                <ImageSection>
                  <ImageViewer2 images={pictures.toJS()} />
                </ImageSection>
              )}
            </SectionContent>
          </InnerSection>
        )}
        <ParcelInfoTabItemsList isExcessItems items={excessItems} />
        <ParcelInfoTabItemsList items={items} />
      </>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    parcel: historySelectFullDetailedConsolidation,
    units: authSelectUnits,
    panelData: panelSelectData,
  }),
  {},
);

export default withConnect(ParcelInfoTabPackageInfo);
