import { Icon } from "components/Icon";
import { TooltipBody, TooltipWrapper } from "components/Tooltip/Tooltip.styles";
import styled from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipContent = styled.div`
  padding: 20px;
  width: 100%;
  ${({ theme }) => theme.xs`
    padding: 20px 35px
  `}
`;

export const Overlay = styled.div`
  display: block;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: -100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
`;

interface SearchTooltipProps {
  $isMobile: boolean;
}

export const SearchTooltip = styled(TooltipWrapper)<SearchTooltipProps>`
  ${({ $isMobile }) =>
    $isMobile &&
    `top: -10px !important;
     position: fixed !important;
  
   .Popover-tipShape {
     display: none;
   }`}
`;

export const FilterTooltipBody = styled(TooltipBody)`
  padding: 0 0 20px 0;
  max-width: 75vw;
  width: 270px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  color: ${(props) => props.theme.black50};
  ${(props) => props.theme.xs`
    width: 100vw;
    max-width: 100vw;
  `}
`;

export const InnerTitle = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 15px 15px 20px 15px;
  color: ${(props) => props.theme.black};
  border-bottom: 1px solid ${(props) => props.theme.lightgrey};
`;

export const ResetFilter = styled.button`
  &:hover i {
    background-color: ${({ theme }) => theme.black};
  }
`;

interface FilterProps {
  $isOpen: boolean;
  $isActive: boolean;
  $activeFilterStyleOverrides?: string[];
}

export const Filter = styled.div<FilterProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  margin: 0 5px;
  color: ${({ theme }) => theme.black50};
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.primaryPale : theme.lightgrey03};
  &:hover {
    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.primaryPale : theme.lightgrey};
  }
  ${({ theme, $isOpen, $isActive }) =>
    $isOpen && !$isActive && `background-color: ${theme.lightgrey};`}
  ${ResetFilter} {
    display: ${({ $isActive }) => !$isActive && "none"};
  }
  ${(props) => props.theme.sm`
     margin: 5px;
  `}
  ${(props) => props.theme.xs`
    font-size : 13px;
  `}
  
  ${({ $isActive, $activeFilterStyleOverrides }) =>
    $isActive ? $activeFilterStyleOverrides : ""}
`;

export const ResetIcon = styled(Icon)`
  margin-left: 10px;
  width: 14px;
  height: 14px;
  transition: background-color 0.3s;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  transition: padding 0.2s ease-in-out;
  & > *:nth-child(n + 1) {
    margin-left: 5px;
  }
`;

export const Content = styled.div`
  flex: 1;
`;

export const CloseIcon = styled(Icon)`
  height: 12px;
  width: 12px;
  transition: background-color 0.3s;
`;

interface ButtonsWrapperProps {
  $isOpen: boolean;
}

export const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  ${({ theme, $isOpen }) =>
    $isOpen &&
    theme.sm`
    width: 100%;
    justify-content: flex-end;`}
`;
