import { fromJS, Map } from 'immutable';
import { combineActions, createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = fromJS({
  authenticated: null,
  impersonated: null,
  user: Map(),
  isLoading: false,
});

// const
export const LOG_IN = 'auth/LOG_IN';
export const LOG_OUT = 'auth/LOG_OUT';
export const SIGN_UP = 'auth/SIGN_UP';
export const GET_PROFILE = 'auth/GET_PROFILE';
export const SET_AUTHENTICATED = 'auth/SET_AUTHENTICATED';
export const SET_IMPERSONATED = 'auth/SET_IMPERSONATED';
export const REQUEST_RESET_PASSWORD = 'auth/REQUEST_RESET_PASSWORD';
export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD';
export const UPDATE_PROFILE = 'auth/UPDATE_PROFILE';

// action creators
export const signInRequest = createAction(LOG_IN, api.auth.signIn);
export const logOutRequest = createAction(LOG_OUT, api.auth.logOut);
export const signUpRequest = createAction(SIGN_UP, api.auth.signUp);
export const getProfile = createAction(GET_PROFILE, api.auth.getProfile);
export const requestPasswordReset = createAction(
  REQUEST_RESET_PASSWORD,
  api.auth.requestPasswordReset,
);
export const changePassword = createAction(
  CHANGE_PASSWORD,
  api.auth.changePassword,
);
export const setAuthenticated = createAction(SET_AUTHENTICATED);
export const setImpersonated = createAction(SET_IMPERSONATED);
export const updateProfile = createAction(
  UPDATE_PROFILE,
  api.auth.updateProfile,
);

// reducer
const authReducer = handleActions(
  {
    /* AUTHENTICATION */
    [combineActions(
      `${LOG_IN}_REQUEST`,
      `${GET_PROFILE}_REQUEST`,
      `${SIGN_UP}_REQUEST`,
      `${UPDATE_PROFILE}_REQUEST`,
    )]: (state) => state.set('isLoading', true),

    [combineActions(
      `${LOG_IN}_SUCCESS`,
      `${GET_PROFILE}_SUCCESS`,
      `${SIGN_UP}_SUCCESS`,
      `${UPDATE_PROFILE}_SUCCESS`,
    )]: (state, { payload }) => {
      const user = payload.getIn(['data', 'data'], payload.get('data'));

      return state
        .set('user', user)
        .set('authenticated', true)
        .set('isLoading', false);
    },

    [combineActions(
      `${GET_PROFILE}_ERROR`,
      `${LOG_IN}_ERROR`,
      `${SIGN_UP}_ERROR`,
      `${LOG_OUT}_SUCCESS`,
    )]: () => initialState,

    [combineActions(`${GET_PROFILE}_ERROR`, `${UPDATE_PROFILE}_ERROR`)]: (
      state,
    ) => state.set('isLoading', false),

    [SET_AUTHENTICATED]: (state, { payload }) =>
      state.set('authenticated', payload),
    [SET_IMPERSONATED]: (state, { payload }) =>
      state.set('impersonated', payload),
  },
  initialState,
);

export default authReducer;
