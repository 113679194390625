import Icon from "components/Icon/Icon";
import PreLoader from "components/PreLoader/PreLoader";
import styled from "styled-components";

export const Spinner = styled(PreLoader)`
  position: absolute;
  width: 20px;
  height: 14px;
  transform-origin: 0 10px;
  transform: scale(0.5);
`;

export const StyledForm = styled.form``;

export const HelpButton = styled.button`
  display: inline-flex;
  position: absolute;
  margin-left: 5px;
`;

export const HelpIcon = styled(Icon)`
  background-color: ${(props) => props.theme.primary};
  height: 16px;
  width: 16px;
  min-width: 16px;
`;
