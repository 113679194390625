import { Map } from 'immutable';
import { createSelectorCreator, lruMemoize } from 'reselect';

export const peek =
  (...path) =>
  (obj) => {
    if (!obj) return;

    return obj.getIn(path);
  };

export const peekOr = (path, def) => (obj) => {
  if (!obj) return;

  return obj.getIn(path, def);
};

const Selector = createSelectorCreator(lruMemoize, Map().is);

export function selector(...args) {
  return Selector(...args);
}
