import React, { FC, useMemo, useState } from "react";
import { FilterDate } from "components/Filters/FilterDate";
import { FilterText } from "components/Filters/FilterText";
import { SearchInput } from "components/SearchInput";
import { ButtonType, IconType } from "enums";
import { FormikProps } from "formik";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { BooksTransactionsHistorySearchValues } from "types/BooksForms/Search";
import {
  ButtonsWrapper,
  CloseButton,
  CloseIcon,
  FilterIcon,
  FiltersWrapper,
  MenuIcon,
  SearchFilterButton,
  SearchWrapper,
} from "./Search.styles";

interface SearchProps {
  formOptions: FormikProps<BooksTransactionsHistorySearchValues>;
  isPanelOpened: boolean;
}

const Search: FC<SearchProps> = React.memo(({ formOptions, isPanelOpened }) => {
  const { t } = useTranslation("common");
  const {
    values,
    initialValues,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    dirty,
  } = formOptions;
  const [isOpen, setOpened] = useState(false);

  const filterProps = useMemo(
    () => ({
      values,
      initialValues,
      handleSubmit,
      handleChange,
      setFieldValue,
    }),
    [values, initialValues, handleSubmit, handleChange, setFieldValue],
  );

  const toggleFilterMenu = async () => {
    if (isOpen && dirty) {
      setValues({ ...initialValues });
      await Promise.resolve();
      handleSubmit();
    }
    setOpened(!isOpen);
  };

  return (
    <>
      <SearchWrapper $isOpen={isOpen} $isPanelOpened={isPanelOpened}>
        <SearchInput
          name="description"
          value={values.description}
          initialValue={initialValues.description}
          placeholder={t("books.search.filterByDescription")}
          {...filterProps}
        />
        <ButtonsWrapper $isOpen={isOpen}>
          {isOpen && (
            <FiltersWrapper>
              {/* @ts-ignore */}
              <FilterDate {...formOptions} />
              {/* @ts-ignore */}
              <FilterText
                title={t("books.search.filterByAmount")}
                name="amount"
                {...formOptions}
              />
            </FiltersWrapper>
          )}
          {isOpen ? (
            <CloseButton
              title={t("common.close")}
              type={ButtonType.Button}
              onClick={toggleFilterMenu}
            >
              <CloseIcon type={IconType.Close} />
            </CloseButton>
          ) : (
            <SearchFilterButton onClick={toggleFilterMenu}>
              <Hidden sm>{t("parcels.search.tools")}</Hidden>
              <Visible sm>
                <FilterIcon type={IconType.Filter} />
              </Visible>
              <MenuIcon type={IconType.Menu} />
            </SearchFilterButton>
          )}
        </ButtonsWrapper>
      </SearchWrapper>
    </>
  );
});

export default Search;
