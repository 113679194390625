import { Map } from "immutable";
import { StorageSearchValues } from "types/StorageForms/Search";

const convertingToUTCDate = (date: Date) =>
  date &&
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

const getDateFilter = (value: any) => {
  switch (value) {
    case "today":
      return { "q[gt_days_period]": 1 };
    case "yesterday":
      return { "q[gt_days_period]": 2, "q[lt_days_period]": 1 };
    case "lastWeek":
      return { "q[gt_days_period]": 7 };
    case "lastMonth":
      return { "q[gt_months_period]": 1 };
    default:
      return typeof value === "object"
        ? {
            "q[date_received_at_gteq]": convertingToUTCDate(value.startDate),
            "q[date_received_at_lteq]": convertingToUTCDate(value.endDate),
          }
        : {};
  }
};

// 25kg = 55lbs
export const getMaxWeightValue = (units: string) => (units === "kg" ? 25 : 55);

export const initialValues = {
  weightRange: undefined,
  date: {},
  vendor: null,
  location: "",
  trackingNumber: "",
};

export const searchFormik = (onGetStorageSubmit: (values: any) => void) => ({
  initialValues,
  onSubmit: (values: StorageSearchValues) => {
    const { weightRange, date, vendor, location, trackingNumber } = values;
    const dateFilter = getDateFilter(date);

    if (
      trackingNumber &&
      (trackingNumber.startsWith(">") || trackingNumber.startsWith(">>"))
    ) {
      const isContains = !trackingNumber.startsWith(">>");
      const itemsSearchTerm = trackingNumber.slice(isContains ? 1 : 2);
      const filterKey = isContains
        ? "q[descriptions_or_customer_comment_cont]"
        : "q[descriptions_or_customer_comment_eq]";

      return onGetStorageSubmit(
        Map({
          page: 1,
          filter: {
            [filterKey]: itemsSearchTerm ?? null,
            "q[weight_gteq]": weightRange && weightRange[0],
            "q[weight_lteq]": weightRange && weightRange[1],
            "q[vendor_eq]": vendor && vendor.value,
            "q[location_in]": location || null,
            "q[has_inventory]": true,
            ...dateFilter,
          },
        }),
      );
    } else {
      return onGetStorageSubmit(
        Map({
          page: 1,
          filter: {
            "q[descriptions_or_customer_comment_cont]": trackingNumber || null,
            "q[weight_gteq]": weightRange && weightRange[0],
            "q[weight_lteq]": weightRange && weightRange[1],
            "q[vendor_eq]": vendor && vendor.value,
            "q[location_in]": location || null,
            ...dateFilter,
          },
        }),
      );
    }
  },

  displayName: "StorageSearch",
});
