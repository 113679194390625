import React, { FC, memo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import bagIcon from "../../components/Icon/icons/package_type_bag.gif";
import boxIcon from "../../components/Icon/icons/package_type_box.gif";
import Tooltip from "../Tooltip/Tooltip";
import { Icon, Wrapper } from "./PackageTypeIcon.styles";

interface PackageTypeIconProps {
  type: string;
}

enum PackageType {
  Box = "box",
  Bag = "bag",
}

const PackageTypeIcon: FC<PackageTypeIconProps> = ({ type }) => {
  const { t } = useTranslation("common");

  const tooltipBody = ((type) => {
    switch (type) {
      case PackageType.Box:
      default:
        return t("tooltips.shipmentIsBox");
      case PackageType.Bag:
        return t("tooltips.shipmentIsBag");
    }
  })(type);

  const icon = ((type) => {
    switch (type) {
      case PackageType.Box:
      default:
        return boxIcon;
      case PackageType.Bag:
        return bagIcon;
    }
  })(type);

  return (
    <Tooltip body={tooltipBody} place="bottom" openOnClick={isMobile}>
      <Wrapper>
        <Icon icon={icon} />
      </Wrapper>
    </Tooltip>
  );
};

export default memo(PackageTypeIcon);
