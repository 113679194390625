import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import * as locales from "date-fns/locale";
import { IconType } from "enums/Icon";
import { InputIconColor } from "enums/Input";
import { ReactDatePickerProps, registerLocale } from "react-datepicker";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { LabelText } from "../../styles/formStyles";
import Input from "../Input/Input";
import "react-datepicker/dist/react-datepicker.css";
import {
  CustomTooltip,
  DatePickerField,
  DatePickerLabel,
  IconWrapper,
  InputIcon,
  TooltipWrapper,
} from "./DatePicker.styles";

interface DatePickerProps extends ReactDatePickerProps {
  iconColor?: InputIconColor;
  iconType?: IconType;
  tooltip?: string;
  disabled?: boolean;
  invalid?: boolean;
  placeholderText?: string;
  className?: string;
  label?: string;
  datePickerRef?: React.RefObject<HTMLInputElement>;
}

const DatePicker: FC<DatePickerProps> = ({
  className = "",
  label,
  placeholderText,
  value,
  invalid,
  onChange,
  tooltip,
  iconType,
  iconColor,
  datePickerRef,
  ...otherProps
}) => {
  const {
    i18n: { language },
  } = useTranslation("common");
  const popperModifiers = useMemo(
    () => ({
      offset: {
        enabled: true,
      },
      preventOverflow: {
        enabled: true,
        escapeWithReference: false,
        boundariesElement: "viewport",
      },
    }),
    [],
  );

  const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.value) return;
    const value: string = event.target.value;

    return onChange(new Date(value), event);
  };

  useEffect(() => {
    registerLocale(language, locales[language as keyof typeof locales]);
  }, [language]);

  const rendererIcon = useCallback(
    () =>
      tooltip && (
        <TooltipWrapper>
          <CustomTooltip body={tooltip} place="right">
            <IconWrapper>
              <InputIcon type={IconType.Wrong} color={InputIconColor.Red} />
            </IconWrapper>
          </CustomTooltip>
        </TooltipWrapper>
      ),
    [invalid, tooltip, iconType, iconColor],
  );

  return (
    <>
      {label && (
        <DatePickerLabel invalid={invalid} className={className}>
          {label && <LabelText>{label}</LabelText>}
        </DatePickerLabel>
      )}
      {isMobile ? (
        <Input
          type="date"
          onChange={handleMobileChange}
          placeholder={placeholderText}
          value={value}
          invalid={invalid}
          tooltip={tooltip}
          iconType={iconType}
          iconColor={iconColor}
        />
      ) : (
        <>
          {/* @ts-ignore */}
          <DatePickerField
            onChange={onChange}
            placeholderText={placeholderText}
            locale={language}
            selected={value ?? null}
            $invalid={invalid}
            popperPlacement="top-end"
            {...otherProps}
            popperModifiers={[
              {
                popperModifiers,
              },
            ]}
            ref={datePickerRef}
          />
          {rendererIcon()}
        </>
      )}
    </>
  );
};

export default memo(DatePicker);
