import PreLoader from "components/PreLoader/PreLoader";
import styled from "styled-components";

export const Spinner = styled(PreLoader)`
  position: absolute;
  top: 100px;
`;

export const FormWrapper = styled.form`
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 22px;

    ${({ theme }) => theme.xs`
      margin-bottom: 10px;
    `}
  }
`;

export const Content = styled.div`
  height: 100%;

  ${({ theme }) =>
    theme.range(
      "padding",
      "15px",
      "22px",
      `${theme.breakpoints.md}px`,
      `${theme.breakpoints.lg}px`,
    )};
`;

export const ButtonsWrapper = styled.div`
  align-self: center;
  padding: 15px 0;

  button {
    margin: 5px;
  }
`;

export const InfoText = styled.p`
  margin: 6px 0;
`;

export const InfoWrapper = styled.div`
  min-height: max-content;
  display: flex;
  justify-content: center;
  padding: 8px 18px;
  margin: 22px auto;
  background-color: #fdf3cd;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  color: #856404;

  i {
    width: 22px;
    height: 22px;
    margin: 0 12px 0 0;
  }
`;

export const CustomSenderErrorMsg = styled.div`
  color: ${({ theme }) => theme.red};
  font-size: 14px;
`;
