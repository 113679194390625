import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  HTMLProps,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from "react";
import { IconType } from "enums/Icon";
import { InputIconColor, InputType } from "enums/Input";
import {
  CloseIcon,
  CloseIconWrapper,
  CustomTooltip,
  EndAdornmentWrapper,
  IconWrapper,
  InputField,
  InputIcon,
  InputWrapper,
  Label,
  LabelText,
  Spinner,
  TooltipWrapper,
} from "./Input.styles";

export interface InputProps extends HTMLProps<HTMLInputElement> {
  iconType?: IconType;
  iconColor?: InputIconColor;
  showClearValueButton?: boolean;
  endAdornment?: ReactElement;
  inputRef?: React.RefObject<HTMLInputElement>;
  flatStyle?: boolean;
  isLoading?: boolean;
  tooltip?: string;
  invalid?: boolean | "";
  mask?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = React.memo(
  ({
    tooltip,
    invalid = false,
    iconType,
    iconColor,
    label,
    onFocus,
    isLoading,
    className = "",
    flatStyle = false,
    height = 39,
    endAdornment,
    showClearValueButton: showClearValueButtonProp = false,
    value,
    inputRef: inputRefProp,
    type = InputType.Text,
    name = "",
    ...otherProps
  }) => {
    const inputRefLocal = useRef<HTMLInputElement>(null);
    const inputRef = inputRefProp ? inputRefProp : inputRefLocal;
    const withIcon = !!(iconType || invalid);
    const showClearValueButton = showClearValueButtonProp && value;
    const showEndAdornment = endAdornment || showClearValueButton;

    const handleFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        onFocus && onFocus(event);
      },
      [onFocus],
    );

    const rendererIcon = useCallback(
      () =>
        tooltip && (
          <TooltipWrapper height={+height}>
            <CustomTooltip body={tooltip} openOnClick place="right">
              <IconWrapper height={+height}>
                <InputIcon
                  type={invalid ? IconType.Wrong : (iconType as IconType)}
                  color={
                    invalid ? InputIconColor.Red : (iconColor as InputIconColor)
                  }
                />
              </IconWrapper>
            </CustomTooltip>
          </TooltipWrapper>
        ),
      [invalid, tooltip, iconType, iconColor, height],
    );

    const handleClearValue = () => {
      const inputElement = inputRef.current;
      if (inputElement) {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          "value",
        )?.set;
        if (nativeInputValueSetter) {
          nativeInputValueSetter.call(inputElement, "");

          const inputEvent = new Event("input", { bubbles: true });
          inputElement.dispatchEvent(inputEvent);
        }
      }
    };

    const renderClearValueButton = () => {
      return (
        <CloseIconWrapper onClick={handleClearValue}>
          <CloseIcon type={IconType.Close} color="red" />
        </CloseIconWrapper>
      );
    };

    return (
      <Label $invalid={!!invalid} className={className}>
        {label && <LabelText>{label}</LabelText>}
        <InputWrapper>
          <InputField
            autoComplete="off"
            onFocus={handleFocus}
            $invalid={!!invalid}
            $withIcon={withIcon}
            $flatStyle={flatStyle}
            height={height}
            value={value}
            ref={inputRef}
            type={type}
            name={name}
            {...otherProps}
          />
          {withIcon && rendererIcon()}
          {isLoading && <Spinner height={+height} isActive={!!isLoading} />}
          {showEndAdornment && (
            <EndAdornmentWrapper>
              {showClearValueButton && renderClearValueButton()}
              {endAdornment}
            </EndAdornmentWrapper>
          )}
        </InputWrapper>
      </Label>
    );
  },
);

export default Input;
