import React, { FC, useEffect, useState } from "react";
import { IconType } from "enums/Icon";
import styled from "styled-components";

export interface IconProps {
  type?: IconType;
  color?: string;
  className?: string;
}

const getIcon = async (type: string) => {
  if (!type) return;

  return (await import(`./icons/${type}.svg`)).default;
};

const IconWrapper = styled.i<{
  $iconUrl: string;
  color?: string;
  className?: string;
}>`
  display: block;
  height: 20px;
  width: 20px;
  background-color: ${({ color, theme }) => color || theme.grey};
  -webkit-mask: url(${({ $iconUrl }) => $iconUrl}) center/cover no-repeat;
  mask: url(${({ $iconUrl }) => $iconUrl}) center/cover no-repeat;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transition:
    -webkit-mask 0.3s ease,
    mask 0.3s ease,
    background-color 0.3s ease;

  @supports not (
    -webkit-mask: url(${({ $iconUrl }) => $iconUrl}) center/cover no-repeat
  ) {
    background-image: url(${({ $iconUrl }) => $iconUrl});
    background-size: cover;
  }
`;

const Icon: React.FC<IconProps> = ({ type, color, className = "" }) => {
  const [iconUrl, setIconUrl] = useState<string>("");

  useEffect(() => {
    const loadIcon = async () => {
      const url = await getIcon(type ?? "");
      setIconUrl(url);
    };
    loadIcon();
  }, [type]);

  return (
    <IconWrapper
      $iconUrl={iconUrl}
      color={color}
      className={className + " icon"}
    />
  );
};

export default Icon;

interface RasterIcon {
  icon: string;
  size?: number[];
}

export const RasterIcon = styled.div<RasterIcon>`
  background-image: url(${(props) => props.icon});
  background-size: cover;
  width: ${(props) => `${props.size?.[0] ?? 22}px`};
  height: ${(props) => `${props.size?.[1] ?? 22}px`};
`;

interface IconPngProps {
  type: string;
  height?: string;
  width?: string;
  className?: string;
}

export const IconPng: FC<IconPngProps> = ({
  type,
  height = "24",
  width = "24",
  className = "",
}) => (
  <StyledPngImg
    src={require(`./icons/${type}.png`)}
    alt=""
    height={height}
    width={width}
    className={className}
  />
);

// regular svg icon is 18px without padding, so we shift (24 - 18) / 2 -> 3px to left and right
const StyledPngImg = styled.img`
  margin-left: -3px;
  margin-right: 3px; // right margin for svg is 6px and because we are shifted by 3px already we add just 3px
`;
