import React, { FC, useCallback } from "react";
import {
  Content,
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonVariant } from "enums/Button";
import { List, Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { outgoingSelectDetailedItems } from "../../../../reduxFolder/selectors/outgoingSelectors";
import { PayButton as Button } from "../../../../styles/outgoingStyles";
import formatter from "../../../../utils/formatter";
import {
  CenterBlock,
  HeaderDate,
  Placeholder,
  Text,
  TextTotalPrice,
  Wrapper,
} from "./PaymentDetailsConfirmationPanel.styles";

interface PaymentDetailsConfirmationPanelProps {
  detailedItem: any;
  panelData: any;
  openRightPanel: OpenRightPanel;
}

const PaymentDetailsConfirmationPanel: FC<PaymentDetailsConfirmationPanelProps> =
  React.memo(({ panelData, detailedItem, openRightPanel }) => {
    const { t } = useTranslation("common");
    const id = panelData.get("detailedItemID");
    const detailed = detailedItem.get(id, Map());
    const declarations = detailed.get("customs_declarations", List());

    const getTotal = (nameProp: string) =>
      declarations.reduce(
        (sum: number, information: any) => sum + information.get(nameProp),
        0,
      );
    const totalPrice = formatter.currency(getTotal("value"));

    const sku = detailed.get("sku");
    const createdAt = new Date(detailed.get("created_at"));
    const createdAtStr = formatter.date_ddmmyyyy(createdAt);

    const address = detailed.get("address_1");
    const fullName = [
      detailed.get("first_name"),
      detailed.get("last_name"),
    ].join(" ");

    const handleBack = useCallback(() => {
      openRightPanel(panelData.get("returnPreviousPanel"), panelData);
    }, [openRightPanel, panelData]);

    const handleConfirm = useCallback(() => {
      openRightPanel("REVIEW_AND_PAY", panelData);
    }, [openRightPanel, panelData]);

    return (
      <ContentWrapper>
        <Heading>
          {sku}
          <HeaderDate>{`${t("common.from")} ${createdAtStr}`}</HeaderDate>
        </Heading>
        <Content>
          <CenterBlock>
            <Wrapper>
              <Text>{t("paymentInfoConfirmation.confirmationTitle")}</Text>
              <TextTotalPrice>
                <span>{`$${totalPrice}`}</span>
              </TextTotalPrice>
              <Placeholder>
                {t("paymentInfoConfirmation.confirmationHint")}
              </Placeholder>
              <Button
                color={ButtonColor.Red}
                variant={ButtonVariant.Bordered}
                onClick={handleBack}
              >
                {t("paymentInfoConfirmation.cancel")}
              </Button>
              <Button
                color={ButtonColor.Primary}
                variant={ButtonVariant.Bordered}
                onClick={handleConfirm}
              >
                {t("paymentInfoConfirmation.confirm")}
              </Button>
            </Wrapper>
          </CenterBlock>
        </Content>
      </ContentWrapper>
    );
  });

const withConnect = connect(
  createStructuredSelector({
    detailedItem: outgoingSelectDetailedItems,
  }),
  {
    openRightPanel,
  },
);

export default withConnect(PaymentDetailsConfirmationPanel);
