import React, { FC, useEffect, useRef } from "react";
import Icon from "components/Icon/Icon";
import ImageViewer2 from "components/ImageViewer2/ImageViewer2";
import PackagesList from "components/PackagesList/PackagesList";
import { IconType } from "enums/Icon";
import { List, Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getDetailedShipment } from "reduxFolder/reducers/historyReducer";
import { openRightPanel } from "reduxFolder/reducers/rightPanel";
import { historySelectFullDetailedConsolidation } from "reduxFolder/selectors/historySelector";
import { panelSelectData } from "reduxFolder/selectors/rightPanelSelectors";
import { createStructuredSelector } from "reselect";
import {
  HeaderTitle,
  InnerSection,
  Section,
  SectionContent,
  SectionHeader,
} from "styles/outgoingStyles";
import { OpenRightPanel } from "types/common/rightPanel";
import { ImageSection } from "../ParcelInfoTab.styles";

interface ParcelInfoTabStandardPackageSectionProps {
  parcel: any;
  panelData: any;
  openRightPanel: OpenRightPanel;
  getDetailedShipment: (id: number) => Promise<void>;
}

const ParcelInfoTabStandardPackageSection: FC<ParcelInfoTabStandardPackageSectionProps> =
  React.memo(({ parcel, panelData, openRightPanel, getDetailedShipment }) => {
    const { t } = useTranslation("common");
    const foundPackageId = panelData.get("foundPackageId", 0);
    const pictures = parcel.get("pictures", List());
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (contentRef.current && foundPackageId)
        contentRef.current.scrollIntoView();
    }, [contentRef.current, foundPackageId]);

    const clickPackagesItem = (item: any) => {
      openRightPanel(
        "STORAGE_ITEM",
        panelData.merge(
          Map({
            readOnly: true,
            id: item.get("shipment_id"),
            originalItem: item,
            consolidationId: parcel.get("id"),
            consolidationSku: parcel.get("sku"),
            emblem_thumb_url: item.get("emblem_thumb_url"),
            description: item.get("shipment_tracking_number"),
            returnPreviousPanel: "PARCEL_DETAILED",
            rootReturnPanel: "WEEK_ITEM",
            getShipment: getDetailedShipment,
            shippingMethod: panelData.get("shippingMethod"),
            updateComment: () => {},
          }),
        ),
      );
    };

    return (
      <Section>
        <HeaderTitle>
          <Icon type={IconType.Package} /> {t("parcels.parcelInformation")}:
        </HeaderTitle>
        {!!pictures.size && (
          <InnerSection>
            <SectionContent>
              <ImageSection>
                <ImageViewer2 images={pictures.toJS()} />
              </ImageSection>
            </SectionContent>
          </InnerSection>
        )}
        <InnerSection ref={contentRef}>
          <SectionHeader>
            <HeaderTitle>
              {`${t("parcels.packagesIncluded")} (${
                (!!parcel.get("items") && parcel.get("items").count()) || 0
              }):`}
            </HeaderTitle>
          </SectionHeader>
          <PackagesList
            extended
            items={parcel.get("items")}
            foundItemId={foundPackageId}
            handleClick={clickPackagesItem}
          />
        </InnerSection>
      </Section>
    );
  });

const withConnect = connect(
  createStructuredSelector({
    parcel: historySelectFullDetailedConsolidation,
    panelData: panelSelectData,
  }),
  { openRightPanel, getDetailedShipment },
);

export default withConnect(ParcelInfoTabStandardPackageSection);
