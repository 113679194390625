import React, { FC, useCallback } from "react";
import {
  ButtonsWrapper,
  Content,
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonType, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { useFormik } from "formik";
import { List, Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { PackOptionsProps } from "types/StorageForms/PackOptions";
import AddonsList from "../../../../components/AddonsList/AddonsList";
import Button from "../../../../components/Button/Button";
import Icon from "../../../../components/Icon/Icon";
import PriceBlock from "../../../../components/PriceBlock/PriceBlock";
import { getAddonsCost } from "../../../../components/TotalCostHint/totalCostHelper";
import useFetchOnceEffect from "../../../../hooks/useFetchOnceEffect";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  getAddonsMethods,
  getConsolidationAddonsMethods,
} from "../../../../reduxFolder/reducers/services";
import { updateAddons } from "../../../../reduxFolder/reducers/storage";
import { outgoingSelectDetailedShipment } from "../../../../reduxFolder/selectors/outgoingSelectors";
import {
  servicesSelectAddons,
  servicesSelectAddonsForInventory,
  servicesSelectConsolidationAddons,
  servicesSelectLoading,
} from "../../../../reduxFolder/selectors/servicesSelectors";
import { storageSelectDetailedShipment } from "../../../../reduxFolder/selectors/storageSelectors";
import {
  Paragraph,
  PriceWrapper,
} from "../../../../styles/serviceRequestStyles";
import { packOptionsFormik } from "./helperPackOptions";
import {
  AdditionalTitle,
  AddonsForm,
  HelpButton,
  HelpIcon,
  Spinner,
  Title,
} from "./PackOptions.styles";

const PackOptions: FC<PackOptionsProps> = React.memo(
  ({
    addons: addonsProp,
    inventoryAddons: inventoryAddonsProp,
    panelData,
    detailedShipment,
    outgoingDetailedShipment,
    openRightPanel,
    getAddonsMethods,
    getConsolidationAddonsMethods,
    consolidationAddons,
    isLoading,
    updateAddons,
  }) => {
    const { t } = useTranslation("common");
    const { values, isSubmitting, setFieldValue, handleSubmit } = useFormik(
      packOptionsFormik(
        t,
        openRightPanel,
        detailedShipment,
        outgoingDetailedShipment,
        panelData,
        updateAddons,
      ),
    );

    useFetchOnceEffect(!addonsProp.size, getAddonsMethods);
    useFetchOnceEffect(
      !consolidationAddons.size,
      getConsolidationAddonsMethods,
    );

    const useInventoryAddons = panelData.get("useInventoryAddons");
    const addons = useInventoryAddons ? inventoryAddonsProp : addonsProp;
    const returnScreen = panelData.get("returnScreen");
    const handleCancel = useCallback(() => {
      openRightPanel(returnScreen, panelData);
    }, [openRightPanel, panelData, returnScreen]);

    const id = panelData.get("id");
    const readOnlyProp = panelData.get("readOnly");
    const overrideAndEnablePackOptions = panelData.get(
      "overrideAndEnablePackOptions",
    );
    const readOnly = readOnlyProp && !overrideAndEnablePackOptions;
    const detailedItem =
      detailedShipment.get(id) || outgoingDetailedShipment.get(id);
    const items = List([detailedItem]);
    const handleSelect = useCallback(
      (addonId: string) => () => {
        const hasAddon = values[id].includes(addonId);
        const value = hasAddon
          ? [...values[id].filter((item: any) => item !== addonId)]
          : [...values[id], addonId];
        setFieldValue(id, value);
      },
      [id, values, setFieldValue],
    );

    const cost = getAddonsCost(List([Map({ addons: values[id] })]), addons);

    const handleHelpClick = useCallback(
      () =>
        openRightPanel(
          "HELP_PANEL",
          panelData
            .set("preventReLoading", true)
            .set("readOnly", readOnlyProp)
            .set(
              "headerTitle",
              `${t("serviceRequests.packOptions.title")}: FAQ`,
            )
            .set("showHelpersCode", "single_options"),
        ),
      [panelData, openRightPanel],
    );

    return (
      <ContentWrapper>
        <Heading $isBordered>
          <button onClick={handleCancel}>
            <Icon type={IconType.Arrow} />
          </button>
          <Title>{t("serviceRequests.packOptions.title")}</Title>
        </Heading>
        <Content>
          <AddonsForm onSubmit={handleSubmit}>
            <Spinner isActive={isLoading} />
            <div>
              <Paragraph>
                {t(`serviceRequests.packOptions.paragraph`)}
                <HelpButton onClick={handleHelpClick}>
                  <HelpIcon type={IconType.Help} />
                </HelpButton>
              </Paragraph>
              <AdditionalTitle>
                {t(`serviceRequests.packOptions.heading`)}:
              </AdditionalTitle>
              <AddonsList
                addons={addons}
                items={items}
                values={values}
                readOnly={readOnly}
                handleSelect={handleSelect}
              />
              <PriceWrapper>
                <PriceBlock
                  price={cost}
                  title={t(`serviceRequests.packOptions.costHeading`)}
                />
              </PriceWrapper>
            </div>
            <ButtonsWrapper>
              <Button onClick={handleCancel}>{t("common.cancel")}</Button>
              {!readOnly && (
                <Button
                  type={ButtonType.Submit}
                  color={ButtonColor.Primary}
                  variant={ButtonVariant.Filled}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {t(`serviceRequests.packOptions.submit`)}
                </Button>
              )}
            </ButtonsWrapper>
          </AddonsForm>
        </Content>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    addons: servicesSelectAddons,
    inventoryAddons: servicesSelectAddonsForInventory,
    detailedShipment: storageSelectDetailedShipment,
    outgoingDetailedShipment: outgoingSelectDetailedShipment,
    isLoading: servicesSelectLoading,
    consolidationAddons: servicesSelectConsolidationAddons,
  }),
  {
    updateAddons,
    openRightPanel,
    getAddonsMethods,
    getConsolidationAddonsMethods,
  },
);

export default withConnect(PackOptions);
