import React, { FC, memo } from "react";
import PackageIcon from "components/Icon/icons/package.svg";
import { format } from "date-fns";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ParcelLogo } from "../../../../styles/parcelsStyles";
import { getFnsLocale } from "../../../../translations/date-fns-locale";
import { useFormattedDate } from "../../../../utils/formatDate";
import {
  Comment,
  Description,
  ExpectedItemRowWrapper,
  LogoWrapper,
  MainStorageCol,
  StorageCol,
  StorageRow,
} from "./ExpectedList.styles";

interface ExpectedItemRowProps {
  parcel: any;
  selectedParcel: string | null;
  disabled: boolean;
  onFocusExpectedItemInPanel: (parcel: any) => void;
  shops: any;
  isPanelOpened: boolean;
}

const ExpectedItemRow: FC<ExpectedItemRowProps> = ({
  parcel,
  selectedParcel,
  onFocusExpectedItemInPanel,
  disabled,
  shops,
  isPanelOpened,
}) => {
  const isSelectedShipment =
    (isMobile || isPanelOpened) && selectedParcel === parcel.get("id");
  const trackingNumber = parcel.get("tracking_number");
  const orderNumber = parcel.get("order_number");
  const customerComment = parcel.get("customer_comment");
  const storeName = parcel.get("store_name");
  const createdAtDate = parcel.get("created_at");

  const shop = shops.find((x: any) => x.get("name") === storeName);
  const logoUrl = shop && shop.get("thumb_image_url");

  const created = useFormattedDate({
    date: createdAtDate,
  });

  return (
    <ExpectedItemRowWrapper disabled={disabled}>
      <StorageRow
        align="center"
        selected={isSelectedShipment}
        onClick={() => onFocusExpectedItemInPanel(parcel)}
      >
        <MainStorageCol sm={12}>
          <StorageCol xs={6} md={4}>
            <LogoWrapper>
              <ParcelLogo image={logoUrl || PackageIcon} />
            </LogoWrapper>
            <Description>
              {trackingNumber}
              {customerComment && <Comment>{customerComment}</Comment>}
            </Description>
          </StorageCol>
          <StorageCol xs={3} md={2} $isTruncate>
            {orderNumber}
          </StorageCol>
          <StorageCol xs={3} md={6}>
            {created}
          </StorageCol>
        </MainStorageCol>
      </StorageRow>
    </ExpectedItemRowWrapper>
  );
};

export default memo(ExpectedItemRow);
