import styled from "styled-components";
import Icon from "../Icon/Icon";

export const PriceWrapper = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  padding: 15px 15px 10px;
  position: relative;

  .icon {
    margin: 5px;
    width: 12px;
    height: 12px;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    background: url(${require("../../containers/Delivery/images/separation-line.png")})
      repeat-x;
  }
`;

export const CostWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 0 2px;
  display: flex;
  white-space: nowrap;
  align-items: baseline;
  &:last-child {
    margin: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.black50};
  font-size: 14px;
  margin: 0 0 5px;
`;

export const CostContent = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.black};
  font-weight: bold;
  margin-left: 5px;
`;
