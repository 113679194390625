import React, { FC, memo } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import styled from "styled-components";

interface LinkProps extends RouterLinkProps {
  color?: LinkColor;
}

export enum LinkColor {
  Primary = "primary",
  Secondary = "secondary",
  DarkGrey = "darkGrey",
  Red = "red",
}

const Link: FC<LinkProps> = ({ color = LinkColor.DarkGrey, ...otherProps }) => (
  <LinkWrapper color={color} {...otherProps} />
);

export default memo(Link);

interface LinkWrapperProps {
  color: LinkColor;
}

const LinkWrapper = styled(RouterLink)<LinkWrapperProps>`
  color: ${({ theme, color }) => theme[color]};
  transition: color 0.2s;

  &:active,
  &:hover,
  &:visited {
    color: ${({ theme, color }) => theme[`${color}05`]};
  }
`;
