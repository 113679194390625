import React from 'react';
import { List } from 'immutable';

export const getAddonDescription = (id, addons) => {
  const currentAddon = addons.find(
    (addon) => addon.get('id') === id || addon.get('code') === id,
  );

  return currentAddon ? currentAddon.get('description') : null;
};

export const getRedirectPath = (link, location) => {
  const isNextStep =
    !location.state || location.pathname === location.state.prevPath;
  const redirectPath = isNextStep ? link : location.state.prevPath;

  return redirectPath;
};

export const getLinkToStep = (access) => {
  if (access.get('summary')) return '/shipping/summary';
  else if (access.get('customInformation'))
    return '/shipping/flow/customs-data';
  else if (access.get('addons')) return '/shipping/flow/delivery/additional';
  else if (access.get('packing')) return '/shipping/flow/delivery/packing';
  else if (access.get('delivery')) return '/shipping/flow/delivery';
  else return '/shipping/flow';
};

export const getAllItemsAddons = (addons, items, values = []) => {
  const allItemsAddons = [];

  addons &&
    addons.forEach((addon) => {
      let isAllHave = true;

      items.forEach((item) => {
        const itemAddon = values[item.get('id')] || item.get('addons');

        if (!(itemAddon && itemAddon.includes(addon.get('id'))))
          isAllHave = false;
      });

      if (isAllHave) allItemsAddons.push(addon.get('id'));
    });

  return allItemsAddons.length ? allItemsAddons : null;
};

export const getConsolidationAddonsIconsImage = (ids, addons, size = 18) => {
  return getAddonsIconsImage(ids, addons, size, (addon) => addon.get('code'));
};

export const getAddonsIconsImage = (
  ids,
  addons,
  size = 18,
  propGetter = (addon) => addon.get('id'),
) => {
  if (
    !ids ||
    (ids.length && ids.length <= 0) ||
    (ids.count && ids.count() <= 0)
  ) {
    return null;
  }

  const iconUrls = List()
    .concat(ids)
    .map((id) => {
      const foundAddon = addons.find((addon) => id === propGetter(addon));
      return foundAddon ? foundAddon.get('icon_url') : null;
    });

  if (List.isList(iconUrls) || Array.isArray(iconUrls)) {
    return (
      <>
        {iconUrls.map((iconUrl, index) => (
          <img
            key={`${iconUrl}${index}`}
            src={iconUrl}
            height={size}
            width={size}
            alt=""
          />
        ))}
      </>
    );
  } else {
    return <img src={iconUrls} alt="" />;
  }
};

export const getAddonPrice = (id, addons) => {
  const currentAddon = addons.find(
    (addon) => addon.get('id') === id || addon.get('code') === id,
  );

  return currentAddon ? currentAddon.get('price') : null;
};
