import { FormikHelpers } from "formik";
import { OpenRightPanel } from "types/common/rightPanel";
import { DeliveryMethodsFormValues } from "types/OutgoingForms/deliveryMethods";
import { toastResponseError } from "utils/responseMessageHelper";
import * as yup from "yup";

export const deliveryMethodsFormik = (
  panelData: any,
  updateDeliveryMethod: (
    consolidationId: string,
    data: {
      preferred_carrier: string;
    },
  ) => Promise<any>,
  setCartDeliveryMethod: (data: { preferred_carrier: string }) => Promise<any>,
  openRightPanel: OpenRightPanel,
) => ({
  initialValues: {
    deliveryName: panelData.get("deliveryMethod"),
  },

  validationSchema: () =>
    yup.object().shape({
      deliveryName: yup.string().required(),
    }),

  onSubmit: (
    values: DeliveryMethodsFormValues,
    { setSubmitting }: FormikHelpers<DeliveryMethodsFormValues>,
  ) => {
    const isCartVariant = panelData.get("isCartVariant");
    const consolidationId = panelData.get("detailedItemID");

    const updateMethod = isCartVariant
      ? setCartDeliveryMethod({
          preferred_carrier: values.deliveryName,
        })
      : updateDeliveryMethod(consolidationId, {
          preferred_carrier: values.deliveryName,
        });

    updateMethod
      .then(() => {
        if (panelData.get("setAccess")) {
          panelData.get("setAccess")();
        }
        openRightPanel(panelData.get("returnPreviousPanel"), panelData);
      })
      .catch((response: Error) => toastResponseError(response))
      .finally(() => setSubmitting(false));
  },
});
