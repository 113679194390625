import React, { FC, useCallback } from "react";
import AddressesList from "components/Addresses/components/AddressesList";
import AddressSearch from "components/Addresses/components/AddressSearch";
import Button from "components/Button/Button";
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "enums/Button";
import { useFormik } from "formik";
import { ProgressDeliverySetAccessFunc } from "hooks/useProgressDelivery";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAddress } from "reduxFolder/reducers/cart";
import { getDeliveryMethods } from "reduxFolder/reducers/services";
import {
  cartSelectDestination,
  cartSelectDestinationId,
} from "reduxFolder/selectors/cartSelectors";
import { destinationsSelectAddresses } from "reduxFolder/selectors/destinationSelectors";
import { createStructuredSelector } from "reselect";
import {
  DeliveryShippingMethodsResponseDto,
  SetAddressDto,
} from "types/api/cart";
import { toastResponseError } from "utils/responseMessageHelper";
import * as yup from "yup";
import {
  BlockContent,
  Content,
  DeliveryBlock,
  Heading,
  SubmitWrapper,
} from "../../styles/Delivery.styles";
import ScrollableForm from "../ScrollableForm";
import { AddButton, Form } from "./ChooseForm.styles";

interface ChooseAddressFormProps {
  destinationId: number;
  setAccess: ProgressDeliverySetAccessFunc;
  setAddress: (data: SetAddressDto) => Promise<void>;
  cartDestination: any;
  destinations: any;
  getDeliveryMethods: () => Promise<DeliveryShippingMethodsResponseDto[]>;
}

const ChooseAddressForm: FC<ChooseAddressFormProps> = React.memo(
  ({
    destinationId,
    setAccess,
    setAddress,
    destinations,
    cartDestination,
    getDeliveryMethods,
  }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const {
      handleSubmit,
      setFieldValue,
      isSubmitting,
      values: { addressId },
    } = useFormik({
      initialValues: {
        addressId: destinationId,
      },
      validationSchema: () =>
        yup.object().shape({
          addressId: yup.number().required(),
        }),
      onSubmit: (values) => {
        const selectedAddressCountry = destinations
          .find(
            (destination: any) => destination.get("id") === values.addressId,
          )
          .get("country");
        const shouldSubmit =
          values.addressId !== destinationId ||
          selectedAddressCountry !== cartDestination.get("country");

        if (shouldSubmit) {
          setAddress({ id: values.addressId })
            .then(() => {
              getDeliveryMethods().catch(toastResponseError);
              setAccess("delivery");
              navigate("/shipping/flow/delivery");
            })
            .catch(toastResponseError);
          return;
        }

        setAccess("delivery");
        navigate("/shipping/flow/delivery");
      },
    });

    const handleAddressSelect = useCallback(
      (id: number | string) => setFieldValue("addressId", id),
      [setFieldValue],
    );

    const handleAddressSubmit = useCallback(
      async (id: number) => {
        await setFieldValue("addressId", id);
        handleSubmit();
      },
      [setFieldValue, handleSubmit],
    );

    return (
      <Col lg={4} md={6} sm={12} offset={{ md: 3, lg: 4, sm: 12 }}>
        <Content>
          <DeliveryBlock>
            <Heading>{t("shipping.selectAddress")}:</Heading>
            <BlockContent>
              <AddressSearch />
              <Form onSubmit={handleSubmit}>
                <ScrollableForm>
                  <AddressesList
                    editable
                    selectedAddress={addressId}
                    onSelect={handleAddressSelect}
                    onSubmit={handleAddressSubmit}
                  />
                </ScrollableForm>
                <AddButton />
                <SubmitWrapper>
                  <Button
                    disabled={!addressId || isSubmitting}
                    isLoading={isSubmitting}
                    type={ButtonType.Submit}
                    size={ButtonSize.Large}
                    color={ButtonColor.Primary}
                    variant={ButtonVariant.Filled}
                  >
                    {t("common.nextStep")}
                  </Button>
                </SubmitWrapper>
              </Form>
            </BlockContent>
          </DeliveryBlock>
        </Content>
      </Col>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    destinationId: cartSelectDestinationId,
    cartDestination: cartSelectDestination,
    destinations: destinationsSelectAddresses,
  }),
  { getDeliveryMethods, setAddress },
);

export default withConnect(ChooseAddressForm);
