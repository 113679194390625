import React, { ChangeEvent, FC, memo, useCallback } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { ShipmentCommentProps } from "types/OutgoingForms/ShipmentComment";
import Input from "../../../../components/Input/Input";
import useDebouncedCallback from "../../../../hooks/useDebouncedCallback";
import { CommentWrapper } from "../InventoryItemComment/InventoryItemCommentSkeleton";
import { shipmentCommentFormik } from "./helperShipmentComment";

const ShipmentComment: FC<ShipmentCommentProps> = ({
  shipment,
  updateComment,
}) => {
  const { t } = useTranslation("common");
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik(shipmentCommentFormik(t, shipment, updateComment));

  const [debouncedHandleSubmit] = useDebouncedCallback(
    () => handleSubmit(),
    500,
    [values],
  );

  const handleCommentChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      debouncedHandleSubmit();
    },
    [debouncedHandleSubmit, handleChange],
  );

  return (
    <CommentWrapper>
      <Input
        name="comment"
        onChange={handleCommentChange}
        onBlur={handleBlur}
        tooltip={errors.comment}
        invalid={errors.comment && touched.comment}
        value={values.comment}
        placeholder={t("parcels.leaveComment")}
        isLoading={isSubmitting}
      />
    </CommentWrapper>
  );
};

export default memo(ShipmentComment);
