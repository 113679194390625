import React, { FC, memo, useCallback } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { AddressFormValues } from "types/address";
import { getCountriesOptions } from "../../../containers/SignUp/signUpHelper";
import { FieldWrapper } from "../../../styles/formStyles";
import Input from "../../Input/Input";
import Select from "../../Select/Select";
import { InfoText } from "../Addresses.style";
import {
  phoneNumberFormatChars,
  phoneNumbersMasks,
} from "../addressFormHelper";

interface AddressProps extends FormikProps<AddressFormValues> {
  currentLocale: string;
  showNativeSections: boolean;
}

const Address: FC<AddressProps> = ({
  setFieldValue,
  values,
  touched: touchedProp,
  errors: { address = {}, native = {} },
  handleChange,
  handleBlur,
  currentLocale,
  showNativeSections,
}) => {
  const touched = {
    address: {},
    native: {},
    ...touchedProp,
  };
  const { t } = useTranslation("common");
  const country = values.address.country;
  const stateOrRegion =
    country && country.value === "US" ? country.value : "other";
  const handleCountryChange = useCallback(
    (value: string) => setFieldValue("address.country", value),
    [setFieldValue],
  );
  const isPhoneMaskDefined =
    country &&
    country.value &&
    phoneNumbersMasks[country.value as keyof typeof phoneNumbersMasks];

  return (
    <div>
      <FieldWrapper>
        <Input
          placeholder={t("auth.firstName")}
          name="address.firstName"
          value={values.address.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          tooltip={address.firstName}
          invalid={address.firstName && touched.address.firstName}
        />
        <Input
          placeholder={t("auth.lastName")}
          name="address.lastName"
          value={values.address.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          tooltip={address.lastName}
          invalid={address.lastName && touched.address.lastName}
        />
      </FieldWrapper>
      {showNativeSections && (
        <Input
          placeholder={t("auth.taxId")}
          name="address.taxId"
          value={values.address.taxId}
          onChange={handleChange}
          onBlur={handleBlur}
          tooltip={address.taxId}
          invalid={address.taxId && touched.address.taxId}
        />
      )}
      <Select
        placeholder={t("auth.country")}
        name="address.country"
        options={getCountriesOptions(currentLocale)}
        value={country}
        onBlur={handleBlur}
        onChange={handleCountryChange}
      />
      <InputMask
        type="tel"
        placeholder={t("auth.phone")}
        name="address.phone"
        value={values.address.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        tooltip={address.phone}
        invalid={address.phone && touched.address.phone}
        formatChars={phoneNumberFormatChars}
        maskChar={isPhoneMaskDefined ? "_" : " "}
        mask={
          isPhoneMaskDefined
            ? phoneNumbersMasks[country.value as keyof typeof phoneNumbersMasks]
            : phoneNumbersMasks.GENERIC
        }
      >
        {/* @ts-ignore */}
        {(inputProps) => <Input {...inputProps} />}
      </InputMask>
      <Input
        placeholder={t(`auth.stateOrRegion.${stateOrRegion}`)}
        name="address.state"
        value={values.address.state}
        onChange={handleChange}
        onBlur={handleBlur}
        tooltip={address.state}
        invalid={address.state && touched.address.state}
      />
      <FieldWrapper>
        <Input
          placeholder={t("auth.city")}
          name="address.city"
          value={values.address.city}
          onChange={handleChange}
          onBlur={handleBlur}
          tooltip={address.city}
          invalid={address.city && touched.address.city}
        />
        <Input
          placeholder={t("auth.postalCode")}
          name="address.postalCode"
          value={values.address.postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          tooltip={address.postalCode}
          invalid={address.postalCode && touched.address.postalCode}
        />
      </FieldWrapper>
      {!showNativeSections && (
        <>
          <Input
            placeholder={t("auth.address1")}
            name="address.address1"
            value={values.address.address1}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={address.address1}
            invalid={address.address1 && touched.address.address1}
          />
          <Input
            placeholder={t("auth.address2")}
            name="address.address2"
            value={values.address.address2}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={address.address2}
            invalid={address.address2 && touched.address.address2}
          />
        </>
      )}
      {showNativeSections && (
        <>
          <Input
            placeholder={t("auth.street")}
            name="address.street"
            value={values.address.street}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={address.street}
            invalid={address.street && touched.address.street}
          />
          <FieldWrapper>
            <Input
              placeholder={t("auth.house")}
              name="address.house"
              value={values.address.house}
              onChange={handleChange}
              onBlur={handleBlur}
              tooltip={address.house}
              invalid={address.house && touched.address.house}
            />
            <Input
              placeholder={t("auth.flat")}
              name="address.flat"
              value={values.address.flat}
              onChange={handleChange}
              onBlur={handleBlur}
              tooltip={address.flat}
              invalid={address.flat && touched.address.flat}
            />
            <Input
              placeholder={t("auth.hull")}
              name="address.hull"
              value={values.address.hull}
              onChange={handleChange}
              onBlur={handleBlur}
              tooltip={address.hull}
              invalid={address.hull && touched.address.hull}
            />
          </FieldWrapper>
          <InfoText>{t("account.addressInNativeAlphabet")}</InfoText>
          <FieldWrapper>
            <Input
              placeholder={t("auth.firstName")}
              name="native.firstName"
              value={values.native.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              tooltip={native.firstName}
              invalid={native.firstName && touched.native.firstName}
            />
            <Input
              placeholder={t("auth.lastName")}
              name="native.lastName"
              value={values.native.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              tooltip={native.lastName}
              invalid={native.lastName && touched.native.lastName}
            />
          </FieldWrapper>
          <Input
            placeholder={t(`auth.stateOrRegion.${stateOrRegion}`)}
            name="native.state"
            value={values.native.state}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={native.state}
            invalid={native.state && touched.native.state}
          />
          <Input
            placeholder={t("auth.city")}
            name="native.city"
            value={values.native.city}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={native.city}
            invalid={native.city && touched.native.city}
          />
          <Input
            placeholder={t("auth.street")}
            name="native.street"
            value={values.native.street}
            onChange={handleChange}
            onBlur={handleBlur}
            tooltip={native.street}
            invalid={native.street && touched.native.street}
          />
        </>
      )}
    </div>
  );
};

export default memo(Address);
