import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { ButtonColor, ButtonSize, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import deepEqual from "fast-deep-equal";
import { List, Map } from "immutable";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { AddonsMethodsResponseDto, CartResponseDto } from "types/api/cart";
import { ItemsReceivedItemDto } from "types/api/items";
import {
  ShipmentsUpdateCommentDto,
  ShopsResponseDto,
} from "types/api/shipments";
import { OpenRightPanel } from "types/common/rightPanel";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import useFetchOnceEffect from "../../hooks/useFetchOnceEffect";
import useProgressDelivery from "../../hooks/useProgressDelivery";
import injectReducer from "../../reduxFolder/injectReducer";
import { getCart } from "../../reduxFolder/reducers/cart";
import outgoingReducer from "../../reduxFolder/reducers/outgoing";
import {
  openRightPanel,
  updateInPanelData,
} from "../../reduxFolder/reducers/rightPanel";
import serviceRequestsReducer from "../../reduxFolder/reducers/serviceRequests";
import { getAddonsMethods } from "../../reduxFolder/reducers/services";
import storageReducer, {
  addParcelsToCart,
  getShipment,
  getShops,
  setSelectedStorageItemId,
  updateComment,
  updateItemComment,
} from "../../reduxFolder/reducers/storage";
import {
  cartSelectCartItems,
  cartSelectIsEmpty,
  cartSelectLoading,
} from "../../reduxFolder/selectors/cartSelectors";
import {
  authSelectFfid,
  authSelectUnits,
} from "../../reduxFolder/selectors/globalSelectors";
import { panelSelectData } from "../../reduxFolder/selectors/rightPanelSelectors";
import { servicesSelectAddonsAll } from "../../reduxFolder/selectors/servicesSelectors";
import {
  storageSelectDetailedLoading,
  storageSelectDetailedShipment,
  storageSelectShops,
} from "../../reduxFolder/selectors/storageSelectors";
import { StorageButton } from "../../styles/parcelsStyles";
import { LeftArrowIcon } from "../RightPanel/RightPanel.styles";
import CartInfo from "../Storage/components/CartInfo/CartInfo";
import SelectedInfo from "../Storage/components/SelectedInfo/SelectedInfo";
import InventoryList from "./components/InventoryList";
import { InventoryListPlaceholder } from "./components/InventoryListPlaceholder";
import {
  BackButtonInventory,
  Buttons,
  ButtonsWrapperInventory,
  SelectAllSwitchWrapper,
  StorageHeading,
  StyledGrid,
  StyledListHeader,
  StyledListHeaderTitle,
  WrapperInventory,
} from "./styles/Inventory.style";

interface InventoryProps {
  isLoading: boolean;
  isCartLoading: boolean;
  units: string;
  openRightPanel: OpenRightPanel;
  getShipment: (id: number) => void;
  userFfid: string;
  updateComment: (id: number, data: ShipmentsUpdateCommentDto) => Promise<void>;
  updateItemComment: (id: number, data: ItemsReceivedItemDto) => Promise<void>;
  getCart: () => Promise<CartResponseDto>;
  getShops: () => Promise<ShopsResponseDto[]>;
  getAddonsMethods: () => Promise<AddonsMethodsResponseDto[]>;
  addParcelsToCart: (data: any) => void;
  isCartEmpty: boolean;
  setSelectedStorageItemId: (id: number) => void;
  detailedShipment: any;
  cartItems: any;
  shops: any;
  addons: any;
  panelData: any;
  updateInPanelData: any;
}

const Inventory: FC<InventoryProps> = ({
  units,
  userFfid,
  isLoading,
  isCartLoading,
  isCartEmpty,
  openRightPanel,
  getShipment,
  updateComment,
  updateItemComment,
  getCart,
  getShops,
  getAddonsMethods,
  addParcelsToCart,
  setSelectedStorageItemId,
  shops,
  addons,
  detailedShipment,
  cartItems: cartItemsProp,
  panelData,
  updateInPanelData,
}) => {
  const paramsRouter = useParams();
  const [, setAccess] = useProgressDelivery(userFfid);
  const { t } = useTranslation("common");
  const params = useParams();
  const [selectedItems, setSelectedItems] = useState<any>({});
  const id = parseInt(params.id ?? "");
  const navigate = useNavigate();

  const shipment = detailedShipment.get(id, Map());
  const items = shipment
    .get("items", List())
    .filter(
      (x: any) =>
        x.get("status") === "Active" ||
        x.get("status") === "InContainer" ||
        x.get("status") === "ContainerPacked",
    );

  const hasItems = !!items.size;
  const cartItems = cartItemsProp.toJS();
  const getIsItemInCart = (itemId: string) => {
    return cartItems.some((item: any) => item.id === itemId);
  };

  const selectedParcels = Object.values(selectedItems).filter(
    (item: any) => item.isSelectedState && !getIsItemInCart(item.id),
  );
  const selectableItems = items
    .toJS()
    .filter((item: any) => !getIsItemInCart(item.id));
  const selectedParcelsWeight = selectedParcels
    .reduce(
      (sum: number, item: any) =>
        sum + items.find((x: any) => x.get("id") === item.id).get("weight"),
      0,
    )
    .toFixed(2);
  const totalSelectableItemsWeight = selectableItems
    .reduce((sum: number, item: any) => sum + item.weight, 0)
    .toFixed(2);
  const hasSelected = selectedParcels.length > 0;
  const isAllSelected = selectedParcels.length === selectableItems.length;

  const convertSelectedItemsToList = (items: any) =>
    Object.keys(items)
      .filter((key) => items[key].isSelectedState)
      .map((x) => items[x].id);

  useFetchOnceEffect(!isCartLoading, getCart);
  useFetchOnceEffect(!shops.size, getShops);
  useFetchOnceEffect(!addons.size, getAddonsMethods);

  useEffect(() => {
    getShipment(id);
  }, [getShipment, id]);

  useEffect(() => {
    if (panelData) {
      const requestType = panelData.get("requestType");
      const isInventoryItemVariant = panelData.get("isInventoryItemVariant");
      const selectedInventoryItems = panelData.get("selectedInventoryItems");
      if (
        requestType === "requestTransferShipment" &&
        isInventoryItemVariant &&
        !deepEqual(
          selectedInventoryItems,
          convertSelectedItemsToList(selectedItems),
        )
      ) {
        updateInPanelData({
          selectedInventoryItems: convertSelectedItemsToList(selectedItems),
        });
      }
    }
  }, [selectedItems, panelData]);

  const toggleAllItemsSelectState = (nextValue: any) => {
    const nextSelectedItems: any = {};
    selectableItems.forEach((item: any) => {
      nextSelectedItems[item.id] = selectedItems[item.id]
        ? {
            ...selectedItems[item.id],
            isSelectedState: nextValue,
          }
        : {
            id: item.id,
            isSelectedState: nextValue,
            ...(item.quantity > 1 && { selectedQuantity: 1 }),
          };
    });
    setSelectedItems(nextSelectedItems);
  };

  const handleSelectAll = () => {
    toggleAllItemsSelectState(true);
  };

  const handleDeselectAll = () => {
    toggleAllItemsSelectState(false);
  };

  const handleSend = useCallback(() => {
    if (!hasSelected && !isCartEmpty) {
      navigate("/shipping");
      return;
    }

    const retVal = {
      id: selectedParcels.map((item: any) => item.id),
      decant_ids: selectedParcels
        .filter((x: any) => x.selectedQuantity)
        .reduce((accumulator: any, item: any) => {
          return { ...accumulator, [item.id]: item.selectedQuantity };
        }, {}),
      useCartItemsApi: true,
      shipmentId: id,
    };
    addParcelsToCart(retVal);
    setSelectedItems({});
    if (isCartEmpty) {
      setAccess("initialSteps");
    }
  }, [selectedParcels, addParcelsToCart, hasSelected, isCartEmpty, setAccess]);

  const handleBack = useCallback(() => {
    openRightPanel(
      "STORAGE_ITEM",
      Map({
        getShipment,
        updateComment,
        updateItemComment,
        hasInventoryItems: true,
        originalItem: shipment,
        isCartVariant: true,
        preventAutoCloseOnNavigationChange: true,
      }).merge(shipment),
    );
    setSelectedStorageItemId(id);
    navigate("/parcels");
  }, [
    openRightPanel,
    getShipment,
    updateComment,
    updateItemComment,
    shipment,
    setSelectedStorageItemId,
    id,
  ]);

  const handleSelectItems = (selectedItems: any) => {
    setSelectedItems((prevState: any) => ({
      ...prevState,
      ...selectedItems,
    }));
  };

  const handleBulkTransfer = useCallback(() => {
    const ids = convertSelectedItemsToList(selectedItems);
    openRightPanel(
      "SERVICE_REQUEST",
      Map({
        id: ids[0],
        shipment,
        state: "received",
        requestType: "requestTransferShipment",
        preventReLoading: true,
        isInventoryItemVariant: true,
        selectedInventoryItems: ids,
      }),
    );
  }, [openRightPanel, selectedItems]);

  return (
    <WrapperInventory>
      <StyledGrid fluid>
        <LoadingBar isLoading={isLoading} isCartLoading={isCartLoading} />
        <StorageHeading>
          {!isLoading && (
            <StyledListHeader>
              {selectableItems.length > 0 && (
                <Visible xs>
                  <SelectAllSwitchWrapper>
                    <Checkbox
                      checked={isAllSelected}
                      onChange={() =>
                        isAllSelected ? handleDeselectAll() : handleSelectAll()
                      }
                    />
                  </SelectAllSwitchWrapper>
                </Visible>
              )}
              <StyledListHeaderTitle>
                <BackButtonInventory
                  color={ButtonColor.Black50}
                  onClick={handleBack}
                >
                  <LeftArrowIcon type={IconType.Arrow} />
                  {shipment.get("description")}
                </BackButtonInventory>
                {t("parcels.total", { amount: selectableItems.length })}
                {selectableItems.length > 0 && (
                  <span>
                    {` / ${totalSelectableItemsWeight} ${t(
                      `units.${units}.primaryShort`,
                    )}`}
                  </span>
                )}
              </StyledListHeaderTitle>
              {selectableItems.length > 0 && (
                <Hidden xs>
                  {isAllSelected ? (
                    <StorageButton onClick={handleDeselectAll}>
                      {t("common.deselect")}
                    </StorageButton>
                  ) : (
                    <StorageButton onClick={handleSelectAll}>
                      {t("common.selectAll")}
                    </StorageButton>
                  )}
                </Hidden>
              )}
            </StyledListHeader>
          )}
        </StorageHeading>
        {hasItems && (
          <InventoryList
            units={units}
            items={items}
            selectedItems={selectedItems}
            disabled={isCartLoading}
            getIsItemInCart={getIsItemInCart}
            handleSelectItems={handleSelectItems}
            shipment={shipment}
          />
        )}
        {!hasItems && !isLoading && <InventoryListPlaceholder />}
        {(hasSelected || !isCartEmpty) && (
          <ButtonsWrapperInventory>
            <Buttons>
              {hasSelected ? (
                <SelectedInfo
                  weight={selectedParcelsWeight}
                  selectedParcelsNumber={selectedParcels.length}
                />
              ) : (
                <CartInfo />
              )}
              <Button
                variant={ButtonVariant.Filled}
                color={
                  hasSelected ? ButtonColor.Primary : ButtonColor.Secondary
                }
                size={ButtonSize.Large}
                disabled={isCartLoading}
                onClick={handleSend}
              >
                {hasSelected ? t("common.addToCart") : t("common.sendTheCart")}
              </Button>
              {hasSelected && (
                <Button
                  variant={ButtonVariant.Filled}
                  color={ButtonColor.Secondary}
                  size={ButtonSize.Large}
                  disabled={isCartLoading}
                  onClick={handleBulkTransfer}
                >
                  {t("serviceRequests.requestTransferShipment.title")}
                </Button>
              )}
              {hasSelected && (
                <StorageButton
                  disabled={isCartLoading}
                  onClick={handleDeselectAll}
                >
                  {t("common.deselect")}
                </StorageButton>
              )}
            </Buttons>
          </ButtonsWrapperInventory>
        )}
      </StyledGrid>
    </WrapperInventory>
  );
};

const withConnect = connect(
  createStructuredSelector({
    userFfid: authSelectFfid,
    isLoading: storageSelectDetailedLoading,
    isCartLoading: cartSelectLoading,
    shops: storageSelectShops,
    units: authSelectUnits,
    cartItems: cartSelectCartItems,
    isCartEmpty: cartSelectIsEmpty,
    addons: servicesSelectAddonsAll,
    detailedShipment: storageSelectDetailedShipment,
    panelData: panelSelectData,
  }),
  {
    addParcelsToCart,
    getCart,
    getShipment,
    getShops,
    getAddonsMethods,
    setSelectedStorageItemId,
    openRightPanel,
    updateComment,
    updateItemComment,
    updateInPanelData,
  },
);

const withStorageReducer = injectReducer({
  key: "storage",
  reducer: storageReducer,
});

const withOutgoingReducer = injectReducer({
  key: "outgoing",
  reducer: outgoingReducer,
});

const withServiceRequestsReducer = injectReducer({
  key: "serviceRequsets",
  reducer: serviceRequestsReducer,
});

const enhance = compose(
  withStorageReducer,
  withOutgoingReducer,
  withServiceRequestsReducer,
  withConnect,
  memo,
);

export default enhance(Inventory);
