import { Map } from 'immutable';

export const normalizeImages = (pictures) =>
  pictures.map((pic) => {
    let large = pic.get('imager_url');
    if (!large) {
      large = pic.get('large');
    }

    let medium = pic.get('imager_medium_url');
    if (!medium) {
      medium = pic.get('medium');
    }

    return Map({
      large,
      medium,
    });
  });
