import styled from "styled-components";
import fileCSV from "../Icon/icons/fileCSV.svg";

interface ContainerProps {
  $backgroundColor: string;
}

export const Container = styled.div<ContainerProps>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  overflow: hidden;
  position: relative;

  &:hover {
    background-color: rgba(225, 226, 230, 0.7);
    box-shadow: 0 1px 6px 0 ${({ theme }) => theme.black05};
  }
`;

export const Label = styled.span`
  margin: 8px;
`;

export const InputFile = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
`;

export const File = styled.div`
  width: 35px;
  height: 35px;
  background-image: url(${fileCSV});
  background-size: cover;
`;
