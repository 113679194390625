import React, { FC, FormEvent } from "react";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import useFetchOnceEffect from "../../../hooks/useFetchOnceEffect";
import { getAddresses } from "../../../reduxFolder/reducers/destinations";
import { openRightPanel } from "../../../reduxFolder/reducers/rightPanel";
import {
  destinationsSelectAddresses,
  destinationsSelectLoading,
} from "../../../reduxFolder/selectors/destinationSelectors";
import { AddressesWrapper, Empty, Spinner } from "../Addresses.style";
import AddressItem from "./AddressItem";

interface AddressesListProps {
  editable?: boolean;
  deletable?: boolean;
  onSelect?: (id: number | string) => void;
  onSubmit?: (e?: any | FormEvent<HTMLFormElement>) => any;
  selectedAddress?: number | string;
  className?: string;
  openRightPanel: OpenRightPanel;
  isLoading: boolean;
  addresses: any;
  getAddresses: () => Promise<AxiosResponse>;
  returnPreviousPanel?: string;
  initialPanelData?: any;
}

const AddressesList: FC<AddressesListProps> = React.memo(
  ({
    editable = false,
    deletable = false,
    onSelect,
    onSubmit,
    selectedAddress,
    isLoading,
    addresses,
    getAddresses,
    returnPreviousPanel,
    initialPanelData,
    className = "",
  }) => {
    const { t } = useTranslation("common");
    useFetchOnceEffect(true, getAddresses);

    return (
      <AddressesWrapper className={className}>
        <Spinner isActive={isLoading} />
        {addresses.map((address: any) => {
          const addressId = address.get("id");

          return (
            <AddressItem
              onSubmit={onSubmit}
              onSelect={onSelect}
              isSelected={selectedAddress === addressId}
              deletable={deletable}
              editable={editable}
              address={address}
              key={addressId}
              returnPreviousPanel={returnPreviousPanel}
              initialPanelData={initialPanelData}
            />
          );
        })}
        {addresses && addresses.count() === 0 && !isLoading && (
          <Empty>{t("shipping.noAddressFound")}</Empty>
        )}
      </AddressesWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    addresses: destinationsSelectAddresses,
    isLoading: destinationsSelectLoading,
  }),
  {
    openRightPanel,
    getAddresses,
  },
);

export default withConnect(AddressesList);
