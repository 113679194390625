import React, { FC } from "react";
import SearchInput from "components/SearchInput/SearchInput";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAddresses } from "../../../reduxFolder/reducers/destinations";
import { SearchWrapper } from "../Addresses.style";

export interface AddressSearchValues {
  address: string;
}

export type AddressSearchProps = {
  getAddresses: (data?: { search_in_address?: string }) => void;
};

const AddressSearch: FC<AddressSearchProps> = ({ getAddresses }) => {
  const { t } = useTranslation("common");

  const { values, handleSubmit, setFieldValue, initialValues } =
    useFormik<AddressSearchValues>({
      initialValues: {
        address: "",
      },
      onSubmit: (values) => {
        const data = values.address
          ? { search_in_address: values.address }
          : {};

        getAddresses(data);
      },
    });

  return (
    <SearchWrapper>
      <SearchInput<AddressSearchValues>
        name="address"
        value={values.address}
        placeholder={t("auth.startTypingAddress")}
        initialValue={initialValues.address}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
      />
    </SearchWrapper>
  );
};

const withConnect = connect(null, { getAddresses });

export default withConnect(AddressSearch);
