import PreLoader from "components/PreLoader/PreLoader";
import styled from "styled-components";
import { ParcelCol, ParcelRow } from "styles/parcelsStyles";

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

export const Spinner = styled(PreLoader)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

interface ExpectedItemRowWrapperProps {
  disabled: boolean;
}

export const ExpectedItemRowWrapper = styled.div<ExpectedItemRowWrapperProps>`
  &:not(:first-child) > div {
    border-top: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const LogoWrapper = styled.div`
  padding: 15px 10px 15px 0;
  margin-right: 15px;
`;

interface StorageColProps {
  $isTruncate?: boolean;
}

export const StorageCol = styled(ParcelCol)<StorageColProps>`
  text-align: left;
  line-height: 20px;
  user-select: none;

  ${({ $isTruncate }) =>
    $isTruncate &&
    `
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}

  .tooltip-content-checkbox {
    min-width: 18px;
  }

  &:nth-child(4) > div {
    margin-left: 15px;
  }

  ${(props) => props.theme.lg`
    white-space: normal;
  `}

  ${(props) => props.theme.xs`
    &:nth-child(4) > div {
      margin-left: 10px;
    }
  `}
  
  ${(props) => props.theme.xs`
    margin-bottom: 5px;
  `}
`;

export const MainStorageCol = styled(StorageCol)`
  padding-left: 0 !important;
`;

export const StorageRow = styled(ParcelRow)`
  ${({ theme }) => theme.range("padding", "0px", "6px")};

  ${(props) => props.theme.xs`
    padding: 5px 0 0;
  `};

  & > ${StorageCol} {
    &:first-child {
      justify-content: ${({ theme }) =>
        theme.xs ? "center" : "space-between"};
    }

    &:last-child {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 17px;

  img {
    margin: 0 0 0 10px;

    &:first-child {
      margin-left: 15px;
    }
  }

  ${(props) => props.theme.sm`
    margin-left: 12px;
  `}

  ${(props) => props.theme.xs`
    margin-left: 0;

    img {
      width: 12px;
      height: 12px;
      margin-left: 6px;
    }
  `}

  &[disabled] {
    color: ${(props) => props.theme.black50};
  }
`;

export const Comment = styled.span`
  font-size: 12px;
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.black50};
`;
