import React, { FC, ReactNode, Suspense, useCallback, useState } from "react";
import { navWidth } from "components/LeftMenu/LeftMenu.styles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { createStructuredSelector } from "reselect";
import FullscreenImage from "../components/ImageViewer2/FullscreenImage";
import LeftMenu from "../components/LeftMenu/LeftMenu";
import LoadingPage from "../components/LoadingPage/LoadingPage";
import HeaderContainer from "../containers/HeaderContainer/HeaderContainer";
import RightPanel from "../containers/RightPanel/RightPanel";
import SignInModal from "../containers/SignIn/components/SignInModal";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { closeRightPanel } from "../reduxFolder/reducers/rightPanel";
import { panelSelectOpened } from "../reduxFolder/selectors/rightPanelSelectors";
import { Content, ContentWrapper, LayoutWrapper } from "./Layout.styles";

interface LayoutProps {
  children: ReactNode;
  withMenu: boolean;
  rightPanel?: boolean;
  withHeader?: boolean;
  isPanelOpened: boolean;
  closeRightPanel: () => void;
}

const Layout: FC<LayoutProps> = ({
  children,
  withMenu,
  withHeader = false,
  rightPanel = false,
  isPanelOpened,
  closeRightPanel,
}) => {
  const { state, pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isExpiredToken = state && state?.signInModal;

  const { t } = useTranslation("common");
  const path = pathname?.split("/")[1];

  const title = t(`pages.${path || "basicInformation"}`);
  useDocumentTitle(title);

  const handleSetMenuOpen = useCallback(
    (isOpen: boolean) => {
      setIsMenuOpen(isOpen);
    },
    [setIsMenuOpen],
  );

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventDate) => {
      if (!isPanelOpened) return;

      closeRightPanel();
      eventDate.event.stopPropagation();
      eventDate.event.preventDefault();
    },
    touchEventOptions: { passive: !isPanelOpened },
  });

  return (
    <LayoutWrapper {...swipeHandlers}>
      {rightPanel && <RightPanel />}

      <Suspense fallback={<LoadingPage />}>
        <LeftMenu
          isOpen={isMenuOpen}
          withMenu={withMenu}
          handleSetMenuOpen={handleSetMenuOpen}
        />
        {isExpiredToken && <SignInModal />}

        <ContentWrapper>
          {withHeader && (
            <HeaderContainer
              withMenu={withMenu}
              isMenuOpen={isMenuOpen}
              toggleMenu={toggleMenu}
            />
          )}
          <Content>{children}</Content>
          <FullscreenImage leftOffsetByTheme={navWidth} />
        </ContentWrapper>
      </Suspense>
    </LayoutWrapper>
  );
};

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
  }),
  { closeRightPanel },
);

export default withConnect(Layout);
