import PreLoader from "components/PreLoader/PreLoader";
import { Content } from "containers/RightPanel/RightPanel.styles";
import styled from "styled-components";

export const Spinner = styled(PreLoader)`
  position: absolute;
  top: 20px;
`;

export const ContentContainer = styled(Content)`
  && > div:first-child {
    padding: 0;
  }
`;

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;

  button {
    margin: 5px;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: auto;
`;
