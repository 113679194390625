import React, { FC, memo, useCallback, useEffect } from "react";
import { FormikProps, withFormik } from "formik";
import { List } from "immutable";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { BooksRecipientsProps } from "types/books";
import AddAddressButton from "../../components/Addresses/components/AddAddressButton";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import injectReducer from "../../reduxFolder/injectReducer";
import booksReducer from "../../reduxFolder/reducers/books";
import { getAddresses } from "../../reduxFolder/reducers/destinations";
import {
  destinationsSelectAddresses,
  destinationsSelectLoading,
} from "../../reduxFolder/selectors/destinationSelectors";
import { panelSelectOpened } from "../../reduxFolder/selectors/rightPanelSelectors";
import BooksRecipientsList from "./components/BooksRecipientsList/BooksRecipientsList";
import BooksSearchPlaceholder from "./components/BooksRecipientsList/BooksSearchPlaceholder";
import BooksSearchRecipients from "./components/BooksRecipientsList/BooksSearchRecipients";
import { searchRecipientsFormik } from "./helpers/searchRecipientsFormik";
import {
  AddAddressBlock,
  RecipientsHeading,
  StyledGrid,
  Wrapper,
} from "./styles/BookRecipients.style";

const BooksRecipients: FC<BooksRecipientsProps> = React.memo(
  ({
    recipientsItems,
    isLoading,
    getAddresses,
    isPanelOpened,
    handleReset,
    handleSubmit,
    setFieldValue,
    initialValues,
    values,
  }) => {
    const hasItems = !!recipientsItems.size;

    useEffect(() => {
      getAddresses();
    }, [getAddresses]);

    const handleResetClick = useCallback(() => {
      handleReset();
      handleSubmit();
    }, [handleReset, handleSubmit]);

    return (
      <Wrapper>
        <StyledGrid fluid>
          <LoadingBar isLoading={isLoading} />
          <RecipientsHeading $isPanelOpened={isPanelOpened}>
            <BooksSearchRecipients
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              values={values}
              initialValues={initialValues}
            />
            <AddAddressBlock>
              <AddAddressButton />
            </AddAddressBlock>
          </RecipientsHeading>

          {hasItems ? (
            <BooksRecipientsList
              recipientsItems={recipientsItems}
              disabled={isLoading}
            />
          ) : (
            !isLoading && (
              <BooksSearchPlaceholder handleReset={handleResetClick} />
            )
          )}
        </StyledGrid>
      </Wrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    recipientsItems: destinationsSelectAddresses,
    isLoading: destinationsSelectLoading,
    isPanelOpened: panelSelectOpened,
  }),
  {
    getAddresses,
  },
);

const withBooksReducer = injectReducer({
  key: "books",
  reducer: booksReducer,
});

const enhance = compose(
  withBooksReducer,
  withConnect,
  withFormik(searchRecipientsFormik),
);

export default enhance(BooksRecipients);
