import { initialState } from '../reducers/outgoing';
import { peek, selector } from '../selectorHelpers';

export const selectOutgoing = (state) => state.get('outgoing', initialState);

export const outgoingSelectPaymentData = selector(
  selectOutgoing,
  peek('waiting_for_payment'),
);
export const outgoingSelectInformationData = selector(
  selectOutgoing,
  peek('waiting_for_information'),
);

export const outgoingSelectPackingData = selector(
  selectOutgoing,
  peek('packing_in_progress'),
);

export const outgoingSelectPaidData = selector(selectOutgoing, peek('paid'));

export const outgoingSelectDetailedItems = selector(
  selectOutgoing,
  peek('detailedItem'),
);
export const outgoingSelectDetailedShipment = selector(
  selectOutgoing,
  peek('detailedShipment'),
);
export const outgoingSelectDeliveryMethods = selector(
  selectOutgoing,
  peek('deliveryMethods'),
);

export const outgoingSelectHasItemsLoad = selector(
  [
    outgoingSelectPaymentData,
    outgoingSelectInformationData,
    outgoingSelectPackingData,
  ],
  (...dataTypes) => !!dataTypes.find((data) => !data.get('items').size),
);

export const outgoingSelectLoading = selector(
  [
    outgoingSelectPaymentData,
    outgoingSelectInformationData,
    outgoingSelectPackingData,
  ],
  (...dataTypes) => !!dataTypes.find((data) => data.get('isLoading')),
);

export const outgoingSelectLoadingInsurance = selector(
  selectOutgoing,
  peek('isLoadingUpdateInsurance'),
);

export const outgoingSelectDetailedLoading = selector(
  selectOutgoing,
  peek('isLoadingDetailedItem'),
);

export const outgoingSelectDetailedShipmentLoading = selector(
  selectOutgoing,
  peek('isLoadingDetailedShipment'),
);

export const outgoingSelectCustomsDeclarationImporting = selector(
  selectOutgoing,
  peek('isLoadingCustomsDeclaration'),
);
