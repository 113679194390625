import React, { FC, memo } from "react";
import { List } from "immutable";
import { Waypoint } from "react-waypoint";
import styled from "styled-components";
import { Spinner } from "../../../Storage/components/StorageList/StorageList.styles";
import BooksTransactionsHistoryItemRow from "./BooksTransactionsHistoryItemRow/BooksTransactionsHistoryItemRow";

interface BooksTransactionsHistoryListProps {
  historyItems: List<any>;
  disabled: boolean;
  onPagination: () => void;
  totalAmount: number;
}

const BooksTransactionsHistoryList: FC<BooksTransactionsHistoryListProps> = ({
  historyItems,
  disabled,
  onPagination,
  totalAmount,
}) => {
  const hasMore = totalAmount > historyItems.size;
  return (
    <Wrapper>
      {historyItems.map((item) => (
        <BooksTransactionsHistoryItemRow
          item={item}
          key={item}
          disabled={disabled}
        />
      ))}
      {hasMore && (
        <>
          <Waypoint onEnter={onPagination} />
          <Spinner key={0} isActive />
        </>
      )}
    </Wrapper>
  );
};

export default memo(BooksTransactionsHistoryList);

const Wrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;
