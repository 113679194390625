import React, { FC } from "react";
import { ButtonColor, ButtonType, ButtonVariant } from "enums/Button";
import { useFormik } from "formik";
import { Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { signInRequest } from "reduxFolder/reducers/auth";
import { SignInFormProps } from "types/signInForm";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import {
  AuthLink,
  Fields,
  FieldWrapper,
  Form,
  LinksWrapper,
} from "../../../styles/formStyles";
import { signInFormik } from "../signInHelper";

const SignInForm: FC<SignInFormProps> = React.memo(
  ({ handleSignIn, signInModal = false, redirect }) => {
    const { t } = useTranslation("common");
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
    } = useFormik(signInFormik(t, handleSignIn, redirect));

    return (
      <>
        <Row justify="center" align="center">
          <Form onSubmit={handleSubmit}>
            <Fields>
              <FieldWrapper>
                <Input
                  autoFocus
                  name="email"
                  placeholder={t("auth.email")}
                  label={t("auth.email")}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  tooltip={errors.email}
                  invalid={errors.email && touched.email}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Input
                  type="password"
                  name="password"
                  placeholder={t("auth.password")}
                  label={t("auth.password")}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  tooltip={errors.password}
                  invalid={errors.password && touched.password}
                />
              </FieldWrapper>
            </Fields>
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting}
              color={ButtonColor.Primary}
              type={ButtonType.Submit}
              variant={ButtonVariant.Filled}
            >
              {t("auth.login")}
            </Button>
          </Form>
        </Row>
        {!signInModal && (
          <Row justify="center">
            <LinksWrapper>
              <AuthLink to="/signup">{t("auth.dontHaveAccount")}</AuthLink>
              <AuthLink
                to={{
                  pathname: "/password-reset",
                }}
                state={{ email: values.email }}
              >
                {t("auth.forgotPassword")}?
              </AuthLink>
            </LinksWrapper>
          </Row>
        )}
      </>
    );
  },
);

const withConnect = connect(null, { handleSignIn: signInRequest });

export default withConnect(SignInForm);
