import React, { FC } from "react";
import { format, Locale } from "date-fns";
import { DateRange } from "react-date-range";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/styles";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { ButtonColor, ButtonSize, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import {
  DateFilterWrapper,
  DateFilterWrapperAnimator,
  DateLabel,
  DateRangeApplyButton,
  DateRangeArrow,
  DateRangeFooter,
  DateRangeHeader,
  DateRangeWrapper,
} from "./CompactDateRangeFilter.styles";

interface CompactDateRangeFilterProps {
  locale: Locale;
  minDate: Date;
  maxDate: Date;
  selectionRange: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
  handleDateSelection: (range: any) => void;
  hanldeApplyDateRange: () => void;
}

const CompactDateRangeFilter: FC<CompactDateRangeFilterProps> = ({
  selectionRange,
  handleDateSelection,
  hanldeApplyDateRange,
  minDate,
  maxDate,
  locale,
}) => {
  const { t } = useTranslation("common");
  const formattedDate = (date: Date) =>
    format(new Date(date), "eee, MMM dd", { locale });

  /* https://hypeserver.github.io/react-date-range/ */
  return (
    <DateFilterWrapper>
      <DateFilterWrapperAnimator>
        <DateRangeHeader>
          <DateLabel>{formattedDate(selectionRange.startDate)}</DateLabel>
          <DateRangeArrow type={IconType.RightArrow} />
          <DateLabel>{formattedDate(selectionRange.endDate)}</DateLabel>
        </DateRangeHeader>
        <DateRangeWrapper>
          <DateRange
            minDate={minDate}
            maxDate={maxDate}
            ranges={[selectionRange]}
            onChange={handleDateSelection}
            showMonthAndYearPickers={false}
            direction={"vertical"}
            showMonthArrow={false}
            showDateDisplay={false}
            rangeColors={[theme.primary]}
            scroll={{ enabled: true }}
          />
        </DateRangeWrapper>
        <DateRangeFooter>
          <DateRangeApplyButton
            variant={ButtonVariant.Filled}
            color={ButtonColor.Primary}
            size={ButtonSize.Large}
            onClick={hanldeApplyDateRange}
          >
            {t("parcels.search.applyFilter")}
          </DateRangeApplyButton>
        </DateRangeFooter>
      </DateFilterWrapperAnimator>
    </DateFilterWrapper>
  );
};

export default CompactDateRangeFilter;
