import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { createGlobalStyle } from "styled-components";
import { AuthUserDto } from "types/api/auth";
import ToastContainer from "../components/ToastContainer/ToastContainer";
import { getProfile, setAuthenticated } from "../reduxFolder/reducers/auth";
import { authSelectLocale } from "../reduxFolder/selectors/globalSelectors";
import { globalStyles } from "../styles/styles";
import { getSupportedLocale } from "../translations/i18nConfig";
import { clearAuthData, getAuthData } from "../utils/authData";

const GlobalStyle = createGlobalStyle`${globalStyles}`;

interface AppProps {
  currentLocale: string;
  getProfile: () => Promise<AuthUserDto>;
  setAuthenticated: (isAuthenticated: boolean) => void;
}

export const App: FC<AppProps> = React.memo(
  ({ currentLocale, getProfile, setAuthenticated }) => {
    const { i18n } = useTranslation();
    const [locale, setLocale] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
      const authData = getAuthData();
      const token = authData?.["access-token" as keyof typeof authData] ?? null;

      if (!token) {
        setAuthenticated(false);
        return;
      }

      getProfile().catch(() => {
        clearAuthData();
        navigate("/signin");
      });
    }, [getProfile, setAuthenticated, navigate]);

    useEffect(() => {
      const appLocale = getSupportedLocale(currentLocale);

      if (appLocale !== locale) {
        i18n.changeLanguage(appLocale);
        setLocale(appLocale);
      }
    }, [currentLocale, setLocale, i18n, locale]);

    return (
      <>
        <GlobalStyle />
        <Outlet />
        <ToastContainer />
      </>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    currentLocale: authSelectLocale,
  }),
  { getProfile, setAuthenticated },
);

export default withConnect(App);
