import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { StorageSearchValues } from "types/StorageForms/Search";
import Select from "../../../../../components/Select/Select";
import { storageSelectShops } from "../../../../../reduxFolder/selectors/storageSelectors";
import { TooltipContent } from "../Search.styles";
import SearchFilter from "../SearchFilter";
import { FilterLogo, TitleWrapper } from "./Filter.styles";

interface FilterShopProps extends FormikProps<StorageSearchValues> {
  shops: any;
}

const FilterShop: FC<FilterShopProps> = React.memo(
  ({
    initialValues,
    setFieldValue,
    values,
    handleSubmit,
    shops,
    ...otherProps
  }) => {
    const { t } = useTranslation("common");
    const value = values.vendor;
    const [innerValue, setInnerValue] = useState(value);
    const initialValue = initialValues.vendor;
    const isVendorChanged = !value;
    const shopTitle = isVendorChanged ? t("parcels.search.shop") : value.label;
    const image = value && value.image;
    const shopOptions = useMemo(
      () =>
        shops
          .map((shop: any) => {
            const name = shop.get("name");
            const image = shop.get("thumb_image_url");
            return { label: name, value: name, image };
          })
          .sort((a: any, b: any) => a.label.localeCompare(b.label)),
      [shops],
    );

    useEffect(() => {
      setInnerValue(value);
    }, [value]);

    const dropInnerValue = useCallback(() => setInnerValue(value), [value]);

    const applyInnerValue = useCallback(
      () => setFieldValue("vendor", innerValue),
      [innerValue, setFieldValue],
    );

    const handleInnerChange = useCallback(
      (value: any) => setInnerValue(value),
      [],
    );

    const setDefault = useCallback(() => {
      setFieldValue("vendor", initialValue);
      dropInnerValue();
    }, [dropInnerValue, initialValue, setFieldValue]);

    return (
      <SearchFilter
        applyInnerValue={applyInnerValue}
        dropInnerValue={dropInnerValue}
        handleSubmit={handleSubmit}
        setDefault={setDefault}
        title={
          <TitleWrapper>
            <FilterLogo image={image} size={18} />
            {shopTitle}
          </TitleWrapper>
        }
        innerTitle={t("parcels.search.selectShop")}
        isActive={!isVendorChanged}
        {...otherProps}
      >
        <TooltipContent>
          <Select
            autoFocus
            value={innerValue}
            onChange={handleInnerChange}
            options={shopOptions}
            placeholder={`${t("parcels.search.shop")}:`}
          />
        </TooltipContent>
      </SearchFilter>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    shops: storageSelectShops,
  }),
);

export default withConnect(FilterShop);
