import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { ListItem, Wrapper } from "../../styles/Delivery.styles";
import ServicesLogo from "../ServicesLogo";
import { Info, Price, Type } from "./PackingList.style";

interface PackingListProps {
  packingType: string;
  onSelect: (code: string) => void;
  onSubmit: (type: string) => Promise<void>;
  packingMethods: any;
}

const PackingList: FC<PackingListProps> = ({
  packingMethods,
  packingType,
  onSelect,
  onSubmit,
}) => {
  const { t } = useTranslation("common");
  return (
    <Wrapper>
      {packingMethods.map((data: any) => {
        const price = parseInt(data.get("price_in_cents")) / 100;
        const priceTitle = price > 0 ? `$${price}` : t("common.free");
        const isSelected = packingType === data.get("code");

        return (
          <ListItem
            key={data.get("id")}
            $isSelected={isSelected}
            onClick={() => onSelect(data.get("code"))}
            onDoubleClick={() => onSubmit(data.get("code"))}
          >
            <ServicesLogo logoUrl={data.get("logo_url")} />
            <Info>
              <Type>{data.get("title")}</Type>
              {data.get("description")}
            </Info>
            <Price>{priceTitle}</Price>
          </ListItem>
        );
      })}
    </Wrapper>
  );
};

export default memo(PackingList);
