import React, { FC } from "react";
import ServiceTooltips from "containers/Storage/components/ServiceTooltip/ServiceTooltips";
import { IconType } from "enums/Icon";
import { List } from "immutable";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ServiceRequestPanelData } from "types/common/rightPanel";
import { getAddonsIconsImage } from "../../containers/Delivery/deliveryHelper";
import { servicesSelectAddonsAll } from "../../reduxFolder/selectors/servicesSelectors";
import { theme } from "../../styles/styles";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";
import Icon from "../Icon/Icon";
import Logo from "../Logo/Logo";
import {
  Comment,
  DetailedButton,
  ItemContent,
  ItemName,
  ListItem,
  ListWrapper,
  OpenItemWrapper,
  RightArrowIcon,
  ServiceRequests,
  TrackingNumber,
} from "./PackagesList.styles";

interface PackagesListProps {
  items?: any;
  addons: any;
  handleClick?: (item: any) => void;
  className?: string;
  extended?: boolean;
  foundItemId?: number;
  serviceRequestPanelData?: ServiceRequestPanelData;
  trackNumberColor?: string;
  isReadOnly?: boolean;
}

const PackagesList: FC<PackagesListProps> = React.memo(
  ({
    items = List(),
    addons,
    extended = false,
    trackNumberColor = theme.black,
    className = "",
    foundItemId,
    handleClick,
    serviceRequestPanelData,
    isReadOnly = false,
  }) => (
    <ListWrapper className={className}>
      {items.map((item: any) => {
        const logo = item.get("emblem_thumb_url");
        const comment = item.get("customer_comment");
        const itemAddons = item.get("addons", List());
        const selected = foundItemId === item.get("shipment_id");

        const requests = item.get("service_requests_map", List());
        const hasServiceRequests = !!requests && !requests.isEmpty();
        const id = item.get("id");
        let mainDescription = item.get("description");
        if (!mainDescription) {
          mainDescription = item.get("shipment_tracking_number");
        }

        const ListItemChild = (
          <ListItem
            key={id}
            $extended={extended}
            $isReadOnly={isReadOnly}
            selected={selected}
            onDoubleClick={() => extended && handleClick?.(item)}
          >
            <ItemContent>
              <ItemName>
                {logo ? (
                  <Logo image={logo} />
                ) : (
                  <Icon type={IconType.Package} />
                )}
                <CopyToClipboard text={mainDescription}>
                  <TrackingNumber $trackNumberColor={trackNumberColor}>
                    {mainDescription}
                  </TrackingNumber>
                </CopyToClipboard>
                {extended && getAddonsIconsImage(itemAddons, addons)}
                {hasServiceRequests && (
                  <ServiceRequests>
                    <ServiceTooltips
                      parcel={item.set("id", item.get("shipment_id"))}
                      requests={requests.toJS()}
                      zIndex={3}
                      additionalPanelData={serviceRequestPanelData}
                    />
                  </ServiceRequests>
                )}
              </ItemName>
              {comment && <Comment>{comment}</Comment>}
            </ItemContent>
            {extended && handleClick && (
              <OpenItemWrapper onClick={() => handleClick(item)}>
                <RightArrowIcon type={IconType.RightArrow} />
              </OpenItemWrapper>
            )}
          </ListItem>
        );

        if (!extended) return ListItemChild;
        return <DetailedButton key={id}>{ListItemChild}</DetailedButton>;
      })}
    </ListWrapper>
  ),
);

const withConnect = connect(
  createStructuredSelector({
    addons: servicesSelectAddonsAll,
  }),
  null,
);

export default withConnect(PackagesList);
