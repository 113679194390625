import React, { FC, memo } from "react";
import {
  Content,
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { OpenRightPanel } from "types/common/rightPanel";
import ImageViewer2 from "../../../../components/ImageViewer2/ImageViewer2";
import injectReducer from "../../../../reduxFolder/injectReducer";
import dashboardReducer from "../../../../reduxFolder/reducers/dashboard";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { Warning } from "../../../../styles/outgoingStyles";
import { ImageSection, MoreInfoButton } from "./ProhibitedGoodsPanel.styles";

// fake images FIXME:
const images = [
  "https://timedotcom.files.wordpress.com/2015/05/471358344.jpg",
  "https://lh6.googleusercontent.com/-XqaFSgYUXLM/VWUu55u4nKI/AAAAAAAAEfw/vUem8jP_adA/w681-h508-no/smashedbox1.jpg",
  "https://www.bitnik.org/media/r/img/thumb/parcel_nike_01_905.jpg",
  "https://previews.123rf.com/images/om12/om121505/om12150500038/40686138-post-office-worker-weighs-the-parcel-before-sending-vertical-photo.jpg",
];

interface ProhibitedGoodsPanelProps {
  openRightPanel: OpenRightPanel;
}

const ProhibitedGoodsPanel: FC<ProhibitedGoodsPanelProps> = React.memo(
  ({ openRightPanel }) => {
    const { t } = useTranslation("common");
    const clickMoreInfo = () =>
      openRightPanel(
        "POPULAR_QUESTIONS",
        Map({
          returnPreviousPanel: "PROHIBITED_FOUND",
        }),
      );

    return (
      <ContentWrapper>
        <Heading $isBordered>{t("parcels.prohobitedGoods")}</Heading>
        <Content>
          <Warning>
            {t("parcels.prohobitedGoodsWarning")}
            <MoreInfoButton onClick={clickMoreInfo}>
              {t("common.moreOfficialInformation")} ›
            </MoreInfoButton>
          </Warning>
          {images.length && (
            <ImageSection>
              <ImageViewer2 images={images} />
            </ImageSection>
          )}
        </Content>
      </ContentWrapper>
    );
  },
);

const withReducer = injectReducer({
  key: "dashboard",
  reducer: dashboardReducer,
});

const withConnect = connect(null, {
  openRightPanel,
});

const enhance = compose(withReducer, withConnect);

export default enhance(ProhibitedGoodsPanel);
