import { fromJS, List } from 'immutable';
import { combineActions, createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = fromJS({
  addresses: List(),
  tempAddressId: null,
  isLoading: false,
});

// const
export const GET_ADDRESSES = 'destinations/GET_ADDRESSES';
export const UPDATE_ADDRESS = 'destinations/UPDATE_ADDRESS';
export const CREATE_ADDRESS = 'destinations/CREATE_ADDRESS';
export const DELETE_ADDRESS = 'destinations/DELETE_ADDRESS';

// action creators
export const createAddress = createAction(
  CREATE_ADDRESS,
  api.destinations.createAddress,
);
export const updateAddress = createAction(
  UPDATE_ADDRESS,
  api.destinations.updateAddress,
);
export const getAddresses = createAction(
  GET_ADDRESSES,
  api.destinations.getAddresses,
);
export const deleteAddress = createAction(DELETE_ADDRESS, (data) => ({
  promise: api.destinations.deleteAddress(data),
  data,
}));

// reducer
const destinations = handleActions(
  {
    [combineActions(
      `${CREATE_ADDRESS}_REQUEST`,
      `${GET_ADDRESSES}_REQUEST`,
      `${UPDATE_ADDRESS}_REQUEST`,
    )]: (state) => state.set('isLoading', true),

    [combineActions(
      `${CREATE_ADDRESS}_ERROR`,
      `${GET_ADDRESSES}_ERROR`,
      `${UPDATE_ADDRESS}_ERROR`,
      `${DELETE_ADDRESS}_ERROR`,
    )]: (state) => state.set('isLoading', false),

    [`${DELETE_ADDRESS}_REQUEST`]: (state, { payload }) =>
      state.set('tempAddressId', payload.id).set('isLoading', true),

    [`${GET_ADDRESSES}_SUCCESS`]: (state, { payload }) => {
      return state
        .set('addresses', payload.get('data'))
        .set('isLoading', false);
    },

    [`${CREATE_ADDRESS}_SUCCESS`]: (state, { payload }) =>
      state
        .update('addresses', (addresses) => addresses.push(payload.get('data')))
        .set('isLoading', false),

    [`${UPDATE_ADDRESS}_SUCCESS`]: (state, { payload }) => {
      const addressId = payload.getIn(['data', 'id']);
      const index = state
        .get('addresses')
        .findIndex((address) => address.get('id') === addressId);

      return state
        .setIn(['addresses', index], payload.get('data'))
        .set('isLoading', false);
    },

    [`${DELETE_ADDRESS}_SUCCESS`]: (state) =>
      state
        .update('addresses', (addresses) =>
          addresses.filter(
            (address) => address.get('id') !== state.get('tempAddressId'),
          ),
        )
        .set('addressId', null)
        .set('isLoading', false),
  },
  initialState,
);

export default destinations;
