import { IconType } from "enums/Icon";
import styled from "styled-components";
import { Field } from "../../styles/formStyles";
import Icon from "../Icon/Icon";
import PreLoader from "../PreLoader/PreLoader";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

interface TextAreaFieldProps {
  $invalid: boolean;
  name: string;
  disabled: boolean;
}

export const TextAreaField = styled.textarea<TextAreaFieldProps>`
  ${Field};
  padding: 6px 10px;
  line-height: 16px;
  font-family: "Roboto", sans-serif;
  height: 100%;
  min-height: 68px;
  resize: none;
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  right: 5px;
  bottom: 8px;
  cursor: pointer;
`;

interface InputIconProps {
  color: string;
  type: IconType;
}

export const InputIcon = styled(Icon)<InputIconProps>`
  background-color: ${({ theme, color }) => theme[color]};
`;

export const Spinner = styled(PreLoader)`
  position: absolute;
  top: 5px;
  margin-right: 5px;
  width: 20px;
  height: 14px;
  transform-origin: 0 -2px;
  transform: scale(0.3);
`;

interface LabelProps {
  invalid: boolean;
  className: string;
}

export const Label = styled.label<LabelProps>`
  width: 100%;
  position: relative;
  display: block;
  font-size: 12px;
  color: ${({ theme, invalid }) => (invalid ? theme.red : theme.black50)};
`;

export const LabelText = styled.p`
  margin-top: 0;
  text-align: left;
  margin-bottom: 8px;
`;
