import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { SettingsFormValues } from "types/accountSettings";
import { CommonOption, NumberOption } from "types/common";
import { UpdateProfileInfoDto } from "types/user";
import * as yup from "yup";
import {
  englishAndCyrillicOnlyRegEx,
  getShouldShowNativeSections,
  withAlphabeticRegEx,
} from "../../components/Addresses/addressFormHelper";
import { toastResponseError } from "../../utils/responseMessageHelper";
import { getCountriesOptions, getLocalesOptions } from "../SignUp/signUpHelper";

export const getSelectValue = (value: string, options: CommonOption[]) => {
  return options.find(
    (option) => option.value.toLowerCase() === value?.toLowerCase(),
  );
};

export const getSelectNumberValue = (
  value: number,
  options: NumberOption[],
) => {
  return options.find((option) => option.value === value);
};

export const getNotificationOptions = (t: TFunction) => [
  { label: t("emailNotifications.oncePerDay"), value: "one_of_the_day" },
  { label: t("emailNotifications.oncePerWeek"), value: "one_of_the_week" },
  { label: t("emailNotifications.eachEvent"), value: "after_each_event" },
];

export const getUnitsOptions = (t: TFunction) =>
  [
    { label: t("units.kg.title"), value: "kg" },
    { label: t("units.lbs.title"), value: "lbs" },
  ].sort((a, b) => a.label.localeCompare(b.label));

export const getHandlingOptions = (t: TFunction) =>
  [
    { label: t("handling.processWhole.title"), value: 3 },
    { label: t("handling.processIndividual.title"), value: 4 },
  ].sort((a, b) => a.label.localeCompare(b.label));

export const settingsForm = (
  countryProp: string,
  currentLocale: string,
  localeProp: string,
  notificationsProp: string,
  unitsProp: string,
  handlingProp: number,
  onSubmitCustom: (data: { user: UpdateProfileInfoDto }) => Promise<any>,
  t: TFunction,
) => ({
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
  initialValues: {
    country: getSelectValue(countryProp, getCountriesOptions(currentLocale)),
    locale: getSelectValue(localeProp, getLocalesOptions(t)),
    notification: getSelectValue(notificationsProp, getNotificationOptions(t)),
    units: getSelectValue(unitsProp, getUnitsOptions(t)),
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    handling: getSelectNumberValue(handlingProp, getHandlingOptions(t)) ?? {
      label: t("handling.processWhole.title"),
      value: 3,
    },
  },
  validationSchema: () =>
    yup.object().shape({
      oldPassword: yup.string().when("newPassword", {
        is: (np: string) => {
          return !!np;
        },
        then: () => yup.string().required(t("error.required")),
        otherwise: () => yup.string().nullable(),
      }),
      newPassword: yup
        .string()
        .min(6, t("error.stringLonger", { length: 6 }))
        .matches(/^\S*$/, t("error.whiteSpaces"))
        .matches(/(?=.*[a-zA-Z])/, t("error.hasAlphabetical"))
        .matches(/(?=.*[0-9])/, t("error.hasNumeric"))
        .matches(/[^a-zA-Z0-9\s]/, t("error.hasSymbol")),
      newPasswordConfirm: yup.string().when("newPassword", {
        is: (np: string) => {
          return !!np;
        },
        then: () =>
          yup
            .string()
            .oneOf([yup.ref("newPassword")], t("error.invalidRePass"))
            .required(t("error.required")),
        otherwise: () => yup.string().nullable(),
      }),
    }),
  onSubmit: (
    values: SettingsFormValues,
    { setSubmitting }: FormikHelpers<SettingsFormValues>,
  ) => {
    const {
      country,
      locale,
      notification,
      units,
      oldPassword,
      newPassword,
      newPasswordConfirm,
      handling,
    } = values;

    const data: UpdateProfileInfoDto = {
      country: country?.value,
      locale: locale?.value,
      notifications: notification?.value,
      display_weight_in: units?.value,
      handling: handling.value,
    };

    if (newPassword) {
      data.current_password = oldPassword;
      data.password = newPassword;
      data.password_confirmation = newPasswordConfirm;
    }

    onSubmitCustom({ user: data })
      .then(() => {
        setSubmitting(false);
      })
      .catch((response: Error) => {
        toastResponseError(response);
        setSubmitting(false);
      });
  },
  displayName: "SettingsForm",
});
