import React, { FC, memo, useCallback } from "react";
import { IconType } from "enums/Icon";
import { InputIconColor } from "enums/Input";
import { FormikProps } from "formik";
import { ServiceRequestFormValues } from "types/StorageForms/ServiceRequest";
import Tooltip from "../Tooltip/Tooltip";
import {
  InputIcon,
  Label,
  LabelText,
  Spinner,
  TextAreaField,
  TooltipWrapper,
  Wrapper,
} from "./TextArea.styles";

interface TextAreaProps {
  name?: string;
  className?: string;
  label?: string;
  iconColor?: InputIconColor;
  disabled: boolean;
  invalid: boolean;
  isLoading?: boolean;
  tooltip?: string;
  iconType?: IconType;
  placeholder?: string;
  value: string;
  onChange: FormikProps<ServiceRequestFormValues>["handleChange"];
  onBlur: FormikProps<ServiceRequestFormValues>["handleBlur"];
}

const TextArea: FC<TextAreaProps> = ({
  tooltip,
  invalid,
  iconType = IconType.Wrong,
  iconColor = InputIconColor.Primary,
  label,
  isLoading = false,
  className = "",
  name = "",
  disabled = false,
  placeholder = "",
  value,
  onChange,
  onBlur,
}) => {
  const withIcon = tooltip && (iconType || invalid);

  const rendererIcon = useCallback(
    () => (
      <TooltipWrapper>
        <Tooltip body={tooltip} openOnClick place="right">
          <InputIcon
            type={invalid ? IconType.Wrong : iconType}
            color={invalid ? InputIconColor.Red : iconColor}
          />
        </Tooltip>
      </TooltipWrapper>
    ),
    [tooltip, invalid, iconType, iconColor],
  );

  return (
    <Label invalid={invalid} className={className}>
      {label && <LabelText>{label} </LabelText>}
      <Wrapper>
        <TextAreaField
          $invalid={invalid}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {withIcon && rendererIcon()}
        {!!isLoading && <Spinner isActive={!!isLoading} />}
      </Wrapper>
    </Label>
  );
};

export default memo(TextArea);
