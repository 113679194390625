import { NavLink } from "react-router-dom";
import styled from "styled-components";
import infoIcon from "../Icon/icons/info.svg";

export const ProfileLogo = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${({ theme }) => theme.black50};
  background-color: ${({ theme }) => theme.lightgrey};
  font-size: 14px;
  text-decoration: none;
  font-weight: normal;
  letter-spacing: 1px;
  transition:
    background-color 0.2s,
    color 0.2s;

  ${(props) => props.theme.xs`
    background: none;
    background-image: url(${infoIcon});
    background-repeat: no-repeat;
    background-size: 30px;
    font-size: 0;
  `}

  &:hover,
  &.active {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.primary};
  }
`;
