import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { format } from "date-fns";
import { FormikProps } from "formik";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { StorageSearchValues } from "types/StorageForms/Search";
import DatePicker from "../../../../../components/DatePicker/DatePicker";
import { getFnsLocale } from "../../../../../translations/date-fns-locale";
import SearchFilter from "../SearchFilter";
import {
  DateButton,
  DatesDivider,
  DatesErrorWrapper,
  DatesWrapper,
  ExactContainer,
  ExactTitle,
  Wrapper,
} from "./Filter.styles";

const FilterDate: FC<FormikProps<StorageSearchValues>> = ({
  initialValues,
  setFieldValue,
  values,
  handleSubmit,
  ...otherProps
}) => {
  const toDatePickerRef = useRef<HTMLInputElement>(null);
  const currentDate = new Date();
  const { t, i18n } = useTranslation("common");
  const locale = getFnsLocale(i18n.language);
  const value = values.date;
  const initialValue = initialValues.date;
  const isRange = typeof value === "object";
  const startValue = isRange && value.startDate;
  const endDate = isRange && value.endDate;
  const [innerValue, setInnerValue] = useState(value);
  const isDateChanged = !isEqual(value, initialValue);

  const isWrongDates =
    (innerValue.endDate && innerValue.endDate > new Date()) ||
    (innerValue.startDate && innerValue.startDate > new Date()) ||
    (innerValue.endDate < innerValue.startDate && innerValue.endDate !== null);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const getFormattedDate = useCallback(
    (value: Date | number) => (value ? format(value, "PP", { locale }) : ""),
    [locale],
  );

  const getDatesTitle = useCallback(() => {
    const formattedStart = getFormattedDate(startValue);
    const formattedEnd = getFormattedDate(endDate);

    if (!isRange) return t(`parcels.search.${value}`);
    if (formattedEnd === formattedStart && formattedStart) return formattedEnd;
    if (endDate && startValue) return `${formattedStart} — ${formattedEnd}`;
    if (startValue) return `${t("parcels.search.dateFrom")} ${formattedStart}`;
    if (endDate) return `${t("parcels.search.dateTill")} ${formattedEnd}`;
    return t("parcels.search.date");
  }, [endDate, getFormattedDate, isRange, startValue, value, t]);

  const dropInnerValue = useCallback(() => setInnerValue(value), [value]);

  const applyInnerValue = () => {
    if (isWrongDates) {
      setInnerValue(value);
      return;
    }

    setFieldValue("date", innerValue);
  };

  const handleInnerStartChange = useCallback(
    (value: any) => {
      setInnerValue({ ...innerValue, startDate: value });

      if (toDatePickerRef.current && toDatePickerRef.current.focus) {
        toDatePickerRef.current.focus();
      }
    },
    [currentDate, innerValue, setInnerValue],
  );

  const handleInnerEndChange = useCallback(
    (value: any) => {
      setInnerValue({ ...innerValue, endDate: value });
    },
    [currentDate, innerValue, setInnerValue],
  );

  const setDefault = useCallback(() => {
    setFieldValue("date", initialValue);
    dropInnerValue();
  }, [dropInnerValue, initialValue, setFieldValue]);

  const setToday = useCallback(() => setInnerValue("today"), [setInnerValue]);

  const setYesterday = useCallback(
    () => setInnerValue("yesterday"),
    [setInnerValue],
  );

  const setWeek = useCallback(() => setInnerValue("lastWeek"), [setInnerValue]);

  const setMonth = useCallback(
    () => setInnerValue("lastMonth"),
    [setInnerValue],
  );

  const dateTitle = useMemo(() => getDatesTitle(), [getDatesTitle]);

  return (
    <SearchFilter
      applyInnerValue={applyInnerValue}
      dropInnerValue={dropInnerValue}
      handleSubmit={handleSubmit}
      setDefault={setDefault}
      title={dateTitle}
      innerTitle={t("parcels.search.selectDate")}
      isActive={isDateChanged}
      isApplyDisabled={isWrongDates}
      {...otherProps}
    >
      <Wrapper>
        <DateButton onClick={setToday} $isSelected={innerValue === "today"}>
          {t("parcels.search.today")}
        </DateButton>
        <DateButton
          onClick={setYesterday}
          $isSelected={innerValue === "yesterday"}
        >
          {t("parcels.search.yesterday")}
        </DateButton>

        <DateButton onClick={setWeek} $isSelected={innerValue === "lastWeek"}>
          {t("parcels.search.lastWeek")}
        </DateButton>
        <DateButton onClick={setMonth} $isSelected={innerValue === "lastMonth"}>
          {t("parcels.search.lastMonth")}
        </DateButton>
        <ExactContainer>
          <ExactTitle>{t("parcels.search.exactDates")}</ExactTitle>
          <DatesWrapper>
            <DatePicker
              selectsStart
              value={innerValue.startDate}
              startDate={innerValue.startDate}
              endDate={innerValue.endDate}
              onChange={handleInnerStartChange}
              placeholderText={t("common.from")}
            />
            <DatesDivider />
            <DatePicker
              selectsEnd
              value={innerValue.endDate}
              startDate={innerValue.startDate}
              endDate={innerValue.endDate}
              onChange={handleInnerEndChange}
              placeholderText={t("common.to")}
              datePickerRef={toDatePickerRef}
              invalid={isWrongDates}
            />
          </DatesWrapper>
          {isWrongDates && (
            <DatesErrorWrapper>
              {t("parcels.search.dateRangeError")}
            </DatesErrorWrapper>
          )}
        </ExactContainer>
      </Wrapper>
    </SearchFilter>
  );
};

export default memo(FilterDate);
