import { initialState } from '../reducers/destinations';
import { peek, selector } from '../selectorHelpers';

export const selectDestinations = (state) =>
  state.get('destinations', initialState);

export const destinationsSelectAddresses = selector(
  selectDestinations,
  peek('addresses'),
);
export const destinationsSelectLoading = selector(
  selectDestinations,
  peek('isLoading'),
);
