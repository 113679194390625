import React, { FC, memo, ReactNode } from "react";
import { ScrollableWrapper } from "./RightPanelComponents.styles";

interface TabProps {
  children: ReactNode;
  title?: string;
  disabled?: boolean;
  tabHeader?: React.ElementType | ReactNode;
}

const Tab: FC<TabProps> = ({ children, title, disabled }) => (
  <ScrollableWrapper>{children}</ScrollableWrapper>
);

export default memo(Tab);
