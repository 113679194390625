import color from "color";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import styled from "styled-components";

export const ServicesSection = styled.section`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  }
`;

export const ArrowIcon = styled(Icon)`
  &&& {
    transform: rotate(180deg);
    height: 12px;
    width: 12px;
    min-width: 12px;
    margin: 0 0 0 5px;
  }
`;

export const Status = styled.span`
  font-size: 12px;
  margin-right: 5px;
  white-space: nowrap;
  color: ${(props) => props.theme.black50};

  &:first-letter {
    text-transform: capitalize;
  }
`;

export const ParcelButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.black};
  padding: 7px;
  margin: 5px 0;
  width: 100%;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
    color: ${(props) => props.theme.black};
  }

  & > div {
    min-width: 16px;
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    margin-right: 5px;
  }

  &:nth-child(2) {
    display: none;
  }

  .icon {
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin-right: 10px;
  }
`;

export const HelpIcon = styled(Icon)`
  background-color: ${(props) => props.theme.grey};
  height: 12px;
  width: 12px;
  min-width: 12px;
`;

export const ReturnButton = styled(ParcelButton)`
  color: ${(props) => props.theme.red};
  width: auto;
  padding-right: 0;

  &:hover {
    opacity: 0.5;
    color: ${(props) => props.theme.red};
  }

  .icon {
    background-color: ${(props) => props.theme.red};
  }
`;

export const ReturnSection = styled.div`
  display: none;
  align-items: center;
`;

export const ReturnTooltip = styled.span`
  display: block;
  text-align: left;
  max-width: 200px;
  color: ${({ theme }) => color(theme.black).alpha(0.75).hex()};

  a {
    white-space: nowrap;
    margin-right: 5px;
    color: ${({ theme }) => theme.black};
  }
`;

export const RemoveButton = styled.div`
  color: ${({ theme }) => theme.red};
  & > i {
    background-color: ${({ theme }) => theme.red};
  }
`;
