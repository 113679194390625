import React, { FC, useEffect } from "react";
import CustomsDeclarationV2 from "components/CustomDeclaration/CustomsDeclarationV2";
import {
  BackButton,
  Heading,
  LeftArrowIcon,
  ContentWrapper as Wrapper,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor } from "enums/Button";
import { IconType } from "enums/Icon";
import { List } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { HistoryResponseDto } from "types/api/history";
import { OpenRightPanel } from "types/common/rightPanel";
import {
  getCustomsDeclarations,
  getHistoryDetailedItem,
} from "../../../../reduxFolder/reducers/historyReducer";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { historySelectFullDetailedConsolidation } from "../../../../reduxFolder/selectors/historySelector";
import { USD } from "../../../../styles/formStyles";
import formatter from "../../../../utils/formatter";
import { toastResponseError } from "../../../../utils/responseMessageHelper";
import Tab from "../../../RightPanel/components/Tab";
import TabsView from "../../../RightPanel/components/TabsView";
import {
  CustomsDeclarationContent,
  DeclarationTotal,
} from "./ParcelComponents.styles";
import ParcelInfoTab from "./ParcelInfoTab/ParcelInfoTab";

interface ParcelDetailedPaneProps {
  isOpen: boolean;
  fullDetailedHistoryConsolidation: any;
  panelData: any;
  openRightPanel: OpenRightPanel;
  getHistoryDetailedItem: (data: string) => Promise<HistoryResponseDto>;
  getCustomsDeclarations: (id: number) => Promise<void>;
}

const ParcelDetailedPanel: FC<ParcelDetailedPaneProps> = React.memo(
  ({
    isOpen,
    panelData,
    getCustomsDeclarations,
    fullDetailedHistoryConsolidation,
    openRightPanel,
    getHistoryDetailedItem,
  }) => {
    const { t } = useTranslation("common");
    const id = panelData.get("id");
    const declarations = fullDetailedHistoryConsolidation.get(
      "customs_declarations",
      List(),
    );

    const getTotal = (nameProp: string) =>
      declarations.reduce(
        (sum: number, information: any) => sum + information.get(nameProp),
        0,
      );
    const totalPrice = formatter.currency(getTotal("value"));
    const handleBack = () => {
      openRightPanel(panelData.get("returnPreviousPanel"), panelData);
    };

    useEffect(() => {
      if (isOpen && id) {
        getHistoryDetailedItem(id)
          .then(() => getCustomsDeclarations(id).catch(toastResponseError))
          .catch(toastResponseError);
      }
    }, [isOpen, getHistoryDetailedItem, getCustomsDeclarations, id]);

    return (
      <Wrapper>
        <Heading>
          <BackButton color={ButtonColor.Black50} onClick={handleBack}>
            <LeftArrowIcon type={IconType.Arrow} />
          </BackButton>
          {panelData.get("sku")}
        </Heading>
        <TabsView>
          <Tab title={t("parcels.details")}>
            <ParcelInfoTab id={id} />
          </Tab>
          <Tab title={t("parcels.customsData")}>
            <CustomsDeclarationContent>
              <DeclarationTotal>
                {`${t("shipping.total")}: ${declarations.size} ${t(
                  "shipping.totalRows",
                )} - $${totalPrice} `}
                <USD />
              </DeclarationTotal>
              {isOpen && (
                <CustomsDeclarationV2
                  itemId={id}
                  readOnly
                  declarations={declarations}
                  noPadding
                />
              )}
            </CustomsDeclarationContent>
          </Tab>
        </TabsView>
      </Wrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    fullDetailedHistoryConsolidation: historySelectFullDetailedConsolidation,
  }),
  {
    getCustomsDeclarations,
    openRightPanel,
    getHistoryDetailedItem,
  },
);

export default withConnect(ParcelDetailedPanel);
