import React, { FC, ReactElement, ReactNode } from "react";
import { ScrollableWrapper } from "./SelectComponents.styles";

interface MenuListProps {
  children: ReactNode | ReactElement | string[] | ReactNode[];
}

const MenuList: FC<MenuListProps> = ({ children }) => (
  <ScrollableWrapper>{children}</ScrollableWrapper>
);

export default React.memo(MenuList);
