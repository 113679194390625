import React, { FC, memo, Suspense } from "react";
import Layout from "layouts/Layout";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import IntercomMessenger from "../../../components/IntercomMessenger/IntercomMessenger";
import Loading from "../../../components/LoadingPage/LoadingPage";

interface PublicRouteProps {
  withMenu?: boolean;
}

const PublicRoute: FC<PublicRouteProps> = ({ withMenu = false }) => (
  <Layout withMenu={withMenu}>
    <Suspense fallback={<Loading />}>
      <Outlet />
      <IntercomWrapper>
        <IntercomMessenger />
      </IntercomWrapper>
    </Suspense>
  </Layout>
);

export default memo(PublicRoute);

const IntercomWrapper = styled.div`
  position: fixed;
  left: 40px;
  bottom: 20px;
`;
