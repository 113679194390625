import React, { FC, memo, useCallback, useState } from "react";
import { IconType } from "enums/Icon";
import { Map } from "immutable";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { HistoryResponseDto } from "types/api/history";
import {
  CollapseIcon,
  HeaderTitle,
  HeaderWrapper,
  SelectYearButton,
  Wrapper,
  YearButton,
  YearsWrapper,
} from "./HistoryHeader.styles";

interface HistoryHeaderProps {
  selectedYear: string | number;
  getHistory: (data: any) => Promise<HistoryResponseDto>;
  years: any;
}

const HistoryHeader: FC<HistoryHeaderProps> = ({
  years,
  selectedYear,
  getHistory,
}) => {
  const { t } = useTranslation("common");
  const [isOpened, setOpened] = useState(false);
  const selectYear = useCallback(
    (year: string) => () => getHistory(Map({ year })),
    [getHistory],
  );
  const toggleCollapse = () => setOpened(!isOpened);
  const hasYears = !!(years && years.size);

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTitle>
          {t("parcels.historyForSelectedYear", { selectedYear })}
        </HeaderTitle>
        {hasYears && (
          <SelectYearButton onClick={toggleCollapse}>
            {t("common.selectYear")}
            <CollapseIcon type={IconType.Arrow} $isOpened={isOpened} />
          </SelectYearButton>
        )}
      </HeaderWrapper>
      {hasYears && (
        <Collapse isOpened={isOpened}>
          <YearsWrapper>
            {years.map((year: any) => (
              <YearButton
                key={year.get(0)}
                $isActive={selectedYear === year.get(0)}
                onClick={selectYear(year.get(0))}
              >
                {year.get(0)}
              </YearButton>
            ))}
          </YearsWrapper>
        </Collapse>
      )}
    </Wrapper>
  );
};

export default memo(HistoryHeader);
