import React, { FC, memo, useCallback, useState } from "react";
import Icon from "components/Icon/Icon";
import PackagesPagination from "components/PackagesPagination/PackagesPagination";
import { IconType } from "enums/Icon";
import { useTranslation } from "react-i18next";
import { HeaderTitle, InnerSection, Section } from "styles/outgoingStyles";
import ParcelInfoTabPackageInfo from "./ParcelInfoTabPackageInfo";

interface ParcelInfoTabPaginatedPackagesSectionProps {
  packages: any;
  hasFewPackages: boolean;
}

const ParcelInfoTabPaginatedPackagesSection: FC<
  ParcelInfoTabPaginatedPackagesSectionProps
> = ({ packages, hasFewPackages }) => {
  const { t } = useTranslation("common");
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);
  const pkg = packages.get(selectedPackageIndex);

  const handleSetSelectPackageIndex = useCallback(
    (index: number) => {
      setSelectedPackageIndex(index);
    },
    [setSelectedPackageIndex],
  );

  return (
    <>
      <Section>
        <HeaderTitle>
          <Icon type={IconType.Package} />
          {hasFewPackages
            ? t("parcels.parcelsInformation")
            : t("parcels.parcelInformation")}
          :
        </HeaderTitle>
        {hasFewPackages && (
          <InnerSection>
            <PackagesPagination
              count={packages.count()}
              selectedIndex={selectedPackageIndex}
              onSelect={handleSetSelectPackageIndex}
            />
          </InnerSection>
        )}
      </Section>
      <ParcelInfoTabPackageInfo pkg={pkg} showMPSInfo={hasFewPackages} />
    </>
  );
};

export default memo(ParcelInfoTabPaginatedPackagesSection);
