import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormikProps } from "formik";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { StorageSearchValues } from "types/StorageForms/Search";
import Input from "../../../../../components/Input/Input";
import RangeSlider from "../../../../../components/RangeSlider/RangeSlider";
import { authSelectUnits } from "../../../../../reduxFolder/selectors/globalSelectors";
import { TooltipContent } from "../Search.styles";
import SearchFilter from "../SearchFilter";
import { getMaxWeightValue } from "../searchFormik";
import { HorizonalDivider, InputsWrapper, UnitsWrapper } from "./Filter.styles";

interface FilterWeightProps extends FormikProps<StorageSearchValues> {
  units: string;
}

const FilterWeight: FC<FilterWeightProps> = React.memo(
  ({
    initialValues,
    setFieldValue,
    values,
    handleSubmit,
    units,
    ...otherProps
  }) => {
    const { t } = useTranslation("common");
    const value = values.weightRange;
    const [innerValue, setInnerValue] = useState(value);
    const initialValue = initialValues.weightRange;
    const isWeightChanged = isEqual(value, initialValue);

    useEffect(() => {
      setInnerValue(value);
    }, [value]);

    const getWeigthTitle = useCallback(() => {
      const value = innerValue || [];
      const isWeightEquals = isEqual(value[0], value[1]);
      if (isWeightChanged) return t("parcels.weight");
      if (isWeightEquals)
        return `${value[0]} ${t(`units.${units}.primaryShort`)}`;

      return `${value[0]} ${t(`units.${units}.primaryShort`)} — ${value[1]} ${t(
        `units.${units}.primaryShort`,
      )}`;
    }, [innerValue, isWeightChanged, units, t]);

    const dropInnerValue = useCallback(() => setInnerValue(value), [value]);

    const applyInnerValue = useCallback(
      () => setFieldValue("weightRange", innerValue),
      [innerValue, setFieldValue],
    );

    const handleInnerChange = useCallback(
      (value: any) => setInnerValue(value),
      [setInnerValue],
    );

    const setDefault = useCallback(() => {
      setFieldValue("weightRange", initialValue);
      dropInnerValue();
    }, [dropInnerValue, initialValue, setFieldValue]);

    const weightRangeTitle = useMemo(() => getWeigthTitle(), [getWeigthTitle]);
    const min = 0;
    const max = getMaxWeightValue(units);

    const handleInputChange = (
      event: ChangeEvent<HTMLInputElement>,
      isMinimumInput = false,
    ) => {
      const parsedValue = parseFloat(event.target.value);
      const nextValue = !isNaN(parsedValue)
        ? parsedValue
        : isMinimumInput
          ? min
          : max;
      const nextArray = isMinimumInput
        ? [nextValue, innerValue ? innerValue[1] : max]
        : [innerValue ? innerValue[0] : min, nextValue];

      handleInnerChange(nextArray);
    };

    return (
      <SearchFilter
        applyInnerValue={applyInnerValue}
        dropInnerValue={dropInnerValue}
        handleSubmit={handleSubmit}
        setDefault={setDefault}
        title={weightRangeTitle}
        innerTitle={t("parcels.search.specifyWeight")}
        isActive={!isWeightChanged}
        {...otherProps}
      >
        <TooltipContent>
          <UnitsWrapper>
            <span>{units}</span>
          </UnitsWrapper>
          <RangeSlider
            onChange={handleInnerChange}
            min={min}
            max={max}
            step={0.1}
            units={t(`units.${units}.primaryShort`)}
            defaultValue={[min, max]}
            value={innerValue ?? []}
          />
          <InputsWrapper>
            <Input
              type="number"
              value={innerValue && innerValue[0]}
              onChange={(event) => handleInputChange(event, true)}
            />
            <HorizonalDivider />
            <Input
              type="number"
              value={innerValue && innerValue[1]}
              onChange={(event) => handleInputChange(event)}
            />
          </InputsWrapper>
        </TooltipContent>
      </SearchFilter>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    units: authSelectUnits,
  }),
);

export default withConnect(FilterWeight);
