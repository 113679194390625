import React, { FC, memo } from "react";
import { CustomsDeclarationV2Props } from "types/customDeclaration";
import CustomsDeclarationV2Form from "./components/CustomsDeclarationV2Form";

const CustomsDeclarationV2: FC<CustomsDeclarationV2Props> = ({
  itemId,
  create,
  remove,
  update,
  declarations: declarationsProp,
  onSubmit,
  readOnly,
  onCustomsDataChange,
  noPadding,
  autoFocus,
  isWizardStep,
  isShipmentImportCustomsDeclaration,
  hideFileImport,
  isShowHSCodeColumn,
  isHSCodeValidationRequired,
  ...rest
}) => {
  const declarations = declarationsProp.toJS
    ? declarationsProp.toJS()
    : declarationsProp;

  return (
    //@ts-ignore FIXME: Delete after refactoring redux
    <CustomsDeclarationV2Form
      declarations={declarations}
      itemId={itemId}
      create={create}
      update={update}
      remove={remove}
      onSubmit={onSubmit}
      readOnly={readOnly}
      onCustomsDataChange={onCustomsDataChange}
      noPadding={noPadding}
      autoFocus={autoFocus}
      isWizardStep={isWizardStep}
      isShipmentImportCustomsDeclaration={isShipmentImportCustomsDeclaration}
      hideFileImport={hideFileImport}
      isShowHSCodeColumn={isShowHSCodeColumn}
      isHSCodeValidationRequired={isHSCodeValidationRequired}
      {...rest}
    />
  );
};

export default memo(CustomsDeclarationV2);
