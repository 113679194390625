import {
  CustomsDeclarationV2FormProps,
  FormValue,
  FormValues,
  HandleSubmitHelpers,
} from "types/customDeclaration";
import { toastResponseError } from "utils/responseMessageHelper";
import * as yup from "yup";
import { detectCyrillic } from "./helpers";

const isFullyDuplicateRow = (
  items: FormValues = [],
  currentIndex: number,
  fields: string[],
) => {
  const currentItem = items[currentIndex];

  const duplicateIndices = items.reduce((acc: number[], item, index) => {
    const isDuplicate = fields.every(
      (field) =>
        item[field as keyof typeof item] ==
        currentItem[field as keyof typeof currentItem],
    );
    if (isDuplicate) {
      acc.push(index);
    }
    return acc;
  }, []);

  return duplicateIndices[0] === currentIndex;
};

const valueTestDuplicated = (
  value: FormValue,
  params: yup.TestContext<FormValues>,
  isShowHSCodeColumn: boolean,
) => {
  if (value.description) {
    const fields = isShowHSCodeColumn
      ? ["description", "hs_code", "quantity", "value"]
      : ["description", "quantity", "value"];
    const { path } = params;
    const items = params.options.context;

    const currentIndex = parseInt(path.match(/\d+/)?.[0] ?? "0", 10);

    return isFullyDuplicateRow(items, currentIndex, fields);
  }
  return true;
};

const descriptionSchema = yup.string().required();
const quantitySchema = yup
  .number()
  .integer()
  .min(1, "Value must be greater than or equal to 1")
  .required();
const valueSchema = yup
  .number()
  .transform((_, value) => {
    if (typeof value === "string") {
      return parseFloat(value.replace(/,/, "."));
    }
    return value;
  })
  .min(0.01, "Value must be greater than or equal to 0.01")
  .required();

export const customDeclarationForm = {
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
  mapPropsToValues: ({ declarations }: CustomsDeclarationV2FormProps) => {
    let retVal = [];
    retVal.push(...declarations);
    for (let i = declarations.length; i < 50; i++) {
      retVal.push({
        tempId: i - declarations.length,
      });
    }
    return retVal;
  },
  validationSchema: ({
    isShowHSCodeColumn,
    isHSCodeValidationRequired,
  }: CustomsDeclarationV2FormProps) =>
    yup.array().of(
      yup
        .object()
        .shape({
          description: yup.string().test("isDescValid", (value, params) => {
            if (detectCyrillic(value ?? "")) {
              return false;
            }
            if (!params.parent.quantity && !params.parent.value && !value) {
              return true;
            }
            return descriptionSchema.isValidSync(value);
          }),
          ...(isShowHSCodeColumn
            ? {
                hs_code: yup
                  .string()
                  .test("isHsCodeValid", "Invalid HS code", function (value) {
                    const { hs_code, value: valueField } = this.parent;
                    if (!hs_code && !valueField && !value) return true;
                    if (isHSCodeValidationRequired)
                      return yup.string().required().isValidSync(value);

                    return yup.string().isValidSync(value);
                  }),
              }
            : {}),
          quantity: yup.number().test("isQuantityValid", (value, params) => {
            if (!params.parent.description && !params.parent.value && !value)
              return true;
            return quantitySchema.isValidSync(value);
          }),
          value: yup.mixed().test("isValueValid", (value, params) => {
            if (!params.parent.description && !params.parent.quantity && !value)
              return true;
            return valueSchema.isValidSync(value);
          }),
        })
        .test(
          "isDuplicateRow",
          "Duplicate row found",
          function (value, params) {
            return valueTestDuplicated(
              value as FormValue,
              params as yup.TestContext<FormValues>,
              isShowHSCodeColumn ?? false,
            );
          },
        ),
    ),
  handleSubmit: (
    values: FormValues,
    {
      props: {
        onSubmit,
        declarations,
        create,
        update,
        remove,
        itemId,
        isShowHSCodeColumn,
      },
      setSubmitting,
    }: HandleSubmitHelpers,
  ) => {
    const toUpdateList = [];
    const toCreateList = [];
    const toDeleteList = [];
    for (const item of values) {
      const convertedItem = {
        ...item,
        description: item.description,
        quantity: item.quantity ? +item.quantity : 0,
        value:
          typeof item.value === "string"
            ? parseFloat(item.value.replace(/,/, "."))
            : item.value,
      };
      if (isShowHSCodeColumn) {
        convertedItem.hs_code = item.hs_code;
      }

      if (item.id) {
        const prevItem = declarations.find((x: any) => x.id === item.id);
        if (
          item.description !== prevItem.description ||
          item.quantity !== prevItem.quantity ||
          item.value !== prevItem.value ||
          item.hs_code !== prevItem.hs_code
        ) {
          toUpdateList.push(convertedItem);
        }
      } else if (item.description && item.quantity && item.value) {
        toCreateList.push(convertedItem);
      }
    }
    for (const item of declarations) {
      const existingItem = values.find((x) => x.id === item.id);
      if (!existingItem) {
        toDeleteList.push(item);
      }
    }

    const promiseRequests = [];
    for (const item of toUpdateList) {
      promiseRequests.push(update({ itemId, id: item.id, data: item }));
    }
    for (const item of toCreateList) {
      promiseRequests.push(
        create({
          itemId,
          data: { ...item, weight: 1, country_of_origin: "US" },
        }),
      );
    }
    for (const item of toDeleteList) {
      promiseRequests.push(remove({ itemId, id: item.id }));
    }
    return Promise.all(promiseRequests)
      .then(() => onSubmit && onSubmit())
      .catch((response) => toastResponseError(response))
      .finally(() => setSubmitting(false));
  },
  displayName: "CustomsDeclaration",
};
