import {
  ErrorsFormValues,
  FormValue,
  FormValues,
} from "types/customDeclaration";

export function getShowError(
  values: FormValues,
  errors: ErrorsFormValues,
  index: number,
  fieldName: keyof FormValue,
  minNumericValue?: number,
): boolean {
  const fieldValue = values[index][fieldName];
  const fieldErrors = errors?.[index]?.[fieldName];

  if (
    fieldErrors &&
    (fieldValue === "" ||
      fieldValue === null ||
      fieldValue === undefined ||
      typeof fieldValue === "string" ||
      errors[index] === "Duplicate row found")
  ) {
    return true;
  }

  if (minNumericValue !== undefined && typeof fieldValue === "string") {
    const parsedNumber = parseFloat(fieldValue);
    return (
      (fieldErrors
        ? isNaN(parsedNumber) || parsedNumber < minNumericValue
        : false) || errors[index] === "Duplicate row found"
    );
  }

  return !!fieldErrors;
}

export const detectCyrillic = (input: string): boolean =>
  /[\u0400-\u04FF]/.test(input);
