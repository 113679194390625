import React, { FC, memo } from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Content,
  ContentWrapper,
  CostContent,
  CostWrapper,
  PriceWrapper,
  Title,
} from "./PriceBlock.styles";

interface PriceBlockProps {
  title: string;
  insuranceCostTitle?: string;
  price: string | number;
  insuranceCost?: string | number;
  serviceAndOptions?: string | number;
  serviceAndOptionsTitle?: string;
  isInsuranceIncluded?: boolean;
  suffix?: string;
}

const PriceBlock: FC<PriceBlockProps> = ({
  title,
  price,
  insuranceCost = 0,
  insuranceCostTitle,
  serviceAndOptions = 0,
  isInsuranceIncluded,
  serviceAndOptionsTitle,
  suffix,
}) => {
  const { t } = useTranslation("common");

  return (
    <PriceWrapper>
      <Content>
        <ContentWrapper>
          <Title>{title}:</Title>
          <CostWrapper>
            <CostContent>
              {price ? (
                <>
                  <span>$</span>
                  {price}&nbsp;{suffix}
                </>
              ) : (
                t("common.free")
              )}
            </CostContent>
          </CostWrapper>
        </ContentWrapper>
        {!!serviceAndOptions && (
          <ContentWrapper>
            <Title>{serviceAndOptionsTitle}:</Title>
            <CostWrapper>
              <CostContent>
                <span>$</span>
                {serviceAndOptions}
              </CostContent>
            </CostWrapper>
          </ContentWrapper>
        )}
        {isInsuranceIncluded && !!insuranceCost && (
          <ContentWrapper>
            <Title>{insuranceCostTitle}:</Title>
            <CostWrapper>
              <CostContent>
                <span>$</span>
                {insuranceCost}
              </CostContent>
            </CostWrapper>
          </ContentWrapper>
        )}
      </Content>
    </PriceWrapper>
  );
};

export default memo(PriceBlock);
