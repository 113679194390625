import React, { FC, useCallback, useEffect } from "react";
import { IconType } from "enums/Icon";
import { isMobile } from "react-device-detect";
import { FocusOn } from "react-focus-on";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useBlocker, useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import useOnClickOutside from "use-onclickoutside";
import Icon from "../../components/Icon/Icon";
import {
  closeRightPanel,
  openRightPanel,
} from "../../reduxFolder/reducers/rightPanel";
import {
  panelSelectData,
  panelSelectOpened,
  panelSelectType,
} from "../../reduxFolder/selectors/rightPanelSelectors";
import { breakpoints } from "../../styles/styles";
import { isInIgnoredElement, PANEL_COMPONENTS } from "./helpers";
import { CloseButton, Container, Wrapper } from "./RightPanel.styles";

interface RightPanelProps {
  isOpen: boolean;
  panelType: string;
  panelData: any;
  openRightPanel: OpenRightPanel;
  closeRightPanel: () => void;
}

const RightPanel: FC<RightPanelProps> = ({
  isOpen,
  panelType,
  panelData,
  closeRightPanel,
  openRightPanel,
}) => {
  const { t } = useTranslation("common");
  const { pathname } = useLocation();
  const panelRef = React.useRef(null);
  const Panel = PANEL_COMPONENTS[panelType as keyof typeof PANEL_COMPONENTS];
  const isScrollDisabled = isOpen && window.innerWidth <= +breakpoints.xs;
  const hideCloseButton = panelData && panelData.get("hideCloseButton");
  const closeButtonHandler = panelData && panelData.get("closeButtonHandler");

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isOpen && currentLocation.pathname !== nextLocation.pathname,
  );

  const handleClose = useCallback(() => {
    if (!isOpen || hideCloseButton) return;

    if (closeButtonHandler) {
      closeButtonHandler({ closeRightPanel, openRightPanel });
    } else {
      closeRightPanel();
    }
  }, [
    closeRightPanel,
    openRightPanel,
    hideCloseButton,
    closeButtonHandler,
    isOpen,
  ]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      handleClose();
      blocker.reset();
    }
  }, [handleClose, blocker]);

  const onClickClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  useOnClickOutside(panelRef, (event) => {
    if (pathname === "/parcels") return;
    if (pathname === "/parcels/expected") return;
    if (pathname === "/books/add-funds" && !isMobile) return;
    if (pathname.startsWith("/parcels/inventory")) return;

    // HOTFIX for popover https://github.com/Andarist/use-onclickoutside/issues/1
    const clickOnIgnoreElement = isInIgnoredElement(
      event.target,
      "ServiceTooltipItem",
    );

    isOpen &&
      !clickOnIgnoreElement &&
      window.addEventListener("mouseup", handleClose, true);
  });

  const handleKeyDown: EventListener = (event: Event) => {
    if (event instanceof KeyboardEvent && event.key === "Escape") {
      handleClose();
    }
  };

  useEffect(() => {
    if (panelData && panelData.get("preventAutoCloseOnNavigationChange")) {
      openRightPanel(
        "STORAGE_ITEM",
        panelData.set("preventAutoCloseOnNavigationChange", false),
      );
      return;
    }

    closeRightPanel();
  }, [pathname]);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown, true);
      window.addEventListener("popstate", handleClose, true);
      window.removeEventListener("mousedown", handleClose, true);
    } else {
      window.removeEventListener("keydown", handleKeyDown, true);
      window.removeEventListener("popstate", handleClose, true);
    }

    return () => {
      window.removeEventListener("mouseup", handleClose, true);
      window.removeEventListener("mousedown", handleClose, true);
      window.removeEventListener("popstate", handleClose, true);
    };
  }, [isOpen, handleKeyDown, handleClose]);

  return (
    <FocusOn autoFocus enabled={isScrollDisabled}>
      <Wrapper ref={panelRef} $isOpen={isOpen}>
        <Container>
          {!hideCloseButton && (
            <CloseButton
              title={t("common.close")}
              aria-label="close-panel"
              onClick={onClickClose}
            >
              <Icon type={IconType.Close} />
            </CloseButton>
          )}
          {!!Panel && (
            <Panel isOpen={isOpen} key={panelData} panelData={panelData} />
          )}
        </Container>
      </Wrapper>
    </FocusOn>
  );
};

const withConnect = connect(
  createStructuredSelector({
    isOpen: panelSelectOpened,
    panelType: panelSelectType,
    panelData: panelSelectData,
  }),
  { closeRightPanel, openRightPanel },
);

export default withConnect(RightPanel);
