import React, { FC, MouseEvent, useCallback, useEffect } from "react";
import { ButtonColor } from "enums/Button";
import { IconType } from "enums/Icon";
import { List, Map } from "immutable";
import { isEmpty } from "lodash";
import queryString from "query-string";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { CartResponseDto, DeleteCartResponseDto } from "types/api/cart";
import { OutgoingResponseDto } from "types/api/outgoing";
import { OpenRightPanel } from "types/common/rightPanel";
import Icon from "../../components/Icon/Icon";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import injectReducer from "../../reduxFolder/injectReducer";
import { deleteCart, getCart } from "../../reduxFolder/reducers/cart";
import reducer, { getOutgoing } from "../../reduxFolder/reducers/outgoing";
import {
  completeAllPayPal,
  completePayPalPayment,
} from "../../reduxFolder/reducers/purchase";
import { openRightPanel } from "../../reduxFolder/reducers/rightPanel";
import { getAllDeliveryMethods } from "../../reduxFolder/reducers/services";
import {
  cartSelectForOutgoing,
  cartSelectLoading,
} from "../../reduxFolder/selectors/cartSelectors";
import { authSelectUnits } from "../../reduxFolder/selectors/globalSelectors";
import {
  outgoingSelectInformationData,
  outgoingSelectLoading,
  outgoingSelectPackingData,
  outgoingSelectPaidData,
  outgoingSelectPaymentData,
} from "../../reduxFolder/selectors/outgoingSelectors";
import { servicesSelectAllDelivery } from "../../reduxFolder/selectors/servicesSelectors";
import { HeaderTitle, ParcelButton } from "../../styles/parcelsStyles";
import formatter from "../../utils/formatter";
import {
  toastResponseError,
  toastResponseSuccess,
} from "../../utils/responseMessageHelper";
import InProgress from "./components/InProgress/InProgress";
import NoItemsPlaceholder from "./components/NoItemsPlaceholder";
import NotFinished from "./components/NotFinished/NotFinished";
import OutgoingSection from "./components/OutgoingSection/OutgoingSection";
import Paid from "./components/Paid/Paid";
import WaitingForInformation from "./components/WaitingFor/WaitingForInformation";
import WaitingForPayment from "./components/WaitingFor/WaitingForPayment";
import { OutgoingWrapper, PayButton } from "./Outgoing.styles";

interface OutgoingProps {
  units: string;
  isLoading: boolean;
  isCartLoading: boolean;
  allDeliveryMethods: any;
  paidItemsData: any;
  paymentItemsData: any;
  informationItemsData: any;
  packingItemsData: any;
  userCart: any;
  getCart: () => Promise<CartResponseDto>;
  deleteCart: () => Promise<DeleteCartResponseDto>;
  openRightPanel: OpenRightPanel;
  getAllDeliveryMethods: () => Promise<void>;
  getOutgoing: (data?: any) => Promise<OutgoingResponseDto>;
  completeAllPayPal: (data: any) => Promise<void>;
  completePayPalPayment: (data: any) => Promise<void>;
}

const Outgoing: FC<OutgoingProps> = React.memo(
  ({
    paymentItemsData,
    informationItemsData,
    packingItemsData,
    paidItemsData,
    openRightPanel,
    getOutgoing,
    isLoading,
    isCartLoading,
    getCart,
    deleteCart,
    userCart,
    units,
    getAllDeliveryMethods,
    allDeliveryMethods,
    completePayPalPayment,
    completeAllPayPal,
  }) => {
    const params = useParams();
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const location = useLocation();
    const hasItems = !!(
      paymentItemsData.get("items").size ||
      informationItemsData.get("items").size ||
      packingItemsData.get("items").size ||
      userCart.get("items", List()).size ||
      paidItemsData.get("items", List()).size
    );
    const consolidationId = location.state && location.state.consolidationId;

    useEffect(() => {
      const query = queryString.parse(location.search);
      const parcelId = params?.id;
      const paymentResult = params?.payment_result;

      if (!parcelId || !paymentResult || isEmpty(query)) {
        return;
      }

      if (paymentResult === "success") {
        if (parcelId === "all") {
          completeAllPayPal({ query });
        } else {
          completePayPalPayment({ id: parcelId, query });
        }
        navigate("/parcels/outgoing", { replace: true });
      } else if (paymentResult === "cancel") {
        toastResponseError(t("error.paymentWasCanceled"));
        navigate("/parcels/outgoing", { replace: true });
      }
    }, []);

    useEffect(() => {
      !allDeliveryMethods.size && getAllDeliveryMethods();

      if (consolidationId) {
        const parcel =
          paymentItemsData
            .get("items", List())
            .find((item: any) => item.get("id") === consolidationId) ||
          informationItemsData
            .get("items", List())
            .find((item: any) => item.get("id") === consolidationId) ||
          packingItemsData
            .get("items", List())
            .find((item: any) => item.get("id") === consolidationId) ||
          userCart
            .get("items", List())
            .find((item: any) => item.get("id") === consolidationId) ||
          List();
        const parcelState = parcel.get("state");

        const namePanel =
          (parcelState === "packed" && "WAITING_PAYMENT") ||
          (parcelState === "requesting" && "NOT_FINISHED") ||
          "IN_PROGRESS";

        openRightPanel(
          namePanel,
          Map({
            detailedItemID: consolidationId,
            returnPreviousPanel: namePanel,
            foundPackageId: location.state.shipmentId,
            shipments: userCart.getIn(["items", 0, "items"]),
            sku: userCart.getIn(["items", 0, "sku"]),
          }),
        );
        navigate(location.pathname, { state: undefined, replace: true });
      }
    }, []);

    const clickPayAll = useCallback(
      (event: MouseEvent) => {
        event.stopPropagation();
        const total = paymentItemsData.get("total");
        const items = paymentItemsData.get("items");
        const shouldFetchAll = parseInt(total, 10) !== items.size;
        const loadMoreData = {
          type: paymentItemsData.get("type"),
          params: {
            per_page: total,
            page: 1,
          },
        };

        if (shouldFetchAll) getOutgoing(Map(loadMoreData));

        openRightPanel("PAY_ALL");
      },
      [paymentItemsData],
    );

    const inProgressItemsCount = packingItemsData.get("total") || "0";
    const informationItemsCount = informationItemsData.get("total") || "0";
    const paymentItemsCount = paymentItemsData.get("total") || "0";
    const paidItemsCount = paidItemsData.get("total") || "0";

    const inProgressHeaderTitle = `${t(
      "parcels.inProgress",
    )}: ${inProgressItemsCount}`;
    const waitingForInformationHeaderTitle = `${t(
      "parcels.waitingForInformation",
    )}: ${informationItemsCount}`;

    const waitingForPaymentHeaderTitle = `${t(
      "parcels.waitingForPayment",
    )}: ${paymentItemsCount}`;

    const paidItemsHeaderTitle = `${t("parcels.paymentPaid")}: ${paidItemsCount}`;

    const onResumeOutgoingHandler = useCallback(
      (event: MouseEvent) => {
        event.stopPropagation();
        navigate("/shipping");
      },
      [navigate],
    );

    const onCancelOutgoingHandler = useCallback(
      (event: MouseEvent) => {
        event.stopPropagation();
        if (
          window.confirm(t("popup.notFinished.deletionConfirmation")) === true
        ) {
          deleteCart()
            .then(() => toastResponseSuccess(t("common.deleteCartSuccess")))
            .catch(toastResponseError);
        }
      },
      [deleteCart, t],
    );

    const renderPayAllButton = () => {
      return (
        <PayButton color={ButtonColor.Secondary} onClick={clickPayAll}>
          {`${t("shipping.total")}`}
          <b>{` $${formatter.currency(paymentItemsData.get("totalPrice"))}`}</b>
        </PayButton>
      );
    };

    return (
      <OutgoingWrapper>
        <Container fluid>
          <LoadingBar isLoading={isLoading} isCartLoading={isCartLoading} />
          <OutgoingSection
            isCollapseOpen
            data={userCart}
            fetchData={getCart}
            itemRenderer={(parcel: any) => (
              <NotFinished
                units={units}
                parcel={parcel}
                key={parcel.get("id")}
                handleContinue={onResumeOutgoingHandler}
                handleCancel={onCancelOutgoingHandler}
              />
            )}
          >
            <Row>
              <Col xs={6}>
                <HeaderTitle>
                  <Icon type={IconType.Pause} color="#ffaf5d" />
                  {t("parcels.notFinished")}
                </HeaderTitle>
              </Col>
              <Col xs={6}>
                <Visible xs>
                  <Row justify="center">
                    <Col xs={12}>
                      <ParcelButton
                        color={ButtonColor.Primary}
                        onClick={onResumeOutgoingHandler}
                      >
                        {t("common.resume")}
                      </ParcelButton>
                      <ParcelButton
                        color={ButtonColor.Red}
                        onClick={onCancelOutgoingHandler}
                      >
                        {t("common.cancel")}
                      </ParcelButton>
                    </Col>
                  </Row>
                </Visible>
              </Col>
            </Row>
          </OutgoingSection>
          <OutgoingSection
            data={packingItemsData}
            fetchData={getOutgoing}
            countItemsPerSection={5}
            isCollapseOpen
            itemRenderer={(parcel: any) => (
              <InProgress
                units={units}
                parcel={parcel}
                key={parcel.get("id")}
              />
            )}
          >
            <HeaderTitle>
              <Icon type={IconType.Add} color="#3ebcf7" />
              {inProgressHeaderTitle}
            </HeaderTitle>
          </OutgoingSection>
          <OutgoingSection
            data={informationItemsData}
            fetchData={getOutgoing}
            isCollapseOpen
            itemRenderer={(parcel: any) => (
              <WaitingForInformation
                units={units}
                parcel={parcel}
                key={parcel.get("id")}
              />
            )}
          >
            <HeaderTitle>
              <Icon type={IconType.Exclamation} color="#d788ee" />
              {waitingForInformationHeaderTitle}
            </HeaderTitle>
          </OutgoingSection>
          <OutgoingSection
            data={paymentItemsData}
            fetchData={getOutgoing}
            isCollapseOpen
            itemRenderer={(parcel: any) => (
              <WaitingForPayment
                units={units}
                parcel={parcel}
                key={parcel.get("id")}
              />
            )}
          >
            <Row>
              <Col xs={7} sm={12}>
                <HeaderTitle>
                  <Icon type={IconType.Money} color="#1fd983" />
                  {waitingForPaymentHeaderTitle}
                </HeaderTitle>
                <Hidden xs>{renderPayAllButton()}</Hidden>
              </Col>
              <Visible xs>
                <Col xs={5}>
                  <Row justify="end">
                    <Col xs={12}>{renderPayAllButton()}</Col>
                  </Row>
                </Col>
              </Visible>
            </Row>
          </OutgoingSection>
          <OutgoingSection
            data={paidItemsData}
            fetchData={getOutgoing}
            isCollapseOpen
            itemRenderer={(parcel: any) => (
              <Paid units={units} parcel={parcel} key={parcel.get("id")} />
            )}
          >
            <HeaderTitle>
              <Icon type={IconType.Money} color="#D25825" />
              {paidItemsHeaderTitle}
            </HeaderTitle>
          </OutgoingSection>
          {!isLoading && !hasItems && <NoItemsPlaceholder />}
        </Container>
      </OutgoingWrapper>
    );
  },
);

const withReducer = injectReducer({
  key: "outgoing",
  reducer,
});

const withConnect = connect(
  createStructuredSelector({
    paymentItemsData: outgoingSelectPaymentData,
    informationItemsData: outgoingSelectInformationData,
    packingItemsData: outgoingSelectPackingData,
    paidItemsData: outgoingSelectPaidData,
    isLoading: outgoingSelectLoading,
    isCartLoading: cartSelectLoading,
    units: authSelectUnits,
    userCart: cartSelectForOutgoing,
    allDeliveryMethods: servicesSelectAllDelivery,
  }),
  {
    getCart,
    deleteCart,
    getOutgoing,
    getAllDeliveryMethods,
    completePayPalPayment,
    completeAllPayPal,
    openRightPanel,
  },
);

const enhance = compose(withReducer, withConnect);

export default enhance(Outgoing);
