import Icon from "components/Icon/Icon";
import Logo from "components/Logo/Logo";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

export const ItemCompact = styled.div`
  align-items: center;
  display: flex;
  padding: 2px 0 !important;
`;

export const HeadTextCompact = styled.div`
  color: ${({ theme }) => theme.black50};
  margin-right: 5px;
`;

export const HeadText = styled.div`
  color: ${({ theme }) => theme.black50};
  width: 100%;
`;

export const Item = styled.div`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  flex-direction: column;
  padding: 18px 0;
  border-top: 1px solid ${({ theme }) => theme.lightgrey};
  animation: 0.8s ${keyframes`${fadeIn}`};
`;

interface ItemHeadProps {
  withDescription?: boolean;
}

export const ItemHead = styled.div<ItemHeadProps>`
  display: flex;
  align-items: ${({ withDescription }) => withDescription && "center"};
  color: ${({ theme }) => theme.black50};
  font-size: 12px;
  margin-bottom: 5px;
  position: relative;
`;

export const ItemHeadContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ItemHeadPrice = styled.div`
  margin-right: 5px;
`;

export const ItemSection = styled.div`
  padding: 0 8px;
  margin: 5px 0px;
`;

export const ListItemCompact = styled.p`
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;

  i + span {
    padding-top: 1px;
  }
`;

export const ListItem = styled.p`
  margin: 0 0 8px 0;
  position: relative;
  display: flex;
  align-items: center;

  i + span {
    padding-top: 1px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ServiceRequestIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.secondary};

  ${({ theme }) => theme.sm`
    height: 12px;
    width: 12px;
  `}
`;

export const Description = styled.div`
  padding-top: 2px;
`;

export const ItemLogo = styled(Logo)`
  margin-right: 5px;
`;

export const ItemIcon = styled.span`
  display: flex;
  margin-right: 8px;
`;
