import formatter from "./formatter";

interface CustomsItem {
  description?: string;
  quantity?: number;
  value?: string | number;
}

const getCustomsDataTotal = (sum: number, item: CustomsItem): number => {
  const parsedNumber =
    typeof item?.value === "string"
      ? parseFloat(item.value.replace(/,/, "."))
      : item.value;

  return isNaN(parsedNumber ?? 0) ||
    parsedNumber === null ||
    parsedNumber === undefined
    ? sum
    : sum + parsedNumber;
};

export const calculateCustomsDataTotal = (
  newValues: CustomsItem[],
): [string, number] => {
  const filteredValues = newValues.filter(
    (item) =>
      item.description &&
      item.quantity &&
      item.value !== undefined &&
      item.value !== null,
  );
  const newTotal = filteredValues.reduce(getCustomsDataTotal, 0);

  return [formatter.currency(newTotal), filteredValues.length];
};
