import React, { FC } from "react";
import Input from "components/Input/Input";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const InventoryItemCommentSkeleton: FC = () => {
  const { t } = useTranslation("common");

  return (
    <CommentWrapper>
      <Input disabled placeholder={t("parcels.leaveComment")} />
    </CommentWrapper>
  );
};

export const CommentWrapper = styled.div`
  margin-bottom: 15px;
`;
