import React, { FC, memo } from "react";
import { ListItem, Wrapper } from "../../styles/Delivery.styles";
import ServicesLogo from "../ServicesLogo";
import { Description, Divider, Info, Price, Type } from "./DeliveryList.styles";

interface DeliveryListProps {
  selectedDelivery: string;
  onSelect: (name: string) => void;
  onSubmit: (type: string) => Promise<void>;
  deliveryMethods: any;
}

const DeliveryList: FC<DeliveryListProps> = ({
  deliveryMethods,
  selectedDelivery,
  onSelect,
  onSubmit,
}) => (
  <Wrapper>
    {deliveryMethods &&
      deliveryMethods.map((item: any) => {
        const name = item.get("name");

        return (
          <ListItem
            key={name}
            $isSelected={selectedDelivery === name}
            onClick={() => onSelect(name)}
            onDoubleClick={() => onSubmit(name)}
          >
            <ServicesLogo logoUrl={item.get("logo_url")} />
            <Info>
              <Type>
                <b>{name}</b> <Divider>|</Divider> {item.get("time_frame")}
              </Type>
              <Description>
                {item
                  .get("descriptions")
                  .map((description: string, index: number) => (
                    <span key={index}> - {description}</span>
                  ))}
              </Description>
            </Info>
            <Price>
              {item.get("price")}
              <span>{item.get("price_per")}</span>
            </Price>
          </ListItem>
        );
      })}
  </Wrapper>
);

export default memo(DeliveryList);
