import AddressForm from "components/Addresses/components/AddressForm";
import BooksAddFundsPanel from "../Books/components/BooksAddFunds/BooksAddFundsPanel";
import ExpectedItemPanel from "../Expected/components/ExpectedItemPanel/ExpectedItemPanel";
import HelpPanel from "../Help/components/HelpPanel/HelpPanel";
import ParcelDetailedPanel from "../History/components/Parcel/ParcelDetailedPanel";
import WeekItemPanel from "../History/components/Week/WeekItemPanel";
import ChangingAddressPanel from "../Outgoing/components/ChangingAddressPanel/ChangingAddressPanel";
import ConsolidationPanel from "../Outgoing/components/ConsolidationPanel/ConsolidationPanel";
import DeliveryMethodsPanel from "../Outgoing/components/DeliveryMethodsPanel/DeliveryMethodsPanel";
import NotFinishedPanel from "../Outgoing/components/NotFinishedPanel/NotFinishedPanel";
import PackingGradePanel from "../Outgoing/components/PackingGradePanel/PackingGradePanel";
import PayAllPanel from "../Outgoing/components/PayAllPanel/PayAllPanel";
import PaymentDetailsConfirmationPanel from "../Outgoing/components/PaymentDetailsConfirmationPanel/PaymentDetailsConfirmationPanel";
import ProhibitedGoodsPanel from "../Outgoing/components/ProhibitedGoodsPanel/ProhibitedGoodsPanel";
import ReviewAndPayPanel from "../Outgoing/components/ReviewAndPayPanel/ReviewAndPayPanel";
import PackOptions from "../Storage/components/PackOptions/PackOptions";
import ServiceRequest from "../Storage/components/ServiceRequests/ServiceRequest";
import StorageItemInfo from "../Storage/components/StorageItemPanel/StorageItemPanel";

export const PANEL_COMPONENTS = {
  PAY_ALL: PayAllPanel,
  WEEK_ITEM: WeekItemPanel,
  ADDRESS_FORM: AddressForm,
  STORAGE_ITEM: StorageItemInfo,
  NOT_FINISHED: NotFinishedPanel,
  SERVICE_REQUEST: ServiceRequest,
  WAITING_PAYMENT: ConsolidationPanel,
  PACK_OPTIONS: PackOptions,
  IN_PROGRESS: ConsolidationPanel,
  DELIVERY_METHODS: DeliveryMethodsPanel,
  PROHIBITED_FOUND: ProhibitedGoodsPanel,
  CHANGING_ADDRESS: ChangingAddressPanel,
  REVIEW_AND_PAY: ReviewAndPayPanel,
  CONFIRM_PAYMENT_DETAILS: PaymentDetailsConfirmationPanel,
  PARCEL_DETAILED: ParcelDetailedPanel,
  PACKING_GRADE: PackingGradePanel,
  HELP_PANEL: HelpPanel,
  EXPECTED_ITEM: ExpectedItemPanel,
  BOOKS_ADD_FUNDS: BooksAddFundsPanel,
};

export const hasIgnoredClass = (
  element: EventTarget,
  ignoredClass: string,
): boolean => {
  if (!(element instanceof HTMLElement)) return false;

  const classList = element.classList;

  return Array.from(classList).some((c) => c.includes(ignoredClass));
};

export const isInIgnoredElement = (
  element: EventTarget | null,
  ignoredClass: string,
): boolean => {
  let currentElement = element as HTMLElement | null;

  while (currentElement) {
    if (hasIgnoredClass(currentElement, ignoredClass)) {
      return true;
    }
    currentElement = currentElement.parentElement;
  }

  return false;
};
