import React, { FC, useMemo, useState } from "react";
import { ButtonColor, ButtonType, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { FormikProps } from "formik";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ExpectedSearchValues } from "types/ExpectedForms/Search";
import Button from "../../../../components/Button/Button";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import { panelSelectOpened } from "../../../../reduxFolder/selectors/rightPanelSelectors";
import FilterComment from "./Filter/FilterComment";
import FilterOrder from "./Filter/FilterOrder";
import FilterShop from "./Filter/FilterShop";
import {
  AddNewButton,
  ButtonsWrapper,
  CloseButton,
  CloseIcon,
  FilterIcon,
  FiltersWrapper,
  MenuIcon,
  SearchFilterButton,
  SearchWrapper,
} from "./Search.styles";

interface SearchProps {
  isPanelOpened: boolean;
  formOptions: FormikProps<ExpectedSearchValues>;
  onAddNewClick: () => void;
}

const Search: FC<SearchProps> = React.memo(
  ({ formOptions, isPanelOpened, onAddNewClick }) => {
    const { t } = useTranslation("common");
    const {
      values,
      initialValues,
      handleSubmit,
      handleChange,
      setFieldValue,
      setValues,
      dirty,
    } = formOptions;

    const [isOpen, setOpened] = useState(false);

    const filterProps = useMemo(
      () => ({
        values,
        initialValues,
        handleSubmit,
        handleChange,
        setFieldValue,
      }),
      [values, initialValues, handleSubmit, handleChange, setFieldValue],
    );

    const toggleFilterMenu = async () => {
      if (isOpen && dirty) {
        setValues({ ...initialValues, trackingNumber: values.trackingNumber });
        await Promise.resolve();
        handleSubmit();
      }
      setOpened(!isOpen);
    };

    return (
      <>
        <SearchWrapper $isOpen={isOpen} $isPanelOpened={isPanelOpened}>
          <SearchInput<ExpectedSearchValues>
            name="trackingNumber"
            value={values.trackingNumber}
            initialValue={initialValues.trackingNumber}
            placeholder={t("parcels.search.filterByTransactionNumber")}
            {...filterProps}
          />
          <ButtonsWrapper $isOpen={isOpen}>
            {isOpen && (
              <FiltersWrapper>
                <FilterShop {...formOptions} />
                <FilterOrder {...formOptions} />
                <FilterComment {...formOptions} />
              </FiltersWrapper>
            )}
            {isOpen ? (
              <CloseButton
                title={t("common.close")}
                type={ButtonType.Button}
                onClick={toggleFilterMenu}
              >
                <CloseIcon type={IconType.Close} />
              </CloseButton>
            ) : (
              <SearchFilterButton onClick={toggleFilterMenu}>
                <Hidden sm>{t("parcels.search.tools")}</Hidden>
                <Visible sm>
                  <FilterIcon type={IconType.Filter} />
                </Visible>
                <MenuIcon type={IconType.Menu} />
              </SearchFilterButton>
            )}
            <AddNewButton
              variant={ButtonVariant.Filled}
              color={ButtonColor.Secondary}
              onClick={onAddNewClick}
            >
              {t("common.new")}
            </AddNewButton>
          </ButtonsWrapper>
        </SearchWrapper>
      </>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
  }),
);

export default withConnect(Search);
