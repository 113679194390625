import { fromJS, List, Map, updateIn } from 'immutable';
import { combineActions, createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';
import { requestNames } from './serviceRequests';
import { normalizeImages } from './utils/helpers';

export const initialState = fromJS({
  isLoadingDetailedItem: false,
  isLoadingDetailedShipment: false,
  isLoadingUpdateInsurance: false,
  isLoadingCustomsDeclaration: false,
  totalPriceWaitingPayment: 0,
  detailedItem: Map(),
  detailedShipment: Map(),
  tempDetailedItemId: null,
  deliveryMethods: List(),
  deliveryCountry: null,
  waiting_for_payment: { type: 'waiting_for_payment', items: [] },
  waiting_for_information: { type: 'waiting_for_information', items: [] },
  packing_in_progress: { type: 'packing_in_progress', items: [] },
  paid: { type: 'paid', items: [] },
});

// helpers
const convertingCostsToNumbers = (costs = Map()) =>
  costs.map((cost) => {
    const parsedCost = parseFloat(cost);

    return isNaN(parsedCost) ? cost : parsedCost;
  });

const normalizeDetailed = (parcel) => {
  const rootPackage = parcel.deleteAll(['packages', 'items']);

  // assign package_sku for all items
  const items = parcel
    .get('items')
    .map((item) =>
      item.set(
        'package_sku',
        item.getIn(['package', 'sku'], rootPackage.get('sku')),
      ),
    )
    .map((item) => {
      // prepare serviceRequests map for all items
      const serviceRequests = item
        .get('service_requests', List())
        .reduce((requests, request) => {
          const requestType =
            requestNames[
              request.getIn(['service_request_type', 'type']).toLowerCase()
            ];
          const status = request.getIn(['processed_state']);
          return requests.set(requestType, status);
        }, Map());
      return item.set('service_requests_map', serviceRequests);
    });

  // update packages list
  const packages = parcel
    .get('packages', List())
    .unshift(rootPackage)
    .map((pkg) =>
      pkg
        .set(
          'items',
          items.filter((item) => item.get('package_sku') === pkg.get('sku')),
        )
        .update('pictures', normalizeImages),
    );

  return parcel
    .set('packages', packages)
    .set('items', items)
    .update('estimate', (estimate) => convertingCostsToNumbers(estimate))
    .update('consolidation_addons', (addons) =>
      addons.map((addon) => addon.get('id')),
    );
};

// const
export const GET_OUTGOING = 'outgoing/GET_OUTGOING';
export const GET_DETAILED_ITEM = 'outgoing/GET_DETAILED_ITEM';
export const GET_DETAILED_SHIPMENT = 'outgoing/GET_DETAILED_SHIPMENT';
export const GET_CUSTOMS_DECLARATIONS = 'outgoing/GET_CUSTOMS_DECLARATIONS';
export const CREATE_CUSTOM_DECLARATION = 'outgoing/CREATE_CUSTOM_DECLARATION';
export const UPDATE_CUSTOM_DECLARATION = 'outgoing/UPDATE_CUSTOM_DECLARATION';
export const DELETE_CUSTOM_DECLARATION = 'outgoing/DELETE_CUSTOM_DECLARATION';
export const UPDATE_DELIVERY_METHOD = 'outgoing/UPDATE_DELIVERY_METHOD';
export const UPDATE_ADDRESS = 'outgoing/UPDATE_ADDRESS';
export const CANCEL_CONSOLIDATION = 'outgoing/CANCEL_CONSOLIDATION';
export const REMOVE_ITEM = 'outgoing/REMOVE_ITEM';
export const MOVE_ITEM_FROM_WAITING_PAYMENT_TO_PAID =
  'outgoing/MOVE_ITEM_FROM_WAITING_PAYMENT_TO_PAID';
export const UPDATE_REQUIRE_INSURANCE = 'outgoing/UPDATE_REQUIRE_INSURANCE';
export const GET_DELIVERY_METHODS = 'outgoing/GET_DELIVERY_METHODS';
export const UPDATE_COMMENT = 'outgoing/UPDATE_COMMENT';
export const UPDATE_CONSOLIDATION_ADDONS =
  'outgoing/UPDATE_CONSOLIDATION_ADDONS';
export const UPDATE_CONSOLIDATION_OPTIONAL_LINE_ITEMS =
  'outgoing/UPDATE_CONSOLIDATION_OPTIONAL_LINE_ITEMS';
export const REMOVE_ITEM_FROM_CONSOLIDATION =
  'outgoing/REMOVE_ITEM_FROM_CONSOLIDATION';
export const IMPORT_CUSTOMS_DECLARATION = 'outgoing/IMPORT_CUSTOMS_DECLARATION';

// action creators
export const getOutgoing = createAction(`${GET_OUTGOING}_REQUEST`);
export const getOutgoingSuccess = createAction(`${GET_OUTGOING}_SUCCESS`);
export const getOutgoingError = createAction(`${GET_OUTGOING}_ERROR}`);
export const getDetailedItem = createAction(
  GET_DETAILED_ITEM,
  api.outgoing.getDetailedItem,
);
export const getDetailedShipment = createAction(
  GET_DETAILED_SHIPMENT,
  api.shipments.getShipment,
);
export const updateComment = createAction(
  UPDATE_COMMENT,
  api.shipments.updateComment,
);
export const getCustomsDeclarations = createAction(
  GET_CUSTOMS_DECLARATIONS,
  (data) => ({
    promise: api.outgoing.getCustomsDeclarations(data),
    data,
  }),
);
export const createCustomsDeclaration = createAction(
  CREATE_CUSTOM_DECLARATION,
  ({ itemId, data }) => ({
    promise: api.outgoing.createCustomsDeclaration({ itemId, data }),
    data: { itemId },
  }),
);
export const deleteCustomsDeclaration = createAction(
  DELETE_CUSTOM_DECLARATION,
  api.outgoing.deleteCustomsDeclaration,
);
export const updateCustomsDeclaration = createAction(
  UPDATE_CUSTOM_DECLARATION,
  ({ itemId, id, data }) => ({
    promise: api.outgoing.updateCustomsDeclaration({ itemId, id, data }),
    data: { itemId },
  }),
);
export const updateDeliveryMethod = createAction(
  UPDATE_DELIVERY_METHOD,
  api.outgoing.updateDeliveryMethod,
);
export const updateAddress = createAction(
  UPDATE_ADDRESS,
  api.outgoing.updateAddress,
);
export const cancelConsolidation = createAction(
  CANCEL_CONSOLIDATION,
  api.outgoing.cancelConsolidation,
);
export const removePackage = createAction(REMOVE_ITEM);
export const movePackageFromWaitingPaymentToPaid = createAction(
  MOVE_ITEM_FROM_WAITING_PAYMENT_TO_PAID,
);
export const updateRequireInsurance = createAction(
  UPDATE_REQUIRE_INSURANCE,
  api.outgoing.updateRequireInsurance,
);
export const getDeliveryMethods = createAction(
  GET_DELIVERY_METHODS,
  (id, data) => ({
    promise: api.outgoing.getDeliveryMethods(id),
    data,
  }),
);

export const updateConsolidationAddons = createAction(
  UPDATE_CONSOLIDATION_ADDONS,
  api.outgoing.updateConsolidationAddons,
);

export const updateConsolidationOptionalLineItems = createAction(
  UPDATE_CONSOLIDATION_OPTIONAL_LINE_ITEMS,
  (id, data) => ({
    promise: api.outgoing.updateConsolidationOptionalLineItems(id, data),
    data: { consolidationId: id },
  }),
);

export const removeItemFromConsolidation = createAction(
  REMOVE_ITEM_FROM_CONSOLIDATION,
  (consolidationId, id, isCartVariant) => ({
    promise: api.outgoing.removeItemFromConsolidation(
      consolidationId,
      id,
      isCartVariant,
    ),
  }),
  // This will be passed as meta to reducer (including SUCCESS and ERROR actions)
  (consolidationId, id, isCartVariant) => ({
    consolidationId,
    id,
    isCartVariant,
  }),
);

export const importCustomsDeclaration = createAction(
  IMPORT_CUSTOMS_DECLARATION,
  ({ id, file }) => {
    const data = new FormData();
    data.append('customs_declaration_import[file]', file);
    return {
      promise: api.cart.importCustomsDeclaration({ id, data }),
    };
  },
);

const outgoingReducer = handleActions(
  {
    [`${GET_OUTGOING}_REQUEST`]: (state, { payload }) =>
      state.setIn([payload.get('type'), 'isLoading'], true),

    [`${GET_OUTGOING}_SUCCESS`]: (state, { payload }) => {
      const type = payload.get('type');
      const page = payload.get('page');
      const items = payload.get('items');
      const oldItems = state.getIn([type, 'items']);

      const newItemsIds = items.map((item) => item.get('id')).toJS();
      const filteredOldItems = oldItems.filter(
        (item) => !newItemsIds.includes(item.get('id')),
      );

      return state
        .mergeIn([type], payload)
        .setIn([type, 'isLoading'], false)
        .setIn(
          [type, 'items'],
          page === 1 ? items : filteredOldItems.push(...items),
        );
    },

    [`${GET_OUTGOING}_ERROR`]: (state, { payload }) =>
      state.setIn([payload.get('type'), 'isLoading'], false),

    [combineActions(`${GET_DETAILED_ITEM}_REQUEST`)]: (state) =>
      state.set('isLoadingDetailedItem', true),

    /* DETAILED SHIPMENT */
    [`${GET_DETAILED_SHIPMENT}_REQUEST`]: (state) =>
      state.set('isLoadingDetailedShipment', true),

    [`${GET_DETAILED_SHIPMENT}_SUCCESS`]: (state, { payload }) => {
      const data = payload.get('data');

      const normolizedData = data.updateIn(['service_requests'], (requests) =>
        requests.map((request) =>
          request.updateIn(['pictures'], normalizeImages),
        ),
      );
      return state
        .setIn(['detailedShipment', data.get('id')], normolizedData)
        .set('isLoadingDetailedShipment', false);
    },

    [`${GET_DETAILED_SHIPMENT}_ERROR`]: (state) =>
      state.set('isLoadingDetailedShipment', false),

    [`${GET_DETAILED_ITEM}_SUCCESS`]: (state, { payload }) =>
      state
        .setIn(
          ['detailedItem', payload.getIn(['data', 'id'])],
          normalizeDetailed(payload.get('data', Map())),
        )
        .set('isLoadingDetailedItem', false),

    [`${GET_DELIVERY_METHODS}_REQUEST`]: (state) =>
      state.set('isLoadingDetailedItem', true),

    [`${GET_DELIVERY_METHODS}_SUCCESS`]: (state, { payload }) =>
      state
        .set('deliveryMethods', payload.get('data'))
        .set('isLoadingDetailedItem', false),

    [`${GET_DELIVERY_METHODS}_ERROR`]: (state) =>
      state.set('isLoadingDetailedItem', false),

    [`${UPDATE_DELIVERY_METHOD}_SUCCESS`]: (state, { payload }) => {
      const consolidationId = payload.getIn(['data', 'id']);
      const deliveryMethod = payload.getIn(['data', 'preferred_carrier']);
      const estimate = payload.getIn(['data', 'estimate']);
      const waitingForPaymentTotalPrice = payload.getIn(
        ['headers', 'total-waiting-for-payment-price'],
        '0',
      );

      const updateDelivery = (type) => [
        [type, 'items'],
        (items) =>
          items.map((item) => {
            return item.get('id') === consolidationId
              ? item
                  .set('preferred_carrier', deliveryMethod)
                  .set('estimate', estimate)
              : item;
          }),
      ];

      return state
        .updateIn(...updateDelivery('waiting_for_payment'))
        .updateIn(...updateDelivery('waiting_for_information'))
        .updateIn(...updateDelivery('packing_in_progress'))
        .updateIn(...updateDelivery('paid'))
        .setIn(
          ['waiting_for_payment', 'totalPrice'],
          waitingForPaymentTotalPrice,
        )
        .setIn(
          ['detailedItem', consolidationId, 'preferred_carrier'],
          deliveryMethod,
        )
        .setIn(['detailedItem', consolidationId, 'estimate'], estimate)
        .set('isLoadingDetailedItem', false);
    },

    [`${UPDATE_COMMENT}_SUCCESS`]: (state, { payload }) => {
      const id = payload.getIn(['data', 'id']);
      const comment = payload.getIn(['data', 'customer_comment']);

      return state.setIn(['detailedShipment', id, 'customer_comment'], comment);
    },

    [`${UPDATE_ADDRESS}_SUCCESS`]: (state, { payload }) => {
      const data = payload.get('data');

      return state
        .mergeIn(['detailedItem', data.get('id')], data)
        .set('isLoadingDetailedItem', false);
    },

    [`${UPDATE_REQUIRE_INSURANCE}_REQUEST`]: (state) =>
      state.set('isLoadingUpdateInsurance', true),

    [`${UPDATE_REQUIRE_INSURANCE}_SUCCESS`]: (state, { payload }) => {
      const data = convertingCostsToNumbers(payload.get('data', Map()));
      const consolidationId = data.get('consolidation_id');

      return state
        .mergeIn(['detailedItem', consolidationId, 'estimate'], data)
        .updateIn(['waiting_for_payment', 'items'], (items) =>
          items.map((item) =>
            item.get('id') === consolidationId
              ? item.set('estimate', data)
              : item,
          ),
        )
        .set('isLoadingUpdateInsurance', false);
    },

    [combineActions(`${GET_DETAILED_ITEM}_ERROR`)]: (state) =>
      state.set('isLoadingDetailedItem', false),

    /* CUSTOM DECLARATION */
    [`${GET_CUSTOMS_DECLARATIONS}_REQUEST`]: (state, { payload }) =>
      state
        .set('tempDetailedItemId', payload)
        .set('isLoadingCustomsDeclaration', true),

    [`${GET_CUSTOMS_DECLARATIONS}_SUCCESS`]: (state, { payload }) => {
      const itemId = state.get('tempDetailedItemId');
      return state
        .setIn(
          ['detailedItem', itemId, 'customs_declarations'],
          payload.get('data'),
        )
        .set('isLoadingCustomsDeclaration', false);
    },

    [combineActions(
      `${CREATE_CUSTOM_DECLARATION}_REQUEST`,
      `${UPDATE_CUSTOM_DECLARATION}_REQUEST`,
    )]: (state, { payload }) => state.set('tempDetailedItemId', payload.itemId),

    [`${CREATE_CUSTOM_DECLARATION}_SUCCESS`]: (state, { payload }) => {
      const itemId = state.get('tempDetailedItemId');

      return updateIn(
        state,
        ['detailedItem', itemId, 'customs_declarations'],
        (customs_declarations = List()) =>
          customs_declarations.push(payload.get('data')),
      );
    },

    [`${UPDATE_CUSTOM_DECLARATION}_SUCCESS`]: (state, { payload }) => {
      const itemId = state.get('tempDetailedItemId');
      const data = payload.get('data');

      return updateIn(
        state,
        ['detailedItem', itemId, 'customs_declarations'],
        (declarations) =>
          declarations.map((declaration) =>
            declaration.get('id') === data.get('id') ? data : declaration,
          ),
      );
    },

    [`${DELETE_CUSTOM_DECLARATION}_SUCCESS`]: (state, { payload }) => {
      const id = payload.getIn(['data', 'id']);

      return updateIn(state, ['detailedItem'], (detaileItem) =>
        detaileItem.map((shipment) =>
          shipment.update('customs_declarations', (declarations) =>
            declarations.filter((declaration) => declaration.get('id') !== id),
          ),
        ),
      );
    },

    [`${IMPORT_CUSTOMS_DECLARATION}_REQUEST`]: (state) =>
      state.set('isLoadingCustomsDeclaration', true),

    [combineActions(
      `${IMPORT_CUSTOMS_DECLARATION}_SUCCESS`,
      `${IMPORT_CUSTOMS_DECLARATION}_ERROR`,
      `${GET_CUSTOMS_DECLARATIONS}_ERROR`,
    )]: (state) => state.set('isLoadingCustomsDeclaration', false),

    /* CANCEL CONSOLIDATION */
    [combineActions(
      `${CANCEL_CONSOLIDATION}_REQUEST`,
      `${CANCEL_CONSOLIDATION}_ERROR`,
    )]: (state) => state,

    /* UPDATE CONSOLIDATION ADDONS AND LINE TIMES */

    [`${UPDATE_CONSOLIDATION_ADDONS}_SUCCESS`]: (state, { payload }) => {
      const consolidationId = payload.getIn(['data', 'id']);
      const addonsCodes = payload.getIn(['data', 'addons_codes']);
      const estimate = payload.getIn(['data', 'estimate']);

      return state
        .setIn(
          ['detailedItem', consolidationId, 'consolidation_addons'],
          addonsCodes,
        )
        .setIn(['detailedItem', consolidationId, 'estimate'], estimate);
    },

    [`${UPDATE_CONSOLIDATION_OPTIONAL_LINE_ITEMS}_REQUEST`]: (
      state,
      { payload },
    ) => state.set('tempDetailedItemId', payload['consolidationId']),

    [`${UPDATE_CONSOLIDATION_OPTIONAL_LINE_ITEMS}_SUCCESS`]: (
      state,
      { payload },
    ) => {
      let consolidationId = state.get('tempDetailedItemId');
      let updatedLineItemCodes = payload.get('data');

      return state
        .setIn(
          ['detailedItem', consolidationId, 'optional_line_item_codes'],
          updatedLineItemCodes,
        )
        .set('tempDetailedItemId', null);
    },

    [`${UPDATE_CONSOLIDATION_OPTIONAL_LINE_ITEMS}_ERROR`]: (state) =>
      state.set('tempDetailedItemId', null),

    [`${CANCEL_CONSOLIDATION}_SUCCESS`]: (state, { payload }) => {
      const getArgumentsUpdate = (type) => [
        type,
        (data) => {
          const items = data.get('items', List());
          const filteredItems = items.filter(
            (item) =>
              item.get('id') !== payload.getIn(['data', 'consolidation_id']),
          );
          if (filteredItems.size === items.size) return data;

          return data
            .set('items', filteredItems)
            .update('total', (total) => total - 1);
        },
      ];

      return state
        .update(...getArgumentsUpdate('waiting_for_payment'))
        .update(...getArgumentsUpdate('waiting_for_information'))
        .update(...getArgumentsUpdate('packing_in_progress'))
        .update(...getArgumentsUpdate('paid'));
    },

    [REMOVE_ITEM]: (state, { payload }) => {
      const removeItemIn = (type) => [
        [type, 'items'],
        (items) => items.filter((item) => item.get('id') !== payload),
      ];
      return state
        .updateIn(...removeItemIn('waiting_for_payment'))
        .updateIn(...removeItemIn('waiting_for_information'))
        .updateIn(...removeItemIn('packing_in_progress'))
        .updateIn(...removeItemIn('paid'));
    },

    [MOVE_ITEM_FROM_WAITING_PAYMENT_TO_PAID]: (state, { payload }) => {
      const items = state.getIn(['waiting_for_payment', 'items']);
      const itemToAdd = items
        .find((item) => item.get('id') === payload)
        .set('state', 'paid');

      const removeItemIn = (type) => [
        type,
        (data) => {
          const items = data.get('items', List());
          const filteredItems = items.filter(
            (item) => item.get('id') !== payload,
          );
          if (filteredItems.size === items.size) return data;

          return data
            .set('items', filteredItems)
            .update('total', (total) => total - 1)
            .update(
              'totalPrice',
              (totalPrice) =>
                totalPrice - itemToAdd.getIn(['estimate', 'total']).toString(),
            );
        },
      ];

      const addItemIn = (type) => [
        type,
        (data) => {
          const items = data.get('items', List());

          return data
            .set('items', items.push(itemToAdd))
            .update('total', (total) => (parseInt(total) + 1).toString());
        },
      ];

      return state
        .update(...removeItemIn('waiting_for_payment'))
        .update(...addItemIn('paid'))
        .update('detailedItem', (detailedItemMap) =>
          detailedItemMap.delete(payload),
        );
    },

    [`${REMOVE_ITEM_FROM_CONSOLIDATION}_SUCCESS`]: (state, { meta }) => {
      if (meta.isCartVariant) return state;

      const shipmentId = state
        .getIn(['detailedItem', meta.consolidationId, 'items'])
        .find((x) => x.get('id') === meta.id)
        .get('shipment_id');
      const shipmentItem = state
        .getIn(['detailedShipment', shipmentId, 'items'])
        .find((x) => x.get('id') === meta.id);
      const removedItemWeight = shipmentItem.get('weight');

      return state
        .updateIn(['detailedItem', meta.consolidationId, 'items'], (items) =>
          items.filter((item) => item.get('id') !== meta.id),
        )
        .updateIn(['packing_in_progress', 'items'], (items) =>
          items.map((item) =>
            item.get('id') !== meta.consolidationId
              ? item
              : item.update('total_weight', (total) =>
                  total - removedItemWeight > 0 ? total - removedItemWeight : 0,
                ),
          ),
        );
    },
  },
  initialState,
);

export default outgoingReducer;
