import React, { FC, memo, ReactNode } from "react";
import T from "prop-types";
import styled from "styled-components";
import ScrollableContent from "../../../components/ScrollableContent/ScrollableContent";

interface ScrollableFormProps {
  children: ReactNode;
  className?: string;
}

const ScrollableForm: FC<ScrollableFormProps> = ({
  children,
  className = "",
  ...props
}) => (
  <ScrollableWrapper
    className={className}
    autoHeight
    autoHeightMax={285}
    autoHeightMin={50}
    {...props}
  >
    {children}
  </ScrollableWrapper>
);

export default memo(ScrollableForm);

const ScrollableWrapper = styled(ScrollableContent)`
  ${(props) => props.theme.xs`
    &,
    & > div {
      min-height: auto !important;
      max-height: initial !important;
    }
  `}
`;
