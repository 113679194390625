import Icon from "components/Icon/Icon";
import PreLoader from "components/PreLoader/PreLoader";
import styled from "styled-components";

export const Title = styled.span`
  margin-left: 10px;
`;

export const AdditionalTitle = styled.h4`
  margin-bottom: 10px;
`;

export const AddonsForm = styled.form`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
`;

export const Spinner = styled(PreLoader)`
  position: absolute;
`;

export const HelpButton = styled.button`
  display: inline-flex;
  position: absolute;
  margin-left: 5px;
`;

export const HelpIcon = styled(Icon)`
  background-color: ${(props) => props.theme.primary};
  height: 16px;
  width: 16px;
  min-width: 16px;
`;
