import { fadeOut } from "react-animations";
import styled, { keyframes } from "styled-components";
import Button from "../Button/Button";

export const UndoWrapper = styled.div`
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: 0.6s ease ${keyframes`${fadeOut}`};
  animation-delay: 2.5s;
`;

export const UndoText = styled.p`
  color: ${(props) => props.theme.black50};
`;

export const UndoButton = styled(Button)`
  padding: 9px 12px;
`;
