import { initialState } from '../reducers/serviceRequests';
import { peek, selector } from '../selectorHelpers';

export const selectServiceRequests = (state) =>
  state.get('serviceRequests', initialState);

export const requestsSelectServiceTypes = selector(
  selectServiceRequests,
  peek('types'),
);

export const requestsSelectTransferRequests = selector(
  selectServiceRequests,
  peek('transferRequests'),
);
