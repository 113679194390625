import React, { FC } from "react";
import { List } from "immutable";
import SideAddonItem from "../../containers/Delivery/components/SideAddons/SideAddonItem/SideAddonItem";
import {
  getAddonDescription,
  getConsolidationAddonsIconsImage,
} from "../../containers/Delivery/deliveryHelper";
import {
  AddonsItems,
  AddonsWrapper,
  AllAddonsWrapper,
  AllConsolidationItemsWrapper,
  ItemIcon,
  ListItem,
} from "./AddonsSummary.style";

export const AddonsSummaryShowSection = {
  ALL: "ALL",
  ONLY_CONSOLIDATION_OPTIONS: "ONLY_CONSOLIDATION_OPTIONS",
  ONLY_ADDONS: "ONLY_ADDONS",
};

interface AddonsSummaryProps {
  sectionVisibility: string;
  addons?: any;
  items?: any;
  consolidationItems: any;
  consolidationAddons: any;
}

const AddonsSummary: FC<AddonsSummaryProps> = ({
  addons = List(),
  items = List(),
  consolidationAddons,
  consolidationItems,
  sectionVisibility = AddonsSummaryShowSection.ALL,
}) => {
  const showAddons =
    sectionVisibility === AddonsSummaryShowSection.ALL ||
    sectionVisibility === AddonsSummaryShowSection.ONLY_ADDONS;
  const showConsolidation =
    sectionVisibility === AddonsSummaryShowSection.ALL ||
    sectionVisibility === AddonsSummaryShowSection.ONLY_CONSOLIDATION_OPTIONS;

  return (
    <AddonsWrapper>
      <AddonsItems>
        {showAddons && (
          <AllAddonsWrapper>
            {consolidationItems.map((id: string) => (
              <ListItem key={id}>
                <ItemIcon>
                  {getConsolidationAddonsIconsImage(id, consolidationAddons)}
                </ItemIcon>
                {getAddonDescription(id, consolidationAddons)}
              </ListItem>
            ))}
          </AllAddonsWrapper>
        )}
        {showConsolidation && (
          <AllConsolidationItemsWrapper>
            {items.map((item: any) => (
              <SideAddonItem
                key={item.get("id")}
                addons={addons}
                consolidationAddons={consolidationAddons}
                itemAddons={item.get("addons").toJS()}
                cartItem={item}
                showAsCompact
              />
            ))}
          </AllConsolidationItemsWrapper>
        )}
      </AddonsItems>
    </AddonsWrapper>
  );
};

export default React.memo(AddonsSummary);
