import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import stateColor from "./helpers";
import { Indicator, Text, Wrapper } from "./ParcelState.styles";

interface ParcelStateProps {
  state: string;
  showText?: boolean;
}

const ParcelState: FC<ParcelStateProps> = ({ state, showText = true }) => {
  const { t } = useTranslation("common");

  const text = showText ? <Text>{t(`parcels.state.${state}`)}</Text> : null;
  return (
    <Wrapper>
      <Indicator color={stateColor(state)} />
      {text}
    </Wrapper>
  );
};

export default memo(ParcelState);
