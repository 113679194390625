import React, { FC, memo } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface ConditionalRouteProps {
  condition?: boolean;
  redirect?: string;
}

const ConditionalRoute: FC<ConditionalRouteProps> = ({
  condition = true,
  redirect = "",
}) => {
  if (!condition) return <Navigate to={redirect} />;

  return condition ? <Outlet /> : null;
};

export default memo(ConditionalRoute);
