declare global {
  interface Window {
    Intercom?: (command: string, ...args: any[]) => void;
  }
}

interface AuthData {
  uid?: string;
  "access-token"?: string;
  "token-type"?: string;
  client?: string;
  expiry?: string;
}

const AUTH_HEADERS: (keyof AuthData)[] = [
  "uid",
  "access-token",
  "client",
  "token-type",
  "expiry",
];

export function setAuthData(data: Partial<AuthData>): void {
  if (data) {
    AUTH_HEADERS.forEach((key) => {
      if (data[key] !== undefined) {
        localStorage.setItem(key, data[key]!);
      }
    });
  }
}

export function getAuthData(): AuthData {
  const data: AuthData = {};
  AUTH_HEADERS.forEach((key) => {
    const value = localStorage.getItem(key);
    if (value !== null) {
      data[key] = value;
    }
  });
  return data;
}

export function clearAuthData(): void {
  if (window.Intercom) {
    window.Intercom("shutdown");
  }
  AUTH_HEADERS.forEach((key) => localStorage.removeItem(key));
}
