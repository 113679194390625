import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Content,
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { HistoryDetailedParamsDto } from "types/api/history";
import ServiceLogo from "../../../../components/ServiceLogo/ServiceLogo";
import { getDetailedItem } from "../../../../reduxFolder/reducers/historyReducer";
import { authSelectUnits } from "../../../../reduxFolder/selectors/globalSelectors";
import {
  historySelectDetailed,
  historySelectDetailedLoading,
  historySelectPageCount,
} from "../../../../reduxFolder/selectors/historySelector";
import { toastResponseError } from "../../../../utils/responseMessageHelper";
import HistoryPagination from "../HistoryPagination";
import Parcel from "../Parcel/Parcel";
import { Spinner, VertLine, WeekDate } from "./WeekComponents.styles";

interface WeekItemPanelProps {
  isOpen: boolean;
  units: string;
  isDetailedLoading: boolean;
  historyPageCount: number;
  parcels: any;
  panelData: any;
  getDetailedItem: (data: HistoryDetailedParamsDto) => Promise<void>;
}

const WeekItemPanel: FC<WeekItemPanelProps> = React.memo(
  ({
    isOpen,
    panelData,
    parcels,
    units,
    getDetailedItem,
    isDetailedLoading,
    historyPageCount,
  }) => {
    const [page, setPage] = useState(0);
    const isPaginationVisible = historyPageCount > 1;

    const shippingMethod = panelData.get("shippingMethod");
    const foundPackageId = panelData.get("foundPackageId");

    useEffect(() => {
      if (isOpen && !panelData.get("returnPreviousPanel")) {
        getDetailedItem({
          week_number: panelData.get("weekNumber"),
          shipping_method: shippingMethod,
          year: panelData.get("selectedYear"),
          page: page + 1,
        }).catch(toastResponseError);
      }
    }, [isOpen, getDetailedItem, panelData, page]);

    const handleChangePage = useCallback(
      (page: number) => setPage(page),
      [setPage],
    );

    return (
      <ContentWrapper>
        <Spinner isActive={isDetailedLoading} />
        {parcels && !isDetailedLoading && (
          <>
            <Heading $isBordered>
              <ServiceLogo serviceKey={shippingMethod} />
              {shippingMethod}
              <VertLine>|</VertLine>
              <WeekDate>{parcels.getIn([0, "week_range"])}</WeekDate>
            </Heading>
            <Content>
              {parcels.map((parcel: any) => {
                return (
                  <Parcel
                    units={units}
                    parcel={parcel}
                    key={parcel.get("id")}
                    foundPackageId={foundPackageId}
                  />
                );
              })}
              {isPaginationVisible && (
                <HistoryPagination
                  selectedPage={page}
                  onSelect={handleChangePage}
                  pageCount={historyPageCount}
                />
              )}
            </Content>
          </>
        )}
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    parcels: historySelectDetailed,
    isDetailedLoading: historySelectDetailedLoading,
    historyPageCount: historySelectPageCount,
    units: authSelectUnits,
  }),
  { getDetailedItem },
);

export default withConnect(WeekItemPanel);
