import { lruMemoize } from 'reselect';
import { initialState } from '../reducers/storage';
import { peek, selector } from '../selectorHelpers';

export const selectStorage = (state) => state.get('storage', initialState);

export const storageSelectItems = selector(selectStorage, peek('items'));
export const storageSelectTransferringItems = selector(
  selectStorage,
  peek('transferringItems'),
);
export const storageSelectTotalAmount = selector(
  selectStorage,
  peek('totalAmount'),
);
export const storageSelectTotalWeight = selector(
  selectStorage,
  peek('totalWeight'),
);
export const storageSelectLoading = selector(selectStorage, peek('isLoading'));
export const storageSelectDetailedShipment = selector(
  selectStorage,
  peek('detailedShipment'),
);
export const storageSelectDetailedLoading = selector(
  selectStorage,
  peek('isShipmentLoading'),
);
export const storageSelectConsolidationLoading = selector(
  selectStorage,
  peek('isConsolidationLoading'),
);
export const storageSelectSelected = selector(selectStorage, peek('selected'));
export const storageSelectSelectedWeight = selector(
  [storageSelectSelected, storageSelectItems],
  lruMemoize((selected, items) =>
    selected
      .reduce((weight, id) => {
        const item = items.find((i) => i.get('id') === id);
        if (!item || !item.get('weight')) return weight;
        return weight + parseFloat(item.get('weight', 0));
      }, 0)
      .toFixed(2),
  ),
);
export const storageSelectParams = selector(selectStorage, peek('params'));
// vendors
export const storageSelectShopsLoading = selector(
  selectStorage,
  peek('isShopsLoading'),
);
export const storageSelectShops = selector(selectStorage, peek('shops'));
export const storageSelectExpectedShopsLoading = selector(
  selectStorage,
  peek('isExpectedShopsLoading'),
);
export const storageSelectExpectedShops = selector(
  selectStorage,
  peek('expectedShops'),
);
export const storageSelectHasItems = selector(selectStorage, peek('hasItems'));
export const selectedStorageItemId = selector(
  selectStorage,
  peek('selectedStorageItemId'),
);
export const storageSelectInventoryItemsSearch = selector(
  selectStorage,
  peek('inventoryItemsSearch'),
);

export const storageSelectExpectedItems = selector(
  selectStorage,
  peek('expectedItemsSearch'),
);
export const storageSelectExpectedItemsTotalAmount = selector(
  selectStorage,
  peek('expectedItemsTotalAmount'),
);
export const selectedExpectedItemId = selector(
  selectStorage,
  peek('selectedExpectedItemId'),
);
export const selectedExpectedItemCustomsDeclarations = selector(
  [storageSelectExpectedItems, selectedExpectedItemId],
  lruMemoize((expectedItems, selectedExpectedItemId) => {
    const selectedItem = expectedItems.find(
      (x) => x.get('id') === selectedExpectedItemId,
    );
    return selectedItem && selectedItem.get('customs_declarations')
      ? selectedItem.get('customs_declarations')
      : [];
  }),
);
