import React, { FC, useCallback } from "react";
import { Map } from "immutable";
import { Hidden, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { OutgoingComponentCommonProps } from "types/outgoing";
import ParcelState from "../../../../components/ParcelState/ParcelState";
import ServiceLogo from "../../../../components/ServiceLogo/ServiceLogo";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  panelSelectData,
  panelSelectOpened,
} from "../../../../reduxFolder/selectors/rightPanelSelectors";
import { ExcessMarker } from "../../../../styles/outgoingStyles";
import { ParcelRow } from "../../../../styles/parcelsStyles";
import formatter from "../../../../utils/formatter";
import { OutgoingCol, WeightAndPrice } from "./InProgress.styles";

const InProgress: FC<OutgoingComponentCommonProps> = React.memo(
  ({ openRightPanel, parcel, isPanelOpened, panelData, units }) => {
    const { t } = useTranslation("common");
    const deliveryName = parcel.get("preferred_carrier");
    const isSelectedShipment =
      isPanelOpened &&
      panelData &&
      panelData.get("detailedItemID") === parcel.get("id");
    const hasExcessItems = parcel.get("excess_items").size > 0;
    const clickParcel = useCallback(
      () =>
        openRightPanel(
          "IN_PROGRESS",
          Map({
            detailedItemID: parcel.get("id"),
            excessItems: parcel.get("excess_items"),
            returnPreviousPanel: "IN_PROGRESS",
          }),
        ),
      [openRightPanel, parcel],
    );

    const state = parcel.get("state");

    const weight = () => {
      let weight = formatter.roundTo2Decimal(parcel.get("total_weight"));
      const unit = t(`units.${units}.primaryShort`);

      if (state === "paid") {
        const deliveryCost = parcel.getIn(["estimate", "price"], 0);
        return (
          <WeightAndPrice>{`${weight} ${unit}/$ ${deliveryCost}`}</WeightAndPrice>
        );
      } else {
        return `${weight} ${unit} (${t("parcels.brutto")})`;
      }
    };

    return (
      <ParcelRow selected={isSelectedShipment} onClick={clickParcel}>
        <Hidden xs>
          <OutgoingCol sm={2} xl={1}>
            {parcel.get("sku")}
            {hasExcessItems ? <ExcessMarker /> : null}
          </OutgoingCol>
        </Hidden>
        <Visible xs>
          <OutgoingCol xs={3}>
            {parcel.get("sku")}
            <ParcelState state={state} showText={false} />
          </OutgoingCol>
        </Visible>
        <OutgoingCol xs={5} sm={3} xl={2}>
          <ServiceLogo serviceKey={deliveryName} />
          {deliveryName}
        </OutgoingCol>
        <OutgoingCol xs={4} sm={2}>
          {weight()}
        </OutgoingCol>
        <OutgoingCol sm={2}>
          <Hidden xs>
            <ParcelState state={state} />
          </Hidden>
        </OutgoingCol>
      </ParcelRow>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
    panelData: panelSelectData,
  }),
  { openRightPanel },
);

export default withConnect(InProgress);
