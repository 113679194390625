import React, { FC, MouseEvent, useCallback } from "react";
import { Content } from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ItemsReceivedItemDto } from "types/api/items";
import {
  ShipmentsUpdateCommentDto,
  StorageDto,
  StorageResponseDto,
} from "types/api/shipments";
import { CloseRightPanel, OpenRightPanel } from "types/common/rightPanel";
import Button from "../../../../../components/Button/Button";
import Icon, { IconPng } from "../../../../../components/Icon/Icon";
import PackagesList from "../../../../../components/PackagesList/PackagesList";
import ServiceLogo from "../../../../../components/ServiceLogo/ServiceLogo";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import useProgressDelivery from "../../../../../hooks/useProgressDelivery";
import { deleteCart } from "../../../../../reduxFolder/reducers/cart";
import {
  closeRightPanel,
  openRightPanel,
} from "../../../../../reduxFolder/reducers/rightPanel";
import {
  getShipment,
  getStorage,
  updateComment,
  updateItemComment,
} from "../../../../../reduxFolder/reducers/storage";
import { cartSelectCart } from "../../../../../reduxFolder/selectors/cartSelectors";
import { authSelectFfid } from "../../../../../reduxFolder/selectors/globalSelectors";
import {
  servicesSelectConsolidationAddons,
  servicesSelectPackingMethods,
} from "../../../../../reduxFolder/selectors/servicesSelectors";
import {
  ExcessMarker,
  HeaderTitle,
  InnerSection,
  Section,
  SectionContent,
  SectionHeader,
} from "../../../../../styles/outgoingStyles";
import { ChangeButton } from "../../../../../styles/parcelsStyles";
import {
  toastResponseError,
  toastResponseSuccess,
} from "../../../../../utils/responseMessageHelper";
import { getAddonsIconsImage } from "../../../../Delivery/deliveryHelper";
import {
  CustomSenderErrorMsg,
  Footer,
  PanelAddressItem,
  TabMain,
} from "../NotFinishedPanel.styles";
import { indexToTitleMapping } from "./helpers";

interface DetailsPanelProps {
  openRightPanel: OpenRightPanel;
  closeRightPanel: CloseRightPanel;
  panelData: any;
  packingMethods: any;
  consolidationAddons: any;
  cart: any;
  userFfid: string;
  getShipment: (id: number) => Promise<void>;
  getStorage: (data?: StorageDto) => Promise<StorageResponseDto[]>;
  updateComment: (id: number, data: ShipmentsUpdateCommentDto) => Promise<void>;
  onActiveTabChange: (tab: string) => void;
  deleteCart: () => Promise<void>;
  updateItemComment: (id: number, data: ItemsReceivedItemDto) => Promise<void>;
}

const DetailsPanel: FC<DetailsPanelProps> = React.memo(
  ({
    panelData,
    closeRightPanel,
    openRightPanel,
    getShipment,
    getStorage,
    updateComment,
    updateItemComment,
    cart,
    packingMethods,
    userFfid,
    consolidationAddons,
    deleteCart,
    onActiveTabChange,
  }) => {
    const { t } = useTranslation("common");

    const [access, setAccess] = useProgressDelivery(userFfid);

    const sku = cart.get("sku");
    const items = cart.get("items");
    const destinationAddress = cart.get("destination_address");
    const destAddressId = cart.get("destination_address_id");
    const showDestAddressSection =
      (destinationAddress && destinationAddress.get("id")) || destAddressId;
    const delivery = cart.get("preferred_carrier");
    const packingCode = cart.getIn(["optional_line_item_codes", 0]);

    const disableContinueToCustomsData =
      !showDestAddressSection || !delivery || !packingCode;

    const isExcessItems = false;
    const itemsCount = (!!items && items.count()) || 0;
    const localizedTitle = isExcessItems
      ? t("parcels.excessPackagesIncluded")
      : t("parcels.shipments");

    const packing = Map(
      packingMethods.find(
        (packingMethod: any) => packingMethod.get("code") === packingCode,
      ),
    );
    const packingTitle = packing ? (packing.get("title") as string) : "";

    const clickChangingAddress = useCallback(() => {
      openRightPanel(
        "CHANGING_ADDRESS",
        panelData
          .set("returnPreviousPanel", "NOT_FINISHED")
          .set("addressId", cart.get("destination_address_id"))
          .set("isCartVariant", true)
          .set("cart", cart)
          .set("setAccess", () => setAccess("delivery")),
      );
    }, [cart, openRightPanel, panelData, setAccess]);

    const editDeliveryMethod = useCallback(
      () =>
        openRightPanel(
          "DELIVERY_METHODS",
          panelData
            .set("detailedItemID", cart.get("id"))
            .set("returnPreviousPanel", "NOT_FINISHED")
            .set("deliveryCountry", destinationAddress.get("country"))
            .set("deliveryMethod", delivery)
            .set("isCartVariant", true)
            .set("cart", cart)
            .set("setAccess", () => setAccess("packing")),
        ),
      [
        openRightPanel,
        panelData,
        cart,
        destinationAddress,
        delivery,
        setAccess,
      ],
    );

    const editPackingGrade = useCallback(
      () =>
        openRightPanel(
          "PACKING_GRADE",
          panelData
            .set("returnPreviousPanel", "NOT_FINISHED")
            .set("consolidationSku", sku)
            .set("deliveryMethod", delivery)
            .set("packingCode", packingCode)
            .set("packingMethods", packingMethods)
            .set("selectedConsolidationAddons", cart.get("addons_codes"))
            .set("isCartVariant", true)
            .set("cart", cart)
            .set("setAccess", (selectedAddonsIds: string[]) => {
              if (selectedAddonsIds && selectedAddonsIds.length) {
                setAccess("customInformation");
              } else {
                setAccess("addons");
              }
            }),
        ),
      [
        openRightPanel,
        panelData,
        sku,
        delivery,
        packingCode,
        packingMethods,
        cart,
        setAccess,
      ],
    );

    const clickPackagesItem = (item: any) => {
      openRightPanel(
        "STORAGE_ITEM",
        Map({
          readOnly: true,
          overrideAndEnablePackOptions: true,
          originalItem: item,
          id: item.get("shipment_id"),
          detailedItemID: cart.get("id"),
          consolidationSku: sku,
          emblem_thumb_url: item.get("emblem_thumb_url"),
          description: item.get("shipment_tracking_number"),
          returnPreviousPanel: "NOT_FINISHED",
          excessItems: panelData.get("excessItems"),
          rootReturnPanel: "NOT_FINISHED",
          getShipment,
          updateComment,
          updateItemComment,
          parcel: cart,
          isCartVariant: true,
          isItemInCart: true,
        }),
      );
    };

    const handleCancel = useCallback(
      (event: MouseEvent) => {
        event.stopPropagation();
        if (
          window.confirm(t("popup.notFinished.deletionConfirmation")) === true
        ) {
          deleteCart()
            .then(() => {
              closeRightPanel();
              toastResponseSuccess(t("common.deleteCartSuccess"));
              if (panelData.get("openedFromStorage")) {
                getStorage();
              }
            })
            .catch(toastResponseError);
        }
      },
      [deleteCart, closeRightPanel, panelData, getStorage, t],
    );

    const detailsHandleContinue = () => {
      onActiveTabChange(t(indexToTitleMapping[1]));
    };

    return (
      <TabMain>
        <Content>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <Icon type={IconType.Address} /> {t("parcels.shippingAddress")}:
              </HeaderTitle>
              <ChangeButton
                color={ButtonColor.Primary}
                onClick={clickChangingAddress}
              >
                {t("common.change")}
              </ChangeButton>
            </SectionHeader>
            {showDestAddressSection && (
              <PanelAddressItem address={destinationAddress} isHideButtons />
            )}
          </Section>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <IconPng type="turbine" /> {t("parcels.shippingMethod")}:
              </HeaderTitle>
              <ChangeButton
                color={ButtonColor.Primary}
                onClick={editDeliveryMethod}
                disabled={!showDestAddressSection}
              >
                {t("common.change")}
              </ChangeButton>
            </SectionHeader>
            {delivery && (
              <InnerSection>
                <SectionContent>
                  <ServiceLogo serviceKey={delivery} />
                  {delivery}
                </SectionContent>
              </InnerSection>
            )}
          </Section>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <IconPng type="tape" /> {t("parcels.packingGrade")}:
              </HeaderTitle>
              <ChangeButton
                color={ButtonColor.Primary}
                onClick={editPackingGrade}
                disabled={!showDestAddressSection || !delivery}
              >
                {t("common.change")}
              </ChangeButton>
            </SectionHeader>
            {packingCode && (
              <InnerSection>
                <SectionContent>
                  <ServiceLogo serviceKey={packingCode} />
                  {packingTitle}
                  {getAddonsIconsImage(
                    cart.get("addons_codes"),
                    consolidationAddons,
                    18,
                    (x) => x.get("code"),
                  )}
                </SectionContent>
              </InnerSection>
            )}
          </Section>
          <Section>
            <SectionHeader>
              <HeaderTitle>
                <Icon type={IconType.Package} />
                {`${localizedTitle} (${itemsCount}):`}
                {isExcessItems ? <ExcessMarker /> : null}
              </HeaderTitle>
            </SectionHeader>
            <InnerSection>
              <SectionContent>
                <PackagesList
                  extended
                  items={items}
                  foundItemId={panelData.get("foundPackageId", 0)}
                  handleClick={clickPackagesItem}
                />
              </SectionContent>
            </InnerSection>
          </Section>
        </Content>
        <Footer>
          <Button color={ButtonColor.Red} onClick={handleCancel}>
            {t("common.cancelAndDelete")}
          </Button>
          {disableContinueToCustomsData ? (
            <Tooltip
              body={
                <CustomSenderErrorMsg>
                  {t("parcels.notFinishedShowRequiredFieldsError")}
                </CustomSenderErrorMsg>
              }
            >
              <Button
                color={ButtonColor.Primary}
                variant={ButtonVariant.Filled}
                disabled
              >
                {t("common.continue")}
              </Button>
            </Tooltip>
          ) : (
            <Button
              color={ButtonColor.Primary}
              variant={ButtonVariant.Filled}
              onClick={detailsHandleContinue}
            >
              {t("common.continue")}
            </Button>
          )}
        </Footer>
      </TabMain>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    cart: cartSelectCart,
    packingMethods: servicesSelectPackingMethods,
    userFfid: authSelectFfid,
    consolidationAddons: servicesSelectConsolidationAddons,
  }),
  {
    closeRightPanel,
    openRightPanel,
    getShipment,
    updateComment,
    updateItemComment,
    deleteCart,
    getStorage,
  },
);

export default withConnect(DetailsPanel);
