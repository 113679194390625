import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import {
  authSelectCreatedAt,
  authSelectEmail,
  authSelectFfid,
  authSelectFullName,
} from '../../reduxFolder/selectors/globalSelectors';
import { CloseIcon, MessengerIcon } from './IconsComponents/IconsComponents';
import {
  IntercomIconClose,
  IntercomIconOpen,
  IntercomLauncher,
} from './IntercomMessenger.styles';

const IntercomMessenger = (props) => {
  const intercomLauncherRef = useRef(null);
  const intercomConfig = {
    app_id: 'g8pf25uo',
    hide_default_launcher: true,
    custom_launcher_selector: '.intercom-launcher',
  };
  const loggedIn = (user) => user.email || user.user_id;

  useEffect(() => {
    window.intercomSettings = intercomConfig;

    if (!window.Intercom) {
      (function (w, d, id, s, x) {
        function i() {
          i.c(arguments);
        }
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
      })(window, document, intercomConfig.app_id);
    }

    if (window.Intercom) {
      window.Intercom('onShow', () =>
        intercomLauncherRef.current.classList.add('intercom-open'),
      );
      window.Intercom('onHide', () =>
        intercomLauncherRef.current.classList.remove('intercom-open'),
      );
    }

    return () => {
      if (!window.Intercom) return false;

      if (loggedIn(props)) {
        window.Intercom('shutdown');
      }

      delete window.Intercom;
      delete window.intercomSettings;
    };
  }, [intercomLauncherRef.current]);

  useEffect(() => {
    window.intercomSettings = intercomConfig;

    if (window.Intercom) {
      if (!loggedIn(props)) {
        window.Intercom('boot', props);
      } else {
        window.Intercom('boot', props);
      }
    }
  }, [loggedIn, props]);

  return (
    <IntercomLauncher
      className="intercom-launcher"
      href={`mailto:${intercomConfig.app_id}@incoming.intercom.io`}
      ref={intercomLauncherRef}
    >
      <IntercomIconOpen>
        <MessengerIcon />
      </IntercomIconOpen>
      <IntercomIconClose>
        <CloseIcon />
      </IntercomIconClose>
    </IntercomLauncher>
  );
};

const withConnect = connect(
  createStructuredSelector({
    name: authSelectFullName,
    email: authSelectEmail,
    created_at: authSelectCreatedAt,
    user_id: authSelectFfid,
  }),
  null,
);

export default withConnect(IntercomMessenger);
