import { FormikHelpers } from "formik";
import { SetPartnerIdResponseDto } from "types/api/cart";
import { CloseRightPanel } from "types/common/rightPanel";
import { toastResponseError } from "utils/responseMessageHelper";

export const indexToTitleMapping = {
  0: "parcels.details",
  1: "parcels.customsData",
  2: "shipping.summary",
};

interface SummaryPanelValues {
  partnerId: string;
}

export const summaryPanelFormik = (
  allowSearchByLocation: boolean,
  closeRightPanel: CloseRightPanel,
  setPartnerId: (id: string) => Promise<SetPartnerIdResponseDto>,
  consolidateCart: () => Promise<void>,
  dropCart: () => void,
) => ({
  initialValues: { partnerId: "" },

  onSubmit: (
    values: SummaryPanelValues,
    { setSubmitting }: FormikHelpers<SummaryPanelValues>,
  ) => {
    const { partnerId } = values;

    const needToSendPartnerId =
      allowSearchByLocation && partnerId && partnerId.length > 0;
    const setPartnerIdIfNeed = needToSendPartnerId
      ? setPartnerId(partnerId)
      : null;

    Promise.all([setPartnerIdIfNeed, consolidateCart()])
      .then(() => {
        dropCart();
        closeRightPanel();
      })
      .catch((error) => toastResponseError(error))
      .finally(() => setSubmitting(false));
  },
});
