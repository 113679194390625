import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const initialState = fromJS({
  data: null,
  faq: [],
  isLoading: false,
  isFAQLoading: false,
  // KB Helpers
  kbHelpers: [],
  isKbHelpersLoading: false,
});

// const
export const GET_DASHBOARD = 'dashboard/GET_DASHBOARD';
export const GET_FAQ = 'dashboard/FAQ';
export const GET_KB_HELPERS = 'dashboard/KB_HELPERS';

// action creators
export const getDashboard = createAction(
  GET_DASHBOARD,
  api.dashboard.getDashboard,
);
export const getFAQ = createAction(GET_FAQ, ({ currentLocale }) => ({
  promise: api.dashboard.getFAQ({ currentLocale }),
}));
export const getKnowledgeBaseHelpers = createAction(
  GET_KB_HELPERS,
  ({ currentLocale }) => ({
    promise: api.dashboard.getKnowledgeBaseHelpers({ currentLocale }),
  }),
);

// reducer
const dashboardReducer = handleActions(
  {
    [`${GET_DASHBOARD}_REQUEST`]: (state) => state.set('isLoading', true),

    [`${GET_DASHBOARD}_SUCCESS`]: (state, { payload }) =>
      state.set('data', payload.get('data')).set('isLoading', false),

    [`${GET_DASHBOARD}_ERROR`]: (state) => state.set('isLoading', false),

    [`${GET_FAQ}_REQUEST`]: (state) => state.set('isFAQLoading', true),

    [`${GET_FAQ}_SUCCESS`]: (state, { payload }) => {
      return state.set('faq', payload.get('data')).set('isFAQLoading', false);
    },

    [`${GET_FAQ}_ERROR`]: (state) => state.set('isFAQLoading', false),

    // KB Helpers
    [`${GET_KB_HELPERS}_REQUEST`]: (state) =>
      state.set('isKbHelpersLoading', true),

    [`${GET_KB_HELPERS}_SUCCESS`]: (state, { payload }) =>
      state
        .set('kbHelpers', payload.get('data'))
        .set('isKbHelpersLoading', false),

    [`${GET_KB_HELPERS}_ERROR`]: (state) =>
      state.set('isKbHelpersLoading', false),
  },
  initialState,
);

export default dashboardReducer;
