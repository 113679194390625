import { PageWrapper } from "layouts/Layout.styles";
import { Container } from "react-grid-system";
import styled from "styled-components";
import { fadeInLeft, fadeOutRight } from "styles/parcelsStyles";

export const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled(PageWrapper)`
  position: absolute;
  width: 100%;
  animation: none;

  &.page-enter {
    animation: ${fadeInLeft} 0.22s ease;
  }

  &.page-exit {
    animation: ${fadeOutRight} 0.22s ease;
  }
`;

export const StyledGrid = styled(Container)`
  position: relative;
`;
