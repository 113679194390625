import { FormikHelpers } from "formik";
import { OpenRightPanel } from "types/common/rightPanel";
import { ChangingAddressFormValues } from "types/OutgoingForms/changingAddressPanel";
import { toastResponseError } from "utils/responseMessageHelper";
import * as yup from "yup";

export const changingAddressPanelFormik = (
  panelData: any,
  destinations: any,
  updateAddress: (
    id: string,
    data: { destination_address: any },
  ) => Promise<any>,
  setCartAddress: (data: { id: string }) => Promise<any>,
  openRightPanel: OpenRightPanel,
) => ({
  initialValues: {
    addressId: panelData.get("addressId"),
  },
  validationSchema: () =>
    yup.object().shape({
      addressId: yup.string().required(),
    }),
  onSubmit: (
    values: ChangingAddressFormValues,
    { setSubmitting }: FormikHelpers<ChangingAddressFormValues>,
  ) => {
    const isCartVariant = panelData.get("isCartVariant");
    const selectedAddress = destinations.find(
      (destination: any) => destination.get("id") === values.addressId,
    );
    const updateMethod = isCartVariant
      ? setCartAddress({ id: values.addressId })
      : updateAddress(panelData.get("detailedItemID"), {
          destination_address: selectedAddress,
        });

    updateMethod
      .then(() => {
        if (panelData.get("setAccess")) {
          panelData.get("setAccess")();
        }
        openRightPanel(panelData.get("returnPreviousPanel"), panelData);
      })
      .catch(toastResponseError)
      .finally(() => setSubmitting(false));
  },
});
