import React, { FC, memo } from "react";
import styled from "styled-components";

interface LogoProps {
  image: string;
  size?: number;
  className?: string;
}

const Logo: FC<LogoProps> = ({ image, size = 20, className = "" }) =>
  image ? (
    <LogoComponent $image={image} size={size} className={className} />
  ) : null;

export default memo(Logo);

interface LogoComponentProps {
  $image: string;
  size: number;
}

const LogoComponent = styled.div<LogoComponentProps>`
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  min-width: ${(props) => `${props.size}px`};
  background: center no-repeat;
  background-size: cover;
  background-image: ${({ $image }) => `url(${$image})`};
  border-radius: 50%;

  &[disabled] {
    opacity: 30%;
  }
`;
