import React, { FC, MouseEvent, useCallback, useRef, useState } from "react";
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "enums/Button";
import { IconType } from "enums/Icon";
import { useFormik } from "formik";
import {
  ProgressDeliveryAccess,
  ProgressDeliverySetAccessFunc,
} from "hooks/useProgressDelivery";
import { Map } from "immutable";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import {
  SetAddonsMethodDto,
  SetConsolidationAddonsMethodsDto,
} from "types/api/cart";
import { OpenRightPanel } from "types/common/rightPanel";
import AddonsList from "../../../../../components/AddonsList/AddonsList";
import Button from "../../../../../components/Button/Button";
import {
  deleteAddonsMethods,
  setAddonsMethods,
  setConsolidationAddonsMethods,
} from "../../../../../reduxFolder/reducers/cart";
import { openRightPanel } from "../../../../../reduxFolder/reducers/rightPanel";
import {
  cartSelectCart,
  cartSelectCartConsolidationItems,
  cartSelectCartItems,
  cartSelectLoading,
} from "../../../../../reduxFolder/selectors/cartSelectors";
import {
  servicesSelectAddons,
  servicesSelectConsolidationAddons,
} from "../../../../../reduxFolder/selectors/servicesSelectors";
import {
  BlockContent,
  Container,
  Content,
  DeliveryBlock,
  Heading,
  RowWrapper,
  SubmitWrapper,
} from "../../../styles/Delivery.styles";
import ConsolidationAddonsList from "../../ConsolidationAddonsList";
import LeftSideContent from "../../LeftSideContent";
import ScrollableForm from "../../ScrollableForm";
import ServiceRequestsList from "../../ServiceRequestsList/ServiceRequestsList";
import SideAddonsInfo from "../../SideAddons/SideAddonsInfo/SideAddonsInfo";
import { HelpButton, HelpIcon, Spinner } from "./ChooseAdditionalForm.styles";
import { chooseAdditionalFormHelper } from "./chooseAdditionalFormHelper";

export interface ChooseAdditionalFormProps {
  openRightPanel: OpenRightPanel;
  isLoading: boolean;
  addons: any;
  cart: any;
  cartItems: any;
  consolidationAddons: any;
  cartConsolidationItems: any;
  access: ProgressDeliveryAccess;
  setAddonsMethods: (data: SetAddonsMethodDto) => Promise<void>;
  setConsolidationAddonsMethods: (
    data: SetConsolidationAddonsMethodsDto,
  ) => Promise<void>;
  deleteAddonsMethods: () => Promise<void>;
  setAccess: ProgressDeliverySetAccessFunc;
}

const ChooseAdditionalForm: FC<ChooseAdditionalFormProps> = React.memo(
  ({
    cartItems,
    cartConsolidationItems,
    consolidationAddons,
    setAddonsMethods,
    setConsolidationAddonsMethods,
    deleteAddonsMethods,
    addons,
    access,
    setAccess,
    cart,
    isLoading,
    openRightPanel,
  }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const navigateFunc = () => navigate("/shipping/flow/customs-data");

    const { setFieldValue, values, setValues, handleSubmit, isSubmitting } =
      useFormik(
        chooseAdditionalFormHelper(
          t,
          cartItems,
          cartConsolidationItems,
          consolidationAddons,
          addons,
          setAddonsMethods,
          setConsolidationAddonsMethods,
          deleteAddonsMethods,
          setAccess,
          navigateFunc,
        ),
      );

    const formRef = useRef<HTMLFormElement>(null);
    const readyForCustomInformationStep = access.get("customInformation");
    const [isConsolidationAddonsDefined, setIsConsolidationAddonsDefined] =
      useState(readyForCustomInformationStep);

    const handleChangeConsolidationAddonsDefined = (bool: boolean) =>
      setIsConsolidationAddonsDefined(bool);

    const handleActiveItemChange = useCallback(
      (value: string) => {
        setFieldValue("activeItem", value);
      },
      [setFieldValue],
    );

    const handleSelect = useCallback(
      (id: string) => () => {
        const activeItemId = values.activeItem.value;
        const hasAddon = values[activeItemId].includes(id);
        const value = hasAddon
          ? [...values[activeItemId].filter((item: string) => item !== id)]
          : [...values[activeItemId], id];

        setFieldValue(activeItemId, value);
      },
      [values, setFieldValue],
    );

    const isAllSelected = values.activeItem.value === "all";
    const headerTitle = isAllSelected
      ? `${t("shipping.selectAdditional")} ${cart.get("sku")}`
      : `${t("shipping.selectAdditionalShipment")} ${values.activeItem.label}`;

    const handleHelpClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();
        openRightPanel(
          "HELP_PANEL",
          Map({
            preventReLoading: true,
            hideBackButton: true,
            showHelpersCode:
              values.activeItem.value === "all"
                ? "primary_options"
                : "single_options",
          }),
        );
      },
      [openRightPanel, values.activeItem.value],
    );

    return (
      <Container>
        <RowWrapper>
          <LeftSideContent>
            <SideAddonsInfo
              addons={addons}
              values={values}
              cartItems={cartItems}
              activeItem={values.activeItem}
              onActiveItemChange={handleActiveItemChange}
              activeSectionRef={formRef}
            />
          </LeftSideContent>

          <Col lg={4} md={6} sm={12}>
            <Content>
              <DeliveryBlock>
                <form onSubmit={handleSubmit} ref={formRef}>
                  <Heading>
                    {headerTitle}
                    <HelpButton onClick={handleHelpClick}>
                      <HelpIcon type={IconType.Help} />
                    </HelpButton>
                  </Heading>
                  <BlockContent>
                    <Spinner isActive={isLoading} />
                    <ScrollableForm>
                      {values.activeItem.value === "all" ? (
                        <ConsolidationAddonsList
                          values={values}
                          items={cartItems}
                          addons={consolidationAddons}
                          setFormValues={setValues}
                          isConsolidationAddonsDefined={
                            !!isConsolidationAddonsDefined
                          }
                          handleChangeConsolidationAddonsDefined={
                            handleChangeConsolidationAddonsDefined
                          }
                        />
                      ) : (
                        <>
                          <AddonsList
                            addons={addons}
                            values={values}
                            items={cartItems}
                            handleSelect={handleSelect}
                          />
                          <ServiceRequestsList
                            serviceRequests={values.activeItem.serviceRequests}
                          />
                        </>
                      )}
                    </ScrollableForm>
                    <SubmitWrapper>
                      <Button
                        isLoading={isSubmitting}
                        disabled={
                          isSubmitting ||
                          isLoading ||
                          !isConsolidationAddonsDefined
                        }
                        size={ButtonSize.Large}
                        color={ButtonColor.Primary}
                        type={ButtonType.Submit}
                        variant={ButtonVariant.Filled}
                      >
                        {t("common.nextStep")}
                      </Button>
                    </SubmitWrapper>
                  </BlockContent>
                </form>
              </DeliveryBlock>
            </Content>
          </Col>
        </RowWrapper>
      </Container>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    cart: cartSelectCart,
    cartItems: cartSelectCartItems,
    addons: servicesSelectAddons,
    cartConsolidationItems: cartSelectCartConsolidationItems,
    consolidationAddons: servicesSelectConsolidationAddons,
    isLoading: cartSelectLoading,
  }),
  {
    setAddonsMethods,
    deleteAddonsMethods,
    setConsolidationAddonsMethods,
    openRightPanel,
  },
);

export default withConnect(ChooseAdditionalForm);
