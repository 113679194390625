import styled from "styled-components";
import PreLoader from "../PreLoader/PreLoader";

export const LoadingWrapper = styled.div`
  position: absolute;
  height: 100vh;
  height: -webkit-fill-available;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.lightgrey03};
`;

export const Spiner = styled(PreLoader)`
  margin-bottom: 20px;
`;
