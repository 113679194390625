import styled from "styled-components";
import Icon from "../Icon/Icon";
import exclamation from "../Icon/icons/exclamation.svg";
import good from "../Icon/icons/good.svg";

export const ToastBody = styled.div`
  .Toastify__toast {
    font-family: inherit !important;
    border-radius: 130px;
    padding: 12px 22px;
    min-height: 0;
    margin-bottom: 0;

    .icon {
      width: 16px;
      height: 16px;
      transition: opacity-color 0.3s;
      opacity: 0.6;
      background-color: ${({ theme }) => theme.white};

      &:hover {
        opacity: 1;
      }
    }
  }

  .Toastify__toast--info {
    color: ${({ theme }) => theme.black50};
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);

    .icon {
      background-color: ${({ theme }) => theme.black};
    }
  }

  .Toastify__progress-bar--info {
    background-color: ${({ theme }) => theme.black50};
  }

  .Toastify__toast--success,
  .Toastify__toast--error {
    .Toastify__toast-body {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 27px;
      color: ${({ theme }) => theme.white};

      &::before {
        content: "";
        width: 18px;
        height: 18px;
        background-color: #fff;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: cover;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: cover;
        position: absolute;
        left: 0;
      }
    }
  }

  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.secondary};
    box-shadow: 0 3px 10px 0 ${({ theme }) => theme.secondary05};

    .Toastify__toast-body::before {
      -webkit-mask-image: url(${good});
      mask-image: url(${good});
    }
  }

  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.yellow};
    box-shadow: 0 3px 10px 0 ${({ theme }) => theme.yellow05};
  }

  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.red};
    box-shadow: 0 3px 10px 0 ${({ theme }) => theme.red05};

    .Toastify__toast-body::before {
      -webkit-mask-image: url(${exclamation});
      mask-image: url(${exclamation});
    }
  }

  ${(props) => props.theme.xs`
    .Toastify__toast-container {
      padding: 15px;
    }
  `}
`;
