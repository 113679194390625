import React, { FC, MouseEvent, ReactNode } from "react";
import Tooltip from "components/Tooltip/Tooltip";
import { CopyToClipboard as CopyToClipboardLib } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { breakpoints } from "styles/styles";
import { CopyToClipboardButton } from "./CopyToClipboard.styles";

interface CopyToClipboardProps {
  children: ReactNode;
  text: string;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({ children, text }) => {
  const { t } = useTranslation("common");
  const isMobileBreakpoint = +window.innerWidth <= +breakpoints.xs;
  const handleClickCopyToClipboardBtn = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    if (!isMobileBreakpoint) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <Tooltip body={t("common.copiedToClipboard")} openOnClick delayHide={1500}>
      <CopyToClipboardLib text={text}>
        <CopyToClipboardButton onClick={handleClickCopyToClipboardBtn}>
          {children}
        </CopyToClipboardButton>
      </CopyToClipboardLib>
    </Tooltip>
  );
};

export default React.memo(CopyToClipboard);
