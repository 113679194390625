import React, { FC } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { DefaultTheme } from "styled-components";
import {
  fullscreenImageSelectorImagePosition,
  fullscreenImageSelectorImageSource,
  fullscreenImageSelectorIsVisisble,
  fullscreenImageSelectorZoomLevel,
} from "../../reduxFolder/selectors/fullscreenImageSelector";
import {
  ImageContainer,
  Wrapper,
  ZoomableImage,
} from "./FullscreenImage.styles";

interface FullscreenImageProps {
  image: string;
  isVisible: boolean;
  leftOffsetByTheme?: (theme: DefaultTheme) => string[];
  zoomLevel: number;
  relativePosition: { x: number; y: number };
}

const FullscreenImage: FC<FullscreenImageProps> = React.memo(
  ({
    image,
    isVisible,
    relativePosition,
    zoomLevel,
    leftOffsetByTheme = () => ["60px", "80px"],
  }) => {
    if (!isVisible) return null;

    return (
      <Wrapper $leftOffsetByTheme={leftOffsetByTheme}>
        <ImageContainer>
          <ZoomableImage
            src={image}
            position={relativePosition}
            $zoomLevel={zoomLevel}
          />
        </ImageContainer>
      </Wrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    isVisible: fullscreenImageSelectorIsVisisble,
    image: fullscreenImageSelectorImageSource,
    relativePosition: fullscreenImageSelectorImagePosition,
    zoomLevel: fullscreenImageSelectorZoomLevel,
  }),
);

export default withConnect(FullscreenImage);
