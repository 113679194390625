import { List } from 'immutable';
import { initialState } from '../reducers/historyReducer';
import { peek, peekOr, selector } from '../selectorHelpers';

export const historySelect = (state) => state.get('history', initialState);

export const historySelectItems = selector(historySelect, peek('items'));
export const historySelectYear = selector(historySelect, peek('selectedYear'));
export const historySelectDetailed = selector(
  historySelect,
  peekOr(['detailedItem'], List()),
);
export const historySelectDetailedShipment = selector(
  historySelect,
  peek('detailedShipment'),
);
export const historySelectPageCount = selector(
  historySelect,
  peek('historyPageCount'),
);
export const historySelectLoading = selector(historySelect, peek('isLoading'));
export const historySelectDetailedLoading = selector(
  historySelect,
  peek('isDetailedLoading'),
);
export const historySelectDetailedShipmentLoading = selector(
  historySelect,
  peek('isLoadingDetailedShipment'),
);
export const historySelectFullDetailedConsolidation = selector(
  historySelect,
  peek('fullDetailedConsolidation'),
);
