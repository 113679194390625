import React, { FC, memo, useCallback } from "react";
import { HeaderTitle } from "containers/HeaderContainer/HeaderContainer.styles";
import { IconType } from "enums/Icon";
import { ProgressDeliveryAccess } from "hooks/useProgressDelivery";
import { Col, Hidden, Row, Visible } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getLinkToStep } from "../../deliveryHelper";
import {
  BackIcon,
  ButtonMD,
  ButtonXS,
  ConnectedLine,
  HeaderGrid,
  LinkItem,
  StepperProgress,
  StepperWrapper,
} from "./DeliveryHeading.styles";

interface DeliveryHeadingProps {
  access: ProgressDeliveryAccess;
}

const DeliveryHeading: FC<DeliveryHeadingProps> = ({ access }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();

  const isCancelRoute = location.pathname === "/shipping/cancel";

  const handleCancel = useCallback(() => {
    if (!isCancelRoute)
      navigate("/shipping/cancel", { state: { prevPath: location.pathname } });
  }, [isCancelRoute, location.pathname, navigate]);

  return (
    <HeaderGrid fluid>
      <Row align="center">
        <Col xs={2}>
          <ButtonMD onClick={handleCancel}>
            <HeaderTitle>Sk</HeaderTitle>
          </ButtonMD>
          {!isCancelRoute && (
            <ButtonXS onClick={handleCancel}>
              <BackIcon type={IconType.Arrow} />
            </ButtonXS>
          )}
        </Col>

        <Col xs={8}>
          <StepperWrapper>
            <StepperProgress>
              <LinkItem
                passed
                end
                to={{
                  pathname: "/shipping/flow",
                }}
                state={{ prevPath: getLinkToStep(access) }}
              >
                <Hidden sm>{t("account.address")}</Hidden>
                <Visible sm>1</Visible>
              </LinkItem>
              <ConnectedLine filled={!!access.get("delivery")} />
              <LinkItem
                to={{
                  pathname: "/shipping/flow/delivery",
                }}
                state={{ prevPath: getLinkToStep(access) }}
                passed={access.get("customInformation")}
                disabled={!access.get("addons")}
              >
                <Hidden sm>{t("shipping.deliveryAndPacking")}</Hidden>
                <Visible sm>2</Visible>
              </LinkItem>
              <ConnectedLine filled={!!access.get("addons")} />
              <LinkItem
                to={{
                  pathname: "/shipping/flow/customs-data",
                }}
                state={{ prevPath: getLinkToStep(access) }}
                passed={access.get("summary")}
                disabled={!access.get("customInformation")}
              >
                <Hidden sm>{t("shipping.customData")}</Hidden>
                <Visible sm>3</Visible>
              </LinkItem>
            </StepperProgress>
          </StepperWrapper>
        </Col>
      </Row>
    </HeaderGrid>
  );
};

export default memo(DeliveryHeading);
