/**
 * Combine all reducers in this file and export the combined reducers.
 */

// redux

// utils
import { Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
// actions
import auth, { LOG_OUT } from './reducers/auth';
import cart from './reducers/cart';
import destinations from './reducers/destinations';
import fullscreenImage from './reducers/fullscreenImage';
import purchase from './reducers/purchase';
// reducers
import rightPanel from './reducers/rightPanel';
import serviceRequests from './reducers/serviceRequests';
import services from './reducers/services';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const permanentReducers = {
    rightPanel,
    purchase,
    destinations,
    cart,
    services,
    auth,
    serviceRequests,
    fullscreenImage,
  };

  const appReducer = combineReducers({
    ...permanentReducers,
    ...injectedReducers,
  });

  return (state, action) =>
    action.type === `${LOG_OUT}_REQUEST`
      ? appReducer(Map(), action)
      : appReducer(state, action);
}
