import styled from "styled-components";

interface PaginationButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  background: string;
}

const PaginationButton = styled.button<PaginationButtonProps>`
  cursor: pointer;
  color: white;
  background-color: ${(props) => props.background};
  width: 20px;
  height: 20px;
  margin-right: 15px;
  margin-bottom: 5px;

  &:hover {
    background-color: ${(props) => props.background}AA;
  }
`;

export default PaginationButton;
