import React, { FC, MouseEvent, useCallback } from "react";
import { List, Map } from "immutable";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { OpenRightPanel } from "types/common/rightPanel";
import { setSelectedRecipientId } from "../../../../reduxFolder/reducers/books";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import { selectedRecipientId } from "../../../../reduxFolder/selectors/booksSelectors";
import BooksRecipientsItemRow from "./BooksRecipientsItemRow";

interface BooksRecipientsListProps {
  recipientsItems: List<any>;
  disabled: boolean;
  openRightPanel: OpenRightPanel;
  selectedRecipientId: string;
  setSelectedRecipientId: (id: number) => void;
}

const BooksRecipientsList: FC<BooksRecipientsListProps> = React.memo(
  ({
    recipientsItems,
    disabled,
    openRightPanel,
    selectedRecipientId,
    setSelectedRecipientId,
  }) => {
    const editAddress = useCallback(
      (event: MouseEvent, item: any) => {
        setSelectedRecipientId(item.get("id"));
        event.stopPropagation();
        openRightPanel(
          "ADDRESS_FORM",
          Map({
            address: item,
            isEditForm: true,
            isRecipientEditForm: true,
            initialPanelData: item,
            addressID: item.get("id"),
          }),
        );
      },
      [openRightPanel],
    );

    return (
      <Wrapper>
        {recipientsItems.map((item) => (
          <BooksRecipientsItemRow
            item={item}
            key={item}
            disabled={disabled}
            onFocusRecipientItemInPanel={editAddress}
            selectedRecipientId={selectedRecipientId}
          />
        ))}
      </Wrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    selectedRecipientId: selectedRecipientId,
  }),
  {
    openRightPanel,
    setSelectedRecipientId,
  },
);

export default withConnect(BooksRecipientsList);

const Wrapper = styled.div`
  width: 100%;
`;
