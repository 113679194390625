import React, { FC, useState } from "react";
import {
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import {
  AddonsMethodsResponseDto,
  ConsolidationAddonsMethodsResponseDto,
  DeliveryShippingMethodsResponseDto,
  PackingMethodsResponseDto,
} from "types/api/cart";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import useFetchOnceEffect from "../../../../hooks/useFetchOnceEffect";
import injectReducer from "../../../../reduxFolder/injectReducer";
import outgoingReducer from "../../../../reduxFolder/reducers/outgoing";
import {
  getAllDeliveryMethods,
  getConsolidationAddonsMethods,
  getPackingMethods,
} from "../../../../reduxFolder/reducers/services";
import storageReducer from "../../../../reduxFolder/reducers/storage";
import { cartSelectCart } from "../../../../reduxFolder/selectors/cartSelectors";
import {
  servicesSelectAddons,
  servicesSelectConsolidationAddons,
  servicesSelectPackingMethods,
} from "../../../../reduxFolder/selectors/servicesSelectors";
import Tab from "../../../RightPanel/components/Tab";
import TabsView from "../../../RightPanel/components/TabsView";
import CustomsPanel from "./components/CustomsPanel";
import DetailsPanel from "./components/DetailsPanel";
import { indexToTitleMapping } from "./components/helpers";
import SummaryPanel from "./components/SummaryPanel";
import { CustomSenderErrorMsg, Title } from "./NotFinishedPanel.styles";

interface NotFinishedPanelProps {
  panelData: any;
  cart: any;
  packingMethods: any;
  addons: any;
  consolidationAddons: any;
  getPackingMethods: () => Promise<PackingMethodsResponseDto[]>;
  getAddonsMethods: () => Promise<AddonsMethodsResponseDto[]>;
  getConsolidationAddonsMethods: () => Promise<
    ConsolidationAddonsMethodsResponseDto[]
  >;
  getAllDeliveryMethods: () => Promise<DeliveryShippingMethodsResponseDto[]>;
}

const NotFinishedPanel: FC<NotFinishedPanelProps> = React.memo(
  ({
    panelData,
    cart,
    packingMethods,
    addons,
    consolidationAddons,
    getPackingMethods,
    getAddonsMethods,
    getConsolidationAddonsMethods,
    getAllDeliveryMethods,
  }) => {
    const { t } = useTranslation("common");

    const [activeTab, setActiveTab] = useState<string>(
      t(indexToTitleMapping[0]),
    );

    const sku = cart.get("sku");
    const destinationAddress = cart.get("destination_address");
    const destAddressId = cart.get("destination_address_id");
    const showDestAddressSection =
      (destinationAddress && destinationAddress.get("id")) || destAddressId;
    const delivery = cart.get("preferred_carrier");
    const packingCode = cart.getIn(["optional_line_item_codes", 0]);

    const disableContinueToCustomsData =
      !showDestAddressSection || !delivery || !packingCode;

    useFetchOnceEffect(!packingMethods.size, getPackingMethods);
    useFetchOnceEffect(!addons.size, getAddonsMethods);
    useFetchOnceEffect(
      !consolidationAddons.size,
      getConsolidationAddonsMethods,
    );
    useFetchOnceEffect(true, getAllDeliveryMethods);

    const handleSetActiveTab = (tab: string) => {
      setActiveTab(tab);
    };

    const tabItems = [];
    if (
      activeTab === t(indexToTitleMapping[0]) ||
      activeTab === t(indexToTitleMapping[1])
    ) {
      tabItems.push(
        <Tab title={t("parcels.details")}>
          <DetailsPanel
            panelData={panelData}
            onActiveTabChange={handleSetActiveTab}
          />
        </Tab>,
      );
    }
    tabItems.push(
      <Tab
        title={t("parcels.customsData")}
        tabHeader={
          disableContinueToCustomsData ? (
            <Tooltip
              body={
                <CustomSenderErrorMsg>
                  {t("parcels.notFinishedShowRequiredFieldsError")}
                </CustomSenderErrorMsg>
              }
            >
              {t("parcels.customsData")}
            </Tooltip>
          ) : (
            t("parcels.customsData")
          )
        }
        disabled={disableContinueToCustomsData}
      >
        <CustomsPanel
          onActiveTabChange={handleSetActiveTab}
          delivery={delivery}
        />
      </Tab>,
    );
    if (activeTab === t(indexToTitleMapping[2])) {
      tabItems.push(
        <Tab title={t("shipping.summary")}>
          <SummaryPanel onActiveTabChange={handleSetActiveTab} />
        </Tab>,
      );
    }

    return (
      <ContentWrapper>
        <Heading>
          <Title>{sku}</Title>
        </Heading>
        <TabsView openTab={activeTab} onTabChange={setActiveTab}>
          {tabItems}
        </TabsView>
      </ContentWrapper>
    );
  },
);

const withStorageReducer = injectReducer({
  key: "storage",
  reducer: storageReducer,
});

const withOutgoingReducer = injectReducer({
  key: "outgoing",
  reducer: outgoingReducer,
});

const withConnect = connect(
  createStructuredSelector({
    cart: cartSelectCart,
    packingMethods: servicesSelectPackingMethods,
    addons: servicesSelectAddons,
    consolidationAddons: servicesSelectConsolidationAddons,
  }),
  {
    getPackingMethods,
    getConsolidationAddonsMethods,
    getAllDeliveryMethods,
  },
);

const enhance = compose(withStorageReducer, withOutgoingReducer, withConnect);

export default enhance(NotFinishedPanel);
