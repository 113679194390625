import { AddonItem } from "components/AddonsList/AddonsList.styles";
import Icon from "components/Icon/Icon";
import styled from "styled-components";

export const ServiceRequestItem = styled(AddonItem)`
  padding-left: 54px;
`;

export const ServiceRequestIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.secondary};

  ${({ theme }) => theme.sm`
    height: 12px;
    width: 12px;
  `}
`;

export const ServiceRequestText = styled.div`
  line-height: 18px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
