import { RefObject, useEffect } from "react";

export function useClickAwayListener<T extends HTMLElement>(
  refs: RefObject<T>[],
  callback: () => void,
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        refs &&
        refs.length > 0 &&
        !anySectionsContainTarget(refs, event) &&
        callback
      ) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs]);
}

function anySectionsContainTarget<T extends HTMLElement>(
  refs: RefObject<T>[],
  event: MouseEvent,
): boolean {
  for (const ref of refs) {
    if (ref.current && ref.current.contains(event.target as Node)) {
      return true;
    }
  }
  return false;
}
