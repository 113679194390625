import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { BooksAddFundsFormValues } from "types/books";
import * as yup from "yup";
import { englishAndCyrillicOnlyRegEx } from "../../../components/Addresses/addressFormHelper";
import {
  toastResponseError,
  toastResponseSuccess,
} from "../../../utils/responseMessageHelper";

const addFundsFormik = (
  t: TFunction,
  createRechargeBalance: any,
  redirect: () => Response,
  updateAmountFunds: (amount: string) => void,
) => {
  return {
    initialValues: {
      cardDetails: {
        name: "",
        number: "",
        expiration: "",
        cvv: "",
      },
      address: {
        country: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
      },
      amount: 0,
    },

    validationSchema: yup.object({
      cardDetails: yup.object({
        name: yup.string().required(t("error.requiredField")),
        number: yup.string().required(t("error.requiredField")),
        expiration: yup
          .string()
          .matches(
            /^\d{2}\/\d{4}$/,
            t("error.creditCard.wrongExpirationFormat"),
          )
          .required(t("error.requiredField")),
        cvv: yup
          .string()
          .matches(
            /^\d{3,4}$/,
            t("error.numberLonger", {
              field: t("purchase.creditCard.cvv"),
              length: "3-4",
            }),
          )
          .required(t("error.requiredField")),
      }),
      address: yup.object({
        country: yup
          .object()
          .shape({
            label: yup.string().required(t("error.requiredField")),
            value: yup.string().required(t("error.requiredField")),
          })
          .required(t("error.requiredField")),
        city: yup
          .string()
          .matches(
            englishAndCyrillicOnlyRegEx,
            t("error.englishAndCyrillicOnly"),
          )
          .required(t("error.requiredField")),
        address1: yup
          .string()
          .matches(
            englishAndCyrillicOnlyRegEx,
            t("error.englishAndCyrillicOnly"),
          )
          .required(t("error.requiredField")),
        address2: yup
          .string()
          .matches(
            englishAndCyrillicOnlyRegEx,
            t("error.englishAndCyrillicOnly"),
          ),
        state: yup
          .string()
          .matches(
            englishAndCyrillicOnlyRegEx,
            t("error.englishAndCyrillicOnly"),
          ),
        postalCode: yup.string().required(t("error.requiredField")),
      }),
      amount: yup.number(),
    }),
    onSubmit: (
      values: BooksAddFundsFormValues,
      { setSubmitting, resetForm }: FormikHelpers<BooksAddFundsFormValues>,
    ) => {
      const { cardDetails, amount } = values;
      const { name, number, expiration, cvv } = cardDetails;
      const [month, year] = expiration.split("/");
      const cardNumber = number.split(" ").join("");
      const amountValue = +amount;

      const rechargeBalanceDto = {
        credit_card: {
          card_name: name,
          card_number: cardNumber,
          exp_month: month,
          exp_year: year,
          card_code: cvv,
        },
        amount: amountValue,
      };

      setSubmitting(true);

      createRechargeBalance({ data: rechargeBalanceDto })
        .then(() => {
          resetForm();
          updateAmountFunds("");
          toastResponseSuccess(t("books.successfulTransactions", { amount }));
          setSubmitting(false);
          redirect();
        })
        .catch((error: Error) => {
          toastResponseError(error);
          setSubmitting(false);
        })
        .finally(() => setSubmitting(false));
    },
  };
};

export default addFundsFormik;
