import React, { FC, memo } from "react";
import { ButtonColor, ButtonSize, ButtonVariant } from "enums/Button";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button/Button";
import { Wrapper } from "../../../../styles/placeholderStyles";
import { ButtonsWrapper, Message } from "../../styles/BookRecipients.style";

interface BooksSearchPlaceholderProps {
  handleReset: () => void;
}

const BooksSearchPlaceholder: FC<BooksSearchPlaceholderProps> = ({
  handleReset,
}) => {
  const { t } = useTranslation("common");

  return (
    <Wrapper>
      <Message>{t("books.notFoundRecipients")}</Message>
      <ButtonsWrapper>
        <Button
          onClick={handleReset}
          color={ButtonColor.Primary}
          size={ButtonSize.Large}
          variant={ButtonVariant.Filled}
        >
          {t("parcels.search.tryAnotherSearch")}
        </Button>
        <Button
          onClick={handleReset}
          color={ButtonColor.Primary}
          size={ButtonSize.Large}
          variant={ButtonVariant.Bordered}
        >
          {t("parcels.search.dismiss")}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default memo(BooksSearchPlaceholder);
