import { peek, selector } from '../../reduxFolder/selectorHelpers';
import { initialState } from '../reducers/fullscreenImage';

export const selectFullscreenImage = (state) =>
  state.get('fullscreenImage', initialState);

export const fullscreenImageSelectorIsVisisble = selector(
  selectFullscreenImage,
  peek('isVisible'),
);

export const fullscreenImageSelectorImageSource = selector(
  selectFullscreenImage,
  peek('imageSource'),
);

export const fullscreenImageSelectorImagePosition = selector(
  selectFullscreenImage,
  peek('relativePosition'),
);

export const fullscreenImageSelectorZoomLevel = selector(
  selectFullscreenImage,
  peek('zoomLevel'),
);
