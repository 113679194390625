import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { createStructuredSelector } from "reselect";
import { CSSProp } from "styled-components";
import { closeRightPanel } from "../../../reduxFolder/reducers/rightPanel";
import { panelSelectOpened } from "../../../reduxFolder/selectors/rightPanelSelectors";
import {
  SwipeContainer,
  TabsContainerWrapper,
  TabsTitleWrapper,
} from "./RightPanelComponents.styles";
import TabTitle from "./TabTitle";

interface TabsViewProps {
  children: ReactNode[];
  openTab?: string;
  onTabChange?: (title: string) => void;
  tabsViewStyles?: CSSProp;
  isPanelOpened: boolean;
  closeRightPanel: () => void;
}

const isReactElement = (node: ReactNode): node is ReactElement => {
  return React.isValidElement(node);
};

const TabsView: FC<TabsViewProps> = ({
  children: childrenProp,
  openTab = "",
  onTabChange,
  tabsViewStyles,
  isPanelOpened,
  closeRightPanel,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const children = childrenProp.filter(isReactElement);

  const handleSetActiveTab = (newIndex: number) => {
    if (onTabChange) {
      const {
        props: { title },
      } = children[newIndex];
      onTabChange(title);
    }
    setActiveTab(newIndex);
  };

  useEffect(() => {
    const activeTab = children.findIndex(
      ({ props: { title } }) => title === openTab,
    );

    activeTab !== -1 && handleSetActiveTab(activeTab);
  }, [openTab]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (eventDate) => {
      if (children.length - 1 > activeTab) {
        const nextActiveTab = activeTab + 1;
        const {
          props: { disabled },
        } = children[nextActiveTab];
        if (!disabled) {
          handleSetActiveTab(nextActiveTab);
        }
      }

      eventDate.event.stopPropagation();
      eventDate.event.preventDefault();
    },
    onSwipedRight: (eventDate) => {
      if (activeTab > 0) {
        const nextActiveTab = activeTab - 1;
        handleSetActiveTab(nextActiveTab);
      } else {
        if (!isPanelOpened) return;
        closeRightPanel();
      }

      eventDate.event.stopPropagation();
      eventDate.event.preventDefault();
    },
    touchEventOptions: { passive: !isPanelOpened },
  });

  return (
    <TabsContainerWrapper {...swipeHandlers}>
      <TabsTitleWrapper index={activeTab} tabsViewStyles={tabsViewStyles}>
        {children.map(({ props: { title, disabled, tabHeader } }, index) => (
          <TabTitle
            key={index}
            isActive={activeTab === index}
            handleClick={() => {
              if (!disabled) {
                setActiveTab(index);
                handleSetActiveTab(index);
              }
            }}
          >
            {tabHeader ?? title}
          </TabTitle>
        ))}
      </TabsTitleWrapper>
      <SwipeContainer index={activeTab} disabled>
        {children}
      </SwipeContainer>
    </TabsContainerWrapper>
  );
};

const withConnect = connect(
  createStructuredSelector({
    isPanelOpened: panelSelectOpened,
  }),
  { closeRightPanel },
);

export default withConnect(TabsView);
