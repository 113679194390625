import Icon, { IconPng } from "components/Icon/Icon";
import Link from "components/Link/Link";
import PreLoader from "components/PreLoader/PreLoader";
import styled from "styled-components";
import { ParcelCol, ParcelRow } from "styles/parcelsStyles";

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

export const Spinner = styled(PreLoader)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

interface StorageItemRowWrapperProps {
  disabled: boolean;
}

export const StorageItemRowWrapper = styled.div<StorageItemRowWrapperProps>`
  &:not(:first-child) > div {
    border-top: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const StorageCol = styled(ParcelCol)`
  text-align: left;
  line-height: 20px;
  white-space: nowrap;
  user-select: none;

  .tooltip-content-checkbox {
    min-width: 18px;
  }

  &:nth-child(4) > div {
    margin-left: 15px;
  }

  ${(props) => props.theme.lg`
    white-space: normal;
  `}

  ${(props) => props.theme.md`
    white-space: nowrap;
  `}

  ${(props) => props.theme.xs`
    &:nth-child(4) > div {
      margin-left: 10px;
    }
  `}
  
  ${(props) => props.theme.xs`
    margin-bottom: 5px;
  `}
`;

export const MainStorageCol = styled(StorageCol)`
  padding-left: 0 !important;
`;

interface StorageRowProps {
  styled: {
    consolidationId: number | string;
  };
}

export const StorageRow = styled(ParcelRow)<StorageRowProps>`
  ${({ theme }) => theme.range("padding", "0px", "6px")};

  ${(props) => props.theme.xs`
    padding: 5px 0 0;
  `};

  & > ${StorageCol} {
    &:first-child {
      justify-content: ${({ theme }) =>
        theme.xs ? "center" : "space-between"};
    }

    &:last-child {
      flex-wrap: wrap;
      margin-bottom: 0;

      ${({ theme, styled }) =>
        !!styled.consolidationId &&
        theme.xs`
          & > div:nth-child(3) > div {
            margin-left: 0px;
          }
        `}
    }
  }
`;

export const NoCheckboxActionArea = styled.div`
  align-items: center;
  display: flex;
  padding: 15px 10px 15px 15px;
  margin-right: 15px;
`;

export const CheckboxAreaButton = styled.button`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 15px 10px 15px 15px;
  margin-right: 15px;
`;

export const EmptyConsolidationSpacing = styled.div`
  width: 18px;
`;

export const MobileCheckboxAreaButton = styled.button`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 15px;
  margin-right: 8px;
`;

export const MobileCheckboxAreaButton2 = styled(MobileCheckboxAreaButton)`
  padding: 21px 31px;
`;

interface DescriptionProps {
  disabled?: boolean;
}

export const Description = styled.div<DescriptionProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 17px;

  img {
    margin: 0 0 0 10px;

    &:first-child {
      margin-left: 15px;
    }
  }

  ${(props) => props.theme.sm`
    margin-left: 12px;
  `}

  ${(props) => props.theme.xs`
    margin-left: 0;

    img {
      width: 12px;
      height: 12px;
      margin-left: 6px;
    }
  `}
  
  ${({ disabled, theme }) => disabled && `color: ${theme.black50};`}
`;

export const Comment = styled.span`
  font-size: 12px;
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.black50};
`;

export const ParcelDate = styled.div`
  ${(props) => props.theme.md`
    margin-left: 12px;
  `}

  ${(props) =>
    props.theme.xs`
    margin-left: 8px;
  `}
`;

export const RedirectLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 0;
  text-decoration: none;

  span {
    font-weight: 700;
    margin-left: 4px;
  }

  &:hover i {
    opacity: 0.5;
  }

  ${(props) => props.theme.md`
    margin-left: auto;
  `}

  ${(props) => props.theme.sm`
    span:first-child {
      display: none;
    }
  `}

  ${(props) => props.theme.xs`
    padding: 0;

    span {
      margin-left: 0;
    }
  `}
`;

export const RightArrowIcon = styled(Icon)`
  width: 8px;
  height: 14px;
  margin-left: 6px;
  transition: opacity 0.2s;
`;

export const WarningText = styled.div`
  width: 18px;
  height: 22px;
  color: red;
  font-size: 20px;
  text-align: center;
`;

export const TransferIcon = styled(IconPng)`
  position: absolute;
  top: 18%;
  right: 0;
`;
