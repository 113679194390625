import Button from "components/Button/Button";
import styled from "styled-components";
import { HelpIcon } from "styles/outgoingStyles";
import { Currency } from "styles/parcelsStyles";

export const Wrapper = styled.ul`
  width: 100%;
  margin: 10px 0 0 0;
  padding: 0;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 4px;
  }

  i {
    margin-left: 4px;
  }
`;

export const TooltipInfo = styled.div`
  max-width: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;

  a {
    color: inherit;
    width: 100%;
  }
`;

export const DashConnector = styled.div`
  width: 100%;
  margin: 3px 8px;
  border-bottom: 1px dashed ${(props) => props.theme.lightgrey};
`;

export const PriceItem = styled.li`
  display: flex;
  justify-content: space-between;
  list-style: none;
  white-space: nowrap;
  margin-bottom: 15px;

  &:last-child {
    font-size: 16px;
    margin-top: 18px;

    div {
      font-weight: 700;
    }
  }
`;

export const TotalPriceItem = styled(PriceItem)`
  padding-top: 10px;
`;

export const InsuranceCheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InsuranceHelpIcon = styled(HelpIcon)`
  margin-left: 8px;
`;

export const InsuranceConfirmation = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  padding-left: 8px;
  padding-right: 8px;
`;

interface InsuranceButtonProps {
  $isConfirm: boolean;
}

export const InsuranceButton = styled(Button)<InsuranceButtonProps>`
  padding: 0 10px;
  color: ${({ $isConfirm, theme }) =>
    $isConfirm ? theme.primaryLight : theme.red};
`;

export const InsurancePriceWrapper = styled(PriceWrapper)`
  color: ${(props) => props.theme.primaryLight};

  span {
    color: ${(props) => props.theme.primaryLight} !important;
  }
`;

export const Separator = styled.div`
  background-color: ${(props) => props.theme.lightgrey};
  height: 1px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

interface SummaryPriceProps {
  $isHighlighted: boolean;
}

export const SummaryPriceItem = styled(PriceItem)<SummaryPriceProps>`
  justify-content: flex-end;
  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.primaryLight : "inherit"};
`;

export const SummaryPriceWrapper = styled(PriceWrapper)<SummaryPriceProps>`
  min-width: 50px;
  margin-left: 8px;
  justify-content: flex-end;

  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.primaryLight : "inherit"};

  span {
    color: ${({ $isHighlighted, theme }) =>
      $isHighlighted ? theme.primaryLight : "inherit"};
  }
`;

export const SummaryCurrency = styled(Currency)`
  margin-right: 0 !important;
`;
