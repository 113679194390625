import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import PreLoader from "../PreLoader/PreLoader";
import Undo from "../Undo/Undo";

export const SectionHeader = styled.div`
  display: flex;
  align-items: baseline;
`;

export const SectionTitle = styled.h2`
  font-size: 18px;
  margin: 7px 0 18px;
`;

interface AddressItemWrapperProps {
  $isSelected: boolean;
  active?: boolean;
}

export const AddressItemWrapper = styled.div<AddressItemWrapperProps>`
  border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  padding: 20px 20px 13px;
  transition: background-color 0.2s;
  animation: 0.8s ${keyframes`${fadeIn}`};
  cursor: pointer;

  ${({ $isSelected, active, theme }) =>
    ($isSelected && `background-color: ${theme.primaryPale}`) ||
    (active && "background-color: rgba(228, 234, 245, 0.25)")};

  &:last-child {
    border-bottom: none;
  }
`;

export const AddressButtons = styled.div`
  margin-left: auto;
  font-weight: normal;
`;

export const AddressButton = styled(Button)`
  padding: 0;
  font-size: 12px;
  margin-left: 15px;
`;

export const SectionWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;

  ${AddressButtons} {
    position: absolute;
    bottom: 80%;
    right: 0;
  }
`;

export const AddressTitle = styled.div`
  margin: 5px 0;
  font-weight: 700;
  display: flex;
  align-items: center;
  position: relative;

  ${(props) => props.theme.sm`
    margin: 0;
  `}
`;

export const AddressVerifyIcon = styled(Icon)`
  width: 13px;
  height: 16px;
  margin-left: 6px;
  background-color: #8a5bf9;
`;

export const AddressText = styled.p`
  line-height: 24px;
  margin: 7px 0;
`;

export const UndoWrapper = styled(Undo)`
  padding: 18px 0;
`;

export const InfoText = styled.p`
  color: ${({ theme }) => theme.primaryLight};
  margin: 10px auto 22px;
  user-select: none;
`;

export const AddButton = styled(Button)`
  padding: 9px 15px;
`;

export const Spinner = styled(PreLoader)`
  position: absolute;
  width: 20px;
  height: 14px;
  transform-origin: 0 10px;
  transform: scale(0.5);
  top: 15px;
`;

export const AddressesWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50px;
  position: relative;
  border: 1px solid ${(props) => props.theme.lightgrey};
`;

export const SearchWrapper = styled.form`
  position: relative;
  padding: 20px;
  border-bottom: 1px solid ${(props) => props.theme.lightgrey};

  input {
    max-height: 34px;
    height: 34px;
    width: 100%;
    padding: 8px 30px 8px 10px;
  }
`;

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;
