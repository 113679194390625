import { FormikHelpers } from "formik";
import { ProgressDeliverySetAccessFunc } from "hooks/useProgressDelivery";
import { TFunction } from "i18next";
import { Map } from "immutable";
import { toastResponseError } from "utils/responseMessageHelper";

export type СhooseAdditionalFormValues = {
  activeItem: { value: string; label: string; serviceRequests?: any };
  [key: string]: any;
};

export const chooseAdditionalFormHelper = (
  t: TFunction,
  cartItems: any,
  cartConsolidationItems: any,
  consolidationAddons: any,
  addons: any,
  setAddonsMethods: any,
  setConsolidationAddonsMethods: any,
  deleteAddonsMethods: any,
  setAccess: ProgressDeliverySetAccessFunc,
  navigateFunc: () => void,
) => {
  const getInitialValues = () => {
    const activeItem = { value: "all", label: t("shipping.allPackages") };
    const defaultValues = {
      activeItem,
    };

    cartItems.valueSeq().forEach((item: any) => {
      const res: string[] = [];

      cartConsolidationItems.toJS().forEach((id: string) => {
        consolidationAddons.toJS().forEach((el: any) => {
          if (el.code === id) {
            res.push(el.id);
          }
        });
      });

      defaultValues[item.get("id") as keyof typeof defaultValues] = item
        .get("addons")
        .toJS()
        .concat(res);
    });

    return defaultValues;
  };

  const initialValues = getInitialValues();

  return {
    initialValues: initialValues,
    onSubmit: (
      { activeItem, ...items }: СhooseAdditionalFormValues,
      { setSubmitting }: FormikHelpers<СhooseAdditionalFormValues>,
    ) => {
      const allPackagesAddons = consolidationAddons
        .toJS()
        .filter((addon: any) => Object.values(items)[0].includes(addon.code))
        .map((addon: any) => addon.id);

      const itemsAddons = Map(items).map((item) =>
        item.filter((id: string) =>
          addons.map((addon: any) => addon.get("id")).includes(id),
        ),
      );

      Promise.all([
        setConsolidationAddonsMethods({
          addons_ids: allPackagesAddons.length ? allPackagesAddons : null,
        }),
        Object.keys(itemsAddons).length
          ? setAddonsMethods({ items: itemsAddons })
          : deleteAddonsMethods(),
      ])
        .then(() => {
          setAccess("customInformation");
          navigateFunc();
        })
        .catch((err) => toastResponseError(err))
        .finally(() => setSubmitting(false));

      return;
    },
  };
};
