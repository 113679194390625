import React, { FC, useCallback, useState } from "react";
import CustomsDeclarationV2 from "components/CustomDeclaration/CustomsDeclarationV2";
import { ProgressDeliverySetAccessFunc } from "hooks/useProgressDelivery";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { servicesSelectDelivery } from "reduxFolder/selectors/servicesSelectors";
import { createStructuredSelector } from "reselect";
import {
  CustomInformationDto,
  UpdateCustomsDeclarationDto,
} from "types/api/cart";
import useFetchOnceEffect from "../../../../hooks/useFetchOnceEffect";
import {
  createCustomsDeclaration,
  deleteCustomsDeclaration,
  importCustomInformation,
  updateCustomsDeclaration,
} from "../../../../reduxFolder/reducers/cart";
import {
  cartSelectCartDeliveryMethod,
  cartSelectCustomInformation,
  cartSelectLoading,
} from "../../../../reduxFolder/selectors/cartSelectors";
import { USD } from "../../../../styles/formStyles";
import { calculateCustomsDataTotal } from "../../../../utils/customsDataHelpers";
import formatter from "../../../../utils/formatter";
import {
  BlockContent,
  Container,
  Content,
  DeliveryBlock,
  Heading,
  RowWrapper,
} from "../../styles/Delivery.styles";
import RightSideContent from "../RightSideContent";
import { Header, Spinner, Wrapper } from "./CheckCustomsData.styles";

interface CheckCustomsDataProps {
  setAccess: ProgressDeliverySetAccessFunc;
  isLoading: boolean;
  customInformation: any;
  importCustomInformation: () => Promise<void>;
  createCustomsDeclaration: (data: CustomInformationDto) => Promise<void>;
  updateCustomsDeclaration: ({
    id,
    data,
  }: UpdateCustomsDeclarationDto) => Promise<void>;
  deleteCustomsDeclaration: ({ id }: { id: number }) => Promise<void>;
  delivery: any;
  deliveryMethods: any;
}

const CheckCustomsData: FC<CheckCustomsDataProps> = React.memo(
  ({
    customInformation,
    importCustomInformation,
    createCustomsDeclaration,
    updateCustomsDeclaration,
    deleteCustomsDeclaration,
    isLoading,
    setAccess,
    delivery,
    deliveryMethods,
  }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const getTotal = (nameProp: string) =>
      customInformation.reduce(
        (sum: number, information: any) => sum + information.get(nameProp),
        0,
      );
    const [headerData, setHeaderData] = useState({
      size: customInformation.size || 0,
      total: formatter.currency(getTotal("value")),
    });

    const deliveryMethod = deliveryMethods.find(
      (method: any) => method.get("name") === delivery,
    );

    const isShowHSCodeColumn =
      deliveryMethod && deliveryMethod.get("name") === "Berlin Post";

    useFetchOnceEffect(!customInformation.size, importCustomInformation);

    const handleSubmit = useCallback(() => {
      setAccess("summary");
      navigate("/shipping/summary");
    }, [navigate, setAccess]);

    const handleCustomsDataChange = (newValues: object[]) => {
      const [newTotal, newLength] = calculateCustomsDataTotal(newValues);
      setHeaderData({
        size: newLength,
        total: newTotal,
      });
    };

    return (
      <Container>
        <RowWrapper justify="center">
          <Col lg={4} md={6} sm={12} offset={{ md: 2 }}>
            <Content>
              <DeliveryBlock>
                <Wrapper>
                  <Header>
                    <Heading>
                      {`${t("shipping.checkCustomData")}: ${headerData.size} ${t(
                        "shipping.totalRows",
                      )} - $${headerData.total}`}
                      <USD>USD</USD>
                    </Heading>
                  </Header>
                  <BlockContent>
                    <Spinner isActive={isLoading} />
                    <CustomsDeclarationV2
                      declarations={customInformation}
                      create={createCustomsDeclaration}
                      update={updateCustomsDeclaration}
                      remove={deleteCustomsDeclaration}
                      onSubmit={handleSubmit}
                      onCustomsDataChange={handleCustomsDataChange}
                      isWizardStep
                      isShowHSCodeColumn={isShowHSCodeColumn}
                      isHSCodeValidationRequired
                    />
                  </BlockContent>
                </Wrapper>
              </DeliveryBlock>
            </Content>
          </Col>
          <RightSideContent />
        </RowWrapper>
      </Container>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    customInformation: cartSelectCustomInformation,
    isLoading: cartSelectLoading,
    delivery: cartSelectCartDeliveryMethod,
    deliveryMethods: servicesSelectDelivery,
  }),
  {
    importCustomInformation,
    createCustomsDeclaration,
    updateCustomsDeclaration,
    deleteCustomsDeclaration,
  },
);

export default withConnect(CheckCustomsData);
