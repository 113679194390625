import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FormikProps } from "formik";
import useFocus from "hooks/useFocus";
import styled from "styled-components";
import { StorageSearchValues } from "types/StorageForms/Search";
import Input from "../Input/Input";
import { weightStringFromRange } from "./helpers";

interface WeightRangeInputProps {
  name: string;
  value: string;
  placeholder: string;
  handleUnfocus: () => void;
  initialValue?: string | number;
  setFieldValue: FormikProps<StorageSearchValues>["setFieldValue"];
  handleSubmit: FormikProps<StorageSearchValues>["handleSubmit"];
}

const WeightRangeInput: FC<WeightRangeInputProps> = ({
  value,
  name,
  placeholder,
  handleUnfocus,
  setFieldValue,
  handleSubmit,
}) => {
  const { isFocused, onFocus, onBlur } = useFocus();
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    let convertedValue = weightStringFromRange(value);

    setInnerValue(convertedValue);
  }, [value, setInnerValue]);

  const filterByRegex = (str: string, regex: RegExp): string => {
    return (str.match(regex) || []).join("");
  };

  const handleInnerChange = (event: ChangeEvent<HTMLInputElement>) => {
    let str = event.target.value || "";
    let regex = /\d+\.?(\d+)?-?(\d+)?\.?(\d+)?/g;
    let value = filterByRegex(str, regex);

    setInnerValue(value);
    if (!value) {
      applyValue(value, true);
    }
  };

  const applyValue = useCallback(
    (value: string, skipUnfocusFilter = false) => {
      let values = value.split("-");

      if (values.length === 1) {
        if (values[0] === "") {
          setFieldValue(name, null);
        } else {
          const singleValue = parseFloat(values[0]) || 0;
          setFieldValue(name, [singleValue, singleValue]);
        }
      } else {
        let [leftValue, rightValue] = values;
        let leftValueFloat = parseFloat(leftValue) || 0;
        let rightValueFloat = parseFloat(rightValue) || 0;

        let min = Math.min(leftValueFloat, rightValueFloat);
        let max = Math.max(leftValueFloat, rightValueFloat);
        setFieldValue(name, [min, max]);
      }
      handleSubmit();
      if (!skipUnfocusFilter) {
        handleUnfocus();
      }
    },
    [handleSubmit, setFieldValue, handleUnfocus],
  );

  const handleEnterPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && isFocused && innerValue !== value) {
        event.preventDefault();
        applyValue(innerValue);
      }
    },
    [applyValue, innerValue, value, isFocused],
  );

  return (
    <InputWrapper>
      <Input
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={handleEnterPress}
        value={innerValue}
        name={name}
        onChange={handleInnerChange}
        placeholder={placeholder}
        showClearValueButton
      />
    </InputWrapper>
  );
};

export default memo(WeightRangeInput);

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-right: 10px;

  input {
    max-height: 34px;
    height: 34px;
    width: 100%;
    padding: 8px 30px 8px 10px;
  }

  ${(props) => props.theme.sm`
    margin: 0;
  `}
`;
