import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { Input } from "components/Input";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { TextFilterType } from "types/common";
import { TitleWrapper, TooltipContent } from "../Filters.styles";
import { SearchFilter } from "../SearchFilter";

type FilterTextProps<
  TKey extends string,
  T extends { [K in TKey]: TextFilterType },
> = FormikProps<T> & {
  name: TKey;
  title: string;
};

const FilterText = <
  TKey extends string,
  T extends { [K in TKey]: TextFilterType },
>({
  name,
  title: titleProp,
  initialValues,
  setFieldValue,
  values,
  handleSubmit,
}: FilterTextProps<TKey, T>) => {
  const { t } = useTranslation("common");
  const value = values[name];

  const [innerValue, setInnerValue] = useState(value);

  const initialValue = initialValues[name];
  const isSearchTermChanged = !value;
  const title = isSearchTermChanged ? titleProp : value;

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const dropInnerValue = useCallback(() => setInnerValue(value), [value]);

  const applyInnerValue = useCallback(
    () => setFieldValue(name, innerValue),
    [innerValue, setFieldValue],
  );

  const handleInnerChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInnerValue(event.target.value as T[TKey]);
    },
    [],
  );

  const setDefault = useCallback(() => {
    void setFieldValue(name, initialValue);
    dropInnerValue();
  }, [dropInnerValue, initialValue, setFieldValue]);

  return (
    <SearchFilter
      applyInnerValue={applyInnerValue}
      dropInnerValue={dropInnerValue}
      handleSubmit={handleSubmit}
      setDefault={setDefault}
      title={<TitleWrapper>{title}</TitleWrapper>}
      innerTitle={`${t("common.enter")} ${titleProp.toLowerCase()}:`}
      isActive={!isSearchTermChanged}
    >
      <TooltipContent>
        <Input
          value={innerValue}
          onChange={handleInnerChange}
          placeholder={`${titleProp}:`}
        />
      </TooltipContent>
    </SearchFilter>
  );
};

export default memo(FilterText);
