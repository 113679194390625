import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { Map } from "immutable";
import { ExpectedItemsPanelFormValues } from "types/ExpectedForms/ExpectedItemsPanel";
import * as yup from "yup";
import { OpenRightPanel } from "../../../../types/common/rightPanel";
import { toastResponseError } from "../../../../utils/responseMessageHelper";

export const expectedItemsFormik = (
  t: TFunction,
  panelData: any,
  shops: any,
  createExpectedItem: (data: { expected_shipment: any }) => Promise<any>,
  updateExpectedItem: (
    id: any,
    data: { expected_shipment: any },
  ) => Promise<any>,
  openRightPanel: OpenRightPanel,
  setSelectedExpectedItemId: (id: string | null) => void,
) => {
  const getInitialValues = () => {
    const selectedExpectedItem = panelData.get("selectedExpectedItem");
    const values = Map({}).merge(selectedExpectedItem);
    const getValue = (name: string) => (values ? values.get(name, "") : "");

    const shopOptions = shops.map((shop: any) => {
      const name = shop.get("name");
      const image = shop.get("thumb_image_url");
      return { label: name, value: name, image };
    });
    const storeName = shopOptions.find((x: any) => {
      return x.value === getValue("store_name");
    });

    return {
      id: getValue("id") as string,
      trackingNumber: getValue("tracking_number") as string,
      orderNumber: getValue("order_number") as string,
      storeName: storeName ? storeName : null,
      customerComment: getValue("customer_comment") as string,
    };
  };

  const initialValues = getInitialValues();

  return {
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: initialValues,
    validationSchema: () =>
      yup.object().shape({
        trackingNumber: yup
          .string()
          .required(
            t("error.required", { field: t("expectedParcel.trackingNumber") }),
          ),
        orderNumber: yup
          .string()
          .required(
            t("error.required", { field: t("expectedParcel.orderNumber") }),
          ),
        storeName: yup
          .object()
          .required(
            t("error.required", { field: t("expectedParcel.storeName") }),
          ),
        customerComment: yup.string(),
      }),
    onSubmit: (
      values: ExpectedItemsPanelFormValues,
      { resetForm, setSubmitting }: FormikHelpers<ExpectedItemsPanelFormValues>,
    ) => {
      const { id, trackingNumber, orderNumber, storeName, customerComment } =
        values;
      const isEditForm = panelData.get("isEditForm");

      if (isEditForm) {
        updateExpectedItem(id, {
          expected_shipment: {
            tracking_number: trackingNumber,
            store_name: storeName ? storeName.value : "",
            customer_comment: customerComment,
            order_number: orderNumber,
          },
        })
          .catch((response: Error) => toastResponseError(response))
          .finally(() => setSubmitting(false));
      } else {
        createExpectedItem({
          expected_shipment: {
            tracking_number: trackingNumber,
            store_name: storeName ? storeName.value : "",
            customer_comment: customerComment,
            order_number: orderNumber,
          },
        })
          .then((resp) => {
            const parcel = resp.value.get("data");
            setSelectedExpectedItemId(parcel.id);
            openRightPanel(
              "EXPECTED_ITEM",
              Map({
                selectedExpectedItem: parcel,
                isEditForm: true,
                isCustomsDataTab: true,
              }).merge(parcel),
            );
          })
          .catch((response: Error) => toastResponseError(response))
          .finally(() => setSubmitting(false));
      }
    },
  };
};
