import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { LoadingWrapper, Spiner } from "./LoadingPage.styles";

const LoadingPage: FC = () => {
  const { t } = useTranslation("common");
  return (
    <LoadingWrapper>
      <Spiner isActive />
      {`${t("common.loading")}...`}
    </LoadingWrapper>
  );
};

export default LoadingPage;
